import React, { useEffect } from 'react'
import styles from './MainRecipients.module.scss'
import { useLazyGetCampaignsRecipientsQuery } from 'common/APIs/OutreachApi'
import { useAppSelector } from 'state'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { trackEvent } from 'eventConfig'
import RecipientsTable from '../Tables/RecipientsTable'

interface mainRecipientsProp {
  numberOfLine?: number
}
const MainRecipients: React.FC<mainRecipientsProp> = ({ numberOfLine }) => {
  const [refetcGetCampaignsRecipients, { data: campaignsRecipientsData, isLoading }] =
    useLazyGetCampaignsRecipientsQuery()
  const { campaign_filters, campaign_date_filters } = useAppSelector((state) => state.outreachCampaign)
  const { userDetails } = useAuth()
  useEffect(() => {
    const getCampaignsRecipients = async () => {
      await refetcGetCampaignsRecipients()
    }
    getCampaignsRecipients()
    trackEvent('ENTRY_TO_THE_RECIPIENTS_SCREEN')
  }, [])

  const handleCampaignFilters = (data: any) => {
    const filterType = campaign_filters?.type
    const filterValue = campaign_filters?.value
    const fromDate = campaign_date_filters?.from
    const toDate = campaign_date_filters?.to

    let filteredData = data
    if (fromDate && toDate) {
      filteredData = filteredData.filter((item: any) => {
        const campaignDate = new Date(item.send_after)
        return campaignDate >= fromDate && campaignDate <= toDate
      })
    }

    if (!filterType || !filterValue || filterValue?.length === 0 || !data) {
      return filteredData
    }

    switch (filterType) {
      case 'Senders':
        return filteredData.filter((item: any) => {
          const currentCampaignSenders = userDetails?.outreach
            ?.filter((item: any) => filterValue?.includes(item.id))
            ?.map((item: any) => item.area_code + item.number)
          return currentCampaignSenders.includes(`+${item.sent_from}`)
        })
      case 'Campaign Name':
        return filteredData.filter((item: any) => filterValue.includes(item.campaign_id))
      case 'Last Step':
        return filteredData.filter((item: any) => filterValue.includes(item.last_step))
      case 'Replied':
        const arr = filterValue.map((value: any) => value === 'Replied')
        return filteredData.filter((item: any) => arr.includes(item.is_replied))
      default:
        return filteredData
    }
  }

  return (
    <div className={styles.table}>
      {/* {isLoading ? <LoadingBox /> : <RecipientsTable data={campaignsRecipientsData?.response || []} />} */}
      <RecipientsTable
        data={campaignsRecipientsData?.response ? handleCampaignFilters(campaignsRecipientsData?.response) : []}
        isLoading={isLoading}
        numberOfLine={numberOfLine}
      />
      {/* {isLoading ? <Skeleton active /> : <RecipientsTable data={campaignsRecipientsData?.response || []} />} */}
    </div>
  )
}

export default MainRecipients

import React from 'react'

import { CommonServices } from 'LEGACY/common/services/common-services'

import styles from './percentage-line.module.scss'
import { Col, Row } from 'antd'

import classNames from 'classnames'

interface IPercentageBlocks {
  data: any
}
const PercentageBlocks: React.FC<IPercentageBlocks> = ({ data }) => {
  let arrData = []
  if (data) {
    arrData = CommonServices.addColorCode(JSON.parse(data))
  }

  return (
    <div className={styles.percentageLine}>
      <div className={styles.line}>
        {arrData
          .filter((item: any) => item.percent > 0)
          .map((element: any, index: number) => {
            return (
              <div
                key={index}
                className={styles.cell}
                style={{
                  width: element.percent ? element.percent : arrData.length / 100 + '%',
                  background: CommonServices.handleBarCollor(index, arrData.length),
                }}>
                ...
              </div>
            )
          })}
      </div>

      <div className={styles.legend}>
        {arrData
          .filter((item: any) => item.percent > 0)
          .map((element: any, index: number) => {
            return (
              <>
                <Row gutter={16} className='rowCustom'>
                  <Col span={18}>
                    <p key={index} className={styles.item}>
                      <span
                        className={styles.bullet}
                        style={{
                          background: CommonServices.handleBarCollor(index, arrData.length),
                        }}></span>
                      {element.chain.charAt(0).toUpperCase() + element.chain.slice(1)}
                    </p>
                  </Col>
                  <Col span={6}>
                    <p key={index} className={classNames(styles.item, 'alignRight')}>
                      {`${element.percent}%`}
                    </p>
                  </Col>
                </Row>
              </>
            )
          })}
      </div>
    </div>
  )
}

export default PercentageBlocks

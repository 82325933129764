import { Card, Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import GenerateABLink from './GenerateABLink'
import ActiveABLink from './ActiveABLink'
import Title from 'antd/es/typography/Title'

const ABLinkManagement: React.FC = () => {
  return (
    <Card style={{ padding: '0px 10px', height: '100%' }}>
      <Title level={3} style={{ margin: 0 }}>
        A/B Link Management
      </Title>

      <Tabs defaultActiveKey='1'>
        <TabPane tab='Generate A/B Link' key='1'>
          <GenerateABLink />
        </TabPane>
        <TabPane tab='Manage Active A/B Test' key='2'>
          <ActiveABLink />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default ABLinkManagement

import React, { useEffect, useState } from 'react'
import styles from './ConvrtTabs.module.scss'
import { Tabs } from 'antd'
import company_icon_purple from 'LEGACY/Assets/svg/company_icon.svg'
import company_icon_black from 'LEGACY/Assets/svg/export_companies.svg'
import contact_icon_black from 'LEGACY/Assets/svg/export_contacts.svg'
import contact_icon_purple from 'LEGACY/Assets/svg/export_contacts_purple.svg'

import type { TabsProps } from 'antd'
import { useNavigate } from 'react-router-dom'

interface ConvrtTabsProps {
  activeTab: string
}

const ConvrtTabs: React.FC<ConvrtTabsProps> = ({ activeTab }) => {
  const [currentTab, setCurrentTab] = useState(activeTab)
  const [companiesIcon, setCompaniesIcon] = useState(
    activeTab === 'companies' ? company_icon_purple : company_icon_black,
  )
  const [contactsIcon, setContactsIcon] = useState(activeTab === 'contacts' ? contact_icon_purple : contact_icon_black)
  const navigate = useNavigate()

  const onChange = (key: string) => {
    if (key === 'contacts') {
      setCompaniesIcon(company_icon_black)
      setContactsIcon(contact_icon_purple)
      navigate('/contacts')
    } else {
      setContactsIcon(contact_icon_black)
      setCompaniesIcon(company_icon_purple)
      navigate('/companies')
    }
  }

  useEffect(() => {
    setCurrentTab(activeTab)
    if (activeTab === 'contacts') {
      setCompaniesIcon(company_icon_black)
      setContactsIcon(contact_icon_purple)
    } else {
      setContactsIcon(contact_icon_black)
      setCompaniesIcon(company_icon_purple)
    }
  }, [activeTab])

  const items: TabsProps['items'] = [
    {
      key: 'companies',
      label: (
        <div className={styles.tabs}>
          <img src={companiesIcon} alt='' />
          <span>Companies</span>
        </div>
      ),
    },
    {
      key: 'contacts',
      label: (
        <div className={styles.tabs}>
          <img src={contactsIcon} alt='' />
          <span>Prospects</span>
        </div>
      ),
    },
  ]

  return (
    <div className={styles.header}>
      <Tabs
        className='convrtTabs'
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        items={items}
        onChange={onChange}
      />
    </div>
  )
}
export default ConvrtTabs

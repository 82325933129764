import { useAppDispatch, useAppSelector } from 'state'
import lead_icon1 from 'common/assets/svg/facebook_icon.svg'
import lead_icon2 from 'common/assets/svg/lead_icon2.svg'
import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'
import { Col, Popconfirm } from 'antd'
import { useEffect, useState } from 'react'
import { useDisclaimer } from 'common/hooks/useDisclaimer'
import { ConfirmBox } from 'common/constants/modal.constants'
import OutOfPointsUseRemaining from 'common/components/GeneralComponents/Modals/OutOfPoints/OutOfPointsUseRemaining'
import { useLazyExposeCompanyPlatformQuery, useLazyExposeTempLeadPlatformQuery } from 'common/APIs/OutreachApi'
import {
  REPLACE_SELECTED_LEADS,
  SET_INITIAL_AI_COMPANIES_DATA,
} from 'common/components/Campaigns/state/outreachAICampaignSlice'

interface ChannelsProps {
  text: string
  data: any
}
export function CompanyLeadChannels({ text, data }: ChannelsProps) {
  const { active_platforms, userPoints, initial_ai_companies_data, selected_leads } = useAppSelector(
    (state) => state.outreachAICampaign,
  )

  const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()
  const [exposeCompanyPlatform] = useLazyExposeCompanyPlatformQuery()

  const [isMagic, setIsMagic] = useState(false)
  const [costPoints, setCostPoints] = useState(0)
  const [toExpose, setToExpose] = useState(false)
  const [exposeTempLead] = useLazyExposeTempLeadPlatformQuery()

  const [outOfPoints, setOutOfPoints] = useState(false)
  const dispatch = useAppDispatch()
  const style = 'grayscale(100%)'
  const retryWithRemainingPoints = () => {
    setOutOfPoints(false)
  }
  useEffect(() => {
    const expose = async () => {
      await exposeTempLead({
        lead: selected_leads.filter((l: any) => l.index === data.index)[0],
        platform: [data.platform],
      })
    }
    if (toExpose) {
      expose()
      setToExpose(false)
    }
  }, [toExpose])
  const socialMediaIcons = [
    {
      platform: 'facebook',
      url: active_platforms?.['facebook'] === true && data?.facebook !== null ? data?.facebook : '',
      icon: lead_icon1,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'instagram',
      url:
        active_platforms?.['instagram'] === true && data.instagram !== null && data.instagram !== ''
          ? `${data.instagram}`
          : '',
      icon: lead_icon2,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'telegram',
      url: active_platforms?.['telegram'] === true && data.telegram !== null ? data.telegram : '',
      icon: lead_icon3,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'linkedin',
      url: active_platforms?.['linkedin'] === true && data.linkedin !== null ? data.linkedin : '',
      icon: lead_icon4,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'discord',
      url: active_platforms?.['discord'] === true && data.discord !== null ? data.discord : '',
      icon: lead_icon5,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'twitter',
      url: active_platforms?.['twitter'] === true && data.twitter !== null ? data.twitter : '',
      icon: lead_icon6,
      index: data.index,
      source: data.source,
    },
  ]
  const updateInitialAiCompaniesData = (newVal: any) => {
    let oldData = initial_ai_companies_data || []
    if (oldData.data) {
      oldData = oldData.data
    }
    // Find the index of the object to replace
    const index = oldData?.findIndex((item: any) => item.id === newVal.id)

    // If the object is found, replace it; otherwise, just add the new object
    let updatedData
    if (index !== -1) {
      updatedData = [...oldData.slice(0, index), newVal, ...oldData.slice(index + 1)]
    } else {
      updatedData = [...oldData, newVal]
    }

    // Dispatch the updated array to the global state
    dispatch(SET_INITIAL_AI_COMPANIES_DATA({ ...(initial_ai_companies_data || []), data: updatedData }))

    const selectedLeadsIndex = selected_leads.findIndex((item: any) => item.key === newVal.id)
    if (selectedLeadsIndex !== -1) {
      const updatedItem = {
        ...selected_leads[selectedLeadsIndex],
        instagram: newVal.instagram,
        linkedin: newVal.linkedin,
        facebook: newVal.facebook,
      }
      dispatch(
        REPLACE_SELECTED_LEADS([
          ...selected_leads.slice(0, selectedLeadsIndex),
          updatedItem,
          ...selected_leads.slice(selectedLeadsIndex + 1),
        ]),
      )
    }
  }
  return (
    <div className='img_group'>
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
          isMagic={isMagic}
          isShowUseRemainingPointsButton={false}
        />
      )}
      {socialMediaIcons.map(({ platform, url, icon, index, source }) => {
        return (
          <>
            {url.length === 0 && source !== 'CSV' ? (
              <Popconfirm
                title={`Get ${platform.charAt(0).toUpperCase() + platform.slice(1)} Details`}
                description={
                  <Col>
                    <div>{`This action will cost you ${
                      userPoints?.pointsStore?.[`business_magic_${platform}`]
                    } Magic point. Read disclaimer:`}</div>
                    {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                    {showDisclaimer ? (
                      <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                        {ConfirmBox.DISCLAIMER}
                      </p>
                    ) : (
                      <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                        {'Disclaimer'}
                      </span>
                    )}
                  </Col>
                }
                onCancel={() => {
                  toggleShowDisclaimer && toggleShowDisclaimer()
                  setShowDisclaimer(false)
                }}
                onConfirm={async () => {
                  toggleShowDisclaimer && toggleShowDisclaimer()
                  setShowDisclaimer(false)
                  const dataExposePlatform = await exposeCompanyPlatform({
                    index: index,
                    platform: platform,
                    isBusiness: true,
                  })
                  if (dataExposePlatform?.error?.status === 470 && setCostPoints && setOutOfPoints && setIsMagic) {
                    if (dataExposePlatform?.error?.data?.type === 'magic_points') {
                      setIsMagic(true)
                    } else {
                      setIsMagic(false)
                    }
                    setCostPoints(dataExposePlatform?.error?.data?.cost_point)
                    setOutOfPoints(true)
                  } else {
                    const data = dataExposePlatform?.data?.leads_index?.[0]?.[platform]
                    if (!data) return
                    setToExpose(true)
                    window.open(data, '_blank')
                    // Update the initial_ai_companies_data with the exposed url
                    updateInitialAiCompaniesData &&
                      updateInitialAiCompaniesData(dataExposePlatform?.data?.leads_index?.[0])
                  }
                }}
                disabled={!active_platforms[platform] ? true : false}>
                <img
                  key={platform}
                  style={{
                    filter: style,
                    opacity: active_platforms[platform] ? 1 : 0.2, //!url ? 0.2 : 1,
                    cursor: 'pointer', //url ? 'pointer' : 'default',
                    height: '20px',
                    width: '20px',
                  }}
                  src={icon}
                  alt={platform}
                />
              </Popconfirm>
            ) : (
              <img
                key={platform}
                onClick={() => url && window.open(url, '_blank')}
                style={{
                  filter: !url ? style : '',
                  opacity: !url ? 0.2 : 1,
                  cursor: url ? 'pointer' : 'default',
                  height: '20px',
                  width: '20px',
                }}
                src={icon}
                alt={platform}
              />
            )}
          </>
        )
      })}
    </div>
  )
}

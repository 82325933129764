import styles from '../CreateCampaign/UI/Groups/GroupCard.module.scss'
import telegram_icon from 'common/assets/svg/tele_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import twitter_icon from 'common/assets/svg/xtwit_icon.svg'
import page from 'common/assets/png/page.png'
import search from 'common/assets/png/Search.png'
import groupsBanner from 'common/assets/svg/GroupsBanner.svg'
import hashtagBanner from 'common/assets/svg/HashtagBanner.svg'
import pageBanner from 'common/assets/svg/pageBanner.svg'
import conferences_badge from 'common/assets/svg/conferences_badge.svg'
import searchBanner from 'common/assets/svg/SearchBanner.svg'
import groupFallback from 'common/assets/svg/add_leads_group.svg'
import hashtag from 'common/assets/png/hashtag.png'

interface GroupData {
  name: string
  username: string
  leads: number
  platform: string
  locked: boolean
  description: string
  tags: string[]
  img_url: string
  progress?: any
  number_leads_request?: any
  trackMode?: any
  collected_leads?: any
  number_of_requested_leads?: any
  status?: any
  source_type: any
  type?: string
  cumulative_leads_fetched?: any
  latest_request?: any
  title?: any
  user_icp_id?: any
  icp_id?: any
  mine?: any
  status_code?: any
  location?: any
  query?: any
  outreach_username?: any
  is_list?: any
  is_web3Event?: boolean
  is_web3Group?: boolean
  contact_count?: any
  telegram_count?: any
  linkedin_count?: any
  company_count?: any
  created_at?: any
  country?: any
  city?: any
  url?: any
}

export interface GroupCardProps {
  group: GroupData
  onSelect: any
  setSelectedGroupName: any
  myGroupsTab?: boolean
  refinement: any
  isSelected?: boolean
  onboarding?: boolean
  pointsStore?: any
  onLinkedinCollect?: any
  icpData?: any
  importData?: any
  setImportData?: any
  goToProgress?: (type?: any) => void
  goToMyHub?: any
  handleFacebookShouldJoin?: any
  isSmallScreen?: any
}

export const getTypeMyHub = (group: any) => {
  if (group.is_list) {
    return {
      icon: groupFallback,
      text: 'List',
    }
  }
  if (group.is_web3Event) {
    return {
      icon: groupFallback,
      text: 'Event',
    }
  }
  if (group.platform === 'facebook') {
    return {
      icon: groupFallback,
      text: 'Group',
    }
  }
  if (group.platform === 'linkedin') {
    return {
      icon: search,
      text: 'Search',
    }
  }
  if (group.source_type === 'hashtag') {
    return {
      icon: hashtag,
      text: 'Hashtag',
    }
  }
  if (group.platform === 'instagram') {
    return {
      icon: page,
      text: 'Page',
    }
  } else {
    return {
      icon: groupFallback,
      text: 'Group',
    }
  }
}

export const getType = (group: any) => {
  const groupTypeBanner = (
    <>
      <div className={styles.groupTypeBanner}>
        <img src={groupsBanner} alt='group banner' />
        <span>Groups</span>
      </div>
    </>
  )
  const searchTypeBanner = (
    <>
      <div className={styles.searchTypeBanner}>
        <img src={searchBanner} alt='search banner' />
        <span>Search</span>
      </div>
    </>
  )
  const hashtagTypeBanner = (
    <>
      <div className={styles.hashtagTypeBanner}>
        <img src={hashtagBanner} alt='hashtag banner' />
        <span>Hashtag</span>
      </div>
    </>
  )
  const pageTypeBanner = (
    <>
      <div className={styles.pageTypeBanner}>
        <img src={pageBanner} alt='page banner' />
        <span>Page Followers</span>
      </div>
    </>
  )
  const eventTypeBanner = (
    <>
      <div className={styles.pageTypeConference}>
        <img src={conferences_badge} alt='event banner' />
        <span>Conferences</span>
      </div>
    </>
  )
  if (group.platform === 'facebook') {
    return groupTypeBanner
  }
  if (group.platform === 'linkedin') {
    return searchTypeBanner //search
  }
  if (group.source_type === 'hashtag') {
    return hashtagTypeBanner // hashtag
  }
  if (group.platform === 'instagram') {
    return pageTypeBanner //page
  } else if (group.platform === 'telegram') {
    if (group?.is_web3Event) return eventTypeBanner
    return groupTypeBanner
  } else {
    return groupTypeBanner
  }
}

export const getLogoByPlatform = (platform: any) => {
  const platformLowerCase = platform?.toLocaleLowerCase()
  if (!platformLowerCase) {
    console.log('group.platform is not defined')
    return ''
  }
  switch (platformLowerCase) {
    case 'instagram':
      return instagram_icon
    case 'facebook':
      return facebook_icon
    case 'linkedin':
      return linkedin_icon
    case 'twitter':
      return twitter_icon
    case 'discord':
      return discord_icon
    case 'telegram':
      return telegram_icon
    default:
      return ''
  }
}

export function formatNumber(num: number) {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000)?.toFixed(1)?.replace(/\.0$/, '') + 'B'
  }
  if (num >= 1_000_000) {
    return (num / 1_000_000)?.toFixed(1)?.replace(/\.0$/, '') + 'M'
  }
  if (num >= 1_000) {
    return (num / 1_000)?.toFixed(1)?.replace(/\.0$/, '') + 'k'
  }
  return num?.toString()
}

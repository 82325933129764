import React, { useEffect, useState } from 'react'
import { Space, Collapse, Select, Divider } from 'antd'
// import type { CheckboxProps } from 'antd'
import styles from './LeadsFilter.module.scss'
// import { countriesWithPhonePrefix } from 'common/static-data/countires'
// import AccuracyData from './AccuracyData'
// import SourceData from './SourceData'
// import ChannelData from './ChannelData'
import { useAppSelector } from 'state'
import { trackEvent } from 'eventConfig'
import Sider from 'antd/es/layout/Sider'
const { Panel } = Collapse
const tryParse = (jsonString: string) => {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return null
  }
}
interface LeasFilterProps {
  onFilter: (filters: any) => void
  clearFilters: boolean
  data: any
}

const siderStyle: React.CSSProperties = {
  border: '1px solid #dfe1e6',
  borderRadius: '10px',
  background: 'white',
  padding: '15px',
  overflowX: 'hidden',
  overflowY: 'auto',
}

const ListsFilter: React.FC<LeasFilterProps> = ({ onFilter, clearFilters, data }) => {
  const { ai_leads_type } = useAppSelector((state) => state.outreachAICampaign)
  const showFilter = ai_leads_type !== 'companies_leads'
  const { active_platforms, campaign_id } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatformsArray = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const [selectedFilters, setSelectedFilters] = useState<any>({
    company: [],
    location: [],
    jobTitle: [],
    tags: [],
    accounts: [],
  })
  const [companyOptions, setCompanyOptions] = useState<any[]>([])
  const [locationOptions, setLocationOptions] = useState<any[]>([])
  const [tagsOptions, setTagsOptions] = useState<any[]>([])
  const [jobTitleOptions, setJobTitleOptions] = useState<any[]>([])
  const [verticalOptions, setVerticalOptions] = useState<any[]>([])
  const accountsOptions = [
    {
      label: 'Telegram',
      value: 'telegram',
    },
    {
      label: 'LinkedIn',
      value: 'linkedin',
    },
  ]
  const [maxHeight, setMaxHeight] = useState('')

  const handleFilterSelectChange = (filterType: string, selectedValues: any[]) => {
    setSelectedFilters((prevFilters: any) => ({
      ...prevFilters,
      [filterType]: selectedValues,
    }))
  }

  const filterOption: any = (inputValue: any, option: any) => {
    if (!option) return false

    return option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
  }

  const handleSaveFilters = () => {
    trackEvent('ADD_LEADS_TO_CAMPAIGN_AI_FILTERS', { selectedFilters })
    trackEvent('ADD_LEADS_TO_CAMPAIGN', {
      from_where: 'Convrt AI Filters',
      leads_type: ai_leads_type,
      date: new Date(),
      social_platform: truePlatformsArray[0],
      campaign_id: campaign_id,
    })
    onFilter(selectedFilters)
  }

  const handleClearFilters = () => {
    const resetFilters = {
      company: [],
      location: [],
      jobTitle: [],
      tags: [],
      vertical: [],
      accounts: [],
    }
    setSelectedFilters(resetFilters)
    onFilter(resetFilters)
  }

  useEffect(() => {
    if (data) {
      // Generate unique options dynamically from data
      const uniqueLocations = Array.from(new Set(data?.map((lead: any) => lead.location)))
        ?.map((location) => ({
          value: location,
          label: location,
        }))
        ?.filter((x) => x.value)
      const uniqueJobTitles = Array.from(new Set(data?.map((lead: any) => lead.job_title)))
        ?.map((jobTitle) => ({
          value: jobTitle,
          label: jobTitle,
        }))
        ?.filter((x) => x.value)
      const uniqueCompanies = Array.from(new Set(data?.map((lead: any) => lead.company_name)))
        ?.map((company) => ({
          value: company,
          label: company,
          key: company,
        }))
        ?.filter((x) => x.value)

      let uniqueTags: any[] = []
      try {
        uniqueTags = Array.from(
          Array.from(
            new Set(
              data?.flatMap((lead: any) => {
                try {
                  // Ensure tags is a non-empty string before parsing
                  const tags = lead.tags && lead.tags.trim() !== '' ? JSON.parse(lead.tags) : []
                  return Array.isArray(tags)
                    ? tags.map((tag: any) => ({
                        value: tag.value,
                        label: tag.value, // Use tag.value for both value and label
                      }))
                    : [] // Return empty array if tags is not an array
                } catch (e) {
                  return [] // Ignore parsing errors
                }
              }),
            ),
          ),
        )
          .filter((tag: any) => tag.value) // Filter out null or undefined values
          .reduce((acc: any[], tag: any) => {
            // Deduplicate by the 'value' property
            if (!acc.some((t) => t.value === tag.value)) {
              acc.push(tag)
            }
            return acc
          }, [])
      } catch (e) {
        uniqueTags = []
        console.error(e)
      }
      const uniqueVerticals = Array.from(new Set(data?.map((lead: any) => tryParse(lead.vertical)?.[0] || null)))
        ?.map((vertical) => ({
          value: vertical,
          label: vertical,
        }))
        ?.filter((x) => x.value)
      setCompanyOptions(uniqueCompanies)
      setLocationOptions(uniqueLocations)
      setJobTitleOptions(uniqueJobTitles)
      setTagsOptions(uniqueTags)
      setVerticalOptions(uniqueVerticals)
    }
  }, [data])

  useEffect(() => {
    if (clearFilters) {
      handleClearFilters()
    }
  }, [clearFilters])
  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 1300) {
        setMaxHeight('545px')
      }
      if (window.innerHeight < 950) {
        setMaxHeight('484px')
      }
      if (window.innerHeight < 900) {
        setMaxHeight('423px')
      }
      if (window.innerHeight < 800) {
        setMaxHeight('362px')
      }
      if (window.innerHeight < 775) {
        setMaxHeight('301px')
      }
      if (window.innerHeight < 675) {
        setMaxHeight('240px')
      }
      if (window.innerHeight < 625) {
        setMaxHeight('179px')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth])

  return (
    <Sider style={{ ...siderStyle, maxHeight }} width={250} className={`filterGroupsSider fullHeightFilters`}>
      <div className='LeadsFilterModal noPadding'>
        <div className={styles.header}>
          <p>Filters</p>
          <Space>
            {/* <span style={{ fontWeight: 600, color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
            Cancel
          </span> */}
            <span onClick={handleClearFilters} style={{ fontWeight: 600, color: '#7043ff', cursor: 'pointer' }}>
              Clear
            </span>
            <Divider type='vertical' style={{ margin: 0 }} />
            <span onClick={handleSaveFilters} style={{ fontWeight: 600, color: '#7043ff', cursor: 'pointer' }}>
              Apply Filters
            </span>
          </Space>
        </div>
        <Collapse className='FilterCollapse' accordion>
          <Panel header='Company' key='1'>
            <div className='filter-item'>
              <Select
                maxTagCount={3}
                className='tagsList'
                size={'middle'}
                style={{ width: '100%' }}
                placeholder='Choose Company'
                mode={'multiple'}
                showSearch={true}
                filterOption={filterOption}
                value={selectedFilters.company}
                options={companyOptions}
                onChange={(selectedValues) => handleFilterSelectChange('company', selectedValues)}
              />
            </div>
          </Panel>
          <Panel header='Location' key='2'>
            <div className='filter-item'>
              <Select
                maxTagCount={3}
                className='tagsList'
                size={'middle'}
                style={{ width: '100%' }}
                placeholder='Choose Location'
                mode={'multiple'}
                showSearch={true}
                filterOption={filterOption}
                value={selectedFilters.location}
                options={locationOptions}
                onChange={(selectedValues) => handleFilterSelectChange('location', selectedValues)}
              />
            </div>
          </Panel>

          {showFilter && (
            <Panel header='Job Title' key='5'>
              <div className='filter-item'>
                <Select
                  maxTagCount={3}
                  showSearch
                  className='tagsList'
                  mode={'multiple'}
                  size={'middle'}
                  style={{ width: '100%' }}
                  placeholder='Search to Select'
                  optionFilterProp='children'
                  filterOption={filterOption}
                  value={selectedFilters.jobTitle}
                  options={jobTitleOptions}
                  onChange={(selectedValues) => handleFilterSelectChange('jobTitle', selectedValues)}
                />
              </div>
            </Panel>
          )}
          <Panel header='Vertical' key='6'>
            <div className='filter-item'>
              <Select
                maxTagCount={3}
                showSearch
                className='tagsList'
                mode={'multiple'}
                size={'middle'}
                style={{ width: '100%' }}
                placeholder='Search to Select'
                optionFilterProp='children'
                filterOption={filterOption}
                value={selectedFilters.vertical}
                options={verticalOptions}
                onChange={(selectedValues) => handleFilterSelectChange('vertical', selectedValues)}
              />
            </div>
          </Panel>
          <Panel header='Tags' key='7'>
            <div className='filter-item'>
              <Select
                maxTagCount={3}
                showSearch
                className='tagsList'
                mode={'multiple'}
                size={'middle'}
                style={{ width: '100%' }}
                placeholder='Search to Select'
                optionFilterProp='children'
                filterOption={filterOption}
                value={selectedFilters.tags}
                options={tagsOptions}
                onChange={(selectedValues) => handleFilterSelectChange('tags', selectedValues)}
              />
            </div>
          </Panel>
          <Panel header='Social Accounts' key='8'>
            <div className='filter-item'>
              <Select
                maxTagCount={3}
                showSearch
                className='tagsList'
                mode={'multiple'}
                size={'middle'}
                style={{ width: '100%' }}
                placeholder='Search to Select'
                optionFilterProp='children'
                filterOption={filterOption}
                value={selectedFilters.accounts}
                options={accountsOptions}
                onChange={(selectedValues) => handleFilterSelectChange('accounts', selectedValues)}
              />
            </div>
          </Panel>
        </Collapse>
      </div>
    </Sider>
  )
}

export default ListsFilter

import React, { useEffect, useState } from 'react'
import { Handle, Position } from 'reactflow'
import styles from './DraggableNode.module.scss'
import step_like_post from 'common/assets/svg/step_like_post.svg'
import step_comment from 'common/assets/svg/step_comment.svg'
import step_view_story from 'common/assets/svg/step_view_story.svg'
import step_send_message from 'common/assets/svg/step_send_message.svg'
import ai_responder_icon from 'common/assets/svg/ai_responder_icon.svg'
import crown from 'common/assets/svg/crown_icon.svg'
// import condition_is_message_replied from 'common/assets/svg/condition_is_message_replied.svg'
import condition_wait from 'common/assets/svg/condition_wait.svg'
import condition_question_mark from 'common/assets/svg/condition_question_mark.svg'
// import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import facebook_logo from 'common/assets/svg/facebook_logo.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
// import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'
import linkedin_logo from 'common/assets/svg/linkedin_logo.svg'
// import twitter_icon from 'common/assets/svg/twitter_logo.svg'
// import discord_icon from 'common/assets/svg/discord_logo.svg'
import lead_respond from 'common/assets/svg/lead_respond.svg'
import lead_view_dm from 'common/assets/svg/lead_view_dm.svg'
import stop_response from 'common/assets/svg/stop_response.svg'
import connect_lead_icon from 'common/assets/svg/connect_lead_icon.svg'
import general_condition_icon from 'common/assets/svg/general_condition_icon.svg'
import { useAppSelector } from 'state'
import { useLocalStorage } from 'usehooks-ts'
import { Tooltip } from 'antd'

interface DraggableNodeProps {
  type: string
  // onDragStart: (event: React.DragEvent, nodeType: string) => void
}

const DraggableNode: React.FC<DraggableNodeProps> = ({ type }) => {
  const [user] = useLocalStorage<any>('user', null)
  const addons = user?.addons
  const ai_responder_node = addons?.ai_responder_node

  const { updated_builder_data } = useAppSelector((state) => state.CampaignBuilder)
  const { active_platforms, flow_data, prompt } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const { nodes } = updated_builder_data
  const [disableNode, setDisableNode] = useState(false)

  const getImageByType = (type: string) => {
    switch (type) {
      case 'send_message':
        return step_send_message
      case 'ai_responder':
        return ai_responder_icon
      case 'is_message_replied':
        return general_condition_icon
      case 'is_wait':
        return condition_wait
      case 'view_story':
        return step_view_story
      case 'like_post':
        return step_like_post
      case 'lead_profiles':
        return condition_question_mark
      case 'comment_on_photo':
        return step_comment
      case 'comment_on_post':
        return step_comment
      case 'comment_on_story':
        return step_comment
      case 'lead_respond':
        return lead_respond
      case 'stop_response':
        return stop_response
      case 'follow_lead':
        return connect_lead_icon
      case 'connect_lead':
        return connect_lead_icon
      case 'connect_lead_with_note':
        return connect_lead_icon
      case 'disconnect_lead':
        return connect_lead_icon
      case 'follow_x_friends':
        return stop_response
      case 'unfollow_lead':
        return connect_lead_icon
      case 'lead_view_dm':
        return lead_view_dm
      case 'is_public':
        return step_view_story
      case 'lead_accepted':
      case 'lead_accepted_connect':
      case 'lead_accepted_follow':
        return general_condition_icon
      case 'if_open':
        return general_condition_icon
      default:
        return ''
    }
  }

  const getTextByType = (type: string, subType: any) => {
    switch (type) {
      case 'send_message':
        return (
          <div className={styles.text}>
            <p>Send Message</p>
            <span className={styles.truncatedText}>Directly message the lead.</span>
          </div>
        )
      case 'ai_responder':
        return (
          <div className={styles.text}>
            <div className={styles.flexDiv}>
              <p>AI Responder</p>
              {!ai_responder_node && (
                <Tooltip title={!ai_responder_node ? 'Premium' : ''}>
                  <img src={crown} alt='' />
                </Tooltip>
              )}
            </div>
            <span className={styles.truncatedText}>Your AI SDR.</span>
          </div>
        )
      case 'is_message_replied':
        return (
          <div className={styles.text}>
            <p>If Replied</p>
            <span>Check if the lead replied.</span>
          </div>
        )
      case 'is_wait':
        return (
          <div className={styles.text}>
            <p>Wait</p>
            <span>Pause before proceeding to the next step.</span>
          </div>
        )
      case 'view_story':
        return (
          <div className={styles.text}>
            <p>View Story</p>
          </div>
        )
      case 'like_post':
        return (
          <div className={styles.text}>
            <p>Like a Post</p>
            <span>Engage with the lead's posts.</span>
          </div>
        )

      case 'lead_profiles':
        return (
          <div className={styles.text}>
            <p>Lead Profiles</p>
            <span>
              <span style={{ color: '#97A0AF' }}>Is</span> Public and Relevant
            </span>
          </div>
        )
      case 'comment_on_photo':
        return (
          <div className={styles.text}>
            <p>Comment on a Photo</p>
          </div>
        )
      case 'comment_on_post':
        return (
          <div className={styles.text}>
            <p>Comment on a Post</p>
            <span>Leave a comment on lead's posts.</span>
          </div>
        )
      case 'comment_on_story':
        return (
          <div className={styles.text}>
            <p>Comment on a Story</p>
          </div>
        )
      case 'lead_view_dm':
        return (
          <div className={styles.text}>
            <p>Lead Viewed the DM</p>
          </div>
        )
      case 'lead_respond':
        return (
          <div className={styles.text}>
            <p>Lead Respond</p>
          </div>
        )
      case 'stop_response':
        return (
          <div className={styles.text}>
            <p>Stop Response</p>
          </div>
        )
      case 'is_public':
        return (
          <div className={styles.text}>
            <p>Lead is Public</p>
          </div>
        )
      case 'follow_lead':
        return (
          <div className={styles.text}>
            <p>Follow</p>
            <span>Follow the lead's profile.</span>
          </div>
        )
      case 'connect_lead_with_note':
        return (
          <div className={styles.text}>
            <p>Connect With a Note</p>
            <span>Connect with lead's profile.</span>
          </div>
        )
      case 'connect_lead':
        return (
          <div className={styles.text}>
            <p>Connect</p>
            <span>Connect with lead's profile.</span>
          </div>
        )
      case 'disconnect_lead':
        return (
          <div className={styles.text}>
            <p>Disconnect</p>
            <span>Disconnect with lead's profile.</span>
          </div>
        )
      case 'follow_x_friends':
        return (
          <div className={styles.text}>
            <p>Follow Lead's Friends</p>
          </div>
        )
      case 'unfollow_lead':
        return (
          <div className={styles.text}>
            <p>Unfollow</p>
            <span>Stop following the lead.</span>
          </div>
        )
      case 'lead_accepted':
      case 'lead_accepted_follow':
      case 'lead_accepted_connect':
        return (
          <div className={styles.text}>
            <p>If Accepted</p>
            <span>Check if the lead accepted.</span>
          </div>
        )
      case 'if_open':
        return (
          <div className={styles.text}>
            <p>If Open</p>
            <span>Description...</span>
          </div>
        )
      default:
        return ''
    }
  }

  const getChannels = (type: string) => {
    const allPlatforms = <span className={styles.allPlatforms}>All Platforms</span>
    const fil = (
      <div className={styles.channels}>
        <img src={facebook_logo} alt='' />
        <img src={instagram_icon} alt='' />
        <img src={linkedin_logo} alt='' />
      </div>
    )
    const fl = (
      <div className={styles.channels}>
        <img src={facebook_logo} alt='' />
        <img src={linkedin_logo} alt='' />
      </div>
    )
    const l = (
      <div className={styles.channels}>
        <img src={linkedin_logo} alt='' />
      </div>
    )
    switch (type) {
      case 'send_message':
      case 'ai_responder':
        return allPlatforms
      case 'like_post':
        return fil
      case 'comment_on_post':
        return fil
      case 'follow_lead':
        return fil
      case 'connect_lead':
        return fl
      case 'connect_lead_with_note':
        return l
      case 'disconnect_lead':
        return fl
      case 'unfollow_lead':
        return fil
      default:
        return <></>
    }
  }

  const image = getImageByType(type)
  const text = getTextByType(type, type)
  const channels = getChannels(type)

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }

  const irrelevantTypes: any = {
    send_message: [],
    ai_responder: [],
    like_post: ['twitter', 'discord', 'telegram'],
    comment_on_post: ['twitter', 'discord', 'telegram'],
    follow_lead: ['twitter', 'discord', 'telegram'],
    connect_lead_with_note: ['instagram', 'twitter', 'discord', 'telegram', 'facebook'],
    connect_lead: ['instagram', 'twitter', 'discord', 'telegram'],
    disconnect_lead: ['instagram', 'twitter', 'discord', 'telegram'],
    unfollow_lead: ['twitter', 'discord', 'telegram'],
  }

  useEffect(() => {
    const shouldDisableNode = () => {
      if (flow_data?.nodes?.length > 0) {
        return true
      }
      if (truePlatforms.includes('telegram')) {
        if (type === 'send_message') {
          return false
        } else if (type === 'ai_responder') {
          // pass
        } else {
          return true
        }
      }
      if (type === 'ai_responder' && !ai_responder_node) {
        return true
      }
      if (type === 'ai_responder' && nodes && !nodes?.some((item: any) => item?.data?.type === 'send_message')) {
        return true
      }
      if (nodes?.length === 0) {
        if (type === 'send_message' && (truePlatforms.includes('facebook') || truePlatforms.includes('instagram'))) {
          return false
        }

        if (type === 'connect_lead' && truePlatforms.includes('instagram')) {
          return true
        }
        if (type === 'connect_lead_with_note' && truePlatforms.includes('facebook')) {
          return true
        }
        if (type === 'connect_lead_with_note' && truePlatforms.includes('instagram')) {
          return true
        }
        if (!['follow_lead', 'connect_lead', 'connect_lead_with_note'].includes(type)) {
          return true
        }
      } else if (
        nodes?.length === 1 &&
        !['lead_accepted', 'lead_accepted_connect', 'lead_accepted_follow'].includes(type)
      ) {
        return true
      } else if (
        nodes?.length === 2 &&
        ['lead_accepted', 'lead_accepted_connect', 'lead_accepted_follow', 'is_message_replied', 'is_wait'].includes(
          type,
        )
      ) {
        return true
      } else if (irrelevantTypes[type]?.some((platform: any) => truePlatforms.includes(platform))) {
        return true
      }
      return false
    }

    setDisableNode(shouldDisableNode())
  }, [nodes, prompt])

  return (
    // <div style={{ position: 'relative' }}>
    <div
      className={`${styles.node} ${type === 'ai_responder' ? styles.aiResponder : ''} ${
        disableNode ? (type === 'ai_responder' && !ai_responder_node ? styles.disableResponder : styles.disable) : ''
      } `}
      onDragStart={(event) => onDragStart(event, type)}
      draggable={!disableNode}>
      <Handle type='target' position={Position.Top} />
      <div className={styles.leftSide}>
        <img src={image} alt='' />
        {text}
      </div>
      <div className={styles.rightSide}>{channels}</div>
      <Handle type='source' position={Position.Bottom} id='a' />
    </div>
    // </div>
  )
}

export default DraggableNode

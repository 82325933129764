import FancyFilterExpandItem from 'LEGACY/Research/Filters/fancy-filter-expand-item/fancy-filter-expand-item'
import React from 'react'

interface IFancyFilter {
  data: any
}

const FancyFilter: React.FC<IFancyFilter> = ({ data }) => (
  <>
    {data?.map((item: any) => {
      return <FancyFilterExpandItem item={item} key={item.key} />
    })}
  </>
)

export default FancyFilter

import React, { useEffect } from 'react'
import { Button, Drawer, Input, message, Spin, Typography, Select, Tag, Modal } from 'antd'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './AddCategorizer.module.scss'
import { injectionRegex } from 'common/static-data/userData'
import { useAddCategoryMutation, useAddCategoryRunAnywayMutation } from 'common/APIs/SettingsApi'
import warning from 'common/assets/svg/warning.svg'

const { Option } = Select

const getColorByIndex = (index: number) => {
  const tagColors = [
    'blue', // Blue
    'purple', // Purple
    'orange', // Orange
    'red', // Red
    'green', // Green
    'yellow', // Yellow
    'pink', // Pink
    'cyan', // Cyan
    'magenta', // Magenta
  ]
  // Use modulo to cycle through colors if the index is larger than the array length
  return tagColors[index % tagColors.length]
}

export const AddCategorizerSider = ({ open, onClose, onAddSuccess, listPersonas }: any) => {
  const [cateName, setCateName] = React.useState('')
  const [prompt, setPrompt] = React.useState('')
  const [selectedPersonas, setSelectedPersonas] = React.useState<string[]>([])
  // listPersonas?.[0]?.name ? [listPersonas?.[0]?.name] : [],
  const [loading, setLoading] = React.useState(false)
  const [warningModalOpen, setWarningModalOpen] = React.useState(false)
  const [addCategory] = useAddCategoryMutation()
  const [addCategoryRunAnyway] = useAddCategoryRunAnywayMutation()
  const [firstTime, setFirstTime] = React.useState(true)
  const cleanUp = () => {
    onAddSuccess()
    setCateName('')
    setPrompt('')
    setSelectedPersonas([])
    setLoading(false)
    setWarningModalOpen(false)
  }
  useEffect(() => {
    if (listPersonas && firstTime) {
      setFirstTime(false)
      handlePersonaSelect(listPersonas?.[0]?.id)
    }
  }, [firstTime, listPersonas])

  const handleAdd = async () => {
    try {
      setLoading(true)

      if (injectionRegex.test(cateName)) {
        message.error('Invalid Category Name')
        setLoading(false)
        return
      }

      setWarningModalOpen(true)
    } catch (error) {
      console.error(error)
      message.error('Failed to add category. Please try again.')
      setLoading(false)
    }
  }
  const applyOnNew = async () => {
    try {
      await addCategory({
        category_name: cateName,
        category_description: prompt,
        personas: selectedPersonas.join(','),
      }).unwrap()
      cleanUp()
      message.success('Category added successfully!')
    } catch (error) {
      console.error(error)
      cleanUp()
      message.error('Failed to add category. Please try again.')

      setLoading(false)
    }
  }

  const runAnyway = async () => {
    try {
      message.loading('Syncing categorization may take a few minutes to complete...')
      await addCategoryRunAnyway({
        category_name: cateName,
        category_description: prompt,
        personas: selectedPersonas.join(','),
      }).unwrap()
      cleanUp()
    } catch (error) {
      console.error(error)
      cleanUp()
      message.error('Failed to add category. Please try again.')
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
    setCateName('')
    setPrompt('')
    setSelectedPersonas([])
  }

  const handlePersonaSelect = (value: string) => {
    if (!selectedPersonas.some((p: any) => p.id === value)) {
      setSelectedPersonas([...selectedPersonas, value])
    }
  }

  const handlePersonaDeselect = (value: string) => {
    setSelectedPersonas(selectedPersonas.filter((item: any) => item !== value))
  }

  const renderTitle = () => (
    <div className={styles.sider__header}>
      <div className={styles.sider__header__left}>
        <Typography>Create New Categorizer</Typography>
        {loading && <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />}
      </div>
      <Button type='primary' style={{ borderRadius: '24px' }} disabled={!cateName || loading} onClick={handleAdd}>
        Save
      </Button>
    </div>
  )

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={warningModalOpen}
        footer={null}
        onCancel={cleanUp}
        width={'fit-content'}
        style={{ minWidth: 500, maxWidth: 500 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            <img src={warning} alt='' />
            <p> Adding a new category will overwrite and rematch any existing categorizations.</p>
            <span style={{ fontSize: '14px', margin: 0, padding: 0 }}></span>
            <span style={{ fontSize: '14px', margin: '10px 0 0 0', padding: 0 }}>
              Previous categorizations will be removed, and the AI will reprocess them unless you choose to apply
              changes only to new replies.
            </span>
            <div className={styles.footer}>
              <div
                // classname for intercom - do not change
                className='applyForNewOnlyButton'>
                <Button size={'large'} onClick={applyOnNew}>
                  Apply for New Only
                </Button>
              </div>
              <Button size={'large'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={runAnyway}>
                Run Anyway
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Drawer
        title={renderTitle()}
        open={open}
        closable
        onClose={handleClose}
        closeIcon={<CloseOutlined />}
        className={'add-list-sider'}>
        <div className={styles.sider__body}>
          <div>
            <Typography>Categorizer Name</Typography>
            <Input
              value={cateName}
              onChange={(e) => setCateName(e.target.value)}
              style={{ padding: '6px 16px' }}
              placeholder='Enter Properties name'
            />
          </div>
          <div>
            <Typography>Categorizer Prompt</Typography>
            <Input
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              style={{ padding: '6px 16px' }}
              placeholder='Enter prompt'
            />
            <span className={styles.sider__note_text}>View description when hovering over fields in task or views</span>
          </div>
          <div>
            <Typography>Attached to Persona</Typography>
            <Select
              mode='multiple'
              value={selectedPersonas}
              placeholder='Type a new option or select from the list'
              onSelect={handlePersonaSelect}
              onDeselect={handlePersonaDeselect}
              tagRender={(props) => {
                const value = props.value
                return (
                  <>
                    <Tag
                      key={value}
                      color={getColorByIndex(0)}
                      closable
                      onClose={() => handlePersonaDeselect(value)}
                      style={{ borderRadius: 10 }}>
                      {props.label}
                    </Tag>
                  </>
                )
              }}
              filterOption={(input, option) =>
                (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }}>
              {listPersonas?.length > 0 &&
                listPersonas.map((persona: any) => (
                  <Option key={persona.id} value={persona.id}>
                    {persona.name}
                  </Option>
                ))}
            </Select>
            <span className={styles.sider__note_text}>Press Enter or comma to confirm your selection</span>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default AddCategorizerSider

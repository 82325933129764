import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const LearningCenterAPI: any = createApi({
  reducerPath: 'LearningCenterAPI',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/articles`,
        method: 'GET',
      }),
    }),
    getVideos: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/videos`,
        method: 'GET',
      }),
    }),
    updateArticle: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/updateArticle`,
        method: 'POST',
        body: { content: params?.content, articleID: params?.articleID },
      }),
    }),
    changeArticleStatus: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/changeArticlePublishStatus`,
        method: 'POST',
        body: { articleID: params?.articleID, status: params?.status },
      }),
    }),
    uploadArticle: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/uploadArticle`,
        method: 'POST',
        body: params.articleData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),
    deleteArticle: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/deleteArticle`,
        method: 'POST',
        body: { articleID: params?.articleID },
      }),
    }),
    deleteVideo: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/deleteVideo`,
        method: 'POST',
        body: { videoID: params?.videoID },
      }),
    }),
    priorityUpOrDown: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/priorityUpOrDown`,
        method: 'POST',
        body: { articleID: params?.articleID, up: params?.up },
      }),
    }),
    priorityUpOrDownAll: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/priorityUpOrDownAll`,
        method: 'POST',
        body: { articleID: params?.articleID, up: params?.up },
      }),
    }),
    priorityUpOrDownVideo: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/priorityUpOrDownVideos`,
        method: 'POST',
        body: { videoID: params?.videoID, up: params?.up },
      }),
    }),
    getAllCategories: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/getAllCategories`,
        method: 'GET',
      }),
    }),
    uploadCategory: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/learning_center/uploadCategory`,
        method: 'POST',
        body: { title: params?.title, name: params?.name },
      }),
    }),
  }),
})
export const {
  useLazyChangeArticleStatusQuery,
  useLazyGetArticlesQuery,
  useLazyGetVideosQuery,
  useLazyUpdateArticleQuery,
  useLazyUploadArticleQuery,
  useLazyDeleteArticleQuery,
  useLazyDeleteVideoQuery,
  useLazyPriorityUpOrDownQuery,
  useLazyPriorityUpOrDownAllQuery,
  useLazyPriorityUpOrDownVideoQuery,
  useLazyGetAllCategoriesQuery,
  useLazyUploadCategoryQuery,
} = LearningCenterAPI

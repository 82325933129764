import React from 'react'
import { Typography, Input, Spin } from 'antd'
import styles from './Tag.module.scss'
import { useAppSelector } from 'state'

import TagItem from './TagItem'
import { isArray } from 'lodash'
import { useTagDialog } from './Hook/useTagDialog'
import { useDebounce } from './Hook/useDebounce'
import { useLazySearchTagQuery } from '../../../APIs/Chat-API'
import { SearchResultTagItem } from './SearchTagItem'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  showDescription?: boolean
}

const TagDialog: React.FC<Props> = ({ showDescription = true }) => {
  const { selected_chat_recipient } = useAppSelector((state) => state.chat)

  const [searchTagQuery, setSearchTagQuey] = React.useState('')
  const [searchResult, setSearchResult] = React.useState<any>()

  const debounedSearchTag = useDebounce(searchTagQuery, 300)
  const [searchTag] = useLazySearchTagQuery()

  const {
    tagsOfDialog,
    loading,
    deleteSuccess,
    setLoading,
    handleRemoveTagFromDialog,
    handleAddTagToDialog,
    handleUpdateTag,
    handleRemoveTag,
  } = useTagDialog(selected_chat_recipient.id)

  const handleEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddTagToDialog(searchTagQuery)
      setSearchTagQuey('')
      setSearchResult([])
    }
  }

  const handleSearchTag = (event: any) => {
    setSearchTagQuey(event.target.value)
  }

  const fetSearchResult = async () => {
    try {
      const res = await searchTag(debounedSearchTag)
      if (res) {
        setSearchResult(res.data.response)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (debounedSearchTag) {
      setLoading(true)

      fetSearchResult()
    }
  }, [debounedSearchTag])

  React.useEffect(() => {
    if (deleteSuccess) fetSearchResult()
  }, [deleteSuccess])

  React.useEffect(() => {
    if (!searchTagQuery) setSearchResult([])
  }, [searchTagQuery])

  React.useEffect(() => {
    if (selected_chat_recipient) {
      setSearchTagQuey('')
    }
  }, [selected_chat_recipient])

  return (
    <div className={styles.chatTag}>
      <div className={styles['chatTag__header']}>
        <Typography className={styles['chatTag__header__text']}>Custom Tags</Typography>
        {loading && (
          <Spin style={{}} indicator={<LoadingOutlined style={{ fontSize: 16, marginBottom: '16px' }} spin />} />
        )}
      </div>
      {showDescription && (
        <Typography className={styles['chatTag__description']}>
          Add tags to simplify and speed up information retrieval
        </Typography>
      )}

      <div className={styles.chatTag__listTag}>
        {isArray(tagsOfDialog) &&
          tagsOfDialog.map((tag: any) => {
            return <TagItem key={tag.id} title={tag.tag_name} onClose={() => handleRemoveTagFromDialog(tag.id)} />
          })}
      </div>

      <Input
        className={styles['chatTag__input']}
        placeholder='Search or create new tag'
        value={searchTagQuery}
        onPressEnter={handleEnterPress}
        onChange={handleSearchTag}
      />

      <div className={styles['chatTag__search']}>
        {isArray(searchResult) &&
          searchResult.map((result: any) => {
            const onUpdateTag = (newTag: string) => {
              const body = {
                id: result.id,
                data: {
                  tagName: newTag,
                },
              }
              handleUpdateTag(body)
            }
            return (
              <SearchResultTagItem
                key={result.id}
                tagId={result.id}
                content={result.tag_name}
                onUpdateTag={onUpdateTag}
                onRemoveTag={handleRemoveTag}
                onClick={() => handleAddTagToDialog(result.tag_name)}
              />
            )
          })}
      </div>

      {searchTagQuery && (
        <div className={styles['chatTag__footer']}>
          <Typography color='#5e6c84'>Enter or click to add a tag</Typography>
          <TagItem title={searchTagQuery} />
        </div>
      )}

      {loading && (
        <Spin
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '40px' }}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}
    </div>
  )
}

export default TagDialog

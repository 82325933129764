import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const SignUpApi: any = createApi({
  reducerPath: 'SignUpApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    signUpAffiliate: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/signupAffiliate`,
        method: 'POST',
        body: values,
      }),
    }),
    updateUser: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/update`,
        method: 'POST',
        body: values,
      }),
    }),
    logAffiliate: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/logAffiliate`,
        method: 'POST',
        body: values,
      }),
    }),
    resendEmail: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/resend-email?email=${values.email}`,
        method: 'GET',
      }),
    }),
    getSettings: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/auth/settings`,
        method: 'GET',
      }),
    }),
    updateOutsideLog: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/updateOutsideLog`,
        method: 'POST',
        body: values,
      }),
    }),
    canSkipEmail: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/canSkipEmail`,
        method: 'GET',
      }),
    }),
    validateRegistrationLink: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/validateRegistrationLink?linkId=${encodeURIComponent(
          values.linkId,
        )}&userId=${encodeURIComponent(values.userId)}`,
        method: 'GET',
      }),
    }),
    getSignUpLinkService: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/getSignUpLink`,
        method: 'GET',
      }),
    }),
  }),
})
export const {
  useLazySignUpAffiliateQuery,
  useLazyUpdateUserQuery,
  useLazyLogAffiliateQuery,
  useLazyResendEmailQuery,
  useLazyGetSettingsQuery,
  useLazyUpdateOutsideLogQuery,
  useLazyCanSkipEmailQuery,
  useLazyValidateRegistrationLinkQuery,
  useLazyGetSignUpLinkServiceQuery,
} = SignUpApi

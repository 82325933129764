import right_arrow from 'LEGACY/Assets/svg/right_arrow.svg'
import saleforce from 'LEGACY/Assets/svg/salesforce_logo.svg'
import export_contacts from 'LEGACY/Assets/svg/export_contacts.svg'
import export_companies from 'LEGACY/Assets/svg/export_companies.svg'
import styles from './SalesforceInitScreen.module.scss'

const SalesforceInitScreen = ({
  onOpenComponent,
  isCompany,
}: {
  onOpenComponent: (componentName: string) => void
  isCompany: boolean
}) => {
  const handleOpenExportContacts = () => {
    onOpenComponent('ExportContacts')
  }

  const handleOpenExportCompanies = () => {
    onOpenComponent('ExportCompanies')
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={saleforce} alt='' />
        <h1>Select Salesforce Export Option</h1>
      </div>
      {!isCompany && (
        <div className={styles.section} onClick={handleOpenExportContacts}>
          <img src={export_contacts} alt='' />
          <div className={styles.item}>
            <p>Export Contacts</p>
            <span>Export a list of contacts based on your Salesforce Mapping</span>
          </div>
          <img className={styles.rightArrow} src={right_arrow} alt='' />
        </div>
      )}

      <div className={styles.section} onClick={handleOpenExportCompanies}>
        <img src={export_companies} alt='' />
        <div className={styles.item}>
          <p>Export Companies</p>
          <span>Export a list of companies based on your Salesforce Mapping</span>
        </div>
        <img className={styles.rightArrow} src={right_arrow} alt='' />
      </div>
    </div>
  )
}
export default SalesforceInitScreen

import React, { useState, useEffect } from 'react'
import { Breadcrumb, Typography, Button, Select, Divider } from 'antd'
import { orderBy } from 'lodash'
import { CheckOutlined } from '@ant-design/icons'

import { useLazyGetCategorizerQuery, useLazyGetUserPersonasQuery } from '../../../APIs/SettingsApi'

import { AddCategorizerSider } from './Components/CategorizerSider/AddCategorizer'
import LoadingBox from 'common/components/GeneralComponents/Loading/LoadingBox'
import CategoryCard from './Components/CategoryCard'
import styles from './Categorizer.module.scss'
import emptyIcon from 'common/assets/svg/categorizer_empty.svg'

const { Option } = Select

interface CategorizerProps {
  shouldHideCheckout: any
}

interface categorizer {
  index: number
  category_name: string
  is_active: boolean
  category_description: string
  personas: any
}

const getFilterItems = (selectedTab: any) => {
  switch (selectedTab) {
    case '1':
      return [
        { name: 'All Categories', value: 'all' },
        { name: 'Active', value: true },
        { name: 'Inactive', value: false },
      ]
    case '2':
      return [
        { name: 'All Types', value: 'all_types' },
        { name: 'By Convrt AI', value: 'by_convrt_ai' },
        { name: 'By You', value: 'by_you' },
      ]
    case '3':
      return [
        { name: 'Alphabetical (A to Z)', value: 'asc' },
        { name: 'Alphabetical (Z to A)', value: 'desc' },
      ]
    default:
      return []
  }
}

const Categorizer: React.FC<CategorizerProps> = ({ shouldHideCheckout }) => {
  const [filteredCategorizerData, setFilteredCategorizerData] = useState<categorizer[]>([])
  const [isCreateModal, setIsCreateModal] = useState(false)
  const [activeFilter, setActiveFilter] = useState<any>('all')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  // API hooks
  const [fetchCategorizer, { data: fetchDataCategorizer, isLoading: loading }] = useLazyGetCategorizerQuery()
  const [fetchUserPersonas, { data: fetchDataUserPersonas }] = useLazyGetUserPersonasQuery()

  const applyFilterAndSort = (data: categorizer[], filter: 'all' | boolean, sortOrder: 'asc' | 'desc') => {
    let updatedData = data
    if (filter !== 'all') {
      updatedData = updatedData.filter((item) => item.is_active === filter)
    }
    updatedData = orderBy(updatedData, 'category_name', sortOrder)
    setFilteredCategorizerData(updatedData)
  }

  const handleAddCategorizer = async (item: categorizer) => {
    fetchCategorizer()
    setIsCreateModal(false)
  }

  const handleEditCategorizer = async (data: categorizer) => {
    fetchCategorizer() // Refetch data after updating
  }

  const handleRemoveCategorizer = async (data: categorizer) => {
    fetchCategorizer() // Refetch data after removing
  }

  const handleChangeActive = async (index: number, is_active: boolean) => {
    fetchCategorizer() // Refetch data after status change
  }

  const handleFilterActive = (value: any) => {
    setActiveFilter(value)
    if (fetchDataCategorizer) {
      applyFilterAndSort(fetchDataCategorizer.data, value, sortOrder)
    }
  }

  const handleSortByName = (value: any) => {
    setSortOrder(value)
    if (fetchDataCategorizer) {
      applyFilterAndSort(fetchDataCategorizer.data, activeFilter, value)
    }
  }

  useEffect(() => {
    fetchCategorizer() // Initial data fetch
    fetchUserPersonas() // Initial data fetch
  }, [])

  useEffect(() => {
    if (fetchDataCategorizer) {
      applyFilterAndSort(fetchDataCategorizer.data, activeFilter, sortOrder)
    }
  }, [fetchDataCategorizer])

  const renderContentEmpty = () => (
    <div className={styles.emptyList}>
      <img src={emptyIcon} alt='empty' />
      <div>
        <Typography className={styles.emptyList__title}>No Categorizer Added</Typography>
        <Typography className={styles.emptyList__description}>
          No Categorizer has been found, our smart AI will create them from your chats, but you can add by clicking on
          New Categorizer.
        </Typography>
      </div>
      <Button type='primary' onClick={() => setIsCreateModal(true)} className={styles.emptyList__button}>
        New Categorizer
      </Button>
    </div>
  )

  return (
    <div className={styles['categorizer-page']}>
      <div className={styles['categorizer-page__header']}>
        <Breadcrumb items={[{ title: 'Settings' }, { title: <span>Manage AI Categorizer</span> }]} />
        <div className={styles['categorizer-page__header__section']}>
          <Typography className={styles['categorizer-page__header__pageName']}>Manage AI Categorizer</Typography>
          {fetchDataCategorizer?.data.length === 0 && (
            <Button onClick={() => setIsCreateModal(true)} type='primary'>
              New Categorizer
            </Button>
          )}
        </div>
      </div>
      <div className={styles['categorizer-page__body']}>
        {loading && <LoadingBox />}
        {!loading && fetchDataCategorizer?.data.length === 0 && renderContentEmpty()}
        {!loading && fetchDataCategorizer?.data.length > 0 && (
          <div className={styles['categorizer-page__body__section']}>
            <div className={styles['categorizer-page__body__section__left']}>
              <Typography className={styles['categorizer-page__body__section__title']}>Categorizer's List</Typography>
            </div>
            <div className={styles['categorizer-page__body__section__right']}>
              <Select style={{ minWidth: '80px', maxWidth: '140px' }} onChange={handleFilterActive} defaultValue='all'>
                {getFilterItems('1').map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name} {activeFilter === item.value && <CheckOutlined />}
                  </Option>
                ))}
              </Select>
              <Divider type='vertical' style={{ height: '24px', margin: '0 8px' }} />

              <Select onChange={handleSortByName} style={{ minWidth: '60px', maxWidth: '180px' }} defaultValue='asc'>
                {getFilterItems('3').map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name} {sortOrder === item.value && <CheckOutlined />}
                  </Option>
                ))}
              </Select>

              <Button onClick={() => setIsCreateModal(true)} type='primary'>
                New Categorizer
              </Button>
            </div>
          </div>
        )}
        {!loading && filteredCategorizerData.length > 0 && (
          <div className={styles['categorizer-page__body__content']}>
            {filteredCategorizerData.map((item: categorizer, index) => (
              <CategoryCard
                key={index}
                data={item}
                handleChange={handleChangeActive}
                handleEdit={handleEditCategorizer}
                handleRemove={handleRemoveCategorizer}
                listPersonas={fetchDataUserPersonas}
              />
            ))}
          </div>
        )}
      </div>
      <AddCategorizerSider
        open={isCreateModal}
        onClose={() => setIsCreateModal(false)}
        onAddSuccess={handleAddCategorizer}
        listPersonas={fetchDataUserPersonas}
      />
    </div>
  )
}

export default Categorizer

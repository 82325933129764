import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Button, Mentions, Divider, Tooltip, Select, Dropdown, Menu, Tag, message, Radio } from 'antd'
import styles from './AIResponderDrawer.module.scss'
import crown from 'common/assets/svg/crown_icon.svg'
import bookmark from 'common/assets/svg/bookmark_purple.svg'
import plus_icon from 'common/assets/svg/add_node_icon.svg'
import stars from 'common/assets/svg/loading_stars.svg'
import magic_wand from 'common/assets/svg/magic_wand.svg'
import { InfoCircleOutlined, RedoOutlined, RetweetOutlined, UndoOutlined } from '@ant-design/icons'
import { useLazyAssistWithAIQuery } from '../../../../APIs/OutreachAI-API'
import { successMessageMapping } from './TabsUtils'
import LoadMessagesDrawer from './LoadMessagesDrawer'
import left_arrow_big from 'common/assets/svg/left_arrow_big.svg'
import { useLazyGetBookmarkQuery } from 'common/APIs/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_BUILDER_SETUP_DATA } from '../Slice/CampaignBuilderSlice'
import CustomPopover from 'common/components/GeneralComponents/UI/CustomPopover/CustomPopover'

interface AIResponderDrawerProps {
  open: boolean
  onClose: () => void
  onSave: (data: any) => any
  onSaveEdit: (data: any, edit: boolean) => any
  loadingSave: boolean
  categorizer: any
  responderToEdit: any
  setResponderToEdit: any
}

const optionLabels = ['first_name', 'full_name', 'job_title', 'company_name', 'vertical', 'custom_1', 'custom_2']

// const messages = [
//   `🧠 I've been feeling mentally drained and overwhelmed. How do you maintain mental clarity and manage stress during busy periods? 🧘‍♂️💆‍♀️`,
//   `🌱 I'm trying to adopt a more sustainable lifestyle, but it's overwhelming with so many changes to make. Any tips for starting this journey? 🌿💚`,
//   `🤐 I have something on my mind that's been bothering me, but I'm not sure how to express it. Can we talk about it? 💬🤔`,
// ]

const AIResponderDrawer: React.FC<AIResponderDrawerProps> = ({
  open,
  onClose,
  onSave,
  onSaveEdit,
  loadingSave,
  categorizer,
  responderToEdit,
  setResponderToEdit,
}) => {
  const dispatch = useAppDispatch()
  const { selected_builder_node, builder_setup_data } = useAppSelector((state) => state.CampaignBuilder)
  const [assistWithAI] = useLazyAssistWithAIQuery()
  const [getBookmarks, { data: bookmarksData }] = useLazyGetBookmarkQuery()
  const [radioValue, setRadioValue] = useState(responderToEdit ? responderToEdit?.responder_type : 'text')
  const [loading, setLoading] = useState(false)
  const [textMessage, setTextMessage] = useState(responderToEdit ? responderToEdit?.responder_message : '')
  const [selectedCategorizer, setSelectedCategorizer] = useState(
    responderToEdit ? responderToEdit?.categorizer_index : null,
  ) // category index
  const [displayedText, setDisplayedText] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [undoStack, setUndoStack] = useState<any>([])
  const [redoStack, setRedoStack] = useState<any>([])
  const [showLoadMessages, setShowLoadMessages] = useState(false) // State to control LoadMessagesComponent display
  const mentionsRef = useRef<any>(null)
  const categorizerOptions = categorizer?.map((cat: any) => ({ label: cat?.category_name, value: cat?.index }))
  const options = optionLabels.map((value) => ({
    value,
    label: value,
  }))
  const disableButton =
    !selectedCategorizer || (selectedCategorizer && radioValue === 'text' && textMessage?.trim()?.length === 0)

  const handleCreateResponder = async () => {
    const data = {
      categorizer_index: selectedCategorizer,
      type: radioValue,
      text: radioValue === 'skip' ? '' : textMessage,
      category_name: categorizerOptions?.find((obj: any) => obj.value === selectedCategorizer)?.label,
    }

    if (responderToEdit) {
      const newData = await onSaveEdit(
        { ...data, responder_id: responderToEdit?.id },
        false, // Not allow to UPDATE anymore, only INSERT and update the current campaign's steps.
      )
      const currentData = builder_setup_data[selected_builder_node?.id] || {}
      const existingResponders = currentData.responders || []

      const updatedResponders = existingResponders.map((responder: any) => {
        if (responder.id === responderToEdit.id) {
          return {
            ...responder,
            ...newData,
            id: newData?.id,
            responder_message: data.type === 'skip' ? '' : data.text,
            categorizer_index: data.categorizer_index,
            categorizer_name: data.category_name,
            responder_type: data.type,
          }
        }

        return responder
      })

      // Update global state with the new array of responders
      dispatch(
        SET_BUILDER_SETUP_DATA({
          id: selected_builder_node?.id,
          data: {
            ...currentData,
            responders: updatedResponders,
            ...(!currentData.configuration && { configuration: { max_response_time: 60 } }),
          },
        }),
      )

      setResponderToEdit(null)
    } else {
      const newResponder = await onSave(data)
      if (newResponder) {
        // Retrieve existing data for the selected builder node
        const currentData = builder_setup_data[selected_builder_node?.id] || {}
        const existingResponders = currentData.responders || []

        // Check if there's an existing active responder with the same categorizer_index
        const activeResponderIndex = existingResponders.findIndex(
          (responder: any) => responder.categorizer_index === data.categorizer_index && responder.isActive,
        )

        let updatedResponders
        if (activeResponderIndex !== -1) {
          // An active responder with the same categorizer_index exists, deactivate it and add the new one as active
          updatedResponders = existingResponders.map((responder: any, index: number) =>
            index === activeResponderIndex
              ? { ...responder, isActive: false } // Deactivate the existing responder
              : responder,
          )
          updatedResponders = [
            ...updatedResponders,
            {
              ...newResponder,
              categorizer_name: data.category_name,
              isActive: true, // Set new responder to active
            },
          ]
        } else {
          // No active responder with the same categorizer_index exists, add the new one as active
          updatedResponders = [
            ...existingResponders,
            {
              ...newResponder,
              categorizer_name: data.category_name,
              isActive: true, // Set new responder to active
            },
          ]
        }

        // Update global state with the new array of responders
        dispatch(
          SET_BUILDER_SETUP_DATA({
            id: selected_builder_node?.id,
            data: {
              ...currentData,
              responders: updatedResponders,
              ...(!currentData.configuration && { configuration: { max_response_time: 60 } }),
            },
          }),
        )
      }
    }
  }

  const onChange = (e: any) => {
    setRadioValue(e.target.value)
  }

  const handleSelect = (e: any) => {
    setSelectedCategorizer(e)
  }

  const handleUndo = () => {
    if (undoStack.length === 0) return
    const previousValue = undoStack[undoStack.length - 1]
    setUndoStack((prevStack: any) => prevStack.slice(0, -1))
    setRedoStack((prevStack: any) => [...prevStack, textMessage])
    setTextMessage(previousValue)
  }

  const handleRedo = () => {
    if (redoStack.length === 0) return
    const nextValue = redoStack[redoStack.length - 1]
    setRedoStack((prevStack: any) => prevStack.slice(0, -1))
    setUndoStack((prevStack: any) => [...prevStack, textMessage])
    setTextMessage(nextValue)
  }

  const setTextMessageWithUndo = (newTextMessage: string) => {
    setUndoStack((prev: any) => [...prev, textMessage]) // Save the current textMessage to the undo stack
    setRedoStack([]) // Clear the redo stack since a new action is being performed
    setTextMessage(newTextMessage)
  }

  const handleMentionSelect = (mention: string) => {
    setUndoStack((prev: any) => [...prev, textMessage])
    const lastMentionIndex = textMessage.lastIndexOf(`@`)
    const messageWithoutLastMention = lastMentionIndex !== -1 ? textMessage.slice(0, lastMentionIndex) : textMessage
    const updatedMessage = `${messageWithoutLastMention}@${mention}`
    setTextMessage(updatedMessage)
  }

  const handleAddToComposer = (message: string) => {
    if (mentionsRef.current) {
      const currentValue = mentionsRef.current.textarea.value
      const cursorPosition = mentionsRef.current.textarea.selectionStart
      const needsSpace = cursorPosition > 0 && currentValue[cursorPosition - 1] !== ' '
      const newValue = `${currentValue.slice(0, cursorPosition)}${needsSpace ? ' ' : ''}${message}${currentValue.slice(
        cursorPosition,
      )}`
      // Save current state to undo stack before updating
      setTextMessageWithUndo(newValue)
    }
  }

  const simulateTypingEffect = (text: string) => {
    setIsTyping(true)
    setDisplayedText(text[0])
    let index = 1

    const intervalId = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index])
        index++
      } else {
        clearInterval(intervalId)
        setIsTyping(false)
      }
    }, 50)
  }

  const handleClickAskAIButton = async (key: string) => {
    if (!textMessage?.trim()) {
      message.warning('Please enter a message to proceed with AI enhancements.')
      return
    }

    setLoading(true)
    try {
      const { data } = await assistWithAI({
        message: textMessage,
        task: key,
      })

      if (data?.result) {
        setTextMessage(data.result)
        simulateTypingEffect(data.result)
        message.success(successMessageMapping[key] || 'Message generated successfully!')
      } else {
        message.error('Something went wrong generating your message. Please try again.')
      }
    } catch (e) {
      console.error('An error occurred:', e)
      message.error('Something went wrong generating your message. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleAskAIAction = ({ key }: any) => {
    switch (key) {
      case 'fix_grammar':
        handleClickAskAIButton('grammar')
        break
      case 'make_shorter':
        handleClickAskAIButton('shorten')
        break
      case 'more_casual':
        handleClickAskAIButton('casual')
        break
      case 'more_formal':
        handleClickAskAIButton('formal')
        break
      default:
        return
    }
  }

  useEffect(() => {
    const bookmarks = async () => {
      await getBookmarks()
    }
    bookmarks()
  }, [])

  const PersonalizationMenu = (
    <Menu onClick={({ key }) => handleAddToComposer(key)}>
      {optionLabels.map((label) => (
        <Menu.Item key={`@${label}`}>
          <Tag color='#F2F0FF' style={{ margin: '0px', padding: '5px' }}>
            <span
              style={{
                color: label === 'custom_1' || label === 'custom_2' ? '#009e87' : '#7043ff',
                fontSize: '13px',
                fontWeight: 600,
              }}>
              @{label}
            </span>
          </Tag>
        </Menu.Item>
      ))}
    </Menu>
  )

  const AskAIMenu = (
    <Menu onClick={handleAskAIAction}>
      <Menu.Item style={{ color: '#7043ff' }} key='fix_grammar'>
        <div className={styles.flexDiv}>
          <img src={magic_wand} alt='' />
          Fix Grammar
        </div>
      </Menu.Item>
      <Menu.Item style={{ color: '#7043ff' }} key='make_shorter'>
        <div className={styles.flexDiv}>
          <img src={magic_wand} alt='' />
          Make Shorter
        </div>
      </Menu.Item>
      <Menu.Item style={{ color: '#7043ff' }} key='more_casual'>
        <div className={styles.flexDiv}>
          <img src={magic_wand} alt='' />
          More Casual
        </div>
      </Menu.Item>
      <Menu.Item style={{ color: '#7043ff' }} key='more_formal'>
        <div className={styles.flexDiv}>
          <img src={magic_wand} alt='' />
          More Formal
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <Drawer
      placement='right'
      title={
        showLoadMessages ? (
          <div className={styles.bookmarksTitle}>
            <img
              src={left_arrow_big}
              alt=''
              style={{ marginRight: '15px', cursor: 'pointer', width: '20px' }}
              onClick={() => {
                setShowLoadMessages(false)
              }}
            />
            Chat Bookmark
          </div>
        ) : (
          'Create New AI Response'
        )
      }
      onClose={onClose}
      open={open}
      className='aiResponderDrawer'
      width={'28%'}
      extra={
        !showLoadMessages && (
          <Button type='primary' onClick={handleCreateResponder} disabled={disableButton} loading={loadingSave}>
            {responderToEdit ? 'Update' : 'Create'}
          </Button>
        )
      }>
      <div className={styles.container}>
        {showLoadMessages ? (
          <LoadMessagesDrawer
            // messages={messages}
            messages={bookmarksData}
            onReplaceComposer={(text: string) => {
              setTextMessageWithUndo(text)
              setShowLoadMessages(false)
            }}
          />
        ) : (
          <>
            <div className={styles.select}>
              <p>Select Categorizer</p>
              <Select
                value={selectedCategorizer}
                onChange={handleSelect}
                placeholder='Select a categorizer name'
                className='platformsSelectSetup'
                size='large'
                options={categorizerOptions}
              />
            </div>

            <div className={styles.select}>
              <p>
                Select Respond Type
                <Tooltip title='Write your own text or skip from responding.'>
                  <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </Tooltip>
              </p>
              <Radio.Group onChange={onChange} value={radioValue} className='custom-radio-group'>
                <Radio value='ai' className='custom-radio custom-disabled' disabled>
                  <div className={styles.radio}>
                    <span>AI Respond</span>
                    <img src={crown} alt='crown' className='crown-icon' />
                  </div>
                </Radio>
                <Radio value='text' className='custom-radio'>
                  Text
                </Radio>
                <Radio value='skip' className='custom-radio'>
                  Skip
                </Radio>
              </Radio.Group>
            </div>

            {radioValue !== 'skip' ? (
              <div className={styles.messageContent}>
                <div className={styles.header}>
                  <p>Respond Content</p>
                  <div className={styles.right}>
                    <Tooltip title='Undo'>
                      <UndoOutlined
                        onClick={handleUndo}
                        style={{
                          cursor: undoStack?.length > 0 ? 'pointer' : 'not-allowed',
                          color: undoStack?.length > 0 ? 'black' : 'lightgray',
                        }}
                      />
                    </Tooltip>
                    <Divider type='vertical' />
                    <Tooltip title='Redo'>
                      <RedoOutlined
                        onClick={handleRedo}
                        style={{
                          cursor: redoStack?.length > 0 ? 'pointer' : 'not-allowed',
                          color: redoStack?.length > 0 ? 'black' : 'lightgray',
                        }}
                      />
                    </Tooltip>
                    <Divider type='vertical' />
                    <span style={{ cursor: 'not-allowed', color: 'lightgray' }}>Save Message</span>
                  </div>
                </div>
                <div className={styles.mentions}>
                  <Mentions
                    ref={mentionsRef}
                    disabled={loading}
                    aria-disabled={isTyping}
                    value={isTyping ? displayedText : textMessage}
                    onChange={(value) => setTextMessageWithUndo(value)}
                    onSelect={(value) => handleMentionSelect(value?.key || '')}
                    className='mentionsDrawer'
                    autoSize={{ minRows: 6, maxRows: 6 }}
                    maxLength={5000}
                    style={{ padding: '5px 0px 165px 5px', backgroundColor: 'white' }}
                    placeholder='Please input the text content you desire. You can ask AI and add personalization. You can use @ to reference user data.'
                    options={options}
                  />
                  <div className={styles.mentionsSettings}>
                    <Dropdown overlay={PersonalizationMenu} trigger={['click']}>
                      <div className={styles.left}>
                        <img src={plus_icon} alt='' />
                        <span>Add Personalization</span>
                      </div>
                    </Dropdown>
                    <Dropdown overlay={AskAIMenu} trigger={['click']}>
                      <div className={styles.right}>
                        <img src={stars} alt='' />
                        <span>Ask AI</span>
                      </div>
                    </Dropdown>
                    <div onClick={() => setShowLoadMessages(true)}>
                      <div className={styles.right}>
                        <img src={bookmark} alt='' />
                        <span>Load Messages</span>
                      </div>
                    </div>
                    <CustomPopover
                      title='Use Spintax'
                      body={
                        <div className={styles.popoverBody}>
                          <span>Use Spintax to randomize your messages for better engagement.</span>
                          <span>Separate variations with the | delimiter. </span>
                          <span>Example: {'{Hi|Hello|Hey}'} will randomly send one of the options,</span>
                          <span>
                            like 'Hi,' 'Hello,' or 'Hey.'{' '}
                            <span style={{ fontWeight: 600 }}>Only the "|" (pipe) delimiter is supported.</span>
                          </span>

                          {/* <SpintaxGenerator /> */}
                        </div>
                      }>
                      <div className={styles.left}>
                        <RetweetOutlined className={styles.retweet} style={{ marginRight: '5px', fontSize: '18px' }} />
                        <span>Spintax</span>
                      </div>
                    </CustomPopover>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.flexDiv}>
                <p>
                  <Tooltip title='We will skip using this categorizer name in our response'>
                    <InfoCircleOutlined style={{ cursor: 'pointer', color: '#005AF4', marginRight: '5px' }} />
                  </Tooltip>
                  We will skip using this categorizer name in our response
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  )
}

export default AIResponderDrawer

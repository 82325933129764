import axios, { AxiosRequestConfig } from 'axios'

const baseURL = process.env.REACT_APP_LEGACY_API_URL
const baseTelegramURL = process.env.REACT_APP_TELEGRAM_CONNECT_API_URL
// const baseHubspotURL = process.env.REACT_APP_HUBSPOT_CONNECT_API_URL
const baseHubspotURL = 'https://connectplus-api.convrt.io'

const req = axios.create()

req.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')

    if (token) {
      config.headers['Authorization'] = `Bearer ${token?.replaceAll('"', '')}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

const outreachReq = req

outreachReq.interceptors.request.use(
  (config) => {
    config.headers['environment'] = process.env.REACT_APP_ENVIRONMENT

    return config
  },
  (error) => Promise.reject(error),
)

export const get = (url: string, config?: AxiosRequestConfig) => req.get(`${baseURL}${url}`, config)

export const post = (url: string, data?: any, config?: AxiosRequestConfig) => req.post(`${baseURL}${url}`, data, config)

export const put = (url: string, data?: any, config?: AxiosRequestConfig) => req.put(`${baseURL}${url}`, data, config)

export const del = (url: string, config?: AxiosRequestConfig) => req.delete(`${baseURL}${url}`, config)

export const patch = (url: string, data?: any, config?: AxiosRequestConfig) =>
  req.patch(`${baseURL}${url}`, data, config)

export const telegramGet = (url: string, config?: AxiosRequestConfig) =>
  outreachReq.get(`${baseTelegramURL}${url}`, config)

export const telegramPost = (url: string, data?: any, config?: AxiosRequestConfig) =>
  outreachReq.post(`${baseTelegramURL}${url}`, data, config)

export const hubspotPost = (url: string, data?: any, config?: AxiosRequestConfig) =>
  outreachReq.post(`${baseHubspotURL}${url}`, data, config)

export const hubspotGet = (url: string, config?: AxiosRequestConfig) =>
  outreachReq.get(`${baseHubspotURL}${url}`, config)

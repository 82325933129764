import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import classNames from 'classnames'

import { CommonServices } from 'LEGACY/common/services/common-services'

import { ReactComponent as IntentAvailable } from 'LEGACY/Assets/svg/intent_available.svg'
import { ReactComponent as LockComponent } from 'LEGACY/Assets/svg/lock.svg'
import { FancyImage } from 'LEGACY/Research/contacts/components/FancyImage/FancyImage'
import { ChainsList } from 'LEGACY/Research/intent-signals/components/ChainsList/ChainsList'
import {
  IIntentSignal,
  IIntentSignalsTable,
} from 'LEGACY/Research/intent-signals/interfaces/intent-signals-table.interface'
import IntentsPopup from '../intentsPopup/IntentsPopup'

// import { milestonesMap } from 'LEGACY/common/static-data/company-filter'
import MoreOptions from '../MoreOptions/MoreOptions'
import { getApproximateHeadcount } from 'LEGACY/Research/StartScreen/utils/getApproximateAmount'
import { TagsList } from 'LEGACY/Research/contacts/components/TagsList/TagsList'

let userDetails: any = null
if (localStorage.getItem('user') !== null) {
  userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
}

const DISPLAYED = 2

export const IntentSignalsTableColumns: ColumnsType<IIntentSignalsTable> = [
  {
    title: 'Company',
    dataIndex: 'name',
    key: 'name',
    width: 270,
    render: (text: string, item: any) => (
      <div className='tblLogoName'>
        <FancyImage className='mainPicture' url={item?.s3_logo} iscompany={true} name={text} />
        <Tooltip placement='topLeft' title={text}>
          <span className={'textTooltip'}>{text}</span>
        </Tooltip>
        {/* {item?.funding_flags &&
          JSON.parse(item.funding_flags).map((flag: string) => (
            <>
              <span className='milestonesSeparator'>{'•'}</span>
              <Tag className='fundingFlag' color={milestonesMap[flag]?.color}>
                {milestonesMap[flag]?.label.toUpperCase()}
              </Tag>
            </>
          ))} */}
      </div>
    ),
  },
  {
    title: (
      <Tooltip
        placement='top'
        title={
          <div>
            {`This feature allows you to see where you connections are working to warm up the lead. Available in the `}
            <span style={{ textDecoration: 'underline' }}>{'Connect+ Plan.'}</span>
          </div>
        }>
        {'Connect+'}
      </Tooltip>
    ),
    dataIndex: 'connect_plus',
    key: 'connect_plus',
    width: 95,
    render: (text: string, item: any) => {
      return (
        <div className='tblLogoName connectPlusContainer'>
          {item?.total_telegram_contacts && item?.contact_picture_urls && (
            <>
              {JSON.parse(item.contact_picture_urls)
                .slice(0, DISPLAYED)
                .map((url: string) => {
                  return <FancyImage className='smallRoundPicture' url={url} size={28} name={item.fallbackName || ''} />
                })}
              {item.total_telegram_contacts > DISPLAYED && (
                <MoreOptions
                  className={'moreContacts smallRoundPicture'}
                  sum={item?.total_telegram_contacts}
                  displayed={DISPLAYED}
                />
              )}
            </>
          )}
        </div>
      )
    },
  },
  {
    title: (
      <Tooltip placement='top' title={'Number of employees in the company.'}>
        {'Employees'}
      </Tooltip>
    ),
    dataIndex: 'number_of_employees',
    width: 105,
    key: 'number_of_employees',
    render: (text: string, item: any) => (
      <div className='tblFieldText'>
        <span>{getApproximateHeadcount(text)}</span>
      </div>
    ),
  },
  {
    title: (
      <Tooltip placement='top' title={'The sub-industry of the company.'}>
        {'Vertical'}
      </Tooltip>
    ),
    dataIndex: 'vertical',
    key: 'vertical',
    width: 180,
    render: (text: string) => (
      <div className='tblFieldText'>
        <Tooltip placement='topLeft' title={CommonServices.generateStringFromArr(text)}>
          <span className={classNames('asLink', 'textTooltip')}>
            {CommonServices.generateStringFromArr(text).length > 18
              ? `${CommonServices.generateStringFromArr(text).substring(0, 18).trim()}...`
              : CommonServices.generateStringFromArr(text)}
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    title: (
      <Tooltip placement='top' title={'Related tag of products and activities of the company.'}>
        {'Tags'}
      </Tooltip>
    ),
    dataIndex: 'company_tags',
    key: 'company_tags',
    width: 200,
    render: (text: string, item: any) => {
      const tagsList = JSON.parse(item?.company_tags)
      const tags: string[] = []
      tagsList.forEach((element: any) => {
        tags.push(element)
      })
      return (
        <div
          className='tblFieldText columnMinWidth'
          style={
            {
              // minHeight: '60px',
              // maxHeight: '60px',
              // display: 'flex',
              // alignItems: 'center',
              // flexDirection: 'column',
              // justifyContent: 'center',
            }
          }>
          {tags.length > 0 ? <TagsList tags={tags} /> : <span>{'N/A'}</span>}
        </div>
      )
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 350,
    render: (text: string, item: any) => {
      return (
        <div className='tblFieldText'>
          <Tooltip placement='topLeft' title={text}>
            <span className={'textTooltip'}>
              {text ? (text.length > 40 ? `${text.substring(0, 40)}...` : text) : 'N/A'}
            </span>
          </Tooltip>
        </div>
      )
    },
  },
  {
    title: (
      <Tooltip
        placement='top'
        title={
          <div>
            {`This feature allows you to filter companies based on signals on their needs and buying signals of products they look for. Available in the `}
            <span style={{ textDecoration: 'underline' }}>{'Market Dominance Plan.'}</span>
          </div>
        }>
        {'Signals'}
      </Tooltip>
    ),
    dataIndex: 'intent',
    key: 'intent',
    width: 80,
    render: (intent: IIntentSignal[], rowData: IIntentSignalsTable) => {
      const milestones = rowData?.funding_flags
      // the intent form the BE, will come from the prop
      const titles: string[] = CommonServices.filterByDistinctField(rowData.intentsSignals, 'intent').map(
        (item) => item.intent,
      )
      let intentsData: IIntentSignal[] = []
      for (let index = 0; index < titles.length; index++) {
        let points = rowData.intentsSignals.filter((item: any) => item.intent === titles[index])
        // let amountValue = 0
        // for (let i = 0; i < points.length; i++) {
        //   amountValue += points[i].count
        // }
        intentsData.push({
          title: titles[index],
          value: points[0]?.intent_score * 100,
          // value: amountValue * 10,
          intent_strength: points[0].intent_strength,
        })
      }

      const isIntentsEmpty = true //name === 'AAVE V2'
      if (
        userDetails &&
        JSON.parse(userDetails).addons &&
        JSON.parse(userDetails).addons['intentsSignals'] &&
        (intentsData.length > 0 || milestones !== null)
      ) {
        const ms = JSON.parse(rowData.funding_flags)
        return (
          <IntentsPopup isIntentsEmpty={isIntentsEmpty} intents={intentsData} milestones={ms}>
            {isIntentsEmpty ? <IntentAvailable /> : <LockComponent />}
          </IntentsPopup>
        )
      } else if (
        userDetails &&
        JSON.parse(userDetails).addons &&
        !JSON.parse(userDetails).addons['intentsSignals'] &&
        (intentsData.length > 0 || milestones !== null)
      ) {
        return (
          <Tooltip placement='topLeft' title={`Connect+ Feature`}>
            <IntentAvailable />
          </Tooltip>
        )
      } else {
        return <IntentAvailable className='lockIntents' />
      }
    },
  },
  {
    title: (
      <Tooltip placement='top' title={'The chains the company is deployed on.'}>
        {'Chains'}
      </Tooltip>
    ),
    dataIndex: 'chains',
    key: 'chains',
    width: 180,
    render: (text: any) => {
      return (
        <div className='tblFieldText columnMinWidth'>
          {text && JSON.parse(text).length > 0 && typeof JSON.parse(text) === 'object' ? (
            <ChainsList chains={JSON.parse(text)} />
          ) : (
            <span>{'N/A'}</span>
          )}
        </div>
      )
    },
  },
]

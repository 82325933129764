import { Badge } from 'antd'
import telegram from 'LEGACY/Assets/svg/telegram_badge.svg'

const TelegramContactBadge = ({
  children,
  isTelegramContact,
  connection_level,
}: {
  children: any
  isTelegramContact: boolean
  connection_level?: number
}) => {
  let classLevel = 'badge'
  const level = () => {
    if (connection_level) {
      if (connection_level === 1) {
        classLevel = 'badgeFirst'
        return <span style={{ color: 'white', fontSize: '12px' }}>1st</span>
      } else if (connection_level === 2) {
        classLevel = 'badgeSecond'
        return <span style={{ color: 'white', fontSize: '12px' }}>2nd</span>
      } else if (connection_level === 3) {
        classLevel = 'badgeThird'
        return <span style={{ color: 'white', fontSize: '12px' }}>3rd</span>
      }
    } else {
      return <img src={telegram} alt='' />
    }
  }

  const badgeLevel = level()

  return isTelegramContact ? (
    <Badge
      className={classLevel === 'badge' ? '' : classLevel}
      offset={[0, '90%']}
      count={<div className={classLevel}>{badgeLevel}</div>}>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  )
}

export default TelegramContactBadge

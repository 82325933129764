import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useLazyAddHubspotFieldMappingQuery,
  useLazyGetCreditsQuery,
  useLazyGetHandleSpothubQuery,
  useLazyGetHubspotPropertiesQuery,
  useLazyGetLoginHubSpotQuery,
} from 'common/APIs/SettingsApi'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import { message } from 'antd'
import { trackEvent } from 'eventConfig'

const defaultIntegrationList = {
  '1': {
    from: { value: 'full_name', label: 'Name' },
    to: { value: 'full_name', label: 'First Name' },
    caption: 'row-1',
    id: 1,
    group: 'Contacts',
  },
  '2': {
    from: { value: 'email', label: 'Email' },
    to: { value: 'email', label: 'Email' },
    caption: 'row-2',
    id: 2,
    group: 'Contacts',
  },
  // '3': {
  //   from: { value: 'phone_numbers', label: 'Phone Number' },
  //   to: { value: 'phone', label: 'Phone' },
  //   caption: 'row-3',
  //   id: 3,
  //   group: 'Contacts',
  // },
  '4': {
    from: { value: 'job_title', label: 'Job title' },
    to: { value: 'jobtitle', label: 'Job title' },
    caption: 'row-4',
    id: 4,
    group: 'Contacts',
  },
  '5': {
    from: { value: 'telegram_account', label: 'Telegram' },
    to: { value: 'telegram', label: 'Telegram' },
    caption: 'row-5',
    id: 5,
    group: 'Contacts',
  },
  '6': {
    from: { value: 'city', label: 'Location' },
    to: { value: 'location', label: 'Location' },
    caption: 'row-6',
    id: 6,
    group: 'Contacts',
  },
  '7': {
    from: { value: 'company_name', label: 'Company' },
    to: { value: 'company', label: 'company' },
    caption: 'row-7',
    id: 7,
    group: 'Contacts',
  },
  '8': {
    from: { value: 'linkedin', label: 'Linkedin page' },
    to: { value: 'linkedin', label: 'Linkedin page' },
    caption: 'row-8',
    id: 8,
    group: 'Contacts',
  },
  '9': {
    from: { value: 'twitter', label: 'Twitter' },
    to: { value: 'twitter', label: 'Twitter' },
    caption: 'row-9',
    id: 9,
    group: 'Contacts',
  },
  '10': {
    from: { value: 'seniority', label: 'Seniority' },
    to: { value: 'seniority', label: 'Seniority' },
    caption: 'row-10',
    id: 10,
    group: 'Contacts',
  },
  '11': {
    from: { value: 'company_domain', label: 'Company Domain' },
    to: { value: 'website', label: 'Company Domain' },
    caption: 'row-11',
    id: 11,
    group: 'Contacts',
  },
  '12': {
    from: { value: 'name', label: 'Company' },
    to: { value: 'name', label: 'Company' },
    caption: 'row-12',
    id: 12,
    group: 'Companies',
  },
  '13': {
    from: { value: 'city', label: 'Location' },
    to: { value: 'city', label: 'Location' },
    caption: 'row-13',
    id: 13,
    group: 'Companies',
  },
  '14': {
    from: { value: 'state', label: 'State' },
    to: { value: 'state', label: 'State' },
    caption: 'row-14',
    id: 14,
    group: 'Companies',
  },
  '15': {
    from: { value: 'domain', label: 'Domain' },
    to: { value: 'domain', label: 'Domain' },
    caption: 'row-15',
    id: 15,
    group: 'Companies',
  },
  // '16': {
  //   from: { value: 'contact_phone', label: 'Phone' },
  //   to: { value: 'phone', label: 'Phone' },
  //   caption: 'row-16',
  //   id: 16,
  //   group: 'Companies',
  // },
  '17': {
    from: { value: 'name', label: 'Name' },
    to: { value: 'name', label: 'Name' },
    caption: 'row-17',
    id: 17,
    group: 'Campaign',
  },
  '18': {
    from: { value: 'platform', label: 'Platform' },
    to: { value: 'platform', label: 'Platform' },
    caption: 'row-18',
    id: 18,
    group: 'Campaign',
  },
}

const useHubspot = () => {
  const [, setUser] = useLocalStorage(LocalStorageKeys.HUBSPOT_ACCESS_TOKEN, null)
  const navigate = useNavigate()
  const [refetchAddHubspotFieldMapping] = useLazyAddHubspotFieldMappingQuery()
  const [refetchGetCredits] = useLazyGetCreditsQuery()
  const [existKey, setExistKey] = useState(false)
  const [refetchAddUpdateHubSpot] = useLazyGetHandleSpothubQuery()
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const [refetchGetHubspotProperties] = useLazyGetHubspotPropertiesQuery()

  useEffect(() => {
    if (window.location.search) {
      const search = window.location.search
      const queryString = search.startsWith('?') ? search.slice(1) : search
      const path = window.location.href
      const [key, value] = queryString.split('=')

      if (
        key === 'code' &&
        value &&
        !path.includes('integrations/slack') &&
        !path.includes('integrations/salesforce')
      ) {
        const addUpdateHubSpot = async () => {
          try {
            const result = await refetchAddUpdateHubSpot(`${value}`)
            if (result?.error) {
              message.error(
                result?.error?.data?.message ? result?.error?.data?.message : 'An Error has occured. Please try again.',
              )
            } else {
              setUser(result.data.response.data.access_token)
              message.success(`Hubspot API key added successfully`)
              await refetchGetCredits()
              await refetchAddHubspotFieldMapping({
                hubspotFieldMapping: defaultIntegrationList,
                isSaveFieldMapping: false,
              })
              navigate(`/settings`)
              const fetchHubspotData = async () => {
                const tokenHubSpot = localStorage.getItem('hubspot_access_token')
                const res = await refetchGetHubspotProperties(tokenHubSpot)
                setExistKey(res.isSuccess)
              }
              fetchHubspotData().catch(console.error)

              //setExistKey(true)
            }
          } catch (e) {
            console.error('ERROR: ', e)
          }
        }
        addUpdateHubSpot()
      }
    }
  }, [])

  useEffect(() => {
    trackEvent('SETTINGS')

    const fetchHubspotData = async () => {
      try {
        const tokenHubSpot = localStorage.getItem('hubspot_access_token')
        const res = await refetchGetHubspotProperties(tokenHubSpot)
        setExistKey(res.isSuccess)
      } catch (e) {
        console.error('ERROR: ', e)
      }
    }

    const tokenHubSpot = localStorage.getItem('hubspot_access_token')
    if (tokenHubSpot && tokenHubSpot !== 'null') {
      fetchHubspotData().catch(console.error)
    }
  }, [])

  const handleLoginHubSpot = async () => {
    try {
      const result = await refetchLoginHubSpot()
      const newWindow = window.open(result.data.response, '_blank')

      if (newWindow) {
        newWindow.focus()
      }
    } catch (e) {
      console.error('ERROR: ', e)
    }
  }

  return {
    existKey,
    handleLoginHubSpot,
  }
}

export default useHubspot

import { createSlice } from '@reduxjs/toolkit'

import { ISettingsSlice } from 'common/components/Settings/state/interface/ISettingsSlice'

const initialState = {
  credits: null,
  status: '',
  deals: null,
  registrationAdmin: false,
} as ISettingsSlice

export const settingsSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    SET_CREDITS(state, action) {
      state.status = 'succeeded'
      state.credits = action.payload
    },
    SET_REGISTRATION_ADMIN(state, action) {
      state.registrationAdmin = action.payload
    },
  },
})

export const { SET_CREDITS, SET_REGISTRATION_ADMIN } = settingsSlice.actions

export const settingsReducer = settingsSlice.reducer

import hubspotLogo from 'LEGACY/Assets/jpg/hubspot.jpg'
import warning_icon from 'LEGACY/Assets/svg/warning_icon.svg'
import { useEffect, useMemo, useState } from 'react'

import { PlusCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Col, message, Row, Tabs, Typography } from 'antd'
import classNames from 'classnames'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetAccountHubspotDetailQuery, useUnlinkHubSpotAccountMutation } from 'LEGACY/API/SettingsApi'
import { useNavigate } from 'react-router-dom'
import { isArray } from 'lodash'
import { ConnectionInfoBox, SettingsBox } from '../Components/HuspotMapping'
import HubspotUnlinkModal from '../Components/HuspotMapping/HubspotUnlinkModal'
import { SelectRow } from '../Components/SelectRow'
import useHubspot from '../Hooks/useHubspot'
import styles from './AppIntegration.module.scss'

interface AppIntegrationProps {
  setCurrentDetailSocial: React.Dispatch<React.SetStateAction<string>>
}

const AppIntegration: React.FC<AppIntegrationProps> = ({ setCurrentDetailSocial }) => {
  const {
    handleAddItemsToMappingList,
    refetchAddHubspotFieldMapping,
    hubspotIntegrationList,
    handleOptionSelect,
    hubspotProps,
    deleteRowFieldMapping,
    isLoading,
    newItems,
    isFetchingAddHubspot,
  } = useHubspot()

  const [refetchGetAccountHubspotDetail, { data: accountHuspotDetail, isFetching: isFetchingAccountHubspotDetail }] =
    useLazyGetAccountHubspotDetailQuery()
  const [unlinkHubspot] = useUnlinkHubSpotAccountMutation()
  const { userDetails } = useAuth()
  const { email } = userDetails
  const [isOpenUnlink, setIsOpenUnlink] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()

  const [warnings, setWarnings] = useState<{ [key: string]: boolean }>({})
  const [activeTab, setActiveTab] = useState('contacts')

  const handleWarningUpdate = (rowId: string, hasWarning: boolean) => {
    setWarnings((prevWarnings) => ({ ...prevWarnings, [rowId]: hasWarning }))
  }
  const isWarning = useMemo(() => Object.values(warnings).some((warning) => warning), [warnings])

  useEffect(() => {
    const getAccountHubspotDetail = () => {
      refetchGetAccountHubspotDetail()
    }
    getAccountHubspotDetail()
  }, [])

  const { portalId } = accountHuspotDetail?.accountDetails || {}
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'The HubSpot integration sync has completed successfully',
      className: 'custom-class',
      style: {
        marginTop: '90vh',
      },
    })
  }

  const handleDeleteRow = (rowId: string) => {
    setWarnings((prev) => {
      const { [rowId]: _, ...newWarning } = prev
      return newWarning
    })

    deleteRowFieldMapping(rowId)
  }

  const customItems = newItems.filter((t: any) => {
    return t.label.toLowerCase() === activeTab
  })

  const selectedHubspot = hubspotIntegrationList.map((f) => f.to.value)

  return (
    <div className={classNames(styles['AppIntegration'], 'AppIntegration')}>
      <div className={styles['AppIntegration__header']}>
        <div className={styles['AppIntegration__header__left']}>
          <img src={hubspotLogo} alt='hubspot-logo' />
          <Typography>Hubspot</Typography>
        </div>

        <div className={styles['AppIntegration__header__right']}>
          {/* <div className={styles['AppIntegration__header__right__switch']}>
            <Switch
              style={{ width: '32px' }}
              checked={isCheckedSwitch}
              onChange={(checked: boolean) => setIsCheckedSwitch(checked)}
              loading={isFetchingAddHubspot}
              disabled={isWarning}
              onClick={
                !isLoading && !isCheckedSwitch
                  ? async () => {
                      await refetchAddHubspotFieldMapping({
                        hubspotFieldMapping: hubspotIntegrationList,
                        isSaveFieldMapping: true,
                      })
                        .unwrap()
                        .then(() => {
                          success()
                          setIsSuccessSync(true)
                        })
                    }
                  : () => {}
              }
            />
            {isSuccessSync ? <span>Synced</span> : <span>Sync</span>}
          </div> */}

          <Button className={styles['AppIntegration__header__right__button']} onClick={() => setIsOpenUnlink(true)}>
            Unlink account
          </Button>
        </div>
      </div>

      {isWarning && (
        <div className={styles['AppIntegration__warning']}>
          <Alert
            message='There are mandatory fields that must be filled in before you can start syncing. Please complete these fields.'
            type='error'
            showIcon
            icon={<img src={warning_icon} alt='warning' />}
            banner
            style={{
              padding: '16px',
              borderRadius: '8px',
              background: '#FF5C5C1A',
              color: '#5E6C84',
              fontSize: '16px',
            }}
          />
        </div>
      )}

      <div className={styles['AppIntegration__content']}>
        <Row gutter={[32, 32]}>
          <Col span={15}>
            <div
              className={classNames(styles['AppIntegration__content--box'], styles['AppIntegration__content__left'])}>
              <Typography className={styles['AppIntegration__content--box__title']}>Mapping</Typography>

              <Tabs
                defaultActiveKey='contacts'
                centered
                className='integrationtab'
                style={{ width: '100%' }}
                onChange={(tab) => {
                  setActiveTab(tab)
                }}>
                <Tabs.TabPane tab={<>Contact</>} key='contacts' style={{ position: 'relative' }}>
                  <div className={styles['AppIntegration__content__left__mandatory']}>
                    <Typography>Mandatory</Typography>
                    <div className={styles['AppIntegration__content__left__list']}>
                      {isLoading ? (
                        <LoadingBox />
                      ) : (
                        <div>
                          <div className={styles.headerList}>
                            <span>{'Convrt'}</span>
                            <span>{'Hubspot'}</span>
                          </div>
                          {hubspotIntegrationList
                            .filter((item) => item.group === 'Contacts')
                            .map((item, index) => (
                              <SelectRow
                                key={index}
                                items={isArray(newItems) ? newItems.filter((item) => item.label === 'Contacts') : []}
                                onOptionSelect={handleOptionSelect}
                                row={item}
                                hubspot={hubspotProps?.response}
                                showDeleteRow={false}
                                deleteRow={handleDeleteRow}
                                updateWarning={handleWarningUpdate}
                                enableMap={true}
                                selectedHubspot={selectedHubspot}
                              />
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<>Company</>} key='company'>
                  <div className={styles['AppIntegration__content__left__mandatory']}>
                    <Typography>Mandatory</Typography>

                    <div className={styles['AppIntegration__content__left__list']}>
                      {isLoading ? (
                        <LoadingBox />
                      ) : (
                        <div>
                          <div className={styles.headerList}>
                            <span>{'Convrt'}</span>
                            <span>{'Hubspot'}</span>
                          </div>
                          {hubspotIntegrationList
                            .filter((item) => item.group === 'Companies')
                            .map((item, index) => (
                              <SelectRow
                                key={index}
                                items={isArray(newItems) ? newItems.filter((item) => item.label === 'Company') : []}
                                onOptionSelect={handleOptionSelect}
                                row={item}
                                hubspot={hubspotProps?.response}
                                showDeleteRow={false}
                                deleteRow={handleDeleteRow}
                                updateWarning={handleWarningUpdate}
                                enableMap
                                selectedHubspot={selectedHubspot}
                              />
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<>Campaign</>} key='campaign'>
                  <div className={styles['AppIntegration__content__left__mandatory']}>
                    <div className={styles['AppIntegration__content__left__list']}>
                      <Typography>Campaign</Typography>

                      <div className={styles.headerList}>
                        <span>{'Convrt'}</span>
                        <span>{'Hubspot'}</span>
                      </div>
                      {hubspotIntegrationList
                        .filter((item) => item.group === 'Campaign')
                        .map((item, index) => (
                          <SelectRow
                            key={index}
                            items={isArray(newItems) ? newItems.filter((item) => item.label === 'Campaign') : []}
                            onOptionSelect={handleOptionSelect}
                            row={item}
                            hubspot={hubspotProps?.response}
                            showDeleteRow={false}
                            deleteRow={deleteRowFieldMapping}
                            updateWarning={handleWarningUpdate}
                            selectedHubspot={selectedHubspot}
                          />
                        ))}
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
              <div className={styles['AppIntegration__content__left__custom']}>
                <Typography>Custom</Typography>
                <div className={styles['AppIntegration__content__left__list']}>
                  <div className={styles.headerList}>
                    <span>{'Convrt'}</span>
                    <span>{'Hubspot'}</span>
                  </div>
                  {hubspotIntegrationList
                    .filter((item) => item.group?.includes(`custom:${activeTab}`))
                    .map((item, index) => (
                      <SelectRow
                        key={index}
                        items={isArray(newItems) ? customItems : []}
                        onOptionSelect={handleOptionSelect}
                        row={item}
                        hubspot={hubspotProps?.response}
                        showDeleteRow={true}
                        deleteRow={handleDeleteRow}
                        updateWarning={handleWarningUpdate}
                        enableMap
                      />
                    ))}
                </div>
                <Button
                  type='text'
                  icon={<PlusCircleOutlined />}
                  className={styles['AppIntegration__header__right__text']}
                  onClick={() => handleAddItemsToMappingList(`custom:${activeTab}`)}>
                  Add another
                </Button>
              </div>
              <div className={styles['AppIntegration__content__rightBtn']}>
                <Button
                  type='primary'
                  disabled={isLoading || isFetchingAddHubspot || isWarning}
                  onClick={
                    !isLoading
                      ? () => {
                          refetchAddHubspotFieldMapping({
                            hubspotFieldMapping: hubspotIntegrationList,
                            isSaveFieldMapping: true,
                          })
                            .unwrap()
                            .then(() => {
                              success()
                            })
                        }
                      : () => {}
                  }>
                  Save
                </Button>
              </div>
            </div>
          </Col>
          <Col span={9}>
            <div className={styles.connectionInfoBox}>
              <ConnectionInfoBox portalID={portalId} isLoadingPortalId={isFetchingAccountHubspotDetail} />
            </div>
            <SettingsBox />
          </Col>
        </Row>
      </div>
      {isOpenUnlink && (
        <HubspotUnlinkModal
          open={isOpenUnlink}
          onCancel={() => setIsOpenUnlink(false)}
          onSubmit={() => {
            unlinkHubspot().then((rs: any) => {
              // console.log(rs)
              if (rs.err) {
                message.error('Some thing went wrong. Please try again')
                return
              }
              message.success('Unlink successfully')
              navigate('/settings', { state: { action: 'unlink' } })
              setCurrentDetailSocial('')
            })
          }}
          email={email}
        />
      )}
      {contextHolder}
    </div>
  )
}

export default AppIntegration

import React from 'react'
import { Tabs, Button } from 'antd'
import styles from './CampaignSummarySidebar.module.scss'
import ViewLeadsList from './ViewLeadsList'
import CampaignSummaryCanvas from './CampaignSummaryCanvas'
import useCampaignNavigation from '../Helpers/useCampaignNavigation'
import { useAppSelector } from 'state'

const CampaignSummaryContent: React.FC = () => {
  const { navigateWithCampaignId } = useCampaignNavigation()
  const { custom_flow_id, use_custom_flow } = useAppSelector((state) => state.CampaignBuilder)

  const operations = (
    <Button
      onClick={() => {
        navigateWithCampaignId(
          `/outreach/create-new-campaign/${
            custom_flow_id || use_custom_flow ? 'campaign-builder' : 'edit-manually/view'
          }`,
          { state: { prevPath: true } },
        )
      }}>
      Edit
    </Button>
  )
  return (
    <div className={`${styles['CampaignSummaryContent']} flex flex-column relative overflow-auto max-h-full`}>
      <Tabs defaultActiveKey='1' tabBarExtraContent={operations} className='CampaignSummaryContent_tabs'>
        <Tabs.TabPane tab='View Campaign Flow' key='1' style={{ position: 'relative' }}>
          <CampaignSummaryCanvas />
        </Tabs.TabPane>
        <Tabs.TabPane tab='View Leads List' key='2'>
          <ViewLeadsList />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default CampaignSummaryContent

import { createSlice } from '@reduxjs/toolkit'

interface ICampaignBuilderSlice {
  status: string
  builder_data: any
  builder_nodes: any
  builder_edges: any
  updated_builder_data: any
  updated_builder_nodes: any
  updated_builder_edges: any
  builder_setup_data: any
  builder_incomplete_nodes: any
  selected_builder_node: any
  use_custom_flow: boolean
  custom_flow_id: any
  open_delete_modal: boolean
  flag_campaign_actions: string[] // An array to check which steps we should create new campaign_actions for (for a running campaign)
}

const initialState = {
  status: '',
  builder_data: {},
  builder_nodes: [],
  builder_edges: [],
  updated_builder_data: {}, // Used for Saving
  updated_builder_nodes: [], // Used for Saving
  updated_builder_edges: [], // Used for Saving
  builder_setup_data: {},
  selected_builder_node: {},
  builder_incomplete_nodes: [],
  use_custom_flow: false,
  custom_flow_id: '',
  open_delete_modal: false,
  flag_campaign_actions: [],
} as ICampaignBuilderSlice

export const CampaignBuilderSlice = createSlice({
  name: 'outreachCampaign',
  initialState,
  reducers: {
    SET_BUILDER_DATA(state, action) {
      state.status = 'succeeded'
      state.builder_data = action.payload
      state.updated_builder_data = action.payload
    },
    SET_BUILDER_NODES(state, action) {
      state.status = 'succeeded'
      state.builder_nodes = action.payload
      state.updated_builder_nodes = action.payload
    },
    SET_BUILDER_EDGES(state, action) {
      state.status = 'succeeded'
      state.builder_edges = action.payload
      state.updated_builder_edges = action.payload
    },
    SET_UPDATED_BUILDER_DATA(state, action) {
      state.status = 'succeeded'
      state.updated_builder_data = action.payload
    },
    SET_UPDATED_BUILDER_NODES(state, action) {
      state.status = 'succeeded'
      state.updated_builder_nodes = action.payload
    },
    SET_UPDATED_BUILDER_EDGES(state, action) {
      state.status = 'succeeded'
      state.updated_builder_edges = action.payload
    },
    SET_BUILDER_SETUP_DATA(state, action) {
      const { id, data } = action.payload
      state.status = 'succeeded'
      if (!state.builder_setup_data) {
        state.builder_setup_data = {}
      }
      state.builder_setup_data[id] = data
    },
    RESET_BUILDER_SETUP_DATA(state) {
      state.status = 'succeeded'
      state.builder_setup_data = {}
    },
    REMOVE_BUILDER_SETUP_DATA_IDS(state, action) {
      const ids = action.payload
      if (Array.isArray(ids)) {
        ids.forEach((id) => {
          delete state.builder_setup_data[id]
        })
      }
      state.status = 'succeeded'
    },
    SET_SELECTED_BUILDER_NODE(state, action) {
      state.status = 'succeeded'
      state.selected_builder_node = action.payload
    },
    SET_USE_CUSTOM_FLOW(state, action) {
      state.status = 'succeeded'
      state.use_custom_flow = action.payload
    },
    SET_BUILDER_INCOMPLETE_NODES(state, action) {
      state.status = 'succeeded'
      state.builder_incomplete_nodes = action.payload
    },
    SET_CUSTOM_FLOW_ID(state, action) {
      state.status = 'succeeded'
      state.custom_flow_id = action.payload
    },
    SET_OPEN_DELETE_MODAL(state, action) {
      state.status = 'succeeded'
      state.open_delete_modal = action.payload
    },
    SET_FLAG_CAMPAIGN_ACTIONS(state, action) {
      state.status = 'succeeded'
      const targetFlag = action.payload

      if (targetFlag === null || targetFlag === undefined) {
        state.flag_campaign_actions = []
        return
      }

      if (state.flag_campaign_actions.includes(targetFlag)) {
        state.flag_campaign_actions = state.flag_campaign_actions.filter((flag) => flag !== targetFlag)
      } else {
        state.flag_campaign_actions.push(targetFlag)
      }
    },

    TOGGLE_DISABLE_BUILDER_NODE(state, action) {
      state.status = 'succeeded'
      const currentNode = state.updated_builder_data?.nodes.find((n: any) => n.id === action.payload)
      const parentNodeId = currentNode?.data?.parentId
      const parentNode = state.updated_builder_data?.nodes.find((n: any) => n.id === parentNodeId)

      if (parentNode?.data?.disabled) return

      const edges = state.updated_builder_data.edges
      const updatedNodes = state.updated_builder_data?.nodes?.map((node: any) => {
        // Step 1: Find the node to toggle the 'disabled' state
        if (node.id === action.payload) {
          const newDisabledState = !node.data.disabled
          return {
            ...node,
            data: {
              ...node.data,
              disabled: newDisabledState, // Toggle the parent node's disabled state
            },
          }
        }
        return node
      })

      let keepDisable = false

      // Step 2: Recursively disable the child nodes and their descendants
      const disableDescendants = (parentId: string, nodes: any[]) => {
        const childNodes = nodes.filter((node: any) => node.data.parentId === parentId)
        if (childNodes.length > 1 || parentId === action.payload || keepDisable) {
          if (parentId !== action.payload) {
            keepDisable = true
          }
          // For each child, set its disabled state based on the parent
          childNodes.forEach((childNode) => {
            const parentNode = updatedNodes.find((n: any) => n.id === parentId)
            const newDisabledState = parentNode?.data.disabled ?? false // Inherit parent's disabled state
            // Disable the child node
            childNode.data.disabled = newDisabledState

            // Now check the child's children recursively
            disableDescendants(childNode.id, updatedNodes) // Recurse for the next level of descendants
          })
        }
      }

      // Start recursion from the node being toggled
      updatedNodes.forEach((node: any) => {
        if (node.id === action.payload) {
          disableDescendants(node.id, updatedNodes) // Recursively disable all descendants
        }
      })

      state.updated_builder_data = { nodes: updatedNodes, edges: edges }
    },

    RESET_BUILDER(state) {
      return initialState
    },
  },
})

export const {
  SET_BUILDER_DATA,
  SET_BUILDER_NODES,
  SET_BUILDER_EDGES,
  SET_UPDATED_BUILDER_DATA,
  SET_UPDATED_BUILDER_NODES,
  SET_UPDATED_BUILDER_EDGES,
  SET_BUILDER_SETUP_DATA,
  RESET_BUILDER_SETUP_DATA,
  SET_SELECTED_BUILDER_NODE,
  SET_USE_CUSTOM_FLOW,
  SET_CUSTOM_FLOW_ID,
  SET_BUILDER_INCOMPLETE_NODES,
  SET_OPEN_DELETE_MODAL,
  REMOVE_BUILDER_SETUP_DATA_IDS,
  SET_FLAG_CAMPAIGN_ACTIONS,
  TOGGLE_DISABLE_BUILDER_NODE,
  RESET_BUILDER,
} = CampaignBuilderSlice.actions
export const CampaignBuilderReducer = CampaignBuilderSlice.reducer

import filters_arrow from 'LEGACY/Assets/svg/filters_arrow.svg'
import dummy_video from 'LEGACY/Assets/svg/dummy_video.svg'
import platform_image from 'LEGACY/Assets/svg/platform_image.svg'
import styles from './StartScreen.module.scss'
import RecentFilters from './components/RecentFilters'
import { useLocation, useNavigate } from 'react-router-dom'
import { get } from 'LEGACY/API/axios'
import { SET_FILTER, SET_SELECTED_COMPANIES } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'
import { SET_SELECTED_CONTACTS } from 'LEGACY/Research/contacts/state/slice/contactsSlice'
import { useAppDispatch } from 'state/hooks'
import { useActiveFilters } from 'LEGACY/common/hooks/useFilters'
import { useEffect, useState } from 'react'
import SavedFilters from './components/SavedFilters'
import { useLazyGetSaveFiltersQuery } from 'LEGACY/API/CompaniesApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useShowStartScreen } from 'common/providers/ShowStartScreenProvider'

type Route = 'companies' | 'contacts'

const StartScreen = () => {
  const [recentFilters, setRecentFilters] = useState<any[]>([])
  const [savedFilters, setSavedFilters] = useState<any[]>([])
  const [isLoadingRecentFilters, setIsLoadingRecentFilters] = useState(false)
  const [isLoadingSavedFilters, setIsLoadingSavedFilters] = useState(false)
  const [refetchGetSaveFilters] = useLazyGetSaveFiltersQuery()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userDetails } = useAuth()
  const [, setActiveFilters] = useActiveFilters()
  const { setShowStartScreen } = useShowStartScreen()
  const { pathname } = useLocation()
  const pageLookUp = {
    home: 'Home',
    events: 'Events',
    contacts: 'People',
    outreach: 'Outreach',
    companies: 'Research',
    settings: 'Profile Settings',
    company_admin: 'Company Admin Panel',
  }
  const locationPathName = (useLocation().pathname.split('/')[1] as keyof typeof pageLookUp) || 'home'
  const user = localStorage.getItem('user') || '{}'
  const userData = JSON.parse(user)
  const has_filtered = userData?.has_filtered

  const newSize = 600
  document.documentElement.style.setProperty('--start-screen-height', `${newSize}px`)

  const handleFilter = (filterObject: any, route: Route) => {
    setShowStartScreen(false)
    setActiveFilters(filterObject)
    dispatch(SET_FILTER(filterObject))

    if (!pathname.includes(route)) {
      navigate(`/${route}`)
    }
  }

  const resetSelected = () => {
    dispatch(SET_SELECTED_CONTACTS([]))
    dispatch(SET_SELECTED_COMPANIES([]))
  }

  useEffect(() => {
    const fetchRecentFilters = async () => {
      setIsLoadingRecentFilters(true)
      const { data } = await get(`/user/filter/${userDetails?.id}`)
      setIsLoadingRecentFilters(false)
      const tempArra = data?.recentFilters.map((item: any) => {
        const { companyList, contactsList, ...filter } = item.filter
        return { filter, route: item.route, created_at: item.created_at }
      })

      const isEmptyObject = (obj: any) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false
          }
        }
        return true
      }
      setRecentFilters(tempArra.filter((item: any) => !isEmptyObject(item.filter)))
    }

    fetchRecentFilters()
    resetSelected()
  }, [])

  useEffect(() => {
    const fetchDataCompanyLists = async () => {
      setIsLoadingSavedFilters(true)
      const filterData = await refetchGetSaveFilters()
      let tempFilter = Array.isArray(filterData?.data) ? [...filterData.data] : []
      const updatedTempFilter = tempFilter.map((filterItem) => {
        const tempFilterCopy = { ...filterItem.filter }

        if (locationPathName === 'contacts') {
          if (tempFilterCopy.companyList) {
            tempFilterCopy.contactsList = tempFilterCopy.companyList
            delete tempFilterCopy.companyList
          }
          if (tempFilterCopy.country) {
            tempFilterCopy.contactsCountry = tempFilterCopy.country
            delete tempFilterCopy.country
          }
          if (tempFilterCopy.searchBarCompany) {
            delete tempFilterCopy.searchBarCompany
          }
        }

        if (locationPathName === 'companies') {
          if (tempFilterCopy.contactsList) {
            tempFilterCopy.companyList = tempFilterCopy.contactsList
            delete tempFilterCopy.contactsList
          }
          if (tempFilterCopy.contactsCountry) {
            tempFilterCopy.country = tempFilterCopy.contactsCountry
            delete tempFilterCopy.contactsCountry
          }
          if (tempFilterCopy.departments) {
            delete tempFilterCopy.departments
          }
          if (tempFilterCopy.jobTitle) {
            delete tempFilterCopy.jobTitle
          }
          if (tempFilterCopy.managementLevel) {
            delete tempFilterCopy.managementLevel
          }
          if (tempFilterCopy.companiesName) {
            delete tempFilterCopy.companiesName
          }
          if (tempFilterCopy.searchBarContact) {
            delete tempFilterCopy.searchBarContact
          }
        }

        return {
          ...filterItem,
          filter: tempFilterCopy,
        }
      })

      setSavedFilters(updatedTempFilter)
      setIsLoadingSavedFilters(false)
    }
    fetchDataCompanyLists()
  }, [])

  return (
    <div className={styles.container}>
      {userDetails?.has_filtered || has_filtered || true ? (
        <>
          <div className={styles.topHeader}>
            <img src={platform_image} alt='' />
            <p>
              Start your prospecting by applying
              <br />
              any filter in the left panel
            </p>
          </div>
          <div className={styles.filters}>
            <RecentFilters
              isLoading={isLoadingRecentFilters}
              recentFilters={recentFilters}
              handleFilter={(filterObject: any, route: Route) => handleFilter(filterObject, route)}
            />
            <SavedFilters
              isLoading={isLoadingSavedFilters}
              savedFilters={savedFilters}
              handleFilter={(filterObject: any, route: Route) => handleFilter(filterObject, route)}
              locationPathName={locationPathName}
            />
          </div>
        </>
      ) : (
        <div className={styles.main}>
          <img src={dummy_video} alt='' />
          <p>
            Welcome To Convrt!
            <br />
            Watch this quick tour to help
            <br />
            you get started or start
            <br />
            filtering companies/people.
          </p>
        </div>
      )}

      <div className={styles.arrow}>
        <p>
          Begin your search
          <br />
          here
        </p>
        <img src={filters_arrow} alt='' />
      </div>
    </div>
  )
}

export default StartScreen

// Old Start Screen
// import { useEffect, useState } from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'

// import LoadingBox from 'LEGACY/common/components/LoadingBox/LoadingBox'
// import { useAuth } from 'common/hooks/useAuth.hooks'
// import { get, post } from 'LEGACY/common/api/axios'
// import RecentFilters from './components/RecentFilters'
// import { SET_FILTER, SET_SELECTED_COMPANIES } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'
// import { SET_SELECTED_CONTACTS } from 'LEGACY/Research/contacts/state/slice/contactsSlice'

// import DiscoverFilters from './components/DiscoverFilters'
// import { shuffleArray } from 'LEGACY/common/utils/shuffleArray'
// import { useAppDispatch } from 'state/hooks'
// import { useActiveFilters } from 'LEGACY/common/hooks/useFilters'
// import { useShowStartScreen } from 'LEGACY/common/providers/ShowStartScreenProvider'
// import { discoverCompanyFilters, discoverContactFilters } from './utils/discover-filters'

// import styles from './StartScreen.module.scss'

// type Route = 'companies' | 'contacts'

// const StartScreen = () => {
//   const [recentFilters, setRecentFilters] = useState<any[]>()
//   const [companyFilters, setCompanyFilters] = useState<any>()
//   const [contactFilters, setContactFilters] = useState<any>()

//   const navigate = useNavigate()
//   const dispatch = useAppDispatch()
//   const { userDetails } = useAuth()
//   const [, setActiveFilters] = useActiveFilters()
//   const { setShowStartScreen } = useShowStartScreen()
//   const { pathname } = useLocation()

//   useEffect(() => {
//     const userDiscoverCompanyFilters = JSON.parse(localStorage.getItem('discover_company_filters') || 'null')
//     const userDiscoverContactFilters = JSON.parse(localStorage.getItem('discover_contact_filters') || 'null')

//     if (!userDiscoverCompanyFilters) {
//       const maxResults = 3

//       const fetchCompanyFilters = async () => {
//         const results = await shuffleArray(discoverCompanyFilters)
//           ?.map((getFilter) => getFilter(userDetails?.funnelData))
//           ?.filter((filter) => !!filter)
//           ?.reduce(
//             async (accumulator, filter) => {
//               const { filters, filtersWithResults } = await accumulator

//               let filtersWithResultsCount = filtersWithResults

//               if (filtersWithResults >= maxResults) return accumulator

//               const {
//                 status,
//                 data: { count, pictures },
//               } = await post(`/companies/preview`, filter?.filterObject)

//               if (status !== 200) return filters

//               if (count > 0) filtersWithResultsCount += 1

//               const updatedFilters = [...filters, { ...filter, count, pictures }]

//               return { filters: updatedFilters, filtersWithResults: filtersWithResultsCount }
//             },
//             { filters: [], filtersWithResults: 0 },
//           )

//         const orderedFilters = results.filters?.filter((filter: any) => !!filter?.count)?.slice(0, maxResults)
//         setCompanyFilters(orderedFilters)
//         localStorage.setItem('discover_company_filters', JSON.stringify(orderedFilters))
//       }

//       fetchCompanyFilters()
//     } else {
//       setCompanyFilters(userDiscoverCompanyFilters)
//     }

//     if (!userDiscoverContactFilters) {
//       const maxResults = 3

//       const fetchContactFilters = async () => {
//         const results = await shuffleArray(discoverContactFilters)
//           ?.map((getFilter) => getFilter(userDetails?.funnelData))
//           ?.filter((filter) => !!filter)
//           ?.reduce(
//             async (accumulator, filter) => {
//               const { filters, filtersWithResults } = await accumulator

//               let filtersWithResultsCount = filtersWithResults

//               if (filtersWithResults >= maxResults) return accumulator

//               const {
//                 status,
//                 data: { count, pictures },
//               } = await post(`/contacts/preview`, filter?.filterObject)

//               if (status !== 200) return filters

//               if (count > 0) filtersWithResultsCount += 1

//               const updatedFilters = [...filters, { ...filter, count, pictures }]

//               return { filters: updatedFilters, filtersWithResults: filtersWithResultsCount }
//             },
//             { filters: [], filtersWithResults: 0 },
//           )

//         const orderedFilters = results.filters?.filter((filter: any) => !!filter?.count)?.slice(0, maxResults)
//         setContactFilters(orderedFilters)
//         localStorage.setItem('discover_contact_filters', JSON.stringify(orderedFilters))
//       }

//       fetchContactFilters()
//     } else {
//       setContactFilters(userDiscoverContactFilters)
//     }

//     const fetchRecentFilters = async () => {
//       const { data } = await get(`/user/filter/${userDetails?.id}`)

//       const tempArra = data?.recentFilters.map((item: any) => {
//         const { companyList, contactsList, ...filter } = item.filter

//         return { filter, route: item.route }
//       })

//       const isEmptyObject = (obj: any) => {
//         for (const key in obj) {
//           if (obj.hasOwnProperty(key)) {
//             return false
//           }
//         }
//         return true
//       }

//       setRecentFilters(tempArra.filter((item: any) => !isEmptyObject(item.filter)))
//     }

//     fetchRecentFilters()
//     resetSelected()
//   }, [])

//   const resetSelected = () => {
//     dispatch(SET_SELECTED_CONTACTS([]))
//     dispatch(SET_SELECTED_COMPANIES([]))
//   }

//   const handleFilter = (filterObject: any, route: Route) => {
//     setShowStartScreen(false)
//     setActiveFilters(filterObject)
//     dispatch(SET_FILTER(filterObject))

//     if (!pathname.includes(route)) navigate(`/${route}`)
//   }

//   return (
//     <div className={styles.container}>
//       <div className={styles.title}>{`Start Your Companies Search by Applying Any Filter in the Left Panel.`}</div>
//       <h4 className={styles.subtitle}>{`Discover Companies:`}</h4>
//       {companyFilters ? (
//         <DiscoverFilters
//           filters={companyFilters.map((filter: any) => ({
//             ...filter,
//             label: filter.label.replaceAll('Web2 Companies', 'Web2'),
//           }))}
//           handleFilter={(filterObject: any) => handleFilter(filterObject, 'companies')}
//         />
//       ) : (
//         <LoadingBox />
//       )}
//       <h4 className={styles.subtitle}>{`Discover Contacts:`}</h4>
//       {contactFilters ? (
//         <DiscoverFilters
//           filters={contactFilters.map((filter: any) => ({
//             ...filter,
//             label: filter.label.replaceAll('Web2 Companies', 'Web2'),
//           }))}
//           handleFilter={(filterObject: any) => handleFilter(filterObject, 'contacts')}
//           isCompany={false}
//         />
//       ) : (
//         <LoadingBox />
//       )}
//       {!!recentFilters?.length && (
//         <RecentFilters
//           recentFilters={recentFilters}
//           handleFilter={(filterObject: any, route: Route) => handleFilter(filterObject, route)}
//         />
//       )}
//     </div>
//   )
// }

// export default StartScreen

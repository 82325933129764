import { Button, Divider, Typography } from 'antd'
import FileSaver from 'file-saver'
import { DownloadOutlined } from '@ant-design/icons'

import styles from './TemplateGuildline.module.scss'
import { useLazyDownloadCSVQuery } from 'common/APIs/OutreachApi'

export const TemplateGuildLine = (props: any) => {
  const { fileName } = props
  const [refetcDownloadCSV] = useLazyDownloadCSVQuery()

  return (
    <div className={styles.templateGuildline}>
      <Typography className={styles.templateGuildline__title}>Template And guideliends</Typography>
      <Typography className={styles.templateGuildline__description}>
        Please use the sample template to ensure your information is in the correct format before uploading.
      </Typography>
      <Divider />
      <div className={styles.templateGuildline__box}>
        <div className={styles.templateGuildline__box__left}>
          <Typography>Leads Sample File</Typography>
          <Typography>Download a sample CSV file to see the required format for importing leads.</Typography>
        </div>

        <Button
          icon={<DownloadOutlined />}
          onClick={async () => {
            const result = await refetcDownloadCSV({ fileName: 'csv_template_leads_ai.csv' })
            const csvData = new Blob([result.error.data], { type: 'text/csv;charset=utf-8;' })
            FileSaver.saveAs(csvData, fileName)
          }}>
          Download
        </Button>
      </div>
    </div>
  )
}

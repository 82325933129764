import React, { useEffect, useState } from 'react'
import {
  Button,
  Popover,
  Select,
  Input,
  message,
  Switch,
  Slider,
  Tooltip,
  Modal,
  Layout,
  SliderSingleProps,
} from 'antd'
import instagram_popover from 'common/assets/png/InstagramPopover.png'
import facebook_popover from 'common/assets/png/FacebookPopover.png'

import crown from 'common/assets/svg/crown_icon.svg'
import instagram from 'common/assets/svg/insta_icon.svg'
import linkedin from 'common/assets/svg/linked_icon.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'
// import telegram from 'common/assets/svg/tele_icon.svg'
// import discord from 'common/assets/svg/disc_icon.svg'
// import twitter from 'common/assets/svg/xtwit_icon.svg'

import linkedin_sales_nav from 'common/assets/png/linkedin_sales_nav.png'
import linkedin_search from 'common/assets/png/linkedin_search.png'
import eye_icon from 'common/assets/svg/eye_icon.svg'
import eye_icon_purple from 'common/assets/svg/eye_icon_purple.svg'
import points_icon from 'common/assets/svg/points_icon.svg'

import styles from './MyGroups.module.scss'
import {
  useLazyImportGroupQuery,
  useLazySetGroupTrackModeQuery,
  useLazyCollectLeadsQuery,
  useLazyGetExploreQuery,
  useLazyGetMyHubQuery,
  useLazyCollectWeb3LeadsQuery,
} from 'common/APIs/OutreachAI-API'
import validator from 'validator'
import { SET_INITIAL_GROUP_DATA } from 'common/components/Campaigns/state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { Content, Header } from 'antd/es/layout/layout'
import ProgressBar from 'common/components/Campaigns/HomePage/CampaignsTab/ProgressBar'
import { trackEvent } from 'eventConfig'
import { AllFailedCollections } from 'common/components/Campaigns/Helpers/CampaignConstants'

const { Option } = Select

interface MyGroupImportProps {
  refinement: any
  pointsStore: any
  openCustomImport: any
  setOpenCustomImport: any
  importData: any
  setImportData: any
  customCollect: any
}

export const MyGroupImport: React.FC<MyGroupImportProps> = ({
  refinement,
  pointsStore,
  openCustomImport,
  setOpenCustomImport,
  importData,
  setImportData,
  customCollect,
}) => {
  const dispatch = useAppDispatch()
  const [user] = useLocalStorage('user', null)
  const { addons, linkedin: linkedinAccounts, facebook: facebookAccounts } = user
  const { importLinkedin, importSalesNav } = addons || {}
  const linkedinOptions = linkedinAccounts?.map((account: any) => account.email)
  const facebookOptions = facebookAccounts?.map((account: any) => account.username)
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const [importGroup] = useLazyImportGroupQuery()
  const [getExplore] = useLazyGetExploreQuery()
  const [getMyHub] = useLazyGetMyHubQuery()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sourceType, setSourceType] = useState<any>(importData?.sourceType || null)
  const truePlatformsArray = Object.entries(active_platforms)
    ?.filter(([key, value]) => value)
    ?.map(([key, value]) => key)
  const [platform, setPlatform] = useState<any>(importData?.plaform || truePlatformsArray?.[0])
  const [account, setAccount] = useState<any>(
    platform === 'linkedin'
      ? linkedinOptions?.[0] || null
      : platform === 'facebook'
      ? facebookOptions?.[0] || null
      : null,
  )
  const [url, setUrl] = useState(importData?.url || '')
  const [hashtag, setHashtag] = useState('')
  const [searchName, setSearchName] = useState('')
  const [numberOfLeads, setNumberOfLeads] = useState(0)
  const instagramMaxImport =
    sourceType === 'hashtag'
      ? addons?.max_import_hashtag_instagram_leads
      : addons?.max_import_group_instagram_leads || 1000
  const max_import_group_leads =
    platform === 'instagram'
      ? instagramMaxImport
      : platform === 'facebook'
      ? addons?.max_import_group_facebook_leads || 1000
      : platform === 'linkedin'
      ? addons?.max_import_group_linkedin_leads || 200
      : addons?.max_import_group_leads || 1000

  const cost = pointsStore?.pointsStore['collect'] || 1
  const totalCost = Math.trunc(numberOfLeads * cost)?.toLocaleString()

  useEffect(() => {
    if (importData) {
      setPlatform(importData?.platform)
      setSourceType(importData?.source_type)
      setUrl(importData?.url)
    }
  }, [importData])
  // const handleOpenChange = (open: boolean) => {
  //   setOpen(open)
  //   setImportData(null)
  //   setPlatform(truePlatformsArray?.[0])
  //   setSourceType(null)
  //   setUrl('')
  // }

  const handleSelectChange = (value: any) => {
    setPlatform(value)
    setSourceType(null)
    setAccount(null)
    setUrl('')
    setSearchName('')
    setNumberOfLeads(0)
  }

  const handleSelectSourceTypeChange = (value: any) => {
    setSourceType(value.key)
    setNumberOfLeads(0)
  }

  const handleSelectAccountChange = (value: any) => {
    setAccount(value.key)
  }

  const handleInputChange = (e: any) => {
    const value = e.target.value
    setUrl(value)
  }

  const handleInputNameChange = (e: any) => {
    const value = e.target.value
    setSearchName(value)
  }

  const handleSliderChange = (e: any) => {
    setNumberOfLeads(e)
  }

  const validateUrl = (value: string) => {
    if (validator.isURL(value) && value?.includes(platform)) {
      return true
    } else {
      return false
    }
  }

  const getGroupNameFromUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url)
      const pathname = parsedUrl.pathname
      const segments = pathname.split('/').filter(Boolean)
      return segments[segments.length - 1]
    } catch (error) {
      console.error('Invalid URL:', error)
      return null
    }
  }

  const handleImportGroup = async () => {
    try {
      setLoading(true)

      if (!sourceType) {
        message.error('Please select a source type to proceed.')
        return setLoading(false)
      }
      if (sourceType === 'hashtag' && (!hashtag || hashtag.replace(' ', '').length === 0)) {
        message.error('Please insert a valid hashtag.')
        setLoading(false)
        return
      }

      let parsedUrl
      if (sourceType !== 'hashtag') {
        const isValidUrl = validateUrl(url)
        if (!isValidUrl && platform !== 'instagram') {
          message.error('Please insert a valid url that matches the platform you selected.')
          return setLoading(false)
        }
        parsedUrl = platform === 'instagram' || platform === 'linkedin' ? url : getGroupNameFromUrl(url)
      } else {
        parsedUrl = hashtag
      }
      const { data, error } = await importGroup({
        platform: platform,
        sourceType: sourceType,
        url: parsedUrl,
        numberOfLeads: numberOfLeads,
        ...(platform === 'linkedin' && { searchName: searchName }),
        ...((platform === 'linkedin' || platform === 'facebook') && { account: account }),
      })

      trackEvent('IMPORT_CUSTOM_LEADS', {
        platform: platform,
        sourceType: sourceType,
        url: parsedUrl,
        numberOfLeads: numberOfLeads,
        ...(platform === 'linkedin' && { searchName: searchName }),
        ...(platform === 'linkedin' && { account: account }),
        status: data?.status,
      })
      if (data?.status && !error) {
        message.success(`${sourceType === 'hashtag' ? 'Hashtag' : 'Suggestion'} imported successfully!`)
        // setPlatform(null)
        setUrl('')
        setOpen(false)
        // getGroups
        const { data } = await getExplore({})
        const { data: myHubData } = await getMyHub({})
        dispatch(
          SET_INITIAL_GROUP_DATA({
            ...data,
            ...myHubData,
          }),
        )
      } else if (error?.data?.status === false) {
        message.error(error?.data?.message)
      } else if (error?.data?.status === 'points') {
        message.error(error?.data?.message)
      } else {
        message.error('Something went wrong. Please try again.')
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const validate = () => {
    switch (platform) {
      case 'instagram':
        return !(sourceType && (url?.length > 0 || hashtag?.replace(' ', '').trim().length > 0) && numberOfLeads > 0)
      case 'facebook':
        return !(sourceType && url?.length > 0 && numberOfLeads > 0 && account)
      case 'linkedin':
        return !(searchName?.trim()?.length > 0 && sourceType && url?.length > 0 && numberOfLeads > 0 && account)
      default:
        return true
    }
  }

  useEffect(() => {
    if (openCustomImport) {
      setOpen(openCustomImport)
      setOpenCustomImport(false)
    }
  }, [openCustomImport])

  const sourceTypeOption =
    platform === 'linkedin'
      ? [
          { key: 'sales_nav', value: 'Sales Navigator Search URL', premium: importSalesNav === true ? false : true },
          { key: 'linkedin_search', value: 'LinkedIn Search URL', premium: importLinkedin === true ? false : true },
        ]
      : platform === 'instagram'
      ? [
          { key: 'followers', value: 'Page Followers', premium: platform === 'facebook' ? true : false },
          { key: 'hashtag', value: 'Hashtag', premium: false },
          { key: 'post_interactions', value: 'Post Likes & Comments', premium: true },
          { key: 'search_results', value: 'Search', premium: true },
        ]
      : [
          { key: 'members', value: 'Group Members', premium: platform === 'instagram' ? true : false },
          { key: 'followers', value: 'Page Followers', premium: platform === 'facebook' ? true : false },
          { key: 'hashtag', value: 'Hashtag', premium: true },
          { key: 'post_interactions', value: 'Post Likes & Comments', premium: true },
          { key: 'search_results', value: 'Search', premium: true },
        ]

  const linkedinSearchContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={linkedin_search} alt='' />
      <p>Insert LinkedIn URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the correct LinkedIn URL:
        <br />
        1. Perform a search on LinkedIn based on what you’re looking for (e.g., job titles, companies, industries).
        <br />
        2. Once the search results appear, select the <span style={{ fontWeight: 600 }}>People</span> tab to filter the
        results to people only.
        <br />
        3. Copy the URL from the browser's address bar and paste it here.
      </span>
    </div>
  )

  const linkedinSalesNavContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={linkedin_sales_nav} alt='' />
      <p>Insert Sales Navigator URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the correct Sales Navigator URL:
        <br />
        1. Perform a search in Sales Navigator and apply filters based on your criteria (e.g., job titles, companies,
        geographies).
        <br />
        2. Once the results are refined, copy the URL from Sales Navigator and paste it here.
      </span>
    </div>
  )

  const defaultLinkedinContent = (
    <div className={styles.popoverContentLinkedin}>
      <p>Insert LinkedIn URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the correct LinkedIn URL, follow these steps:
        <br />
        1. Perform a search on LinkedIn based on what you’re looking for (e.g., job titles, companies, industries).
        <br />
        2. Once the search results appear, select the <span style={{ fontWeight: 600 }}>People</span> tab to filter the
        results to people only.
        <br />
        3. Copy the URL from the browser's address bar and paste it here.
        <br />
        <br />
        If you’re using <span style={{ fontWeight: 600 }}>LinkedIn Sales Navigator</span>:
        <br />
        1. Perform a search and apply filters based on your criteria.
        <br />
        2. Once the results are refined, copy the URL from Sales Navigator and paste it here.
      </span>
    </div>
  )
  const instagramPopoverContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={instagram_popover} alt='' />
      <p>Enter Instagram username or page URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the right page to collect leads from:
        <br />
        1. Locate a page related to your niche that your prospects might follow, and ensure the followers are visible to
        more than just the page admin (e.g., “only CNN can view all followers”).
        <br />
        2. Copy the URL or username into the field below.
      </span>
    </div>
  )
  const facebookPopoverContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={facebook_popover} alt='' />
      <p>Enter Facebook group URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the right group to collect leads from:
        <br />
        1. Locate a group related to your niche that your prospects might join. Ensure it’s a public group, or if it’s
        private, verify that the user you're collecting from is a member and approved by the admins.
        <br />
        2. Copy the URL or username into the field below.
      </span>
    </div>
  )
  const getLinkedinPopoverContent = (sourceType: string) => {
    switch (sourceType) {
      case 'sales_nav':
        return linkedinSalesNavContent
      case 'linkedin_search':
        return linkedinSearchContent
      default:
        return defaultLinkedinContent
    }
  }
  const getInstagramPopoverContent = () => {
    return instagramPopoverContent
  }
  const getFacebookPopoverContent = () => {
    return facebookPopoverContent
  }
  const content = (
    <div className={styles.popoverContent}>
      <div className={styles.container}>
        <label style={{ marginBottom: '10px' }}>Channel</label>
        <Select
          onChange={handleSelectChange}
          value={platform}
          defaultValue={platform}
          placeholder='Select Channel'
          // disabled
          style={{ width: '100%', marginTop: '5px' }}>
          <Option value='instagram'>
            <div className={styles.option}>
              <img src={instagram} alt='Instagram' className={styles.icon} />
              <span>Instagram</span>
            </div>
          </Option>
          <Option value='facebook'>
            <div className={styles.option}>
              <img src={facebook} alt='Facebook' className={styles.icon} />
              <span>Facebook</span>
            </div>
          </Option>
          <Option value='linkedin'>
            <div className={styles.option}>
              <img src={linkedin} alt='LinkedIn' className={styles.icon} />
              <span>LinkedIn</span>
            </div>
          </Option>
          {/* <Option value='telegram'>
            <div className={styles.option}>
              <img src={telegram} alt='Telegram' className={styles.icon} />
              <span>Telegram</span>
            </div>
          </Option>
          <Option value='discord'>
            <div className={styles.option}>
              <img src={discord} alt='Discord' className={styles.icon} />
              <span>Discord</span>
            </div>
          </Option>
          <Option value='twitter'>
            <div className={styles.option}>
              <img src={twitter} alt='Twitter' className={styles.icon} />
              <span>Twitter</span>
            </div>
          </Option> */}
        </Select>
      </div>
      <div className={styles.container}>
        <label>Source Type</label>
        {/* <Select
          onChange={handleSelectSourceTypeChange}
          value={sourceType}
          defaultValue={sourceType}
          placeholder='Select where to gather leads from'
          style={{ width: '100%', marginTop: '5px' }}>
          {sourceTypeOption.map((type: any) => (
            <Option key={type.key} value={type.value}>
              <div className={styles.option}>
                <span>{type.value}</span>
              </div>
            </Option>
          ))}
        </Select> */}
        <Select
          onChange={handleSelectSourceTypeChange}
          value={sourceType}
          labelInValue
          placeholder='Select where to gather leads from'
          style={{ width: '100%', marginTop: '5px' }}>
          {sourceTypeOption.map((type: any) => (
            <Option key={type.key} value={type.key} disabled={type.premium}>
              <div className={styles.option}>
                <span style={type.premium ? { color: 'gray' } : {}}>{type.value}</span>
                {type.premium && (
                  <img style={{ marginLeft: '5px', width: '15px', height: '15px' }} src={crown} alt='' />
                )}
              </div>
            </Option>
          ))}
        </Select>
      </div>

      <div className={styles.container}>
        <>
          {sourceType === 'hashtag' ? (
            <>
              <label style={{ display: 'flex', alignItems: 'center' }}>Enter Hashtag</label>
            </>
          ) : (
            <label style={{ display: 'flex', alignItems: 'center' }}>
              Insert URL
              {platform === 'facebook' && (
                <>
                  <Popover trigger='hover' content={getFacebookPopoverContent()}>
                    <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                  </Popover>
                </>
              )}
              {platform === 'linkedin' && (
                <Popover trigger='hover' content={getLinkedinPopoverContent(sourceType)}>
                  <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </Popover>
              )}
              {platform === 'instagram' && (
                <Popover trigger='hover' content={getInstagramPopoverContent()}>
                  <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </Popover>
              )}
            </label>
          )}
        </>
        {sourceType === 'hashtag' ? (
          <>
            <Input
              value={hashtag}
              defaultValue={hashtag}
              addonBefore='#'
              onChange={(e) => setHashtag(e.target.value?.replace('#', ''))}
              style={{ marginTop: '5px' }}
              placeholder={'Enter Instagram hashtag'}
              required
            />
          </>
        ) : (
          <>
            <Input
              value={url}
              defaultValue={url}
              onChange={handleInputChange}
              style={{ marginTop: '5px' }}
              placeholder={
                platform === 'instagram'
                  ? 'Enter the username or URL you want to add.'
                  : 'Please Insert the URL you want to add'
              }
            />
          </>
        )}
      </div>
      {(platform === 'linkedin' || platform === 'facebook') && (
        <div className={styles.container}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            Account
            {platform === 'facebook' && (
              <Tooltip
                color='white'
                style={{ color: 'black' }}
                title={
                  <div style={{ color: 'black' }}>
                    If it’s a private group, please select an account that can view it, meaning the account is either
                    approved by the admins and already a member.
                  </div>
                }>
                <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </label>

          <Select
            onChange={handleSelectAccountChange}
            value={account}
            defaultValue={account}
            labelInValue
            placeholder='Select where to gather leads from'
            style={{ width: '100%', marginTop: '5px' }}>
            {(platform === 'linkedin' ? linkedinOptions : facebookOptions).map((acc: any) => (
              <Option key={acc} value={acc}>
                <div className={styles.option}>
                  <span>{acc}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      )}
      {platform === 'linkedin' && (
        <div className={styles.container}>
          <label style={{ display: 'flex', alignItems: 'center' }}>Search Name</label>
          <Input
            value={searchName}
            defaultValue={searchName}
            onChange={handleInputNameChange}
            style={{ marginTop: '5px' }}
            placeholder='Please include your custom search name'
          />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.flexDiv}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            Number of Leads
            <Tooltip
              overlayInnerStyle={{ background: 'white', color: 'black' }}
              title={`Choose how many leads to import from the selected group, up to ${max_import_group_leads} or the total number of leads in the group, whichever is lower.`}>
              <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
            </Tooltip>
          </label>
          <Tooltip
            style={{ width: '600px' }}
            overlayInnerStyle={{ background: 'white', color: 'black' }}
            title={
              <div className={styles.tooltipPoints}>
                <InfoCircleOutlined />{' '}
                <span>
                  Importing {numberOfLeads} costs {totalCost} Convrt Points
                </span>
              </div>
            }>
            <div className={styles.points}>
              <span>{totalCost}</span>
              <img src={points_icon} alt='' />
            </div>
          </Tooltip>
        </div>
        <Slider
          min={0}
          max={max_import_group_leads}
          value={numberOfLeads}
          defaultValue={numberOfLeads}
          step={sourceType === 'hashtag' ? 20 : 100}
          onChange={handleSliderChange}
          tooltip={{ formatter }}
        />
      </div>
      <div className={styles.footer}>
        <Button loading={loading} disabled={validate()} type='primary' onClick={handleImportGroup}>
          Import
        </Button>
      </div>
    </div>
  )

  return (
    <div className={styles.import}>
      <span style={{ color: 'black', fontWeight: 600 }}>My Hub</span>
      <Popover
        placement='bottomRight'
        content={content}
        title=''
        trigger='click'
        open={open}
        onOpenChange={customCollect}>
        <Button type='primary'>Collect Leads</Button>
      </Popover>
    </div>
  )
}

interface MyGroupsSwitchOptionsProps {
  group: any
  refinement: any
  myGroups?: boolean
}

export const MyGroupsSwitchOptions: React.FC<MyGroupsSwitchOptionsProps> = ({ group, refinement, myGroups }) => {
  // const [removeGroup] = useLazyRemoveGroupQuery()
  // const [refetchGroup] = useLazyRefetchGroupQuery()
  const [setGroupTrackMode] = useLazySetGroupTrackModeQuery()
  const [getExplore] = useLazyGetExploreQuery()
  const [getMyHub] = useLazyGetMyHubQuery()
  const [trackMode, setTrackMode] = useState(group?.track_mode)
  const [loadingTrackMode, setLoadingTrackMode] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const handleSwitchChange = async (checked: boolean) => {
    setLoadingTrackMode(true)
    setTrackMode(checked)
    await setGroupTrackMode({ platform: group.platform, group_id: group.username, trackMode: checked })
    // Get Groups
    setLoadingTrackMode(false)
    const { data } = await getExplore({})
    const { data: myHubData } = await getMyHub({})
    dispatch(
      SET_INITIAL_GROUP_DATA({
        ...data,
        ...myHubData,
      }),
    )
  }

  useEffect(() => {
    setTrackMode(group?.track_mode)
  }, [group])

  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: (
  //       <div className={styles.dropdown}>
  //         <img src={refetch_group} alt='' />
  //         <span>Request to Refetch</span>
  //       </div>
  //     ),
  //     onClick: (e: any) => handleMenuItemClick(e),
  //   },
  //   {
  //     key: '2',
  //     label: (
  //       <div className={styles.dropdown}>
  //         <img src={trash_icon_groups} alt='' />
  //         <span>Remove</span>
  //       </div>
  //     ),
  //     onClick: (e: any) => handleMenuItemClick(e),
  //   },
  // ]

  // const contentStyle: React.CSSProperties = {
  //   backgroundColor: 'white',
  //   borderRadius: '12px',
  //   boxShadow:
  //     '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  // }

  // const menuStyle: React.CSSProperties = {
  //   boxShadow: 'none',
  //   padding: 0,
  // }

  return (
    <div
      className={styles.myGroups}
      onClick={(e: any) => {
        e.stopPropagation()
      }}>
      <Switch
        defaultChecked={trackMode}
        checked={trackMode}
        className='custom-switch'
        onChange={handleSwitchChange}
        loading={loadingTrackMode}
      />
      {/* <Dropdown
        placement='bottomRight'
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{ padding: '8px 5px' }}
        dropdownRender={(menu) => (
          <div
            style={contentStyle}
            onClick={(e: any) => {
              e.stopPropagation()
            }}>
            {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
            <Space style={{ padding: '5px 12px' }}>
              <span>Tracking Mode On</span>
              <Switch
                defaultChecked={trackMode}
                checked={trackMode}
                className='custom-switch'
                onChange={handleSwitchChange}
                loading={loadingTrackMode}
              />
            </Space>
          </div>
        )}>
        <div className={styles.moreOptions}>
          <img src={three_dots_myGroups} alt='' />
        </div>
      </Dropdown> */}
    </div>
  )
}

interface CustomEyeButtonProps {
  eyeText: string
  buttonText: string
  onViewLeads: () => void
  onCollect: () => void
  myGroupsTab?: boolean
  collectionProgress: any
  isHovered: boolean
  collectedLeads: any
  totalRequested: any
  actualRequested: any
  status: any
  notFull: any
  platform: string
  hideProgressBar: boolean
  cumulative_leads_fetched: any
  name: string
  group?: any
  onLinkedinCollect?: (group: any) => void
}

export const CustomEyeButton: React.FC<CustomEyeButtonProps> = ({
  eyeText,
  buttonText,
  onViewLeads,
  onCollect,
  myGroupsTab,
  collectionProgress,
  isHovered,
  collectedLeads,
  totalRequested,
  actualRequested,
  status,
  notFull,
  platform,
  hideProgressBar,
  cumulative_leads_fetched,
  name,
  group,
  onLinkedinCollect,
}) => {
  const [isEyeHovered, setIsEyeHovered] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const animationClass = isHovered ? styles.fadeInUp : styles.fadeOutDown

  const isSuccess = status === 'partial_success' || (status === 'success' && notFull)
  const isReachLimit = status === 'reach_limit'
  const isPending = status === 'pending'
  const isInProgress = status === 'processing' || status === 'pending'
  const displayProgress = isSuccess || isReachLimit ? 100 : collectionProgress
  const isRestricted = status === 'restricted_access'
  const isNotFound = status === 'not_found'
  const didntFinish = group?.status !== 'completed'
  const isFailedAndHasLeads = AllFailedCollections.includes(group?.status) && group?.collected_leads > 0
  useEffect(() => {
    if (isHovered) {
      setIsVisible(true)
    } else {
      const timer = setTimeout(() => setIsVisible(false), 500) // Match the duration of fade-out animation
      return () => clearTimeout(timer)
    }
  }, [isHovered])

  if (isRestricted) {
    return (
      <div className={`${styles.restricted}`}>
        <span>The followers of this page are hidden. You were not charged. Please try a different page.</span>
      </div>
    )
  }
  if (isNotFound) {
    return (
      <div className={`${styles.restricted}`}>
        <span>The hashtag your requested was not found. You were not charged. Please try a different hashtag.</span>
      </div>
    )
  }
  return (
    <>
      {collectionProgress !== false &&
        (collectionProgress < 100 || isSuccess) &&
        myGroupsTab &&
        !hideProgressBar &&
        didntFinish && (
          <>
            {isFailedAndHasLeads ? (
              <>
                <div style={{ width: '100%' }}>
                  <div className={styles.groupImportanatStatus}>
                    <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
                      <Tooltip
                        title={`The ${group.platform === 'linkedin' ? 'search' : 'collection'} was not successful`}>
                        <WarningOutlined
                          style={{
                            position: 'relative',
                            marginRight: '5px',
                            color: 'orange',
                            cursor: 'pointer',
                            zIndex: 3333,
                          }}
                        />
                        {group.platform === 'linkedin' ? 'Search' : 'Collection'} failed.
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <Tooltip
                title={
                  isSuccess || isReachLimit ? (
                    ''
                  ) : isPending ? (
                    <span>Waiting for other tasks to finish...</span>
                  ) : (
                    <span>Importing leads; it will take a couple of minutes.</span>
                  )
                }>
                <div
                  className={`${styles.progress}`}
                  style={{
                    width: '100%',
                    // width: isHovered ? '35%' : '100%', // Adjust width based on visibility
                    transition: 'width 0.75s ease', // Add transition for smooth animation
                  }}>
                  <>
                    <p>
                      Collecting{' '}
                      {Number(collectedLeads)
                        ? Number(
                            collectedLeads -
                              (cumulative_leads_fetched || (collectedLeads > totalRequested ? totalRequested : 0)),
                          ).toLocaleString()
                        : '0'}{' '}
                      / {Number(actualRequested || totalRequested) ? totalRequested.toLocaleString() : '0'} leads
                      {isSuccess ||
                        (isReachLimit && (
                          <Tooltip
                            title={
                              isSuccess
                                ? `${actualRequested - collectedLeads} leads were not imported.`
                                : `These are the unique leads currently available from this hashtag, prioritizing account safety. Any missed leads will be refunded.`
                            }>
                            <WarningOutlined style={{ marginLeft: '5px', color: 'orange' }} />
                          </Tooltip>
                        ))}
                    </p>
                    <ProgressBar percentage={displayProgress} size='xl' />
                  </>
                </div>
              </Tooltip>
            )}
          </>
        )}
      {myGroupsTab && isVisible && collectedLeads > 0 && (
        <div className={`${styles.eye} ${animationClass}`}>
          {/* eslint-disable-next-line */}
          <a
            onMouseEnter={() => setIsEyeHovered(true)}
            onMouseLeave={() => setIsEyeHovered(false)}
            onClick={onViewLeads}>
            <img src={isEyeHovered ? eye_icon_purple : eye_icon} alt='View' className={styles.buttonImage} />
            <span>{eyeText}</span>
          </a>
        </div>
      )}
      {isVisible && (platform === 'linkedin' ? !myGroupsTab : true) && !isFailedAndHasLeads && !group?.is_list && (
        <div className={`${styles.collectButton} ${animationClass}`}>
          <Button type='primary' onClick={onCollect} disabled={isInProgress}>
            {buttonText}
          </Button>
        </div>
      )}
    </>
  )
}

interface CollectLeadsModalProps {
  open: boolean
  onClose: () => void
  group: any
  pointsStore: any
  importData: any
}

const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value?.toLocaleString()}`

export const CollectLeadsModal: React.FC<CollectLeadsModalProps> = ({
  open,
  onClose,
  group,
  pointsStore,
  importData,
}) => {
  const dispatch = useAppDispatch()
  const [getExplore] = useLazyGetExploreQuery()
  const [getMyHub] = useLazyGetMyHubQuery()
  const [collectLeads] = useLazyCollectLeadsQuery()
  const [collectWeb3Leads] = useLazyCollectWeb3LeadsQuery()
  const [loading, setLoading] = useState(false)
  const [numberOfLeads, setNumberOfLeads] = useState(0)
  // const [disclaimerText, setDisclaimerText] = useState('Disclaimer')
  const [user] = useLocalStorage('user', null)
  const { addons } = user
  const platform = group?.platform
  const instagramMaxImport =
    group.source_type === 'hashtag'
      ? addons?.max_import_hashtag_instagram_leads
      : addons?.max_import_group_instagram_leads || 1000
  const max_import_group_leads =
    platform === 'instagram'
      ? instagramMaxImport
      : platform === 'facebook'
      ? addons?.max_import_group_facebook_leads || 1000
      : platform === 'linkedin'
      ? addons?.max_import_group_linkedin_leads || 200
      : addons?.max_import_group_leads || 1000

  const parseLeads = (leads: any) => {
    if (typeof leads === 'string') {
      if (leads.endsWith('k') || leads.endsWith('K')) {
        return parseFloat(leads) * 1000
      } else if (leads.endsWith('m') || leads.endsWith('M')) {
        return parseFloat(leads) * 1000000
      } else {
        return parseFloat(leads)
      }
    }
    return leads
  }

  const remainingLeads = parseLeads(group?.leads) - +group.number_of_requested_leads || 5000
  const max_leads =
    group.source_type === 'hashtag' ? max_import_group_leads : Math.min(remainingLeads, max_import_group_leads)

  const cost = pointsStore?.pointsStore['collect'] || 1
  const totalCost = Math.trunc(numberOfLeads * cost)?.toLocaleString()

  const handleSliderChange = (e: any) => {
    setNumberOfLeads(e)
  }

  const handleCollect = async () => {
    setLoading(true)

    const { data: collectionData, error } =
      group?.is_web3Event || group?.is_web3Group
        ? await collectWeb3Leads({
            amount: numberOfLeads,
            group: group || '',
          })
        : await collectLeads({
            amount: numberOfLeads,
            group: group || '',
          })

    trackEvent('COLLECT_LEADS', { platform: group.platform, group: group.username, amount: numberOfLeads })

    if (collectionData?.message) {
      const { data } = await getExplore({})
      const { data: myHubData } = await getMyHub({})
      dispatch(
        SET_INITIAL_GROUP_DATA({
          ...data,
          ...myHubData,
        }),
      )
      message.success(collectionData?.message)

      onClose()
    } else if (error?.data?.status === 'points') {
      const convrt_points = error?.data?.convrt_points
      message.error(`You have ${convrt_points} points left. This action costs ${totalCost} points.`)
    } else if (error?.data?.status === 'not_found') {
      message.error(`This group is not found - you were not charged.`)
      const { data } = await getExplore({})
      const { data: myHubData } = await getMyHub({})
      dispatch(
        SET_INITIAL_GROUP_DATA({
          ...data,
          ...myHubData,
        }),
      )
    } else {
      message.error('Something went wrong collecting leads. Please try again or contact support.')
    }
    setLoading(false)
  }

  // const handleDisclaimer = () => {
  //   setDisclaimerText('x')
  // }

  const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: 'black',
    height: 64,
    paddingInline: 20,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #EBECF0',
  }

  const contentStyle: React.CSSProperties = {
    minHeight: 320,
    height: '100%',
    color: 'black',
    backgroundColor: 'white',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  return (
    <Modal
      className='CollectLeadsModal'
      open={open}
      onCancel={onClose}
      centered
      closable={false}
      footer={null}
      maskClosable={false}>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <div className={styles.modalHeader}>
            <p>Select How Many Leads to Collect</p>
            <span onClick={onClose}>Cancel</span>
          </div>
        </Header>
        <Content style={contentStyle}>
          <div className={styles.modalContent}>
            <div className={styles.progressContainer}>
              <img src={points_icon} alt='' />
              <p>{numberOfLeads?.toLocaleString()}</p>
              <Slider
                min={0}
                max={max_leads}
                style={{ width: 300 }}
                defaultValue={numberOfLeads}
                step={group.source_type === 'hashtag' ? 20 : 100}
                onChange={handleSliderChange}
                tooltip={{ formatter }}
              />
            </div>
            <span style={{ color: '#5E6C84' }}>
              Collecting {numberOfLeads?.toLocaleString()} leads from {group?.is_web3Event ? 'Event' : 'Group'} will
              cost you {totalCost} points
            </span>
            <div className={styles.footer}>
              <Button type='primary' loading={loading} disabled={!numberOfLeads} onClick={handleCollect}>
                Start Collecting
              </Button>
              {/* <span className={styles.disclaimer} onClick={handleDisclaimer}>
                {disclaimerText}
              </span> */}
            </div>
          </div>
        </Content>
      </Layout>
    </Modal>
  )
}
interface OnboardingOptionsProps {
  group: any
  onSelect: any
  isSelected: any
}

export const OnboardingsSwitchOptions: React.FC<OnboardingOptionsProps> = ({ group, onSelect, isSelected }) => {
  const handleSwitchChange = async (checked: boolean) => {
    onSelect()
  }

  return (
    <div
      className={styles.myGroups}
      onClick={(e: any) => {
        e.stopPropagation()
      }}>
      <Switch
        defaultChecked={isSelected}
        checked={isSelected}
        className='custom-switch'
        onChange={handleSwitchChange}
      />
    </div>
  )
}

import { Button, Drawer, Input, Spin, Typography, message } from 'antd'
import { useEffect, useState } from 'react'
import styles from './SaveTemplateDrawer.module.scss'
import { LoadingOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { useLazyInsertCustomMessageQuery, useLazyUpdateCustomMessageQuery } from 'common/APIs/OutreachApi'
const siderStyle: React.CSSProperties = {
  color: '#ffffff',
  backgroundColor: '#F9FAFB',
  height: '100%',
  padding: 0,
  margin: 0,
  overflowY: 'scroll',
}
export const SaveTemplateDrawer = ({
  open,
  onClose,
  messageContentInitial,
  refetchGetCustomMessages,
  editMessage,
  isEditTemplate,
}: any) => {
  const [insertCustomMessage] = useLazyInsertCustomMessageQuery()
  const [updateCustomMessage] = useLazyUpdateCustomMessageQuery()
  const [name, setName] = useState<any>(isEditTemplate ? editMessage?.name : '')
  const [messageContent, setMessageContent] = useState<any>(
    isEditTemplate ? editMessage?.message : messageContentInitial,
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      setMessageContent(isEditTemplate ? editMessage?.message : messageContentInitial)
      setName(isEditTemplate ? editMessage?.name : '')
    } else {
      setName('')
      setMessageContent('')
    }
  }, [open, editMessage, messageContentInitial])

  const handleSaveTemplate = async () => {
    try {
      setLoading(true)
      if (isEditTemplate) {
        await updateCustomMessage({ message: messageContent, messageName: name, messageId: editMessage.id })
      } else {
        await insertCustomMessage({ message: messageContent, messageName: name })
      }
      await refetchGetCustomMessages()
      setLoading(false)
      onClose()
      message.success('Template saved successfully')
    } catch (error) {
      setLoading(false)
      message.error('Failed to save template')
    }
  }
  const renderTitle = () => {
    return (
      <div className={styles.sider__header}>
        <div className={styles.sider__header__left}>
          <Typography>Save Template</Typography>
          {loading && (
            <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          )}
        </div>
        <Button type='primary' disabled={loading || !name || !messageContent} onClick={handleSaveTemplate}>
          Save
        </Button>
      </div>
    )
  }

  return (
    <Drawer
      style={siderStyle}
      width={'100%'}
      title={renderTitle()}
      open={open}
      placement='left'
      closable
      onClose={onClose}
      getContainer={false}
      className={'save-template-sider'}>
      <div className={styles.sider__body}>
        <div>
          <Typography className='font-bold mb-1'>Template Name</Typography>
          <Input value={name} onChange={(e) => setName(e.target.value)} style={{ padding: '6px 16px' }} />
        </div>

        <div>
          <Typography className='font-bold mb-2'>Message Content</Typography>
          <TextArea
            autoSize={{ minRows: 6, maxRows: 6 }}
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            style={{ padding: '6px 16px' }}
          />
        </div>
      </div>
    </Drawer>
  )
}

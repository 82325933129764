import React, { useState } from 'react'
import { Card, Typography, Button } from 'antd'
import styles from './StepCard.module.scss'
import right_arrow from 'common/assets/svg/right_arrow_right_short.svg'
import full_tick from 'common/assets/svg/full_tick.svg'
import empty_tick from 'common/assets/svg/empty_tick.svg'

const { Paragraph } = Typography

type StepCardProps = {
  title: string
  description: string
  buttonText: string
  imageSrc: string
  isComplete: boolean
  onClick: () => void
}

export const StepCard: React.FC<StepCardProps> = ({
  title,
  description,
  buttonText,
  imageSrc,
  isComplete,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Card className={`CardNoPadding ${styles.card}`} bordered={false} bodyStyle={{ height: '100%' }}>
      <div style={{ height: '100%', justifyContent: 'space-between' }} className={styles.content}>
        <div className={styles.imageWrapper}>
          <img src={imageSrc} alt={title} className={styles.image} />
          <img src={isComplete ? full_tick : empty_tick} alt='' className={styles.absoluteImg} />
          {/*</div>*/}
          <div className={styles.description}>
            <p className={styles.title}>{title}</p>
            <Paragraph className={styles.pDescription}>{description}</Paragraph>
          </div>
        </div>

        <Button
          style={{ marginLeft: '6%', marginBottom: '6%' }}
          type='primary'
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={styles.actionButton}>
          <span className={`${styles.buttonText} ${isHovered ? styles.textShift : ''}`}>{buttonText}</span>
          <img src={right_arrow} alt='' className={`${styles.rightArrow} ${isHovered ? styles.visible : ''}`} />
        </Button>
      </div>
    </Card>
  )
}

import React, { useEffect, useState } from 'react'
import styles from './Communities.module.scss'
import telegram_white from 'LEGACY/Assets/svg/telegram_white.svg'
import telegram_purple from 'LEGACY/Assets/svg/send_message.svg'
import notify_me from 'LEGACY/Assets/svg/notify_me.svg'
import notify_me_succeed from 'LEGACY/Assets/svg/notify_me_succeed.svg'
import upgrade_now from 'LEGACY/Assets/svg/upgrade_now.svg'
import no_logo_contact from 'LEGACY/Assets/svg/no_logo_contact.svg'
import blur_image_one from 'LEGACY/Assets/svg/blur_image_one.svg'
import blur_image_two from 'LEGACY/Assets/svg/blur_image_two.svg'
import blur_image_three from 'LEGACY/Assets/svg/blur_image_three.svg'
import { Button, Col, Collapse, Popconfirm, Tooltip } from 'antd'
import { ConfirmBox } from 'LEGACY/common/constants/modal.constants'
import LoadingDots from 'LEGACY/General/LoadingDots/LoadingDots'
import { DrawerLebels } from 'LEGACY/common/constants/label.constants'
import { useDisclaimer } from 'LEGACY/common/hooks/useDisclaimer'
import { useAuth } from 'common/hooks/useAuth.hooks'
import ConnectPlusModal from 'LEGACY/common/components/ConnectPlus/ConnectPlusModal'
import CircularImage from './CircularImage'
import { post } from 'LEGACY/API/axios'
import { useLazyGetPointsStoreQuery } from 'LEGACY/API/SettingsApi'

// import { useSetExposeMutation } from '../../state/api/ContactsApi'

interface CommunitiesProps {
  data: any
  communitiesData: any
  visibleFields: any
  setIsTelegramStartAnima: any
  handleGetDetails: any
  setContactsErrorData: any
  contactsErrorData: any
  contactsData: any
  notifyTracking: any
  telegramDisplayNone: any
  isTelegramStartAnima: any
  setTelegramDisplayNone: any
}

const Communities: React.FC<CommunitiesProps> = ({
  data,
  communitiesData,
  visibleFields,
  isTelegramStartAnima,
  setIsTelegramStartAnima,
  handleGetDetails,
  setContactsErrorData,
  contactsErrorData,
  contactsData,
  notifyTracking,
  telegramDisplayNone,
  setTelegramDisplayNone,
}) => {
  const { communities_already, shared_groups_already, full_name, telegram_already } = data

  const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()
  const [showImages, setShowImages] = useState(true)
  const { userDetails } = useAuth()
  const hasCommunities = userDetails?.addons?.communities || false
  const hasTelegram = Object.keys(userDetails?.telegram).length > 0
  const { Panel } = Collapse
  const [userPoints, setUserPoints] = useState<any>()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [])
  //   const communities = communities_already ? JSON.parse(communities_already) : []

  const subsetUsernames = new Set(
    JSON.parse(communitiesData?.shared_groups || shared_groups_already || '[]')?.map(
      (item: any) => item.group_username,
    ),
  )

  const [isCommunitiesStartAnima, setIsCommunitiesStartAnima] = useState(false)

  const [communities, setCommunities] = useState(communities_already ? JSON.parse(communities_already) : [])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [collapse, setCollapse] = useState(false)

  const displayedImages = communities.slice(0, 3).map((community: any, index: number) => (
    <img
      key={index}
      src={community.s3_logo || no_logo_contact}
      alt={community.group_title}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement
        target.src = no_logo_contact
      }}
    />
  ))
  const remainingCount = communities.length - 3
  const communityHeader = (
    <div className={styles.communityHeader}>
      <div className={styles.communityText}>
        <p className={styles.titleSection}>Communities</p>
        <span>Boost response rates with tailored messaging</span>
      </div>
      {showImages && (
        <div className={styles.imageStack}>
          {displayedImages}
          {remainingCount > 0 && (
            <div className={styles.remainingImages}>
              <span style={{ padding: '0px 5px' }}>+{remainingCount}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )

  const communityHeaderBlur = (
    <div className={styles.communityHeader}>
      <div className={styles.communityText}>
        <p className={styles.titleSection}>Communities</p>
        <span>Boost response rates with tailored messaging</span>
        {/* {collapse ? (
          <span style={{ color: 'transparent' }}>.</span>
        ) : (
          <span>Boost response rates with tailored messaging</span>
        )} */}
      </div>

      <div className={styles.imageStackBlur}>
        <img src={blur_image_one} alt='' />
        <img src={blur_image_two} alt='' />
        <img src={blur_image_three} alt='' />
        <div className={styles.remainingImages}>
          <span style={{ fontSize: '13px', fontWeight: '500', padding: '0px 5px' }}>+{'more'}</span>
        </div>
      </div>
    </div>
  )
  // const [refetchExpose, { data: expose, isLoading: exposeLoading }] = useSetExposeMutation()

  // const handleGetExposeDetails = async () => {
  //   const key = 'communities'
  //   // const result = await refetchExpose({ selectedId, key })
  // }

  const exposeTelegram = (
    <Popconfirm
      title={ConfirmBox.GET_DETAILS}
      description={
        <Col>
          <div>{`This action will cost you ${userPoints?.pointsStore?.expose_communities} points. Read disclaimer:`}</div>
          {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
          {showDisclaimer ? (
            <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
              {ConfirmBox.DISCLAIMER}
            </p>
          ) : (
            <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
              {'Disclaimer'}
            </span>
          )}
        </Col>
      }
      onConfirm={() => {
        setIsTelegramStartAnima(true)
        setIsCommunitiesStartAnima(true)

        const telegramTimer = setTimeout(() => {
          // handleGetExposeDetails()

          handleGetDetails('telegram_account', true)
          setShowDisclaimer(false)
          setContactsErrorData({
            email: '',
            phone_numbers: { res: '', msg: '', msgStatus: '' },
            telegram_account: '',
            discord: '',
          })
          clearTimeout(telegramTimer)
        }, 2000)
      }}
      onCancel={() => {
        setShowDisclaimer(false)
      }}
      okText='Get'
      cancelText='No'>
      {/* {!isTelegramStartAnima  || contactsErrorData?.telegram_account?.msgStatus === 5 ? ( */}
      {/* {!isCommunitiesStartAnima || contactsErrorData?.telegram_account?.msgStatus === 5 ? ( */}
      {!isCommunitiesStartAnima || contactsErrorData?.telegram_account?.msgStatus === 5 ? (
        <Button
          onClick={() => {
            post('/log', {
              action: 'Expose telegram in Communities',
            })
          }}>
          <div className={styles.buttonContent}>
            <img src={telegram_white} alt='' />
            <span>Expose Communities</span>
          </div>
        </Button>
      ) : (
        <div className={styles.buttonContent}>
          <LoadingDots />
        </div>
      )}
    </Popconfirm>
  )

  const upgradeNow = (
    <Button
      onClick={() => {
        window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
      }}>
      <div className={styles.buttonContent}>
        <img src={upgrade_now} alt='' />
        <span>Upgrade Now</span>
      </div>
    </Button>
  )

  const sortByConnectionLevel = (a: any, b: any) => {
    if ('connection_level' in a && 'connection_level' in b) {
      return a.connection_level - b.connection_level
    } else if ('connection_level' in a) {
      return -1
    } else if ('connection_level' in b) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (communities_already) {
      const updatedCommunities = communities?.map((item: any) => {
        if (hasTelegram && subsetUsernames.has(item.group_username)) {
          return { ...item, connection_level: communitiesData?.connection_level }
        }
        return item
      })
      updatedCommunities.sort(sortByConnectionLevel)
      setCommunities(updatedCommunities)
    }
  }, [])

  useEffect(() => {
    if (contactsData?.telegram_account?.communities) {
      const updatedCommunities = JSON.parse(contactsData?.telegram_account?.communities)?.map((item: any) => {
        if (hasTelegram && subsetUsernames.has(item.group_username)) {
          return { ...item, connection_level: communitiesData?.connection_level }
        }
        return item
      })
      updatedCommunities.sort(sortByConnectionLevel)
      setCommunities(updatedCommunities)
    }
  }, [contactsData?.telegram_account?.communities])

  return (
    <>
      {(!visibleFields.includes('communities') && !communities_already) || !hasCommunities ? (
        // !visibleFields.includes('telegram_account') && !data?.telegram_already) || !hasCommunities
        <div className={styles.contactDetails}>
          <Collapse
            ghost
            expandIconPosition='right'
            className='communities'
            onChange={() => {
              setCollapse(!collapse)
            }}>
            <Panel header={communityHeaderBlur} key='about'>
              <div className={styles.contactDetails} style={{ padding: '0px' }}>
                {/* <p className={styles.titleSection}>{'Communities'}</p> */}
                <p className={styles.header} style={{ marginTop: '0px' }}>
                  {full_name}'s Communities
                </p>
                <div className={styles.content}>
                  <span>
                    {hasCommunities
                      ? visibleFields.includes('telegram_account') || telegram_already
                        ? ''
                        : `Expose Telegram for community insights.`
                      : `Boost response rates with tailored messaging: Upgrade Now to discover the communities ${full_name} is part of.`}
                  </span>
                </div>
                <div className={styles.button}>{hasCommunities ? exposeTelegram : upgradeNow}</div>
              </div>
            </Panel>
          </Collapse>
        </div>
      ) : (
        <>
          {
            // contactsData?.telegram_account?.msgStatus === 0 ||
            // data?.telegram_already ||
            contactsData?.telegram_account?.communities || communities_already ? (
              communities.length > 0 ? (
                <div className={styles.contactDetails}>
                  <Collapse
                    // defaultActiveKey={['about']}
                    ghost
                    expandIconPosition='right'
                    className='communities'
                    onChange={() => {
                      setShowImages(!showImages)
                    }}>
                    <Panel header={communityHeader} key='about'>
                      {communities.map((community: any, index: number) => (
                        <div className={styles.container} key={index}>
                          <div className={styles.containerItem}>
                            {hasTelegram && community?.connection_level ? (
                              // add badge
                              <CircularImage
                                src={community.s3_logo || no_logo_contact}
                                alt={community.group_title}
                                color={community?.connection_level}
                                badge={false}
                                addStyle={false}
                              />
                            ) : (
                              <img
                                src={community.s3_logo || no_logo_contact}
                                alt=''
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                  const target = e.target as HTMLImageElement
                                  target.src = no_logo_contact
                                }}
                              />
                            )}
                            <span>{community.group_title || community.group_username}</span>
                          </div>
                          <a href={`https://t.me/${community.group_username}`} target='_blank' rel='noreferrer'>
                            <button>
                              <div
                                className={styles.buttonContent}
                                onClick={() => {
                                  post('/log', {
                                    action:
                                      hasTelegram && community?.connection_level
                                        ? 'Shared Communities'
                                        : 'Join Communities',
                                    data: community.group_username,
                                  })
                                }}>
                                <img src={telegram_purple} alt='' />
                                <span>{hasTelegram && community?.connection_level ? 'Shared' : 'Join'}</span>
                              </div>
                            </button>
                          </a>
                        </div>
                      ))}
                      {!hasTelegram && (
                        <div className={styles.connectTelegram}>
                          {/* <span>Connect your telegram to ...............................</span> */}
                          <Tooltip
                            placement='top'
                            title={`Connect to Telegram to discover the communities ${full_name} is part of.`}
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0}>
                            <Button
                              onClick={() => {
                                setIsModalOpen(true)
                              }}>
                              <div className={styles.buttonContent}>
                                <img src={telegram_white} alt='' />
                                <span>Connect Telegram</span>
                              </div>
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </Panel>
                  </Collapse>
                </div>
              ) : (
                <div className={styles.contactDetails}>
                  <p className={styles.titleSection}>{'Communities'}</p>
                  <p className={styles.header}>{full_name} does not have any Communities currently.</p>
                </div>
              )
            ) : (
              <>
                <div className={styles.contactDetails}>
                  <p className={styles.titleSection}>{'Communities'}</p>
                  <p className={styles.header}>Data is currently missing, you were not charged.</p>
                  <Tooltip placement='top' title={'We will notify you know when this contact’s telegram is available.'}>
                    <p
                      className={!telegramDisplayNone ? 'contactTypes display_none' : 'contactTypes'}
                      onClick={() => {
                        setTelegramDisplayNone(false)
                        notifyTracking('telegram_account')
                      }}>
                      <img src={notify_me} alt={''} />
                      <span>{DrawerLebels.NOTIFY_ME}</span>
                    </p>
                  </Tooltip>
                  <Tooltip placement='top' title={'We will notify you know when this contact’s telegram is available.'}>
                    <p
                      className={telegramDisplayNone ? 'display_none' : ''}
                      style={{ margin: '0px', textAlign: 'center' }}>
                      <img src={notify_me_succeed} alt={''} />
                    </p>
                  </Tooltip>
                </div>
              </>
            )
          }
        </>
      )}
      {isModalOpen && (
        <ConnectPlusModal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
          }}
          isImportTelegram={false}
        />
      )}
    </>
  )
}
export default Communities

// const confirmExpose = (
//     <Popconfirm
//       title={ConfirmBox.GET_DETAILS}
//       description={
//         <Col>
//           <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div>
//           {showDisclaimer ? (
//             <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
//               {ConfirmBox.DISCLAIMER}
//             </p>
//           ) : (
//             <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
//               {'Disclaimer'}
//             </span>
//           )}
//         </Col>
//       }
//       onConfirm={() => {
//         setIsTelegramStartAnima(true)
//         const telegramTimer = setTimeout(() => {
//           handleGetDetails('telegram_account')
//           setShowDisclaimer(false)
//           setContactsErrorData({
//             email: '',
//             phone_numbers: { res: '', msg: '', msgStatus: '' },
//             telegram_account: '',
//             discord: '',
//           })
//           clearTimeout(telegramTimer)
//         }, 2000)
//       }}
//       onCancel={() => {
//         setShowDisclaimer(false)
//       }}
//       okText='Get'
//       cancelText='No'>
//       {!isTelegramStartAnima || contactsErrorData?.telegram_account?.msgStatus === 5 ? (
//         <Button>
//           <div className={styles.buttonContent}>
//             <img src={data_test.communities ? telegram_white : upgrade_now} alt='' />
//             <span>{data_test.communities ? `Expose Telegram` : `Upgrade Now`}</span>
//           </div>
//         </Button>
//       ) : (
//         <LoadingDots />
//       )}
//     </Popconfirm>
//   )

// <>
//   {contactsData?.telegram_account?.msgStatus === 0 || data?.telegram_already ? (
// <div className={styles.contactDetails}>
//   <Collapse defaultActiveKey={['about']} ghost expandIconPosition='right' className='communities'>
//     <Panel header={communityHeader} key='about'>
//       {communities.map((company, index) => (
//         <div className={styles.container} key={index}>
//           <div className={styles.containerItem}>
//             <img src={company.image} alt={company.name} />
//             <span>{company.name}</span>
//           </div>
//           <a href='https://www.google.com/' target='_blank' rel='noreferrer'>
//             <button>
//               <div className={styles.buttonContent}>
//                 <img src={telegram_purple} alt='' />
//                 <span>Join</span>
//               </div>
//             </button>
//           </a>
//         </div>
//       ))}
//     </Panel>
//   </Collapse>
// </div>
//   ) : (
// <>
//   <Tooltip placement='top' title={'We will notify you know when this contact’s telegram is available.'}>
//     <p
//       className={!telegramDisplayNone ? 'contactTypes display_none' : 'contactTypes'}
//       onClick={() => {
//         setTelegramDisplayNone(false)
//         notifyTracking('telegram_account')
//       }}>
//       <img src={notify_me} alt={''} />
//       <span>{DrawerLebels.NOTIFY_ME}</span>
//     </p>
//   </Tooltip>
//   <Tooltip placement='top' title={'We will notify you know when this contact’s telegram is available.'}>
//     <p className={telegramDisplayNone ? 'display_none' : ''}>
//       <img src={notify_me_succeed} alt={''} />
//     </p>
//   </Tooltip>
// </>
//     <div className={styles.contactDetails}>
//       <p className={styles.titleSection}>{'Communities'}</p>
//       <p className={styles.header}>{full_name}'s Communities</p>
//       <div className={styles.content}>
//         <span>
//           {data_test.communities
//             ? `Boost response rates with tailored messaging: Connect Telegram to discover the communities ${data_test.name} is part
//       of.`
//             : `Boost response rates with tailored messaging: Upgrade Now to discover the communities ${data_test.name} is part of.`}
//         </span>
//       </div>
//       <div className={styles.button}>{exposeTelegram}</div>
//     </div>
//   )}
// </>

import { Button, Tooltip } from 'antd'
import styles from './CompanyDomain.module.scss'
import { useAppSelector } from 'state'
import { useIntercom } from 'react-use-intercom'
import { useLocalStorage } from 'usehooks-ts'
import { trackEvent } from 'eventConfig'
interface CompanyDomainProps {
  domain: string
  setDomain: (value: string) => void
  oopsWeb3: boolean
}

export default function CompanyDomain({ domain, setDomain, oopsWeb3 }: CompanyDomainProps) {
  const { settings } = useAppSelector((state) => state.signup)
  const [user] = useLocalStorage<any>('user', null)
  const domains: any = settings?.company_domain || []
  const domainsMap: any = {}
  domains.forEach((place: any) => {
    domainsMap[place.value] = {
      label: place.label,
      value: place.value,
    }
  })
  const { boot, showNewMessage } = useIntercom()
  const clickedStyle = {
    borderRadius: ' 10px',
    background: ' #faf9ff',
    border: '1px solid #7144FF',
  }

  return (
    <div className={styles.frame}>
      {oopsWeb3 ? (
        <>
          <span className={styles.lets2}>To access the Web3 product, please select one of the options below.</span>

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: 12,
              flexDirection: 'column',
              marginTop: 20,
            }}>
            <Button
              className={styles.firstButton}
              type='primary'
              style={{ width: '100%' }}
              onClick={() => {
                trackEvent('OUTSIDE_FUNNEL_WEB3_DEMO', {
                  button: 'Get web3 access',
                })
                boot({
                  name: user?.name,
                  email: user?.email,
                })
                showNewMessage(`Hello, I'm interested in trying out your Web3 product!`)
              }}>
              Get web3 access
            </Button>
            <Button
              className={styles.secondButton}
              style={{ width: '100%' }}
              onClick={() => {
                trackEvent('OUTSIDE_FUNNEL_WEB3_DEMO', {
                  button: 'Book a demo',
                })
                window.location.href = 'https://calendly.com/jonathan-w-/call'
              }}>
              Book a demo
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.frame_title}>
            <span className={styles.text}>What describes best your company?</span>
          </div>
          <div className={styles.places}>
            {domains.map((place: any) => (
              <div
                className={styles.friend}
                style={{ cursor: 'pointer', ...(domain === place.value ? clickedStyle : {}) }}
                onClick={() => setDomain(place.value)}>
                <Tooltip title={place.label}>
                  <span className={styles.text}>{place.label}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

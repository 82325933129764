import { Button } from 'antd'
import { useState } from 'react'
import './IntentSignalsPermanentPaywall.scss'
import BuyCreditsModal from 'LEGACY/common/components/BuyConvrt/BuyCreditsModal'

interface IIntentSignalsPermanentPaywall {
  trialExpired?: boolean
  message?: string
  setIsModalErrorModalOpen?: any
}

const IntentSignalsPermanentPaywallMsg: React.FC<IIntentSignalsPermanentPaywall> = ({
  message,
  setIsModalErrorModalOpen,
}) => {
  const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)

  const handleRedirect = () => {
    setIsModalErrorModalOpen(false)
    setOpenBuyCreditsModal(true)
  }

  // window.open(`https://calendly.com/roy-convrt/demo-convrt`, '_blank', 'noreferrer')

  return (
    <div className='paywall-modal'>
      <>
        <h1>{'Time to Top Up Your Points'}</h1>
        <p>{message}</p>
        <Button className='mt-3' type='primary' onClick={handleRedirect}>
          {'Get more points'}
        </Button>
      </>
      <BuyCreditsModal
        open={openBuyCreditsModal}
        onClose={() => {
          setOpenBuyCreditsModal(false)
        }}
      />
    </div>
  )
}

export default IntentSignalsPermanentPaywallMsg

import { createApi } from '@reduxjs/toolkit/query/react'
import { get, post } from 'LEGACY/API/axios'
import { baseQueryWithValidation } from 'LEGACY/API/baseQueryWithValidation'
import { IExposedContact } from 'LEGACY/Research/contacts/interfaces/contact.interface'

export type GetExposedQueryType = {
  current: number
  pageSize: number
  search?: string
  order_by?: string
  list?: string
}
export interface TResponseData<T extends Object> {
  rows: T[]
  totalNumber?: number
  connectedContact?: any[]
  allIdContacts?: string[]
}

export const ContactsApi = createApi({
  reducerPath: 'ContactsApiWeb3',
  baseQuery: baseQueryWithValidation,
  tagTypes: ['getExposedContacts', 'getContactsByCompanyId'],
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts?current=${params.current}&pageSize=${params.pageSize}`,
      }),
    }),

    getContactsById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/contactsbyid`,
        method: 'POST',
        body: { ids: params },
      }),
    }),

    getContact: builder.mutation({
      query: (contact_id: any, is_imported?: any) => {
        let url = `${process.env.REACT_APP_LEGACY_API_URL}/contacts/${contact_id}`

        if (is_imported) {
          url += `&is_imported=${is_imported}`
        }
        return {
          url,
        }
      },
    }),
    getContactsByCompanyId: builder.query({
      query: (params: any) => {
        let url = `${process.env.REACT_APP_LEGACY_API_URL}/companies/contacts?current=${params.current}&pageSize=${params.pageSize}`
        if (params.search) {
          url += `&search=${params.search}`
        }
        if (params.order_by) {
          url += `&order_by=${params.order_by}`
        }
        if (params.invalidateCache) {
          url += `&invalidateCache=${params.invalidateCache}`
        }
        return {
          url,
          method: 'POST',
          body: { companiesId: params.company_ids, filters: params.filters },
        }
      },

      providesTags: ['getContactsByCompanyId'],
    }),
    getContactsByCompanyIdForHub: builder.query({
      query: (params: any) => {
        let url = `${process.env.REACT_APP_LEGACY_API_URL}/companies/contactsForHub`
        return {
          url,
          method: 'POST',
          body: { filters: params.filters, platform: params.platform },
        }
      },

      providesTags: ['getContactsByCompanyId'],
    }),
    getExposedContacts: builder.query<TResponseData<IExposedContact>, undefined>({
      query: (params) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/contacts`,
        method: 'GET',
        params: params,
      }),
      providesTags: ['getExposedContacts'],
    }),
    updateMetaDataContact: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/contacts/${body.contactId}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        const { data } = await queryFulfilled
        for (const { endpointName, originalArgs } of ContactsApi.util.selectInvalidatedBy(getState(), [
          { type: 'getExposedContacts', id },
          { type: 'getContactsByCompanyId', id },
        ])) {
          if (endpointName !== 'getExposedContacts') continue
          dispatch(
            ContactsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
              const contact: any = draft.rows.find(
                (contact) => contact.contact_id === data.response.metadata_contact_id,
              )
              if (contact) {
                contact.priority = data.response.priority
                contact.meeting_booked = data.response.meeting_booked
              }
            }),
          )
        }
      },
    }),
    getTelegramContactsByCompanyId: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/telegram?current=${params.current}&pageSize=${params.pageSize}`,
        method: 'POST',
        body: { companiesId: params.company_ids },
      }),
    }),
    getTakeCredits: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/${params.contact_id}/credits?key=${
          params.value
        }&isDisplayed=${JSON.stringify(params.isDisplayed)}&where_opened=${params?.where_opened}&isCommunitiesAlready=${
          params.isCommunitiesAlready
        }`,
      }),
    }),
    getCsvExport: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/export`,
        body: { contactsId: params },
        headers: {
          'Content-Type': 'text/csv',
          'Content-Disposition': `attachment; filename="users.csv"`,
        },
      }),
    }),
    getContactsHubSpot: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot`,
        body: {
          contactsId: params.contacts,
          do_override_if_exists: params.do_override_if_exists,
          telegramCredits: params.telegramCredits,
          emailCredits: params.emailCredits,
          email: params.emailToggle,
          telegram: params.telegramToggle,
          phone: false,
        },
      }),
    }),
    getContactsSalesforce: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/salesforce`,
        body: {
          contactsId: params.contacts,
          do_override_if_exists: params.do_override_if_exists,
          telegramCredits: params.telegramCredits,
          emailCredits: params.emailCredits,
          email: params.emailToggle,
          telegram: params.telegramToggle,
          phone: false,
        },
      }),
    }),
    getTracking: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/tracking?contact_id=${params.contact_id}&activity=${
          params.activity
        }${params?.linked_in ? `&linked_in=${params?.linked_in}` : ''}`,
      }),
    }),
    getAlreadyTackedCredits: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/tackedcredits`,
        body: { contacts: params.contacts },
      }),
    }),
    getForContactsList: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/contacts/list`,
        body: { company_ids: params.company_ids },
      }),
    }),
    getForCompaniesList: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/companies/list`,
        body: { contact_ids: params.contact_ids },
      }),
    }),
    getTelegramContacts: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/telegram-contacts`,
      }),
    }),
    getNotifyTracking: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/${params.contact_id}/notify?key=${params.value}`,
      }),
    }),
    getHubspotProperties: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/getProperties`,
      }),
    }),
    getJobTitle: builder.query({
      query: (params: string) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/jobTitle?search=${params}`,
      }),
    }),
    setExpose: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/expose`,
        body: {
          contact_id: params.selectedId,
          key: params.key,
        },
      }),
    }),
    exportToCsv: builder.mutation({
      query: (params: any) => {
        return {
          method: 'POST',
          url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/exportToCsv`,
          body: {
            contacts_for_telegram: params.telegramCredits,
            contacts_for_email: params.emailCredits,
            contactsId: params.contacts_to_export,
            email: params.emailToggle,
            telegram: params.telegramToggle,
            phone: params.phone,
            contactsArr: params.contacts,
          },
          headers: {
            'Content-Type': 'text/csv',
            'Content-Disposition': `attachment; filename="users.csv"`,
          },
        }
      },
    }),
    exportToCsvRemainingPoints: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/exportToCsvRemainingPoints`,
        body: {
          contacts_for_telegram: params.telegramCredits,
          contacts_for_email: params.emailCredits,
          contactsId: params.contacts_to_export,
          email: params.emailToggle,
          telegram: params.telegramToggle,
          phone: params.phone,
          contactsArr: params.contacts,
        },
        headers: {
          'Content-Type': 'text/csv',
          'Content-Disposition': `attachment; filename="users.csv"`,
        },
      }),
    }),
  }),
})
// typed query and mutation hooks
export const {
  useLazyGetExposedContactsQuery,
  useGetExposedContactsQuery,
  useGetContactsByCompanyIdQuery,
  useUpdateMetaDataContactMutation,
} = ContactsApi

export const {
  useLazyGetContactsQuery,
  useLazyGetContactsByIdQuery,
  useGetContactMutation,
  useLazyGetContactsByCompanyIdQuery,
  useLazyGetContactsByCompanyIdForHubQuery,
  useLazyGetTelegramContactsByCompanyIdQuery,
  useLazyGetTakeCreditsQuery,
  useGetCsvExportMutation,
  useGetContactsHubSpotMutation,
  useGetContactsSalesforceMutation,
  useLazyGetTrackingQuery,
  useGetAlreadyTackedCreditsMutation,
  useGetForContactsListMutation,
  useGetForCompaniesListMutation,
  useLazyGetTelegramContactsQuery,
  useLazyGetNotifyTrackingQuery,
  useLazyGetHubspotPropertiesQuery,
  useLazyGetJobTitleQuery,
  useSetExposeMutation,
  useExportToCsvMutation,
  useExportToCsvRemainingPointsMutation,
} = ContactsApi as any

export const fetchExposed = async (params: any) => {
  let url = `/outreach/contacts`
  get(url, { params })
}
export const fetchCustomList = async (params: any) => {
  let url = `/companies/contacts?current=${params.current}&pageSize=${params.pageSize}`
  if (params.search) {
    url += `&search=${params.search}`
  }
  if (params.order_by) {
    url += `&order_by=${params.order_by}`
  }
  if (params.invalidateCache) {
    url += `&invalidateCache=${params.invalidateCache}`
  }
  post(url, { companiesId: params.company_ids, filters: params.filters })
}

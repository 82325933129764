import { Button, Card, Checkbox, Divider, Form, Input, message, Table, Typography, Select, Space, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import styles from './GenerateRegistrationCode.module.scss'
import { useLazyGetAffiliatesTableQuery, useLazyGetRegistrationURLQuery } from 'common/APIs/SettingsApi'
import { AVAILABLE_PLANS, AVAILABLE_SCREENS, DEFAULT_PRICING, DEFAULT_SCREENS } from './SettingsUtils'
import { capitalize, cloneDeep } from 'lodash'

const { Title } = Typography
const { Option } = Select
const { TabPane } = Tabs

const GenerateRegistrationCode: React.FC<{ screenSize?: { width: number; height: number } }> = ({ screenSize }) => {
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [skipEmailVerification, setSkipEmailVerification] = useState<boolean>(true)
  const [screens, setScreens] = useState<
    Array<{
      order: number
      screen: string
      allowSkip: boolean
    }>
  >(DEFAULT_SCREENS)
  const [generateUrl] = useLazyGetRegistrationURLQuery()
  const [getTable] = useLazyGetAffiliatesTableQuery()
  const [tableData, setTableData] = useState<any>([])
  const [pricingConfig, setPricingConfig] = useState<
    Array<{
      order: number
      plan_name: string
      tier_order: {
        tier_1: number
        tier_2: number
        tier_3: number
      }
    }>
  >(DEFAULT_PRICING)
  const [defaultBillingCycle, setDefaultBillingCycle] = useState('yearly')

  const handleGenerateToken = async () => {
    if (!name) {
      message.error('Name is required')
      return
    }

    try {
      setLoading(true)
      const response = await generateUrl({
        name,
        settings: {
          skip_email_verification: skipEmailVerification,
          onboarding_screens: cloneDeep(screens).sort((a, b) => a.order - b.order),
          pricing_settings: cloneDeep(pricingConfig).sort((a, b) => a.order - b.order),
          default_billing_cycle: defaultBillingCycle,
        },
      })
      setUrl(response.data.url)
      message.success('URL generated successfully')
    } catch (error) {
      message.error('Error generating URL')
      console.error('Error generating URL', error)
    } finally {
      setLoading(false)
    }
  }

  const addPlan = () => {
    if (pricingConfig.length >= 4) {
      message.error('Maximum 4 plans allowed')
      return
    }

    const availablePlans = AVAILABLE_PLANS.filter((plan) => !pricingConfig.map((p) => p.plan_name).includes(plan))

    if (availablePlans.length === 0) {
      message.error('All plans have been added')
      return
    }

    setPricingConfig([
      ...pricingConfig,
      {
        order: pricingConfig.length + 1,
        plan_name: availablePlans[0],
        tier_order: {
          tier_1: 1,
          tier_2: 2,
          tier_3: 3,
        },
      },
    ])
  }

  const removePlan = (index: number) => {
    const newConfig = cloneDeep(pricingConfig).filter((_, i) => i !== index)
    // Reorder remaining plans
    newConfig.forEach((plan, i) => {
      plan.order = i + 1
    })
    setPricingConfig(newConfig)
  }

  const movePlan = (index: number, direction: 'up' | 'down') => {
    if ((direction === 'up' && index === 0) || (direction === 'down' && index === pricingConfig.length - 1)) {
      return
    }

    const newConfig = cloneDeep(pricingConfig)
    const swapIndex = direction === 'up' ? index - 1 : index + 1

    const temp = { ...newConfig[index] }
    const tempSwap = { ...newConfig[swapIndex] }

    const tempOrder = temp.order
    temp.order = tempSwap.order
    tempSwap.order = tempOrder

    newConfig[index] = tempSwap
    newConfig[swapIndex] = temp

    setPricingConfig(newConfig)
  }

  const updatePlanTier = (index: number, tier: 'tier_1' | 'tier_2' | 'tier_3', value: number) => {
    const newConfig = cloneDeep(pricingConfig)
    newConfig[index] = {
      ...newConfig[index],
      tier_order: {
        ...newConfig[index].tier_order,
        [tier]: value,
      },
    }
    setPricingConfig(newConfig)
  }

  const addScreen = () => {
    if (screens.length >= 4) {
      message.error('Maximum 4 screens allowed')
      return
    }

    const availableScreens = AVAILABLE_SCREENS.filter((screen) => !screens.map((s) => s.screen).includes(screen.id))

    if (availableScreens.length === 0) {
      message.error('All screens have been selected')
      return
    }

    setScreens([
      ...screens,
      {
        order: screens.length + 1,
        screen: availableScreens[0].id,
        allowSkip: false,
      },
    ])
  }

  const removeScreen = (index: number) => {
    const newScreens = cloneDeep(screens).filter((_, i) => i !== index)
    // Reorder remaining screens
    newScreens.forEach((screen, i) => {
      screen.order = i + 1
    })
    setScreens(newScreens)
  }

  const moveScreen = (index: number, direction: 'up' | 'down') => {
    if ((direction === 'up' && index === 0) || (direction === 'down' && index === screens.length - 1)) {
      return
    }

    const newScreens = cloneDeep(screens)
    const swapIndex = direction === 'up' ? index - 1 : index + 1

    // Create temporary copies of the objects we want to swap
    const temp = { ...newScreens[index] }
    const tempSwap = { ...newScreens[swapIndex] }

    // Swap the order values
    const tempOrder = temp.order
    temp.order = tempSwap.order
    tempSwap.order = tempOrder

    // Update the array with the swapped objects
    newScreens[index] = tempSwap
    newScreens[swapIndex] = temp

    setScreens(newScreens)
  }

  const updateScreen = (index: number, field: string, value: any) => {
    if (field === 'screen') {
      // Check if the screen is already selected
      const isScreenSelected = screens.some((s, i) => i !== index && s.screen === value)
      if (isScreenSelected) {
        message.error('This screen is already selected')
        return
      }
    }

    const newScreens = cloneDeep(screens)
    newScreens[index] = {
      ...newScreens[index],
      [field]: value,
    }
    setScreens(newScreens)
  }

  const refinedData = tableData.map((data: any) => ({
    id: data.id,
    email: data.email,
    link_name: data?.affiliate_data?.name,
    is_skip_outside_onboarding: data?.affiliate_data?.is_skip_outside_onboarding,
    subscription_type: data?.subscription_type,
  }))

  const init = async () => {
    try {
      const res = await getTable()
      setTableData(res.data?.affiliates)
    } catch (error) {
      console.error('Error fetching table data', error)
    }
  }

  useEffect(() => {
    setLoadingTable(true)
    init().then(() => setLoadingTable(false))

    const interval = setInterval(() => {
      init()
    }, 10000)

    return () => clearInterval(interval)
  }, [])
  const rowHeight = 60 // Approximate height of a row in pixels
  const reservedHeight = 600 // Space reserved for headers, footers, etc.
  const tableLine = () => {
    if (screenSize) return Math.max(1, Math.floor(((screenSize?.height ?? 0) - reservedHeight) / rowHeight))
  }
  return (
    <Card className={styles.profileCard}>
      <Title level={3}>Generate Registration URL</Title>
      <Tabs
        defaultActiveKey='1'
        tabBarExtraContent={
          <Button type='primary' onClick={handleGenerateToken} loading={loading} style={{ margin: '0px 0px 10px 0px' }}>
            Generate Registration URL
          </Button>
        }>
        <TabPane tab='Basic Settings' key='1'>
          <Form layout='vertical' className={styles.form}>
            <Form.Item
              label={
                <Title level={5} style={{ margin: 0 }}>
                  Name
                </Title>
              }>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter Name'
                style={{ maxWidth: '300px' }}
              />
            </Form.Item>
            <Form.Item
              label={
                <Title level={5} style={{ margin: 0 }}>
                  Description
                </Title>
              }>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Enter Description'
                style={{ maxWidth: '300px' }}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox checked={skipEmailVerification} onChange={(e) => setSkipEmailVerification(e.target.checked)}>
                Skip Email Verification
              </Checkbox>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab='Onboarding Screens' key='2'>
          {screens.map((screen, index) => (
            <div
              key={`${screen.screen}-${index}`}
              style={{
                marginBottom: '16px',
                display: 'flex',
                alignItems: 'center',
                gap: '40px',
                padding: '8px 16px',
                border: '1px solid #f0f0f0',
                borderRadius: '8px',
                width: 'fit-content',
              }}>
              {/* <Select
                value={screen.screen}
                style={{ width: '200px' }}
                onChange={(value) => updateScreen(index, 'screen', value)}>
                {getAvailableScreens(index).map((s) => (
                  <Option key={s.id} value={s.id}>
                  {s.name}
                  </Option>
                  ))}
                </Select> */}
              <Title level={5} style={{ margin: 0, minWidth: '168px' }}>
                {AVAILABLE_SCREENS?.find((scr) => scr.id === screen?.screen)?.name}
              </Title>

              <Checkbox checked={screen.allowSkip} onChange={(e) => updateScreen(index, 'allowSkip', e.target.checked)}>
                Allow Skip
              </Checkbox>

              <Space>
                <Button icon={<ArrowUpOutlined />} disabled={index === 0} onClick={() => moveScreen(index, 'up')} />
                <Button
                  icon={<ArrowDownOutlined />}
                  disabled={index === screens.length - 1}
                  onClick={() => moveScreen(index, 'down')}
                />
                <Button icon={<DeleteOutlined />} onClick={() => removeScreen(index)} danger />
              </Space>
            </div>
          ))}

          {screens.length < 4 && (
            <Button onClick={addScreen} style={{ marginBottom: '20px' }} disabled={screens.length >= 4}>
              Add Screen
            </Button>
          )}
        </TabPane>

        <TabPane tab='Pricing Configuration' key='3'>
          {pricingConfig.map((plan, index) => (
            <div
              key={index}
              style={{
                marginBottom: '16px',
                padding: '8px 16px',
                border: '1px solid #f0f0f0',
                borderRadius: '8px',
                width: 'fit-content',
              }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                <Title level={5} style={{ margin: 0, minWidth: '100px' }}>
                  {plan.plan_name}
                </Title>
                <div style={{ display: 'flex', gap: '16px' }}>
                  {(['tier_1'] as const).map((tier) => (
                    <Form.Item key={tier} label={'Default Tier'} style={{ margin: 0 }}>
                      <Select
                        value={plan.tier_order[tier]}
                        onChange={(value) => updatePlanTier(index, tier, value)}
                        style={{ width: '80px' }}
                        disabled={['Team', 'Agency']?.includes(plan.plan_name)}>
                        {[1, 2, 3].map((num) => (
                          <Option key={num} value={num}>
                            Tier {num}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ))}
                </div>
                <Space>
                  <Button icon={<ArrowUpOutlined />} disabled={index === 0} onClick={() => movePlan(index, 'up')} />
                  <Button
                    icon={<ArrowDownOutlined />}
                    disabled={index === pricingConfig.length - 1}
                    onClick={() => movePlan(index, 'down')}
                  />
                  <Button icon={<DeleteOutlined />} onClick={() => removePlan(index)} danger />
                </Space>
              </div>
            </div>
          ))}
          {pricingConfig.length < 4 && (
            <Button onClick={addPlan} disabled={pricingConfig.length >= 4}>
              Add Plan
            </Button>
          )}

          <div className={styles.flexRow} style={{ marginTop: '15px' }}>
            <Form.Item
              key={'billing_cycle'}
              label={
                <Title level={5} style={{ margin: 0, minWidth: '120px' }}>
                  Default Billing Cycle
                </Title>
              }
              style={{ margin: 0 }}>
              <Select
                value={defaultBillingCycle}
                onChange={(e) => setDefaultBillingCycle(e)}
                style={{ width: '100px' }}>
                {['monthly', 'yearly'].map((bc) => (
                  <Option key={bc} value={bc}>
                    {capitalize(bc)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </TabPane>
      </Tabs>
      <Divider />

      {url && (
        <div>
          <Form.Item label='Generated URL'>
            <Input.TextArea value={url} readOnly autoSize={{ minRows: 1, maxRows: 4 }} />
          </Form.Item>
          <Divider />
        </div>
      )}

      <div>
        <Title level={3}>Signed Up Affiliates</Title>
        <Table
          dataSource={refinedData}
          loading={loadingTable}
          rowKey={(record) => record.id}
          scroll={{ x: 700 }}
          pagination={{ pageSize: tableLine() }}
          columns={[
            {
              title: 'Link Name',
              dataIndex: 'link_name',
              key: 'link_name',
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Skip Outside Onboarding',
              dataIndex: 'is_skip_outside_onboarding',
              key: 'is_skip_outside_onboarding',
              render: (is_skip_outside_onboarding: boolean) => (is_skip_outside_onboarding ? '✅' : '❌'),
            },
            {
              title: 'Finished',
              dataIndex: 'subscription_type',
              key: 'subscription_type',
              render: (subscription_type: string) => (subscription_type !== 'REGISTERED' ? '✅' : '❌'),
            },
          ]}
        />
      </div>
    </Card>
  )
}

export default GenerateRegistrationCode

import React, { useEffect, useState } from 'react'
import { Modal, message } from 'antd'
import styles from './BuyCreditsModal.module.scss'
import BuyCredits from './BuyCredits'
import { get } from 'LEGACY/API/axios'
import {
  useLazyBuyMorePointsQuery,
  useLazyGetIsStripeCustomerQuery,
  useLazyGetPaymentDetailsQuery,
  useLazyGetUnassignedPointsQuery,
} from 'LEGACY/API/SettingsApi'
import PaymentSummaryModal from './PaymentSummaryModal'
import { useAuth } from 'common/hooks/useAuth.hooks'

interface BuyCreditsModalProps {
  open: boolean
  onClose: () => void
}

const BuyCreditsModal: React.FC<BuyCreditsModalProps> = ({ open, onClose }) => {
  // const [refetchGetCredits] = useLazyGetCreditsQuery()
  //   const [planDuration, setPlanDuration] = useState('monthly')
  // const planDuration = 'monthly'
  const [convrtPoints, setConvrtPoints] = useState(0)
  // eslint-disable-next-line
  const [paymentStatus, setPaymentStatus] = useState<any>(null)
  // eslint-disable-next-line
  const [isPaid, setIsPaid] = useState(false)
  // eslint-disable-next-line
  const [sessionExpired, setSessionExpired] = useState(false)
  // eslint-disable-next-line
  const [sessionId, setSessionId] = useState<any>('')
  // eslint-disable-next-line
  const [loadingPage, setLoadingPage] = useState(false)
  // eslint-disable-next-line
  const [errorUpgradingUser, setErrorUpgradingUser] = useState(false)
  const { updateUserDetails } = useAuth()
  const [openPaymentSummaryModal, setOpenPaymentSummaryModal] = useState(false)
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false)
  const [refetchGetPaymentDetails, { data: paymentData }] = useLazyGetPaymentDetailsQuery()
  // const [refetchGetUserDetails, { data: dataUserDetails }] = useLazyGetUserDetailsQuery()
  const [refetchGetIsStripeCustomer, { data: isStripeCustomer }] = useLazyGetIsStripeCustomerQuery()
  const [refetchBuyMorePoints] = useLazyBuyMorePointsQuery()
  const [totalPrice, setTotalPrice] = useState(0)
  const [refetchGetUnassignedPoints] = useLazyGetUnassignedPointsQuery()

  const handlePayNow = async () => {
    // check if admin: buy to assign later, else: buy for the specific user
    if (isStripeCustomer?.customer) {
      setOpenPaymentSummaryModal(true)
    } else {
      try {
        const { data } = await refetchBuyMorePoints({
          convrtPoints: convrtPoints,
        })
        const success_url = data?.url
        if (success_url) {
          window.open(success_url, '_self')
        } else {
          //
        }
      } catch (e: any) {
        console.error(e)
      }
    }
  }

  const handlePayFromModal = async () => {
    try {
      setIsPaymentLoading(true)
      const { data } = await refetchBuyMorePoints({
        convrtPoints: convrtPoints,
      })
      if (data) {
        setIsPaymentCompleted(true)
      } else {
        message.error('Something went wrong with your payment. Please try again later or contact us.')
      }
      await refetchGetUnassignedPoints()
      updateUserDetails()
      setIsPaymentLoading(false)
      const success_url = data?.url
      if (success_url) {
        window.open(success_url, '_self')
      } else {
        //
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  // const handleSubscribe = async () => {
  //   try {
  //     const data = await post('/user/createPaymentIntentForCredits', {
  //       duration: planDuration,
  //       convrtPoints: convrtPoints,
  //     })
  //     window.open(data.data.url, '_self')
  //   } catch (e: any) {
  //     console.error(e)
  //   }
  // }

  const getPaymentStatus = async (session_id: string) => {
    try {
      const data = await get('/user/getPaymentStatus', { params: { session_id: session_id } })
      setPaymentStatus(data.data.order)
      setIsPaid(data.data.status === 'paid')
      setSessionId(data.data.sessionId)
    } catch (e) {
      setSessionExpired(true)
      console.error(e)
    }
  }

  // const addCredits = async () => {
  //   try {
  //     if (!isPaid || sessionExpired) {
  //       setLoadingPage(false)
  //       return
  //     }
  //     const user = await get(`/user/userDetails`)
  //     if (user.data.subscription_type !== 'PREMIUM') {
  //       setLoadingPage(false)
  //       return
  //     }
  //     setLoadingPage(true)
  //     const convrtPoints = paymentStatus?.points
  //     const { data } = await post('/user/addExtraCredits', {
  //       session_id: sessionId,
  //       convrtPoints: convrtPoints,
  //     })

  //     if (!data) {
  //       setErrorUpgradingUser(true)
  //       setLoadingPage(false)
  //       return
  //     }
  //     await refetchGetCredits()
  //     navigate('/settings')
  //     setLoadingPage(false)
  //   } catch (e) {
  //     console.error(e)
  //     navigate('/settings')
  //     setLoadingPage(false)
  //     setErrorUpgradingUser(true)
  //   }
  // }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const session_id = query.get('session_id')
    if (session_id) {
      setLoadingPage(true)
      getPaymentStatus(session_id)
    }

    if (query.get('canceled')) {
      message.warning('Payment cancelled.')
    }
  }, [])

  // useEffect(() => {
  //   if (paymentStatus !== null) {
  //     // addCredits()
  //   }
  // }, [paymentStatus])

  //   const handleSelectChange = (e: RadioChangeEvent) => {
  //     setPlanDuration(e.target.value)
  //   }

  const handleClose = () => {
    setConvrtPoints(0)
    setPaymentStatus(null)
    onClose()
  }

  useEffect(() => {
    // const getUserDetails = async () => {
    //   await refetchGetUserDetails()
    // }
    const getPaymentDetails = async () => {
      await refetchGetPaymentDetails()
    }
    const getIsStripeCustomer = async () => {
      await refetchGetIsStripeCustomer()
    }
    // getUserDetails()
    getPaymentDetails()
    getIsStripeCustomer()
  }, [])

  const handleClosePaymentModal = () => {
    if (isPaymentCompleted) {
      setOpenPaymentSummaryModal(false)
      setIsPaymentCompleted(false)
      handleClose()
    } else {
      setOpenPaymentSummaryModal(false)
      setIsPaymentCompleted(false)
    }
    // onClose()
  }

  return (
    <Modal
      title={<p style={{ display: 'flex', justifyContent: 'center' }}>Buy More Convrt Points!</p>}
      open={open}
      onCancel={handleClose}
      destroyOnClose={true}
      centered
      className='buyConvrtModal'
      footer={null}
      style={{ minWidth: 'fit-content' }}>
      <div className={styles.container}>
        <>
          {/* <div className={styles.payDuration}>
            <span>How would you like to pay?</span>
            <Radio.Group value={planDuration} onChange={handleSelectChange}>
              <Radio.Button value='monthly' style={{ minWidth: '100px', textAlign: 'center' }}>
                Monthly
              </Radio.Button>
              <Radio.Button value='six_months' style={{ minWidth: '100px', textAlign: 'center' }}>
                6-Months Periods
              </Radio.Button>
              <Radio.Button value='yearly' style={{ minWidth: '100px', textAlign: 'center' }}>
                Annually
              </Radio.Button>
            </Radio.Group>
          </div> */}
          <div className={styles.credits}>
            <BuyCredits
              setConvrtPoints={setConvrtPoints}
              showTitle={false}
              // duration={'monthly'}
              duration={'three_months'}
              nextButton={'Pay Now'}
              handlePayment={handlePayNow}
              isFromModal={true}
              setTotalPrice={setTotalPrice}
              isStripeCustomer={isStripeCustomer}
            />
          </div>
          {/* <div className={styles.footer}>
            <Button
              style={{
                borderRadius: '8px',
                fontSize: '16px',
                height: '35px',
                width: '160px',
                marginTop: '5px',
              }}
              key='next'
              type='primary'
              disabled={convrtPoints <= 0}
              onClick={handleSubscribe}>
              Pay Now
            </Button>
          </div> */}
        </>
      </div>
      {openPaymentSummaryModal && (
        <PaymentSummaryModal
          open={openPaymentSummaryModal}
          onClose={handleClosePaymentModal}
          // selectedPlan={}
          // billingCycle={'monthly'}
          billingCycle={'three_months'}
          convrtPoints={convrtPoints * 10}
          // quantity={quantity}
          defaultPaymentMethod={paymentData}
          totalPrice={totalPrice}
          onPay={handlePayFromModal}
          isLoading={isPaymentLoading}
          isCompleted={isPaymentCompleted}
        />
      )}
    </Modal>
  )
}

export default BuyCreditsModal

import React from 'react'
import styles from './AffiliateSignUp.module.scss'
import convrt_logo_login from 'common/assets/svg/convrt_logo_login.svg'
import rightSection from 'common/assets/png/RightSection-new.png'
import rightSection2 from 'common/assets/png/RightSection2-new.png'
import rightSection3 from 'common/assets/png/RightSection3-new.png'
import InitialForm from './UI/InitialForm/InitialForm'
import VerifyEmail from './UI/VerifyEmail/VerifyEmail'
import { useLocalStorage } from 'usehooks-ts'
import GetToKnow from './UI/GetToKnow/GetToKnow'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SETTINGS } from './state/slice/signupSlice'
import {
  useLazyGetSettingsQuery,
  useLazyUpdateUserQuery,
  useLazyValidateRegistrationLinkQuery,
} from '../../../APIs/SignUpApi'
import { message } from 'antd'
import { trackEvent } from 'eventConfig'
import { SET_JUST_SIGNED_UP } from 'GeneralSlice'

const imageMap: any = {
  0: <img src={rightSection} className={styles.rightImage} alt='rightSection' />,
  1: <img src={rightSection2} className={styles.rightImage} alt='rightSection' />,
  2: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  3: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  4: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  5: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
}

// Default configuration for backend data, used when onboarding screens are not available
const defaultBackendData = [
  { allowSkip: false, order: 4, screen: 'company_domain' },
  { allowSkip: true, order: 2, screen: 'where_did_you_find_us' },
  { allowSkip: true, order: 3, screen: 'company_details' },
  { allowSkip: true, order: 1, screen: 'main_goal' },
]

export default function AffiliateSignUp() {
  const [user] = useLocalStorage<any>('user', null)
  const settings_id = user?.settings_id
  const [step, setStep] = useLocalStorage('step', 0)
  const [checked, setChecked] = useState(false)
  const [getSettings] = useLazyGetSettingsQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isMobile } = useAppSelector((state) => state.General)
  const { uuid } = useParams<{ uuid: string }>()
  const [validateUrl] = useLazyValidateRegistrationLinkQuery()
  const [linkId, setLinkId] = useState<any>(uuid)
  const { affiliateData } = useAppSelector((state) => state.signup)
  const [updateUser] = useLazyUpdateUserQuery()
  const [init, setInit] = useState(false)

  // Dynamic mapping for rendering different onboarding steps
  const [stepMap, setStepMap] = useState<any>({
    0: <InitialForm linkId={linkId} />,
    1: <VerifyEmail />,
  })

  const handleNavigate = () => {
    window.open('https://www.convrt.ai/', '_self')
  }

  // Validate the registration link provided in the URL
  const validateRegistrationLink = async (linkId: string) => {
    const { data, error } = await validateUrl({ linkId, userId: user?.id || '' })
    if (data) {
      const { link_id, changeUrl } = data
      if (changeUrl) {
        const currentUrl = new URL(window.location.href)
        let newUrl = `${window.location.origin}/register/${link_id}`
        const token = currentUrl.searchParams.get('token')
        if (token) {
          newUrl += `?token=${token}`
        }
        setLinkId(link_id)
        window.history.replaceState(null, '', newUrl)
      }

      setChecked(true)
      return true
    }

    if (error) {
      message.error('Registration link is invalid.')
      navigate('/login')
      return false
    }
    return false
  }

  // Check and validate the UUID from URL on component mount
  useEffect(() => {
    if (!checked) {
      if (uuid) {
        validateRegistrationLink(uuid)
      } else {
        console.error('No UUID found in URL')
        navigate('/login')
      }
    }
  }, [uuid])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getSettings()
      if (data) {
        dispatch(SET_SETTINGS(data?.settings))
      }
    }
    fetchData()
  }, [])

  const handleNavigateToPricing = async () => {
    await updateUser({
      updates: {
        outside_onboarding: null,
      },
      id: user.id,
    })
    // GA LOG
    trackEvent(
      'SIGNUP_COMPLETION',
      {
        affiliateData,
        user: user.email,
        details: user,
        settings_id,
      },
      true,
    )
    dispatch(SET_JUST_SIGNED_UP(true))
    navigate('/upgrade?pricing-first-time=true')
  }

  // Update step map and dynamic data based on user settings
  useEffect(() => {
    // Ensure backend data is updated based on user settings
    const updateDynamicDataAndStepMap = () => {
      const onboarding_screens = user?.outside_onboarding?.settings?.onboarding_screens || []
      if (onboarding_screens?.length > 0) setInit(true)

      const newBackendData = onboarding_screens.length > 0 ? onboarding_screens : defaultBackendData

      // If there are no screens, navigate to pricing
      if (onboarding_screens.length === 0) {
        handleNavigateToPricing()
        return
      }

      // Build updated stepMap
      const updatedStepMap: any = {
        0: <InitialForm linkId={linkId} />,
        1: <VerifyEmail />,
      }

      // Mapping different steps based on backend data
      const allSteps: any = {
        company_domain: (
          <GetToKnow
            innerStep={1}
            order={1}
            totalSteps={newBackendData?.length}
            allowSkip={true}
            screen={'company_domain'}
          />
        ),
        where_did_you_find_us: (
          <GetToKnow
            innerStep={2}
            order={2}
            totalSteps={newBackendData?.length}
            allowSkip={true}
            screen={'where_did_you_find_us'}
          />
        ),
        company_details: (
          <GetToKnow
            innerStep={3}
            order={3}
            totalSteps={newBackendData?.length}
            allowSkip={true}
            screen={'company_details'}
          />
        ),
        main_goal: (
          <GetToKnow
            innerStep={4}
            order={4}
            totalSteps={newBackendData?.length}
            allowSkip={true}
            screen={'main_goal'}
          />
        ),
      }

      // Initialize onboarding steps for the first time
      newBackendData.forEach((step: any) => {
        updatedStepMap[step.order + 1] = React.cloneElement(allSteps[step.screen], {
          order: step.order,
          allowSkip: step.allowSkip,
        })
      })

      setStepMap(updatedStepMap)
    }

    if (user && !init) {
      updateDynamicDataAndStepMap()
      // Ensure correct step initialization
      if (user.outside_onboarding?.step >= 0) {
        setStep(user.outside_onboarding.step)
      }
    }
  }, [user, linkId, init])

  useEffect(() => {
    setStepMap((prevStepMap: any) => ({
      ...prevStepMap,
      0: <InitialForm linkId={linkId} />,
    }))
  }, [linkId])

  return (
    <>
      {isMobile ? (
        <div style={{ overflowY: 'auto', height: 'max-content' }}>
          <div
            style={{
              display: 'flex',
              height: 'fit-content',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <div
              className={styles.mainBody}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}>
              <div style={{ marginTop: 15, marginLeft: 15 }}>
                <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
              </div>
              {stepMap[step]}
            </div>
          </div>
          <div style={{ height: '100px', width: '100%', background: 'transparent', zIndex: -5 }}></div>
        </div>
      ) : (
        <div className={styles.outerContainer}>
          <div className={styles.header}>
            <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
          </div>
          <div className={styles.mainBodyWeb}>
            <div className={styles.leftMain}>{stepMap[step]}</div>
            <div className={styles.rightMain}>{imageMap[step]}</div>
          </div>
        </div>
      )}
    </>
  )
}

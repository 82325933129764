import { createSlice } from '@reduxjs/toolkit'

interface IMessageReplied {
  nodeId: string
  duration: number
  units: string
}

interface IOutreachedCampaignSlice {
  status: string
  template: number
  selectedSender: number[]
  selected_node: any
  message_replied: IMessageReplied
  wait: IMessageReplied
  sequence_data: any
  campaign_id: any
  campaign_name: any
  campaign_settings: any
  edit_mode: any
  enable_editing: any
  imported_leads: boolean
  selected_leads: any
  campaign_filters: any
  campaign_date_filters: any
  all_campaigns_data: any
  load_animation: boolean
  ai_generated_data: any
  senders_approved: any
  open_sender_modal: boolean
  isCompareAbTesting: boolean
  showPercentage: boolean
  loadingCampaigns: boolean
  dynamicCampaignsColumns: any
}

const initialState = {
  status: '',
  template: 0,
  selectedSender: [],
  selected_node: '1',
  message_replied: { nodeId: '', duration: 1, units: 'days' },
  wait: { nodeId: '', duration: 1, units: 'days' },
  sequence_data: {},
  campaign_id: '',
  campaign_name: '',
  campaign_settings: {},
  edit_mode: false,
  enable_editing: false,
  imported_leads: false,
  selected_leads: [],
  campaign_filters: {},
  campaign_date_filters: { dateType: 'sent_at' },
  all_campaigns_data: [],
  load_animation: false,
  ai_generated_data: {},
  senders_approved: -1,
  open_sender_modal: false,
  isCompareAbTesting: false,
  showPercentage: true,
  loadingCampaigns: true,
  dynamicCampaignsColumns: [],
} as IOutreachedCampaignSlice

export const outreachedCampaignSlice = createSlice({
  name: 'outreachCampaign',
  initialState,
  reducers: {
    SET_TEMPLATE(state, action) {
      state.status = 'succeeded'
      state.template = action.payload
    },
    SET_SELECTED_SENDER(state, action) {
      state.status = 'succeeded'
      state.selectedSender = action.payload
    },
    SET_SELECTED_NODE(state, action) {
      state.status = 'succeeded'
      state.selected_node = action.payload
    },
    SET_MESSAGE_REPLIED(state, action) {
      state.status = 'succeeded'
      state.message_replied = action.payload
    },
    SET_WAIT(state, action) {
      state.status = 'succeeded'
      state.wait = action.payload
    },
    SET_SEQUENCE_DATA(state, action) {
      state.status = 'succeeded'
      state.sequence_data = action.payload
    },
    SET_CAMPAIGN_ID(state, action) {
      state.status = 'succeeded'
      state.campaign_id = action.payload
    },
    SET_CAMPAIGN_NAME(state, action) {
      state.status = 'succeeded'
      state.campaign_name = action.payload
    },
    SET_CAMPAIGN_SETTINGS(state, action) {
      state.status = 'succeeded'
      state.campaign_settings = action.payload
    },
    SET_EDIT_MODE(state, action) {
      state.status = 'succeeded'
      state.edit_mode = action.payload
    },
    SET_ENABLE_EDITING(state, action) {
      state.status = 'succeeded'
      state.enable_editing = action.payload
    },
    SET_IMPORTED_LEADS(state, action) {
      state.status = 'succeeded'
      state.imported_leads = action.payload
    },
    SET_SELECTED_LEADS(state, action) {
      state.status = 'succeeded'
      state.selected_leads = action.payload
    },
    SET_CAMPAIGN_FILTERS(state, action) {
      state.status = 'succeeded'
      state.campaign_filters = action.payload
    },
    SET_CAMPAIGN_DATES_FILTERS(state, action) {
      state.status = 'succeeded'
      state.campaign_date_filters = action.payload
    },
    SET_ALL_CAMPAIGNS_DATA(state, action) {
      state.status = 'succeeded'
      state.all_campaigns_data = action.payload
    },
    SET_LOAD_ANIMATION(state, action) {
      state.status = 'succeeded'
      state.load_animation = action.payload
    },
    SET_AI_GENERATED_DATA(state, action) {
      state.status = 'succeeded'
      state.ai_generated_data = action.payload
    },
    SET_SENDERS_APPROVED(state, action) {
      state.status = 'succeeded'
      state.senders_approved = action.payload
    },
    SET_OPEN_SENDER_MODAL(state, action) {
      state.status = 'succeeded'
      state.open_sender_modal = action.payload
    },
    SET_IS_COMPARE_AB_TESTING(state, action) {
      state.status = 'succeeded'
      state.isCompareAbTesting = action.payload
    },
    SET_SHOW_PERCENTAGE(state, action) {
      state.status = 'succeeded'
      state.showPercentage = action.payload
    },
    SET_LOADING_CAMPAIGNS(state, action) {
      state.status = 'succeeded'
      state.loadingCampaigns = action.payload
    },
    SET_DYNAMIC_CAMPAIGNS_COLUMNS(state, action) {
      state.status = 'succeeded'
      state.dynamicCampaignsColumns = action.payload
    },
  },
})

export const {
  SET_TEMPLATE,
  SET_SELECTED_SENDER,
  SET_SELECTED_NODE,
  SET_MESSAGE_REPLIED,
  SET_WAIT,
  SET_SEQUENCE_DATA,
  SET_CAMPAIGN_ID,
  SET_CAMPAIGN_NAME,
  SET_CAMPAIGN_SETTINGS,
  SET_EDIT_MODE,
  SET_ENABLE_EDITING,
  SET_IMPORTED_LEADS,
  SET_SELECTED_LEADS,
  SET_CAMPAIGN_FILTERS,
  SET_CAMPAIGN_DATES_FILTERS,
  SET_ALL_CAMPAIGNS_DATA,
  SET_LOAD_ANIMATION,
  SET_AI_GENERATED_DATA,
  SET_SENDERS_APPROVED,
  SET_OPEN_SENDER_MODAL,
  SET_IS_COMPARE_AB_TESTING,
  SET_SHOW_PERCENTAGE,
  SET_LOADING_CAMPAIGNS,
  SET_DYNAMIC_CAMPAIGNS_COLUMNS,
} = outreachedCampaignSlice.actions
export const outreachedCampaignReducer = outreachedCampaignSlice.reducer

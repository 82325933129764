import React, { useState } from 'react'
import _ from 'lodash'
import { Col, InputNumber, Radio, Row } from 'antd'

import { SET_FILTER } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'

import { useAppDispatch, useAppSelector } from 'state/hooks'

import { expandShortNumber, shortenNumber } from 'LEGACY/common/utils/inputUtils'
import { removeEmpty } from 'LEGACY/common/utils/objectToValues'

import styles from './fancy-filter-expand-item-tabs.module.scss'

type TabPosition = 'twitter' | 'discord' | 'telegram'
interface IFancyFilterExpandItemTabs {
  data: any
}
const FancyFilterExpandItemTabs: React.FC<IFancyFilterExpandItemTabs> = ({ data }) => {
  const [mode, setMode] = useState<TabPosition>('twitter')
  const [current, serCurrent] = useState<any>(data[0])
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.companies)

  const onNumberChange = (minKey: string, maxKey: string, event: any, value: 'min' | 'max') => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    if (value === 'min') {
      _.set(filterCopy, minKey, event)
    } else {
      _.set(filterCopy, maxKey, event)
    }
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  return (
    <div className={styles.tabsContainer}>
      {data?.length > 0 && (
        <>
          <Radio.Group
            className={styles.tabsBox}
            onChange={(event) => {
              setMode(event.target.value)
              data.forEach((element: any) => {
                if (element.key.indexOf(event.target.value) > 0) serCurrent(element)
              })
            }}
            value={mode}
            style={{ marginBottom: 8 }}>
            {data?.map((item: any, idx: number) => (
              <Radio.Button
                className='w-full flex justify-content-center'
                value={item.key.split('.')[item.key.split('.').length - 1]}
                key={idx}>
                {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
          {current && (
            <Row>
              <Col span={10}>
                <InputNumber
                  addonBefore={current?.children?.[0]?.hasCurrency && '$'}
                  className={styles.numberBox}
                  step={current?.children?.[0]?.steps}
                  min={0}
                  placeholder={shortenNumber(current.children[0]?.defaultValue)}
                  formatter={(value) => shortenNumber(value)}
                  parser={(value) => expandShortNumber(value)}
                  value={_.get(filter, current.children[0].key)}
                  onChange={(event) => {
                    onNumberChange(current?.children?.[0]?.key || '', current?.children?.[1]?.key || '', event, 'min')
                  }}
                />
              </Col>
              <Col span={4}>
                <span className={'rangeSeparator'}>-</span>
              </Col>
              <Col span={10}>
                <InputNumber
                  addonBefore={current?.children?.[0]?.hasCurrency && '$'}
                  className={styles.numberBox}
                  min={_.get(filter, current?.children?.[0].key || 0)}
                  placeholder={shortenNumber(current.children[1]?.defaultValue)}
                  formatter={(value) => shortenNumber(value)}
                  parser={(value) => expandShortNumber(value)}
                  step={current?.children?.[1]?.steps}
                  value={_.get(filter, current.children[1].key)}
                  onChange={(event) => {
                    onNumberChange(current?.children?.[0]?.key || '', current?.children?.[1]?.key || '', event, 'max')
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  )
}

export default FancyFilterExpandItemTabs

import { Skeleton, Table, TablePaginationConfig } from 'antd'
import { getListColumns } from './GroupsColumns'
import LoadingSkeleton from 'LEGACY/General/LoadingBox/LoadingSkeleton'
import { IContactsTable } from 'LEGACY/Research/contacts/interfaces/contacts-table.interface'
import { useMemo, useState } from 'react'
import TableRowSelectionDropdownLists from 'common/components/GeneralComponents/UI/TableRowSelectionDropdownLists'

interface GroupsTableListsProps {
  selectedGroup: string
  data: any
  isLoading: boolean
  groupType: string
  selectedRowKeys: any
  setSelectedRowKeys: any
  onLeads: () => void
  loadingLeads: boolean
  showNewLeads: boolean
  isList?: boolean
  tableParams?: any
  setTableParams?: any
  handleSelectAllData?: any
  handleSelectFifty?: any
  handleSelectedRows?: any
  handleRemoveRow?: any
  handleDeselectAll?: any
  handleUnSelectRows?: any
  selected_items: any
  platform: any
  refetch: any
}

const GroupsTableLists: React.FC<GroupsTableListsProps> = ({
  selectedGroup,
  data,
  isLoading,
  groupType,
  selectedRowKeys,
  setSelectedRowKeys,
  onLeads,
  loadingLeads,
  showNewLeads,
  isList = false,
  tableParams = null,
  setTableParams = null,
  handleSelectAllData,
  handleSelectFifty,
  handleSelectedRows,
  handleRemoveRow,
  handleDeselectAll,
  handleUnSelectRows,
  selected_items,
  platform,
  refetch,
}) => {
  const isDisabled = (record: any) => {
    if (platform === 'telegram') {
      return record.telegram_account === null
    }
    return record.linkedin === null
  }

  const [currentPage, setCurrentPage] = useState(1)
  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (!setTableParams) return
    let params = { ...pagination, pageSize: Number(pagination.pageSize), total: pagination?.total }
    setTableParams(params)
  }
  const listPagination: TablePaginationConfig = {
    pageSize: tableParams?.pageSize,
    position: ['bottomRight'],
    onChange: setCurrentPage,
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IContactsTable[]) => {
      handleSelectedRows && handleSelectedRows(selectedRows, selectedRowKeys)
    },
    selectedRowKeys: selected_items,
    onSelect: (selected: any, selectedRows: boolean) => {
      if (!selectedRows) handleRemoveRow && handleRemoveRow(selected?.key)
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (!selected) handleUnSelectRows && handleUnSelectRows(changeRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: isDisabled(record),
    }),
    columnTitle: (
      <TableRowSelectionDropdownLists
        data={data}
        pageSize={tableParams?.pageSize}
        currentPage={currentPage}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={(newKeys: any) => {
          if (!loadingLeads) {
            setSelectedRowKeys(newKeys.filter((key: any) => !isDisabled(data.find((item: any) => item.key === key))))
          }
        }}
      />
    ),
  }
  const convertedData = useMemo(() => {
    if (!data) return []
    return data?.map((item: any) => {
      if (isDisabled(item)) {
        return { ...item, is_disabled: true }
      }
      return { ...item, is_disabled: false }
    })
  }, [data])
  return (
    <Table
      dataSource={
        loadingLeads || isLoading
          ? [...Array(10)].map((_, index) => ({
              key: `key${index}`,
            }))
          : convertedData
      }
      columns={
        loadingLeads || isLoading
          ? getListColumns(null, platform)?.map((column: any) => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return (
                    <Skeleton
                      // className='campaignsPageTable'
                      style={{
                        margin: '0px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '5px',
                        height: '30px',
                        paddingRight: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        opacity: 0.5,
                      }}
                      key={column.dataIndex}
                      title={true}
                      paragraph={false}
                      active
                    />
                  )
                },
              }
            })
          : getListColumns(refetch, platform)
      }
      rowSelection={rowSelection}
      size='small'
      pagination={listPagination}
      className={`groupsTable Leads_Table regularTableLists`}
      scroll={{ x: 500 }}
      // scroll={tablePaywall ? {} : undefined}
      // scroll={tablePaywall ? undefined:'auto'}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      {...(tableParams && { onChange: handleTableChange })}
      locale={{
        emptyText: isLoading ? (
          // <Skeleton active={true} paragraph={{ rows: 10 }} style={{ opacity: 0.5 }} />
          <LoadingSkeleton
            listStyle={{ margin: '-8px' }}
            listItemStyle={{ padding: '12px 0px 12px 0px' }}
            skeletonStyle={{
              paddingTop: '0px',
              paddingLeft: '60px',
              paddingRight: '60px',
              display: 'flex',
              alignItems: 'center',
              opacity: 0.5,
            }}
          />
        ) : data?.length === 0 ? (
          <div className='fancyTable-nodata'>No Data Available</div>
        ) : (
          {}
        ),
      }}
    />
  )
}

export default GroupsTableLists

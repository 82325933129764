import React from 'react'
import { Col, Popconfirm, TableProps, Tooltip } from 'antd'
import CompanyInfo from './CompanyInfo'
import { LinkOutlined } from '@ant-design/icons'
import lead_icon1 from 'common/assets/svg/facebook_logo.svg'
import lead_icon2 from 'common/assets/svg/instagram_logo.svg'
import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/linkedin_logo.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'
import styles from './Leads.module.scss'
import { capitalize } from 'lodash'
import { ConfirmBox } from 'common/constants/modal.constants'
import { FancyImage } from 'common/components/GeneralComponents/UI/FancyImage'
import { TagsList } from 'common/components/GeneralComponents/UI/TagsList'

export const getCompaniesColumns = ({
  userPoints,
  showDisclaimer,
  toggleShowDisclaimer,
  active_platforms,
  setCostPoints,
  setOutOfPoints,
  setShowDisclaimer,
  exposeCompanyPlatform,
  updateInitialAiCompaniesData,
  setIsMagic,
}: {
  userPoints?: any
  showDisclaimer?: boolean
  toggleShowDisclaimer?: () => void
  active_platforms?: any
  setCostPoints?: (points: number) => void
  setOutOfPoints?: (outOfPoints: boolean) => void
  setShowDisclaimer?: any
  exposeCompanyPlatform?: any
  updateInitialAiCompaniesData?: (newVal: any) => void
  setIsMagic?: any
}): TableProps<any>['columns'] => [
  {
    title: 'Company',
    dataIndex: 'name',
    key: 'name',
    width: 230,
    render: (text: string, data: any) => (
      <div style={{ minWidth: 230 }}>
        <span className='flex_items'>
          {data.company_photo !== '' && (
            <FancyImage
              url={data.company_photo}
              className='smallRoundPicture'
              iscompany={false}
              name={text}
              size={28}
            />
          )}
          <div className={styles.companyDiv}>
            <div>
              <Tooltip placement='topLeft' title={text}>
                <span>{text?.length > 15 ? `${text.substring(0, 15)}...` : text}</span>
              </Tooltip>
              <CompanyInfo data={data} />
            </div>
            <div className={styles.logos}>
              <Tooltip title={data.company_website ? 'Visit Website' : 'No Website Available'}>
                <LinkOutlined
                  onClick={() => data.company_website && window.open(data.company_website, '_blank')}
                  style={{
                    color: data.company_website ? '#1890ff' : 'gray',
                    cursor: data.company_website ? 'pointer' : 'default',
                  }}
                />
              </Tooltip>
              {/* <Tooltip title={data.company_linkedin ? 'Visit LinkedIn' : 'No LinkedIn Available'}>
                <img
                  onClick={() => data.company_linkedin && window.open(data.company_linkedin, '_blank')}
                  src={lead_icon4}
                  alt='LinkedIn'
                  style={{
                    filter: data.company_linkedin ? 'none' : 'grayscale(100%)',
                    cursor: data.company_linkedin ? 'pointer' : 'default',
                  }}
                />
              </Tooltip> */}
            </div>
          </div>
        </span>
      </div>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 245,
    render: (text: string, data: any) => (
      <Tooltip placement='topLeft' title={text}>
        <span>{text?.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Industry',
    dataIndex: 'industry',
    key: 'industry',
    width: 120,
    render: (text: string, data: any) => <span>{capitalize(text)}</span>,
  },
  // {
  //   title: 'Business Type',
  //   dataIndex: 'businessType',
  //   key: 'businessType',
  //   width: 145,
  //   render: (businessTypes: string[]) => {
  //     return (
  //       <div className='tblFieldText'>
  //         {businessTypes?.length > 0 ? <TagsList tags={businessTypes} sliceNum={1} /> : <span>{''}</span>}
  //       </div>
  //     )
  //   },
  // },
  // {
  //   title: 'Accuracy',
  //   dataIndex: 'accuracy',
  //   key: 'accuracy',
  //   width: 100,
  //   render: (accuracy: any) => <CustomAccuracyTag number={accuracy} />,
  // },

  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
    width: 150,
    render: (text: string, data: any) => (
      <Tooltip placement='topLeft' title={text}>
        <span>{text?.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Technologies',
    dataIndex: 'technologies',
    key: 'technologies',
    width: 120,
    render: (technologies: string[]) => {
      return (
        <div className='tblFieldText'>
          {technologies?.length > 0 ? <TagsList tags={technologies} sliceNum={1} /> : <span>{''}</span>}
        </div>
      )
    },
  },
  {
    title: '# of Employees',
    dataIndex: 'number_of_employees',
    key: 'number_of_employees',
    width: 150,
  },

  active_platforms
    ? {
        title: 'Channels',
        dataIndex: 'channels',
        key: 'channels',
        width: 180,
        render: (text: string, data: any) => {
          const style = 'grayscale(100%)'
          const socialMediaIcons = [
            {
              platform: 'facebook',
              url: active_platforms?.['facebook'] === true && data?.facebook !== null ? data?.facebook : '',
              icon: lead_icon1,
              index: data.index,
              source: data.source,
            },
            {
              platform: 'instagram',
              url:
                active_platforms?.['instagram'] === true && data.instagram !== null && data.instagram !== ''
                  ? `${data.instagram}`
                  : '',
              icon: lead_icon2,
              index: data.index,
              source: data.source,
            },
            {
              platform: 'telegram',
              url: active_platforms?.['telegram'] === true && data.telegram !== null ? data.telegram : '',
              icon: lead_icon3,
              index: data.index,
              source: data.source,
            },
            {
              platform: 'linkedin',
              url: active_platforms?.['linkedin'] === true && data.linkedin !== null ? data.linkedin : '',
              icon: lead_icon4,
              index: data.index,
              source: data.source,
            },
            {
              platform: 'discord',
              url: active_platforms?.['discord'] === true && data.discord !== null ? data.discord : '',
              icon: lead_icon5,
              index: data.index,
              source: data.source,
            },
            {
              platform: 'twitter',
              url: active_platforms?.['twitter'] === true && data.twitter !== null ? data.twitter : '',
              icon: lead_icon6,
              index: data.index,
              source: data.source,
            },
          ]

          return (
            <div className='img_group'>
              {socialMediaIcons.map(({ platform, url, icon, index, source }) => {
                return (
                  <>
                    {url.length === 0 && source !== 'CSV' ? (
                      <Popconfirm
                        title={`Get ${platform.charAt(0).toUpperCase() + platform.slice(1)} Details`}
                        description={
                          <Col>
                            <div>{`This action will cost you ${
                              userPoints?.pointsStore[`business_magic_${platform}`]
                            } Magic point. Read disclaimer:`}</div>
                            {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                            {showDisclaimer ? (
                              <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                                {ConfirmBox.DISCLAIMER}
                              </p>
                            ) : (
                              <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                                {'Disclaimer'}
                              </span>
                            )}
                          </Col>
                        }
                        onCancel={() => {
                          toggleShowDisclaimer && toggleShowDisclaimer()
                          setShowDisclaimer(false)
                        }}
                        onConfirm={async () => {
                          toggleShowDisclaimer && toggleShowDisclaimer()
                          setShowDisclaimer(false)
                          const dataExposePlatform = await exposeCompanyPlatform({
                            index: index,
                            platform: platform,
                            isBusiness: true,
                          })

                          if (
                            dataExposePlatform?.error?.status === 470 &&
                            setCostPoints &&
                            setOutOfPoints &&
                            setIsMagic
                          ) {
                            if (dataExposePlatform?.error?.data?.type === 'magic_points') {
                              setIsMagic(true)
                            } else {
                              setIsMagic(false)
                            }
                            setCostPoints(dataExposePlatform?.error?.data?.cost_point)
                            setOutOfPoints(true)
                          } else {
                            const data = dataExposePlatform?.data?.leads_index?.[0]?.[platform]
                            window.open(data, '_blank')
                            // Update the initial_ai_companies_data with the exposed url
                            updateInitialAiCompaniesData &&
                              updateInitialAiCompaniesData(dataExposePlatform?.data?.leads_index?.[0])
                          }
                        }}
                        disabled={!active_platforms[platform] ? true : false}>
                        <img
                          key={platform}
                          style={{
                            filter: 'style',
                            opacity: active_platforms[platform] ? 1 : 0.2, //!url ? 0.2 : 1,
                            cursor: 'pointer', //url ? 'pointer' : 'default',
                            height: '20px',
                            width: '20px',
                          }}
                          src={icon}
                          alt={platform}
                        />
                      </Popconfirm>
                    ) : (
                      <img
                        key={platform}
                        onClick={() => url && window.open(url, '_blank')}
                        style={{
                          filter: !url ? style : '',
                          opacity: !url ? 0.2 : 1,
                          cursor: url ? 'pointer' : 'default',
                          height: '20px',
                          width: '20px',
                        }}
                        src={icon}
                        alt={platform}
                      />
                    )}
                  </>
                )
              })}
            </div>
          )
        },
      }
    : {
        title: 'Channels',
        dataIndex: 'channels',
        key: 'channels',
        width: 150,
        render: (text: string, data: any) => {
          const style = 'grayscale(100%)'
          const socialMediaIcons = [
            { platform: 'facebook', url: data.facebook, icon: lead_icon1 },
            {
              platform: 'instagram',
              url: data.instagram !== null && data.instagram !== '' ? `${data.instagram}` : '',
              icon: lead_icon2,
            },
            {
              platform: 'telegram',
              url: data.telegram !== null && data.telegram !== '' ? data.telegram : '',
              icon: lead_icon3,
            },
            {
              platform: 'linkedin',
              url: data.linkedin !== null && data.linkedin !== '' ? data.linkedin : '',
              icon: lead_icon4,
            },
            {
              platform: 'discord',
              url: data.discord !== null && data.discord !== '' ? data.discord : '',
              icon: lead_icon5,
            },
            {
              platform: 'twitter',
              url: data.twitter !== null && data.twitter !== '' ? data.twitter : '',
              icon: lead_icon6,
            },
          ]

          return (
            <div className='img_group'>
              {socialMediaIcons.map(({ platform, url, icon }) => (
                <img
                  key={platform}
                  onClick={() => url && window.open(url, '_blank')}
                  style={{
                    filter: !url ? style : '',
                    opacity: !url ? 0.2 : 1,
                    cursor: url ? 'pointer' : 'default',
                    height: '20px',
                    width: '20px',
                  }}
                  src={icon}
                  alt={platform}
                />
              ))}
            </div>
          )
        },
      },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: 100,
  },
]

import React, { useState, useEffect, useMemo } from 'react'
import { Table, Tooltip, Typography } from 'antd'
import { ColumnsType, ColumnType } from 'antd/lib/table'
import { capitalize, isObject, zipObject } from 'lodash'
import styles from './CsvPreview.module.scss'

export const CsvPreview = (props: any) => {
  const { csvData, fieldMapping } = props
  const [currentTab, setCurrentTab] = useState('total')

  const processData = (csvData: any, handleField: boolean) => {
    const handleIndex = csvData?.fieldArray.indexOf(handleField)

    if (handleIndex === -1) {
      return {
        ...csvData,
        rowData: handleField ? csvData?.totalRowData : [],
        unvalidRowData: handleField ? [] : csvData?.totalRowData,
      }
    }

    return {
      ...csvData,
      rowData: handleField
        ? csvData?.rowData?.filter(
            (row: any) => row[handleIndex] !== '' && row[handleIndex] !== null && row[handleIndex] !== undefined,
          )
        : [],
      unvalidRowData: handleField
        ? csvData?.rowData?.filter(
            (row: any) => row[handleIndex] === '' || row[handleIndex] === null || row[handleIndex] === undefined,
          )
        : csvData?.totalRowData,
    }
  }

  const [data, setData] = useState(processData(csvData, fieldMapping['handle']))

  useEffect(() => {
    setData(processData(csvData, fieldMapping['handle']))
  }, [csvData, fieldMapping['handle']])

  const tabItems = useMemo(
    () => [
      {
        label: 'Total Rows',
        value: 'total',
        number: data?.totalRowData?.length || 0,
      },
      {
        label: 'Valid Rows',
        value: 'valid',
        number: fieldMapping['handle'] ? data?.rowData?.length || 0 : 0,
      },
      {
        label: 'Errors',
        value: 'error',
        number: fieldMapping['handle'] ? data?.unvalidRowData?.length || 0 : data?.totalRowData?.length,
      },
    ],
    [data, fieldMapping],
  )

  const formatTableRowData = useMemo(() => {
    switch (currentTab) {
      case 'valid':
        return data.rowData
      case 'error':
        return data.unvalidRowData
      default:
        return data.totalRowData
    }
  }, [data, currentTab])

  const tableColumn: ColumnsType<any> = useMemo(() => {
    if (data && data?.fieldArray) {
      return ['row', ...data.fieldArray]?.map(
        (field: any): ColumnType<any> => ({
          title: <div style={{ whiteSpace: 'nowrap', textAlign: 'left', fontSize: '12px' }}>{capitalize(field)}</div>,
          dataIndex: field,
          key: field,
          width: field === 'row' ? 80 : 120,
          render: (text: any) => {
            if (isObject(text)) return ''

            return (
              <Tooltip title={text?.length > 16 ? text : ''}>
                <span>{text?.length > 16 ? `${text?.substring(0, 16)}...` : text}</span>
              </Tooltip>
            )
          },
        }),
      )
    }
    return []
  }, [data])

  const tableRow = useMemo(() => {
    const keys = data.fieldArray
    const values = formatTableRowData
    return values?.map((row: any, index: number) => ({
      ...zipObject(keys, row),
      row: index + 1,
    }))
  }, [data, formatTableRowData])

  return (
    <div className={styles.csvPreview}>
      <Typography className={styles.csvPreview__title}>CSV Preview</Typography>
      <Typography className={styles.csvPreview__description}>
        Please use the sample template to ensure your information is in the correct format before uploading.
      </Typography>

      <div className={styles.csvPreview__tab}>
        {tabItems.map((item: any) => (
          <TabItem
            {...item}
            key={item.value}
            isSelected={item.value === currentTab}
            onChange={() => setCurrentTab(item.value)}
          />
        ))}
      </div>

      <div
        className={`${styles.csvPreview__table} ${
          data?.totalRowData?.length > 150 && styles.csvPreview__table__withPagination
        }`}>
        <Table
          className='hoverableScrollbarTable'
          dataSource={tableRow}
          size={'middle'}
          columns={tableColumn}
          style={{ borderRadius: '8px', border: '1px solid #f0f0f0' }}
          rootClassName={'csvPreviewTable'}
          loading={data?.totalRowData?.length === 0 && data?.fieldArray?.length === 0}
          pagination={
            data?.totalRowData?.length > 150
              ? {
                  size: 'small',
                }
              : false
          }
          scroll={{ y: 'calc(100vh - 500px)', x: 'max-content' }}
        />
      </div>
    </div>
  )
}

const TabItem = (props: any) => {
  const { isSelected, onChange, label, number } = props

  const handleSelect = () => {
    onChange()
  }

  return (
    <div
      className={`${styles.csvPreview__tab__item} ${isSelected && styles['csvPreview__tab__item--active']}`}
      onClick={handleSelect}>
      <Typography
        className={`${styles.csvPreview__tab__item__label} ${
          isSelected && styles['csvPreview__tab__item__label--active']
        }`}>
        {label}
      </Typography>
      <Typography className={styles.csvPreview__tab__item__number}>{number}</Typography>
    </div>
  )
}

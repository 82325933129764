import { Table, Tooltip, Typography } from 'antd'
import { capitalize, isObject, zipObject } from 'lodash'
import React from 'react'
import styles from './CsvPreview.module.scss'

export const CsvPreview = (props: any) => {
  const { csvData } = props
  const [currentTab, setCurrentTab] = React.useState('total')

  const tabItems = React.useMemo(
    () => [
      {
        label: 'Total Rows',
        value: 'total',
        number: csvData?.totalRowData?.length || 0,
      },
      {
        label: 'Vaild Rows',
        value: 'valid',
        number: csvData?.rowData?.length || 0,
      },
      {
        label: 'Errors',
        value: 'error',
        number: csvData?.unvalidRowData?.length || 0,
      },
    ],
    [csvData],
  )

  const formatTableRowData = React.useMemo(() => {
    let result: any = []

    switch (currentTab) {
      case 'total':
        result = csvData.totalRowData
        break

      case 'valid':
        result = csvData.rowData
        break

      case 'error':
        result = csvData.unvalidRowData
        break

      default:
        result = csvData.totalRowData
    }

    return result
  }, [csvData, currentTab])

  const tableColumn = React.useMemo(() => {
    if (csvData && csvData?.fieldArray) {
      return ['row', ...csvData.fieldArray]?.map((field: any) => ({
        title: capitalize(field),
        dataIndex: field,
        key: field,
        width: field === 'row' ? 80 : 120,
        render: (text: any) => {
          if (isObject(text)) return ''

          return (
            <Tooltip title={text?.length > 20 ? text : ''}>
              <span>{text?.length > 20 ? `${text?.substring(0, 20)}...` : text}</span>
            </Tooltip>
          )
        },
      }))
    }
  }, [csvData])

  const tableRow = React.useMemo(() => {
    const keys = csvData.fieldArray
    const values = formatTableRowData
    return values?.map((row: any, index: number) => ({
      ...zipObject(keys, row),
      row: index + 1,
    }))
  }, [csvData, formatTableRowData])

  return (
    <div className={styles.csvPreview}>
      <Typography className={styles.csvPreview__title}>CSV Preview </Typography>
      <Typography className={styles.csvPreview__description}>
        Please use the sample template to ensure your information is in the correct format before uploading.
      </Typography>

      <div className={styles.csvPreview__tab}>
        {tabItems.map((item: any) => (
          <TabItem
            {...item}
            key={item.value}
            isSelected={item.value === currentTab}
            onChange={() => setCurrentTab(item.value)}
          />
        ))}
      </div>

      <div
        className={`${styles.csvPreview__table} ${
          csvData?.totalRowData?.length > 150 && styles.csvPreview__table__withPagination
        }`}>
        <Table
          dataSource={tableRow}
          columns={tableColumn}
          style={{ borderRadius: '16px' }}
          rootClassName={'csvPreviewTable'}
          loading={csvData?.totalRowData?.length === 0 && csvData?.fieldArray?.length === 0}
          pagination={
            csvData?.totalRowData?.length > 150
              ? {
                  size: 'small',
                }
              : false
          }
          scroll={{ y: 'calc(100vh - 425px)', x: 'auto' }}
        />
      </div>
    </div>
  )
}

const TabItem = (props: any) => {
  const { isSelected, onChange, label, number } = props

  const handleSelect = () => {
    onChange()
  }

  return (
    <div
      className={`${styles.csvPreview__tab__item} ${isSelected && styles['csvPreview__tab__item--active']}`}
      onClick={handleSelect}>
      <Typography
        className={`${styles.csvPreview__tab__item__label} ${
          isSelected && styles['csvPreview__tab__item__label--active']
        }`}>
        {label}
      </Typography>
      <Typography className={styles.csvPreview__tab__item__number}>{number}</Typography>
    </div>
  )
}

import { CommonServices } from 'LEGACY/common/services/common-services'

export const getApproximateHeadcount = (number: number | string) => {
  if (!number) return 'N/A'

  const amount = `${number}`
  const count = +amount?.split('-')[0]

  const ranges = [
    { range: 10, value: '1-10' },
    { range: 50, value: '11-50' },
    { range: 200, value: '51-200' },
    { range: 500, value: '201-500' },
    { range: 1000, value: '501-1,000' },
    { range: 5000, value: '1,001-5,000' },
  ]

  const { value } = ranges.find(({ range }) => count <= range) || { value: '5,001+' }

  return value
}

export const getApproximateByFirstDigit = (number: number | string) => {
  if (!number) return 'N/A'

  const amount = `${number}`
  const approximate = +`${amount[0]}${Array(amount.length - 1)
    .fill(0)
    .join('')}`

  return `${approximate.toLocaleString()}+`
}

export const getApproximateSocialNumbers = (number: number | string) => {
  if (!number) return 'N/A'

  const amount = `${Math.round(+number * 1.1)}`
  const approximate = `${amount[0]}${Array(amount.length - 1)
    .fill(0)
    .join('')}`

  return `${CommonServices.convertToK(approximate).replace('.0', '')}+`
}

export const getApproximateMarketDominance = (number: number | string) => {
  if (!number) return 'N/A'

  const amount = `${number}`

  return ` > ${amount.split('.')[0]}%`
}

import React from 'react'
import { Row, Col } from 'antd'
import styles from './PricingModal.module.scss'
import pointsIcon from 'LEGACY/Assets/svg/points.svg'
import dataIcon from 'LEGACY/Assets/svg/data.svg'
import greenV from 'LEGACY/Assets/svg/greenV.svg'
import redX from 'LEGACY/Assets/svg/redX.svg'
import lead_generation from 'LEGACY/Assets/svg/lead_generation.svg'
import outreach from 'LEGACY/Assets/svg/outreach.svg'
import contactsBlack from 'LEGACY/Assets/svg/contactsBlack.svg'
interface PricingModalProps {}
interface Plan {
  name: string
  [key: string]: any // This allows any other properties with any type
}

const comparePlans: Plan[] = [
  {
    name: 'Go to Market',
    Points: {
      'Convrt Points': '1,250',
    },
    Data: {
      'Companies Research': 'Basic',
      'Prospects Finder': 'Basic',
      'On Chain Signals': false,
      // 'Sentiment Buying Signals': false,
      'Funding Data': false,
      'On Chain Data Filtering': 'Unlimited',
      Milestones: 'Enterprises entering web3',
      'Technologies Used': false,
      'SC Intel Data': false,
      'Similar Companies Market Share': true,
      'Company Social Media': true,
    },
    'Lead Generation': {
      'Selection limit': 10,
      Lists: 3,
      'Hubspot Enrichment': 10,
      'Hubspot Deals': true,
      'Connect+: Telegram Connections Mapping': '1st Circle',
      'Contacts Importing': false,
      'CSV Export': false,
      'Telegram contacts Importing': true,
      'CRM Activity Sync': false,
      'Convrt Chrome Extension': true,
      'Team Collab': false,
    },
    Prospects: {
      Linkedin: 25,
      Twitter: 25,
      'Communities+': 10,
    },
    Outreach: {
      'Telegram Bulk Messages': 5,
      'Contacts Outreached': 50,
      'Telegram Personalized Outreach': true,
      'Outreach Replies Tracking': false,
      'Warmed Managed Account': false,
      'Block Protection': false,
      Automations: false,
      'Unified Inbox': false,
      'LinkedIn Outreach Sequence': false,
    },
  },
  {
    name: 'Connect+',
    Points: {
      'Convrt Points': '5,000',
    },
    Data: {
      'Companies Research': 'Advanced',
      'Prospects Finder': 'Advanced',
      'On Chain Signals': true,
      // 'Sentiment Buying Signals': false,
      'Funding Data': true,
      'On Chain Data Filtering': 'Unlimited',
      Milestones: 'All',
      'Technologies Used': false,
      'SC Intel Data': false,
      'Similar Companies Market Share': true,
      'Company Social Media': true,
    },
    'Lead Generation': {
      'Selection limit': 50,
      Lists: 15,
      'Hubspot Enrichment': 80,
      'Hubspot Deals': true,
      'Connect+: Telegram Connections Mapping': 'All',
      'Contacts Importing': true,
      'CSV Export': true,
      'Telegram contacts Importing': true,
      'CRM Activity Sync': true,
      'Convrt Chrome Extension': true,
      'Team Collab': true,
    },
    Prospects: {
      Linkedin: 'Unlimited',
      Twitter: 'Unlimited',
      'Communities+': 50,
    },
    Outreach: {
      'Telegram Bulk Messages': 'Unlimited',
      'Contacts Outreached': 300,
      'Telegram Personalized Outreach': true,
      'Outreach Replies Tracking': true,
      'Warmed Managed Account': false,
      'Block Protection': false,
      Automations: false,
      'Unified Inbox': false,
      'LinkedIn Outreach Sequence': false,
    },
  },
  {
    name: 'Market Dominance',
    Points: {
      'Convrt Points': '15,000',
    },
    Data: {
      'Companies Research': 'Advanced',
      'Prospects Finder': 'Advanced',
      'On Chain Signals': true,
      // 'Sentiment Buying Signals': false,
      'Funding Data': true,
      'On Chain Data Filtering': 'Unlimited',
      Milestones: 'All',
      'Technologies Used': true,
      'SC Intel Data': true,
      'Similar Companies Market Share': true,
      'Company Social Media': true,
    },
    'Lead Generation': {
      'Selection limit': 150,
      Lists: 'Unlimited',
      'Hubspot Enrichment': 200,
      'Hubspot Deals': true,
      'Connect+: Telegram Connections Mapping': 'All',
      'Contacts Importing': true,
      'CSV Export': true,
      'Telegram contacts Importing': true,
      'CRM Activity Sync': 'Full Sync',
      'Convrt Chrome Extension': true,
      'Team Collab': true,
    },
    Prospects: {
      Linkedin: 'Unlimited',
      Twitter: 'Unlimited',
      'Communities+': 'Unlimited',
    },
    Outreach: {
      'Telegram Bulk Messages': 'Unlimited',
      'Contacts Outreached': 850,
      'Telegram Personalized Outreach': true,
      'Outreach Replies Tracking': true,
      'Warmed Managed Account': true,
      'Block Protection': true,
      Automations: true,
      'Unified Inbox': true,
      'LinkedIn Outreach Sequence': true,
    },
  },
  {
    name: 'AI Meeting Setter',
    Points: {
      'Convrt Points': '40,000',
    },
    Data: {
      'Companies Research': 'Advanced',
      'Prospects Finder': 'Advanced',
      'On Chain Signals': true,
      // 'Sentiment Buying Signals': false,
      'Funding Data': true,
      'On Chain Data Filtering': 'Unlimited',
      Milestones: 'All',
      'Technologies Used': true,
      'SC Intel Data': true,
      'Similar Companies Market Share': true,
      'Company Social Media': true,
    },
    'Lead Generation': {
      'Selection limit': 150,
      Lists: 'Unlimited',
      'Hubspot Enrichment': 200,
      'Hubspot Deals': true,
      'Connect+: Telegram Connections Mapping': 'All',
      'Contacts Importing': true,
      'CSV Export': true,
      'Telegram contacts Importing': true,
      'CRM Activity Sync': 'Full Sync',
      'Convrt Chrome Extension': true,
      'Team Collab': true,
    },
    Prospects: {
      Linkedin: 'Unlimited',
      Twitter: 'Unlimited',
      'Communities+': 'Unlimited',
    },
    Outreach: {
      'Telegram Bulk Messages': 'Unlimited',
      'Contacts Outreached': 850,
      'Telegram Personalized Outreach': true,
      'Outreach Replies Tracking': true,
      'Warmed Managed Account': true,
      'Block Protection': true,
      Automations: true,
      'Unified Inbox': true,
      'LinkedIn Outreach Sequence': true,
    },
  },
]

const description = {
  'Convrt Points': (
    <span>
      Convrt Points are tokens you get to use the Telegram finder or email finder.
      <br />
      <span style={{ textDecoration: 'underline' }}>Costs:</span>
      <br />
      Telegram Finder: 50 Points
      <br />
      Email Finder: 15 Points
    </span>
  ),
  'On Chain Signals': `Filtering by TVL, Wallets pm, Volume, Audits, Chains distributionthe companies annualized revenue.`,
}
const PricingModal: React.FC<PricingModalProps> = () => {
  const fields = new Set<string>()
  comparePlans.forEach((plan) => {
    Object.keys(plan).forEach((key) => {
      if (key !== 'name') fields.add(key)
    })
  })
  return (
    <div>
      <Row className={styles.stickyAppBar}>
        {comparePlans.map((plan, index) => (
          <Col span={4} key={index} className={styles.title}>
            {plan.name}
          </Col>
        ))}
      </Row>

      {Array.from(fields).map((field, index) => (
        <React.Fragment key={index}>
          <Row gutter={[16, 16]} style={{ display: 'flex' }}>
            <Col>
              <div className={styles.fields}>
                <div className={styles.icon}>
                  {field === 'Points' ? (
                    <img src={pointsIcon} alt={''} />
                  ) : field === 'Data' ? (
                    <img src={dataIcon} alt={''} />
                  ) : field === 'Lead Generation' ? (
                    <img src={lead_generation} alt={''} />
                  ) : field === 'Prospects' ? (
                    <img src={contactsBlack} alt={''} />
                  ) : field === 'Outreach' ? (
                    <img src={outreach} alt={''} />
                  ) : (
                    ''
                  )}
                </div>{' '}
                <div>{field}</div>
              </div>
            </Col>
            {comparePlans.map((_, idx) => (
              <Col key={idx}></Col>
            ))}
          </Row>
          {Object.keys(comparePlans[0][field] || {}).map((topic, topicIndex) => (
            <Row key={topicIndex} gutter={[16, 16]}>
              <Col span={2} className={styles.contentTopic}>
                <div style={{ paddingLeft: '15px' }}>
                  {topic}
                  <div className={styles.discription}>
                    {' '}
                    {topic === 'Convrt Points' || topic === 'On Chain Signals' ? description[topic] : ''}{' '}
                  </div>
                </div>
              </Col>
              {comparePlans.map((plan, planIndex) => (
                <Col span={4} key={planIndex} className={styles.contentData}>
                  {typeof plan[field]?.[topic] === 'boolean' ? (
                    plan[field]?.[topic] ? (
                      <img src={greenV} alt={''} />
                    ) : (
                      <img src={redX} alt={''} />
                    )
                  ) : (
                    plan[field]?.[topic]
                  )}
                </Col>
              ))}
            </Row>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

export default PricingModal

import React, { useEffect, useState } from 'react'
import { Collapse, Menu, Avatar, Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './ChatChannels.module.scss'
import user from 'common/assets/png/user_table.png'
import telegram from 'common/assets/svg/tele_icon.svg'
import instagram from 'common/assets/svg/insta_icon.svg'
import linkedin from 'common/assets/svg/linked_icon.svg'
import discord from 'common/assets/svg/disc_icon.svg'
import twitter from 'common/assets/svg/xtwit_icon.svg'
import facebook from 'common/assets/svg/facebook_messenger.svg'
import facebook_messenger from 'common/assets/svg/facebook_messenger.svg'
import add_icon from 'common/assets/svg/add_icon.svg'
import channel_icon from 'common/assets/svg/channel_icon.svg'
import {
  useLazyCatchUpQuery,
  useLazyGetDialogsQuery,
  useLazyUnifyQuery,
  useLazyUnifySpecificAccountsQuery,
} from '../../APIs/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_DIALOGS_DATA,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_CHAT_PLATFORM,
  SET_SELECTED_CHAT_RECIPIENT,
} from './state/chatSlice'
import { useAuth } from 'common/hooks/useAuth.hooks'
import classNames from 'classnames'
import TruncatedTooltip from 'common/components/GeneralComponents/UI/TruncatedTooltip'
import ImageWithFallback from '../GeneralComponents/UI/ImageWithFallback'

const { Panel } = Collapse
const icons: any = { telegram, instagram, linkedin, discord, twitter, facebook, user, add_icon }

const MENU_ITEMS = [
  {
    id: '1',
    type: 'telegram',
    icon: 'telegram',
    title: 'Telegram',
    channels: [],
  },
  {
    id: '2',
    type: 'instagram',
    icon: 'instagram',
    title: 'Instagram',
    channels: [],
  },
  {
    id: '3',
    type: 'facebook',
    icon: 'facebook',
    title: 'Facebook',
    channels: [],
  },
  // {
  //   id: '4',
  //   type: 'linkedin',
  //   icon: 'linkedin',
  //   title: 'LinkedIn',
  //   channels: [],
  // },
  // {
  //   id: '5',
  //   type: 'discord',
  //   icon: 'discord',
  //   title: 'Discord',
  //   channels: [],
  // },
  // {
  //   id: '6',
  //   type: 'twitter',
  //   icon: 'twitter',
  //   title: 'X (Twitter)',
  //   channels: [],
  // },
]

interface ChatChannelsProps {
  collapsed: boolean
}
const ChatChannels: React.FC<ChatChannelsProps> = ({ collapsed }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const addons = user?.addons
  const unified_inbox_facebook = addons?.unified_inbox_facebook
  const [getDialogs, { data: dialogDataFromRedux }] = useLazyGetDialogsQuery()
  const [unifiySpecificAccounts] = useLazyUnifySpecificAccountsQuery()
  const [firstTime, setFirstTime] = useState(true)
  const [catchedUp, setCatchedUp] = useState<any>([])
  const [catchUp] = useLazyCatchUpQuery()
  const { userDetails } = useAuth()
  const {
    is_unify_finished,
    selected_chat_recipient,
    thread_from,
    dialogs_data: dialogsData,
  } = useAppSelector((state) => state.chat)
  const dispatch = useAppDispatch()
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<any>([])
  const [unifyAccounts] = useLazyUnifyQuery()
  useEffect(() => {
    dispatch(SET_DIALOGS_DATA(dialogDataFromRedux))
  }, [dialogDataFromRedux])
  const handleMenuItemClick = async (item: any) => {
    // Find the selected channel by ID from chatData
    dispatch(SET_SELECTED_ACCOUNT(item))
    dispatch(SET_SELECTED_CHAT_PLATFORM(item.type))
    if (item.username !== selected_chat_recipient.sender_username && item.account_id !== 'all_data') {
      dispatch(SET_SELECTED_CHAT_RECIPIENT({}))
    }

    if (item.account_id === 'all_data') {
      setSelectedMenuKeys([`all_data_${item.type}`])
    } else {
      setSelectedMenuKeys([item?.username])
    }

    if (item?.status?.is_syncing === false && !item?.status?.is_init) {
      const { username, type: platform } = item
      const account = { username, platform }
      await unifiySpecificAccounts({ accounts: [account] })
      await getDialogs({ thread_from })
    }
  }

  const countTotalUnread = (data: any) => {
    let totalUnread = 0
    if (!data) return 0

    Object.values(data).forEach((platform: any) => {
      if (platform?.dialogData && Array.isArray(platform?.dialogData)) {
        const unreadCount = platform.dialogData.reduce((sum: number, dialog: any) => {
          const unread = parseInt(dialog.unread_count, 10)
          return sum + (isNaN(unread) ? 0 : unread)
        }, 0)
        totalUnread += unreadCount
      }
    })

    return totalUnread
  }

  const totalUnreadCount = countTotalUnread(dialogsData)

  useEffect(() => {
    const getChatdata = async () => {
      await getDialogs({ thread_from })
    }
    if (is_unify_finished) {
      getChatdata()
    }
  }, [is_unify_finished])

  useEffect(() => {
    dispatch(SET_SELECTED_ACCOUNT({ name: 'All Active Accounts', account_id: `all_data`, type: 'instagram' }))
    setSelectedMenuKeys(['all_data_instagram'])
  }, [])

  const handleViewAllChannels = () => {
    dispatch(
      SET_SELECTED_ACCOUNT({ name: 'All Active Accounts', account_id: `view_all_channels`, type: 'all_platforms' }),
    )
    setSelectedMenuKeys([])
  }
  useEffect(() => {
    const check = async () => {
      console.log(dialogsData?.telegram?.accounts)
      if (dialogsData?.telegram?.accounts?.length > 0) {
        // check if any of them are still loading
        const isLoading = dialogsData?.telegram?.accounts?.some(
          (account: any) => account?.status?.message === 'started unifying' && account?.status?.is_finished === false,
        )
        if (isLoading) {
          // call unify
          unifyAccounts()
          setTimeout(() => {
            console.log('calling check internal')
            check()
          }, 15000)
        }
      }
    }
    check()
  }, [dialogsData?.telegram?.accounts])
  const handleCatchup = async (dialogItem: any) => {
    const senderData = {
      area_code: dialogItem.area_code,
      number: dialogItem.number,
      email: dialogItem.email,
    }
    if (dialogItem?.status?.is_finished) {
      await catchUp({
        senderData: senderData,
      })
    }
  }
  const makeIntervalToCatchup = (dialogItem: any) => {
    if (dialogItem?.status?.is_finished) {
      if (catchedUp.includes(dialogItem.id)) {
        return
      }
      setCatchedUp([...catchedUp, dialogItem.id])
      const interval = setInterval(async () => {
        await handleCatchup(dialogItem)
      }, 120000)

      return () => {
        clearInterval(interval)
      }
    }
  }

  useEffect(() => {
    if (dialogsData && firstTime && Object.keys(dialogsData || {}).length > 0) {
      setFirstTime(false)
      MENU_ITEMS.forEach((item: any) => {
        const platform = item.type
        const dialog = dialogsData?.[platform]
        dialog?.accounts?.forEach((dialogItem: any) => {
          makeIntervalToCatchup(dialogItem)
        })
      })
    }
  }, [dialogsData])
  return (
    <div className={styles.chat_channels}>
      <Collapse className='chat_channels_collapse' defaultActiveKey={['2', '3']}>
        <Panel
          className={`${styles['view_all_channel_item']} view_all_channel_item ${
            selectedMenuKeys?.length === 0 ? styles.selected : styles.unselected
          }`}
          header={
            <div onClick={handleViewAllChannels} className={`${styles['left']} left`}>
              <img src={channel_icon} alt='channel_icon' />
              <span className='hide all'>View</span>
              <span className='all m_2'>All</span>
              <span className='hide all'>Channels</span>
              <span className='hide all'>
                <div className='right'>
                  {totalUnreadCount > 0 && (
                    <div className={styles.unreadCount}>
                      <span>{totalUnreadCount}</span>
                    </div>
                  )}
                </div>
              </span>
            </div>
          }
          key='0'></Panel>
        <Collapse className='chat_channels_collapse' defaultActiveKey={['1', '2', '3']} collapsible='header'>
          {MENU_ITEMS.map((item: any) => {
            if (!unified_inbox_facebook && item.type === 'facebook') {
              return <></>
            }
            if (user.user_type === 'web3' && item.type !== 'telegram') {
              return null
            }
            const isLoading = !dialogsData && userDetails?.addons?.unified_inbox
            const platform = item.type
            const d = dialogsData?.[platform]
            const dialog = d
              ? thread_from === 'convrt'
                ? {
                    ...d,
                    dialogData: [...d?.dialogData].filter(
                      (dialog: any) => dialog.campaign_name && dialog.campaign_name !== null,
                    ),
                  }
                : d
              : null
            const numberOfAccounts = dialog?.accounts?.length
            const atLeastOneActive = dialog?.accounts?.some((account: any) => account?.status?.is_syncing === true)

            const unread_count = dialog?.dialogData?.reduce((acc: any, item: any) => {
              const count = parseInt(item.unread_count, 10)
              return acc + (isNaN(count) ? 0 : count > 0 ? 1 : 0)
              // return acc + parseInt(item.unread_count, 10)
            }, 0)

            return (
              <Panel
                collapsible={isLoading || !dialog ? 'disabled' : 'header'}
                showArrow={!isLoading}
                header={
                  <div className={classNames(styles.panelHeader, { [styles.open]: !collapsed })}>
                    <span className={`${styles['left']} left`}>
                      <img src={icons[item.icon]} alt='' />
                      {/* {collapsed && <img src={icons[item.icon]} alt='' />} */}
                      {item.title}
                      {unread_count > 0 && !collapsed && (
                        <div className={styles.unreadCount}>
                          <span>{unread_count}</span>
                        </div>
                      )}
                    </span>
                    <span className='right'>
                      {isLoading ? (
                        <div>
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                        </div>
                      ) : (
                        <div className={styles.chat_channels_accounts}>
                          <span
                            className={styles.chat_channels_dot}
                            style={{ backgroundColor: atLeastOneActive ? '#50e2ba' : '#ffc700' }}></span>
                          <p>
                            {numberOfAccounts || '0'} Account{numberOfAccounts === 1 ? '' : 's'}
                          </p>
                        </div>
                      )}
                    </span>
                  </div>
                }
                key={item.id}>
                <Menu
                  theme='light'
                  mode='inline'
                  defaultSelectedKeys={[`all_data_${platform}`]}
                  selectedKeys={selectedMenuKeys}>
                  <Menu.Item
                    key={`all_data_${platform}`}
                    className='default-class'
                    onClick={() =>
                      handleMenuItemClick({ name: 'All Active Accounts', account_id: `all_data`, type: platform })
                    }>
                    <div className='menu_content'>
                      <div className={styles.centered} style={{ alignItems: 'center' }}>
                        <img src={icons[item.icon]} alt='' style={{ height: '25px' }} />
                        <span className='nav-text'>All Active Accounts</span>
                      </div>
                      <div className='right'>
                        {unread_count > 0 && (
                          <div className={styles.unreadCount}>
                            <span>{unread_count}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Menu.Item>
                  {dialog?.accounts?.map((dialogItem: any) => {
                    const status = ['instagram', 'facebook'].includes(platform)
                      ? dialogItem?.status?.is_syncing === true
                      : dialogItem?.status?.is_finished === true
                    const canBeUsed = dialogItem?.canBeUsed || dialogItem?.status?.is_init
                    const notExists = dialogItem?.status?.detail === `User has not signed to ${platform} yet`
                    if (notExists) return <></>
                    const totalUnreadCount =
                      platform === 'telegram'
                        ? dialog?.unreadData
                            ?.filter((d: any) => d.receiver_number === dialogItem?.receiver_number)
                            .reduce((acc: any, current: any) => {
                              const count = parseInt(current.unread_count, 10)
                              return acc + (isNaN(count) ? 0 : count > 0 ? 1 : 0)
                              // return acc + (isNaN(count) ? 0 : count)
                            }, 0)
                        : dialog?.unreadData
                            ?.filter((d: any) => {
                              return d?.sender_username === dialogItem?.username
                            })
                            .reduce((acc: any, current: any) => {
                              const count = parseInt(current.unread_count, 10)

                              return acc + (isNaN(count) ? 0 : count > 0 ? 1 : 0)
                              // return acc + (isNaN(count) ? 0 : count)
                            }, 0)

                    const firstName = dialogItem.first_name

                    return (
                      <Menu.Item
                        key={dialogItem.username}
                        className='online'
                        onClick={() => handleMenuItemClick(dialogItem)}
                        // disabled={}
                      >
                        <div
                          className='menu_content'
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <div className='left' style={{ display: 'flex', alignItems: 'center' }}>
                            {platform === 'telegram' ? (
                              <>
                                {status ? (
                                  <Avatar
                                    size={24}
                                    icon={
                                      <ImageWithFallback
                                        style={{ borderRadius: '50%' }}
                                        src={dialogItem.picture ? dialogItem.picture : icons[item.icon]}
                                        fallbackStrOrElement={icons[item.icon]}
                                      />
                                    }
                                  />
                                ) : (
                                  <Spin
                                    indicator={<LoadingOutlined style={{ fontSize: 20, marginRight: '15px' }} spin />}
                                  />
                                )}
                                <Tooltip
                                  title={`${dialogItem.first_name || dialogItem.userName} / ${dialogItem.area_code}${
                                    dialogItem.number
                                  }`}>
                                  <span className='nav-text'>{`${
                                    dialogItem.first_name?.length > 15
                                      ? `${dialogItem.first_name.slice(0, 15)?.trim()}...`
                                      : dialogItem.first_name || ''
                                  } (${
                                    dialogItem.username ||
                                    dialogItem.userName ||
                                    `${dialogItem.area_code}${dialogItem.number}`
                                  })`}</span>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {status && canBeUsed ? (
                                  <Avatar
                                    size={24}
                                    icon={
                                      <img
                                        style={{ borderRadius: '50%' }}
                                        src={
                                          dialogItem.picture
                                            ? dialogItem.picture
                                            : platform === 'facebook'
                                            ? facebook_messenger
                                            : icons[item.icon]
                                        }
                                        alt=''
                                      />
                                    }
                                  />
                                ) : canBeUsed ? (
                                  <Spin
                                    indicator={<LoadingOutlined style={{ fontSize: 20, marginRight: '15px' }} spin />}
                                  />
                                ) : (
                                  <Avatar
                                    size={24}
                                    className='outOfSync'
                                    icon={
                                      <img
                                        style={{ borderRadius: '50%' }}
                                        src={
                                          dialogItem.picture
                                            ? dialogItem.picture
                                            : platform === 'facebook'
                                            ? facebook_messenger
                                            : icons[item.icon]
                                        }
                                        alt=''
                                      />
                                    }
                                  />
                                )}
                                {firstName ? (
                                  <Tooltip
                                    title={`${dialogItem.first_name} / ${dialogItem.area_code}${dialogItem.number}`}>
                                    <span className='nav-text'>{`${
                                      firstName?.length > 15 ? `${firstName.slice(0, 15)?.trim()}...` : firstName
                                    } (${dialogItem.username})`}</span>
                                  </Tooltip>
                                ) : (
                                  <span className='nav-text'>
                                    <TruncatedTooltip
                                      text={dialogItem.username}
                                      fontSize='14px'
                                      maxLength={25}
                                      tooltipPlacement='top'
                                    />
                                  </span>
                                )}
                                {/* <span className='nav-text'>{`${dialogItem.area_code}${dialogItem.number}`}</span> */}
                              </>
                            )}
                          </div>
                          <div className='right'>
                            {dialogItem.count ? <span className='count'>{dialogItem.count}</span> : ''}
                            {/* {!status && !dialogItem?.status?.is_init
                              ? loadingAccounts[dialogItem.username] && (
                                  <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                )
                              : ''} */}
                            {status && (
                              <>
                                {totalUnreadCount > 0 && (
                                  <div className={styles.unreadCount}>
                                    <span>{totalUnreadCount}</span>
                                  </div>
                                )}
                                {/* <Avatar
                                style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                                size={24}
                                icon={<EllipsisOutlined />}
                              /> */}
                              </>
                            )}
                          </div>
                        </div>
                      </Menu.Item>
                    )
                  })}
                  {/* <Menu.Item
                  key='add_account'
                  className='default-class'
                  onClick={() =>
                    handleMenuItemClick({ name: 'Add Account', account_id: 'add_account', type: platform })
                  }>
                  <div className='menu_content'>
                    <div className={styles.centered} style={{ alignItems: 'center' }}>
                      <img src={icons['add_icon']} alt='' />
                      <span className='nav-text'>Add Account</span>
                    </div>
                    <div className='right'>
                      <Avatar
                        style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                        size={24}
                        icon={<EllipsisOutlined />}
                      />
                    </div>
                  </div>
                </Menu.Item> */}
                </Menu>
              </Panel>
            )
          })}
        </Collapse>
      </Collapse>
    </div>
  )
}

export default ChatChannels

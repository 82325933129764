import dagre from 'dagre'

// const initializeGraph = () => {
//   const dagreGraph = new dagre.graphlib.Graph()
//   dagreGraph.setDefaultEdgeLabel(() => ({}))
//   return dagreGraph
// }

// let dagreGraph = initializeGraph()
// const dagreGraph = initializeGraph()

// export const resetDagreGraph = () => {
//   dagreGraph = initializeGraph()
// }

export const getLayoutedElements = (nodes: any = [], edges: any = [], resetGraph = true, direction = 'TB') => {
  const nodeWidth = 250
  const nodeHeight = 60

  // Init every time for now
  // if (resetGraph) {
  // dagreGraph = initializeGraph()
  // }

  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  dagreGraph.setGraph({
    rankdir: direction,
    //  ranker: 'tight-tree'
  })

  const sortedNodes = [...nodes]?.sort((a: any, b: any) => a.id.localeCompare(b.id))
  const sortedEdges = [...edges]?.sort((a: any, b: any) => a.source.localeCompare(b.source))

  sortedNodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  sortedEdges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  const newNodes = sortedNodes.map((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    return {
      ...node,
      targetPosition: 'top',
      sourcePosition: 'bottom',
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    }
  })

  return { nodes: newNodes, edges }
}

export const getParentNodeId = (nodeId: any, edges: any): string | null => {
  const parentEdge = edges.find((edge: any) => edge.target === nodeId)
  if (parentEdge) {
    return parentEdge.source
  }
  return null
}

export const getGrandparentNodeId = (nodeId: any, edges: any): string | null => {
  // Get the parent node ID
  const parentNodeId = getParentNodeId(nodeId, edges)
  if (parentNodeId) {
    // Get the grandparent node ID by using the parent's ID
    return getParentNodeId(parentNodeId, edges)
  }
  return null // If no parent or grandparent is found, return null
}

// export const filterPlaceholderNodesAndEdges = (nodes: any[], edges: any[]) => {
//   const filteredNodes = nodes.filter((node) => node.type !== 'placeholderNode' && node.type !== 'initialNode')
//   const placeholderNodeIds = new Set(
//     nodes.filter((node) => node.type === 'placeholderNode' || node.type === 'initialNode').map((node) => node.id),
//   )

//   const filteredEdges = edges.filter(
//     (edge) => !placeholderNodeIds.has(edge.source) && !placeholderNodeIds.has(edge.target),
//   )

//   return { filteredNodes, filteredEdges }
// }

export const filterPlaceholderNodesAndEdges = (nodes: any[], edges: any[], nonEditableNodes?: any[]) => {
  if (!nonEditableNodes) {
    const filteredNodes = nodes.filter((node) => node.type !== 'placeholderNode' && node.type !== 'initialNode')
    const placeholderNodeIds = new Set(
      nodes.filter((node) => node.type === 'placeholderNode' || node.type === 'initialNode').map((node) => node.id),
    )

    const filteredEdges = edges.filter(
      (edge) => !placeholderNodeIds.has(edge.source) && !placeholderNodeIds.has(edge.target),
    )

    return { filteredNodes, filteredEdges }
  }

  // Helper function to check if a node's parent is in nonEditableNodes and respects `canAddChild`
  const isParentRestricted = (nodeId: string, print?: boolean): boolean => {
    const parentId = getParentNodeId(nodeId, edges)
    const granParentId = getParentNodeId(parentId, edges)
    if (granParentId) {
      const parentStatus = nonEditableNodes.find((n) => n.step_id === granParentId)
      return parentStatus ? !parentStatus.canAddChild : false
    }
    return false
  }

  // Filter nodes based on type and parent condition
  const filteredNodes = nodes.filter((node) => {
    // if (node.type === 'placeholderNode') {
    //   return !isParentRestricted(node.id, true)
    // }
    return node.type !== 'initialNode'
  })

  const updatedNodes = filteredNodes.map((node) => {
    if (node.type === 'placeholderNode') {
      return { ...node, data: { ...node.data, isFlagged: isParentRestricted(node.id, true) } }
    } else {
      return node
    }
  })

  // Identify IDs of nodes to be excluded
  const excludedNodeIds = new Set(nodes.filter((node) => node.type === 'initialNode'))

  // Filter edges to exclude connections involving excluded nodes
  const filteredEdges = edges.filter((edge) => !excludedNodeIds.has(edge.source) && !excludedNodeIds.has(edge.target))

  return { filteredNodes: updatedNodes, filteredEdges }
}

export const getIsCondition = (type: string) => {
  switch (type) {
    case 'send_message':
      return false
    case 'ai_responder':
      return false
    case 'is_message_replied':
      return true
    case 'is_wait':
      return true
    case 'view_story':
      return false
    case 'like_post':
      return false
    case 'lead_profiles':
      return false
    case 'comment_on_photo':
      return false
    case 'comment_on_post':
      return false
    case 'comment_on_story':
      return false
    case 'lead_view_dm':
      return true
    case 'lead_respond':
      return true
    case 'stop_response':
      return false
    case 'is_public':
      return true
    case 'follow_lead':
      return false
    case 'connect_lead':
      return false
    case 'connect_lead_with_note':
      return false
    case 'disconnect_lead':
      return false
    case 'follow_x_friends':
      return false
    case 'unfollow_lead':
      return false
    case 'lead_accepted':
    case 'lead_accepted_follow':
    case 'lead_accepted_connect':
      return true
    case 'if_open':
      return true
    default:
      return false
  }
}

export const splitEdges = (type: any) => {
  if (type === 'is_wait') {
    return false
  } else {
    return getIsCondition(type)
  }
}

export const getId = (id: string, isCondition: boolean): string | string[] => {
  const segments = id.split('_')
  const firstSegment = parseInt(segments[0], 10) + 1
  segments[0] = firstSegment.toString()
  const incrementedId = segments.join('_')

  if (!isCondition) {
    return [incrementedId]
  } else {
    return [`${incrementedId}_1`, `${incrementedId}_2`]
  }
}

export const addInitialAndPlaceholderNodes = (nodes: any, edges: any) => {
  // Ensure nodes and edges are arrays
  if (!Array.isArray(nodes) || !Array.isArray(edges)) {
    console.error("Invalid input: 'nodes' and 'edges' must be arrays")
    return { nodes: [], edges: [] }
  }

  const validNodeIds = new Set(nodes.map((node: any) => node.id))

  // Filter edges to retain only those where both source and target nodes exist in the validNodeIds set
  const validEdges = edges.filter((edge: any) => validNodeIds.has(edge.source) && validNodeIds.has(edge.target))

  // Collect all unique source and target nodes from valid edges
  const sourceNodes = new Set(validEdges.map((edge: any) => edge.source))
  const targetNodes: any = new Set(validEdges.map((edge: any) => edge.target))

  // The last nodes are those in targetNodes but not in sourceNodes
  const leafNodeIds: any = [...targetNodes].filter((target) => !sourceNodes.has(target))

  const updatedNodes = [...nodes]
  const updatedEdges = [...edges]

  const hasInitialNode = updatedNodes.some((node) => node.id === '0')
  const hasInitialEdge = updatedEdges.some((edge) => edge.source === '0' && edge.target === '1')

  if (!hasInitialNode) {
    const initialNode = {
      id: '0',
      type: 'initialNode',
      data: { type: 'initial', parentId: '' },
      position: { x: 0, y: 0 },
    }
    updatedNodes.unshift(initialNode)
  }

  // If edge "0 -> 1" doesn't exist, add it
  if (!hasInitialEdge) {
    const initialEdge = {
      id: '0-1',
      type: 'smoothstep',
      source: '0',
      target: '1',
      style: { stroke: '#7043ff' },
    }
    updatedEdges.unshift(initialEdge)
  }

  // Iterate through leaf nodes to check for placeholder type
  leafNodeIds.forEach((leafNodeId: any) => {
    const leafNode = updatedNodes.find((node: any) => node.id === leafNodeId)

    // If leaf node is not a placeholderNode, add a new placeholder node
    if (leafNode && leafNode.type !== 'placeholderNode') {
      const leafNodeTyoe = leafNode?.type
      const isCondition = getIsCondition(leafNodeTyoe)
      const newNodeIds: any = getId(leafNodeId, isCondition)

      if (!Array.isArray(newNodeIds) || newNodeIds.length === 0) {
        console.error(`Failed to generate new node IDs for leafNodeId: ${leafNodeId}`)
        return
      }

      if (isCondition) {
        // Create a new placeholder node
        const newPlaceholderNode1 = {
          id: newNodeIds?.[0],
          data: { parentId: leafNodeId, isCondition: true },
          type: 'placeholderNode',
        }

        const newPlaceholderNode2 = {
          id: newNodeIds?.[1],
          data: { parentId: leafNodeId, isCondition: true },
          type: 'placeholderNode',
        }

        // Update the children array of the original leaf node to connect to the new placeholder node
        if (!leafNode.data.children) {
          leafNode.data.children = []
        }
        leafNode.data.children.push(...newNodeIds)

        // Add the new placeholder node to the nodes array
        updatedNodes.push(newPlaceholderNode1)
        updatedNodes.push(newPlaceholderNode2)

        // Add a new edge from the current leaf node to the new placeholder node
        const newEdge1 = {
          id: `${leafNodeId}-${newNodeIds?.[0]}`,
          type: 'smoothstep',
          source: leafNodeId,
          target: newNodeIds?.[0],
          style: { stroke: '#7043ff' },
        }
        const newEdge2 = {
          id: `${leafNodeId}-${newNodeIds?.[1]}`,
          type: 'smoothstep',
          source: leafNodeId,
          target: newNodeIds?.[2],
          style: { stroke: '#7043ff' },
        }

        updatedEdges.push(newEdge1)
        updatedEdges.push(newEdge2)
      } else {
        // Create a new placeholder node
        const newId = newNodeIds?.[0]
        const newPlaceholderNode = {
          id: newId,
          data: { parentId: leafNodeId, isCondition: true },
          type: 'placeholderNode',
        }

        // Update the children array of the original leaf node to connect to the new placeholder node
        if (!leafNode.data.children) {
          leafNode.data.children = []
        }
        leafNode.data.children.push(newId)

        // Add the new placeholder node to the nodes array
        updatedNodes.push(newPlaceholderNode)

        // Add a new edge from the current leaf node to the new placeholder node
        const newEdge = {
          id: `${leafNodeId}-${newId}`,
          type: 'smoothstep',
          source: leafNodeId,
          target: newId,
          style: { stroke: '#7043ff' },
        }

        updatedEdges.push(newEdge)
      }
    }
  })

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(updatedNodes, updatedEdges)

  return { nodes: layoutedNodes, edges: layoutedEdges }
}

export const defaultCustomSelect: any = {
  is_message_replied: { amount: 2, unit: 'day' },
  is_wait: { amount: 1, unit: 'day' },
  like_post: { amount: 1, unit: 'post' },
  lead_view_dm: { amount: 2, unit: 'post' },
  lead_respond: { amount: 2, unit: 'post' },
  lead_accepted: { amount: 2, unit: 'day' },
  lead_accepted_connect: { amount: 2, unit: 'day' },
  lead_accepted_follow: { amount: 2, unit: 'day' },
}

// export const isNodeInBranch = (node: any, closestNode: any, allNodes: any) => {
//   let currentNode = closestNode
//   while (currentNode?.data?.parentId) {
//     if (currentNode.id === node.id) {
//       return true
//     }
//     currentNode = allNodes.find((n: any) => n.id === currentNode.data.parentId)
//   }
//   return false
// }

const findParentNode = (allNodes: any[], parentId: string) => {
  return allNodes.find((n: any) => n.id === parentId)
}

export const isNodeInBranch = (node: any, closestNode: any, allNodes: any) => {
  let currentNode = closestNode
  while (currentNode?.data?.parentId) {
    if (currentNode.id === node.id) {
      return true
    }
    currentNode = findParentNode(allNodes, currentNode.data.parentId)
  }
  return false
}

import { Button, Modal } from 'antd'
import hubspotLogo from 'LEGACY/Assets/jpg/hubspot.jpg'
import styles from './HubspotUnlinkModal.module.scss'

interface HubspotUnlinkModalProps {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  email?: string
}
const HubspotUnlinkModal: React.FC<HubspotUnlinkModalProps> = ({ open, onCancel, onSubmit, email }) => {
  return (
    <Modal
      rootClassName={styles.unlinkModalCustomized}
      centered
      width={380}
      open={open}
      title=''
      onCancel={onCancel}
      footer={[
        <div className={styles.footer} key='footer'>
          <Button className={styles.yesBtn} key='yes' onClick={onSubmit} type='text'>
            Yes, Unlink account
          </Button>
          <Button className={styles.cancelBtn} key='cancel' onClick={onCancel} type='text'>
            Cancel
          </Button>
        </div>,
      ]}>
      <div className={styles.contentCustomized}>
        <img src={hubspotLogo} alt='hubspot-logo' />
        <label>{`Unlink Hubspot account ${email}`}</label>
        <span>
          {`Are you sure you want to unlink the HubSpot account ${email}? This action will stop syncing for
          this account and remove the connection with HubSpot.`}
        </span>
      </div>
    </Modal>
  )
}
export default HubspotUnlinkModal

import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const ChatApi: any = createApi({
  reducerPath: 'ChatApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    ////////////////////////////
    ///////   TELEGRAM   ///////
    ////////////////////////////
    initUnify: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/initUnify`,
        method: 'GET',
      }),
    }),
    unify: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/unify`,
        method: 'GET',
      }),
    }),
    unifySpecificAccounts: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/unifySpecificAccounts`,
        method: 'POST',
        body: {
          accounts: params.accounts,
        },
      }),
    }),
    getChat: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getChat`,
        method: 'POST',
        body: params,
      }),
    }),
    getChatMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getChatMessages`,
        method: 'POST',
        body: params,
      }),
    }),
    getDialogs: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getDialogs?threadFrom=${encodeURIComponent(params.thread_from)}`,
        method: 'GET',
      }),
    }),
    getMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getMessages?dialogId=${encodeURIComponent(
          params.dialogId,
        )}&senderData=${encodeURIComponent(params.senderData)}&platform=${encodeURIComponent(params.platform)}`,
        method: 'GET',
      }),
    }),
    sendChatMessage: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/sendMessage`,
        method: 'POST',
        body: {
          dialog_id: params.dialog_id,
          message: params.message,
          senderData: params.senderData,
          platform: params.platform,
          file: params.file || null,
          // area_code: params.area_code,
          // number: params.number,
        },
      }),
    }),
    markDialogRead: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/markDialogAsRead`,
        method: 'POST',
        body: {
          senderData: params.senderData,
          dialog_id: params.dialog_id,
          platform: params.platform,
        },
      }),
    }),
    changeMetadata: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/changeMetadata`,
        method: 'POST',
        body: {
          senderData: params.senderData,
          dialog_id: params.dialog_id,
          platform: params.platform,
          metadata: params.metadata,
        },
      }),
    }),
    getBookmark: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getBookmark`,
        method: 'GET',
      }),
    }),
    setBookmark: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/setBookmark`,
        method: 'POST',
        body: {
          message: params.message,
          platform: params.platform,
        },
      }),
    }),
    //////////////////////////////
    ///////   INSTAGRAM   ///////
    ////////////////////////////
    unifyInstagram: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/unifyInstagram`,
        method: 'GET',
      }),
    }),
    getInstagramDialogs: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getInstagramDialogs`,
        method: 'GET',
      }),
    }),
    getInstagramMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getInstagramMessages?dialogId=${encodeURIComponent(
          params.dialogId,
        )}&senderData=${encodeURIComponent(params.senderData)}`,
        method: 'GET',
      }),
    }),
    //////////////////////////////
    /////////   TAGS   //////////
    ////////////////////////////
    getAllTagByUserId: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/tag`,
        method: 'GET',
      }),
    }),

    getAllTagByUserIdAndDialogId: builder.query({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/dialog/${body.dialogId}`,
        method: 'GET',
      }),
    }),

    attachTagToDialog: builder.query({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${body.tagId}/dialog/${body.dialogId}`,
        method: 'POST',
      }),
    }),

    removeTagFromDialog: builder.query({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${param.tagId}/dialog/${param.dialogId}`,
        method: 'DELETE',
      }),
    }),

    createTag: builder.query({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag`,
        method: 'POST',
        body: param,
      }),
    }),

    updateTag: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${params.id}`,
        method: 'PATCH',
        body: params.data,
      }),
    }),

    searchTag: builder.query({
      query: (searchQuery: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag?tagName=${searchQuery}`,
        method: 'GET',
      }),
    }),

    removeTag: builder.query({
      query: (tagId: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${tagId}`,
        method: 'DELETE',
      }),
    }),

    getTagMultiDialog: builder.query({
      query: (arrID: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/dialog`,
        method: 'POST',
        body: {
          dialogIds: arrID,
        },
      }),
    }),

    getMeetingBookedTags: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/tag/getMeetingBooked`,
        method: 'GET',
      }),
    }),
    insertMeetingBookedTags: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/insertMeetingBooked`,
        method: 'POST',
        body: params,
      }),
    }),
    deleteMeetingBookedTags: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/deleteMeetingBooked`,
        method: 'POST',
        body: params,
      }),
    }),
    changeMeetingBookedName: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/changeMeetingBookedTagName`,
        method: 'POST',
        body: params,
      }),
    }),
    catchUp: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/catchup`,
        method: 'POST',
        body: {
          senderData: params.senderData,
        },
      }),
    }),
    //////////////////////////////
    /////////   HUBSPOT   ///////
    ////////////////////////////
    //hubspot
    getAllContactHubspot: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/getAllContacts`,
        method: 'GET',
      }),
    }),

    getConvrtObjectsMap: builder.query({
      query: (dialog_id: string) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/getConvrtObjectsMap`,
        method: 'GET',
        params: {
          dialog_id,
        },
      }),
    }),

    saveConvrtObjectsMap: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/saveConvrtObjectsMap`,
        method: 'POST',
        body: {
          dialog_id: params.dialog_id,
          contact_id: params.contact_id,
          hubspot_type: params.hubspot_type,
          convrt_item_type: params.convrt_item_type,
        },
      }),
    }),

    getHubspotUnifiedInbox: builder.query({
      query: (dialog_id: string) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/getUnifiedInbox`,
        method: 'GET',
        params: {
          dialog_id,
        },
      }),
    }),

    saveHubspotUnifiedInbox: builder.mutation({
      query: ({ dialog_id = '', is_synced = false, is_sync = false, sender_id = '' }) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/saveUnifiedInbox`,
        method: 'POST',
        body: { dialog_id, is_synced, is_sync, sender_id },
      }),
    }),

    deleteHubspotSync: builder.mutation({
      query: ({
        dialog_id,
        platform = 'telegram',
        action_type = 'telegram_unified_inbox',
        hubspot_action_type = 'update_contact_chat_activity',
      }) => {
        return {
          url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/hubspot/deleteHubspotSync`, // enter api path
          method: 'DELETE',
          params: { dialog_id, platform, action_type, hubspot_action_type },
        }
      },
    }),
  }),
})

export const {
  // TELEGRAM
  useLazyCatchUpQuery,
  useLazyChangeMeetingBookedNameQuery,
  useLazyGetMeetingBookedTagsQuery,
  useLazyInsertMeetingBookedTagsQuery,
  useLazyDeleteMeetingBookedTagsQuery,
  useLazyInitUnifyQuery,
  useLazyUnifyQuery,
  useLazyUnifySpecificAccountsQuery,
  useLazyGetChatQuery,
  useLazyGetChatMessagesQuery,
  useLazyGetDialogsQuery,
  useLazyGetMessagesQuery,
  useLazySendChatMessageQuery,
  useLazyMarkDialogReadQuery,
  useLazyChangeMetadataQuery,
  useLazyGetBookmarkQuery,
  useLazySetBookmarkQuery,

  // INSTAGRAM
  useLazyUnifyInstagramQuery,
  useLazyGetInstagramDialogsQuery,
  useLazyGetInstagramMessagesQuery,

  // TAGS
  useLazyGetAllTagByUserIdQuery,
  useLazyGetAllTagByUserIdAndDialogIdQuery,
  useLazyAttachTagToDialogQuery,
  useLazyRemoveTagFromDialogQuery,
  useLazyCreateTagQuery,
  useLazyUpdateTagQuery,
  useLazySearchTagQuery,
  useLazyRemoveTagQuery,
  useLazyGetTagMultiDialogQuery,

  useGetAllContactHubspotQuery,
  useGetConvrtObjectsMapQuery,
  useSaveConvrtObjectsMapMutation,
  useDeleteHubspotSyncMutation,
  useSaveHubspotUnifiedInboxMutation,
  useGetHubspotUnifiedInboxQuery,
} = ChatApi

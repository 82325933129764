import React from 'react'
import styles from './OutreachBanner.module.scss'
// import outreach from 'LEGACY/Assets/svg/vector_white.svg'
// import santa from 'LEGACY/Assets/svg/santa.svg'
// import tree from 'LEGACY/Assets/svg/tree.svg'
// import decorations from 'LEGACY/Assets/svg/decorations.svg'
import { CloseOutlined } from '@ant-design/icons'
// import { useNavigate } from 'react-router-dom'
// import { post } from 'LEGACY/common/api/axios'

interface OutreachBannerProps {
  handleCloseBanner: () => void
}

const OutreachBanner: React.FC<OutreachBannerProps> = ({ handleCloseBanner }) => {
  // const navigate = useNavigate()

  // const handleOutreachPage = () => {
  //   post('/log', {
  //     action: 'Take Me there',
  //   })
  //   navigate('/outreach')
  // }
  return (
    // Old outreach banner
    // <div className={styles.trialBanner}>
    //   <p className={styles.trialText}>
    //     <img src={outreach} alt='' />
    //     Outreach to your exposed contacts today! <span>New</span>
    //   </p>
    //   <div className={styles.rightSide}>
    //     <button className={styles.trialButton} style={{ backgroundColor: 'black' }} onClick={handleOutreachPage}>
    //       Take Me There!
    //     </button>
    //     <CloseOutlined onClick={handleCloseBanner} style={{ fontSize: '16px', cursor: 'pointer' }} />
    //   </div>
    // </div>

    <div className={styles.trialBanner}>
      <div className={styles.trialText}>
        {/* <div className={styles.images}>
          <img src={tree} alt='' />
          <img src={santa} alt='' />
        </div> */}
        {/* <p style={{ margin: '5px 5px 5px 100px' }}>
          Holidays <span style={{ marginRight: '5px' }}>**30% Off**</span> MEGA Discount Until Nov 28th 2023 - PROMO
          CODE: <span>HD60</span>
        </p> */}
        <p style={{ margin: '5px 5px 5px 30px' }}>
          Special Discount: <span>10%</span> OFF Untill The End of Your Trial - PROMO CODE: <span>Talk to Us</span>
        </p>
      </div>
      <div className={styles.rightSide}>
        {/* <img src={decorations} alt='' /> */}
        <CloseOutlined onClick={handleCloseBanner} style={{ fontSize: '16px', cursor: 'pointer' }} />
      </div>
    </div>
  )
}

export default OutreachBanner

import React, { useEffect, useMemo, useState } from 'react'
import { Typography, Breadcrumb, Tooltip } from 'antd'
import styles from './SocialSettings.module.scss'
import useHubspot from './Hooks/useHubspot'
import { useLocalStorage } from 'usehooks-ts'
import {
  useLazyGetCampaignsForAccountsServiceQuery,
  //  useLazyGetCampaignsForAccountsServiceQuery,
  useLazyGetUserMetadataQuery,
} from 'common/APIs/SettingsApi'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
import {
  getFacebookTableData,
  getInstagramTableData,
  getLinkedinTableData,
  getTelegramTableData,
} from 'common/utils/userHelpers'
import SocialBox from './Components/SocialBox'
import hubspotLogo from 'common/assets/jpg/hubspot.jpg'
import zapierLogo from 'common/assets/jpg/zapier.png'
import pipedriveLogo from 'common/assets/jpg/pipedrive.jpg'
import slackLogo from 'common/assets/svg/slack_square_logo.svg'
import salesforceLogo from 'common/assets/svg/salesforce_logo.svg'
import SocialAccountTable from './Components/SocialAccountTable'
import useIntegrations from './Hooks/useIntegrations'
import NotificationModal from 'common/components/GeneralComponents/Notifications/NotificationModal'
import { useLazyGetPricingPlansQuery } from '../../../APIs/UpgradeApi'
import { useIntercom } from 'react-use-intercom'
//////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////// LEGACY /////////////////////////////////////////////////
import OutreachConnection from 'LEGACY/common/components/OutreachConnection/OutreachConnection'
import AppIntegration from 'LEGACY/features/settings/Pages/AppIntegration'
import Salesforce from 'LEGACY/features/settings/Pages/SalesforceIntegration'
import { useUser } from 'Utils/UserContext'
///////////////////////////////////////////////// LEGACY /////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

interface SocialSettingsProps {
  hubspotKey: any
  slackKey: any
  salesforceKey?: any
  resetKeysStates?: any

  shouldHideCheckoutLegacy?: any
  hubspotKeyLegacy?: any
}

const SocialSettings: React.FC<SocialSettingsProps> = ({
  hubspotKey,
  slackKey,
  salesforceKey,
  resetKeysStates,
  shouldHideCheckoutLegacy,
  hubspotKeyLegacy,
}) => {
  const [getUserMetadata] = useLazyGetUserMetadataQuery()
  const [getCampaignNames, { data: allCampaignsAccount }] = useLazyGetCampaignsForAccountsServiceQuery()
  const { user, setUser } = useUser()
  const user_type = user?.user_type
  const { handleLoginHubSpot } = useHubspot()
  const [openSocialConnectionModal, setOpenSocialConnectionModal] = useState(false)
  const [platform, setPlatform] = useState<any>('')
  const [checkLocalStorage, setCheckLocalStorage] = useState()
  const { addons } = user || '{}'
  const { slack_integration, salesforce_integration } = addons
  const [currentDetailSocial, setCurrentDetailSocial] = useState('')
  const [linkedinTableData, setLinkedinTableData] = useState<any>(getLinkedinTableData(user))
  const [instagramTableData, setInstagramTableData] = useState<any>(getInstagramTableData(user))
  const [facebookTableData, setFacebookTableData] = useState<any>(getFacebookTableData(user))
  const { handleConnect } = useIntegrations()
  const [userMetadata] = useLocalStorage<any>('user_metadata', {})
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false)
  const [getPricingPlans, { data: plansData }] = useLazyGetPricingPlansQuery()
  const { boot, showNewMessage } = useIntercom()

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////// LEGACY //////////////////////////////////////////////////////
  const [telegramTableData, setTelegramTableData] = useState<any>(getTelegramTableData(user, allCampaignsAccount)) ///
  const [outreachConnectionModalOpen, setOutreachConnectionModalOpen] = useState(false) //////////////////////////////
  ////////////////////////////////////////////////////// LEGACY //////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleMessageIntercom = (platform: string) => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage(`Hi, I'm interested in the ${platform} integration!`)
  }

  const canConnectMore = (platform: string) => {
    if (user?.addons?.[`channel_${platform}`] === true) {
      return true
    }
    const maxChannels = user?.addons?.maxChannels || 0

    const trueAddons = [
      user?.addons?.channel_instagram,
      user?.addons?.channel_facebook,
      user?.addons?.channel_linkedin,
      user?.addons?.channel_telegram,
      user?.addons?.channel_discord,
      user?.addons?.channel_twitter,
    ].filter(Boolean).length

    const canConnectMore = trueAddons < maxChannels
    return canConnectMore
  }

  const channelConnectedCount = useMemo(() => {
    let count = 0
    telegramTableData?.length > 0 && count++
    telegramTableData?.length > 0 && count++
    linkedinTableData?.length > 0 && count++
    instagramTableData?.length > 0 && count++
    facebookTableData?.length > 0 && count++

    return count
  }, [
    // telegramTableData,
    linkedinTableData,
    instagramTableData,
    facebookTableData,
    telegramTableData,
  ])

  const formatAmountBySocial: any = {
    telegram: telegramTableData?.length,
    linkedin: linkedinTableData?.length,
    instagram: instagramTableData?.length,
    facebook: facebookTableData?.length,
  }
  const shouldRender = Object.values(formatAmountBySocial).some((amount) => amount === 0)

  const handleHubspotMapping = async () => {
    if (user_type === 'web3') {
      setCurrentDetailSocial('Hubspot')
    } else {
      handleMessageIntercom('Hubspot')
    }
  }

  const handleSalesforceMapping = async () => {
    if (user_type === 'web3') {
      setCurrentDetailSocial('Salesforce')
    } else {
      handleMessageIntercom('Salesforce')
    }
  }

  const appIntergration = [
    {
      name: 'Hubspot',
      logo: hubspotLogo,
      description: 'A CRM platform for managing customer interactions, marketing, and sales automation.',
      connected: hubspotKey,
      functions: { connect: handleLoginHubSpot, button: handleHubspotMapping },
      button: user_type === 'web3' ? 'HubSpot Field Mapping' : 'Talk to Us',
    },
    {
      name: 'Slack',
      logo: slackLogo,
      description:
        'A collaboration hub that connects your work with the people you work with through real-time messaging and integrations.',
      comingSoon: slack_integration !== true,
      connected: slackKey,
      functions: {
        connect: async () => {
          await handleConnect('Slack')
        },
        button: () => {
          handleMessageIntercom('Slack')
        },
      },
      button: 'Talk to Us',
      // button: 'Disconnect Slack',
    },
    {
      name: 'Salesforce',
      logo: salesforceLogo,
      description:
        'A leading CRM platform that helps businesses manage customer relationships, sales, and marketing efforts.',
      comingSoon: salesforce_integration !== true,
      connected: salesforceKey,
      functions: {
        connect: async () => {
          await handleConnect('Salesforce')
        },
        button: handleSalesforceMapping,
      },
      // button: 'Disconnect Salesforce',
      button: user_type === 'web3' ? 'Salesforce Field Mapping' : 'Talk to Us',
    },
    {
      name: 'Zapier',
      logo: zapierLogo,
      description: 'Automates workflows by connecting your apps, saving time and reducing manual work.',
      comingSoon: true,
    },
    {
      name: 'Pipedrive',
      logo: pipedriveLogo,
      description: 'A sales CRM with visual pipelines and customizable workflows to streamline sales processes.',
      comingSoon: true,
    },
  ]

  const handleOpenSocialConnectionModal = (platform: string) => {
    setPlatform(platform)
    if (canConnectMore(platform)) {
      setOpenSocialConnectionModal(true)
    } else {
      setOpenUpgradeModal(true)
    }
  }

  const handleAddSocialAccount = (socialName: string) => {
    console.log('socialName', socialName)
    if (socialName === 'telegram') {
      setOutreachConnectionModalOpen(true)
    } else {
      handleOpenSocialConnectionModal(socialName)
    }
  }

  // LEGACY //
  const handleAddTelegramAccount = () => {
    setOutreachConnectionModalOpen(true)
  }
  const handleEditTelegramAccount = (data: any) => {
    // setPhoneNumber({ area_code: data.area_code, number: data.number })
  }
  // LEGACY //

  const handleNavigateToDetailSocial = (socialName: string) => {
    setCurrentDetailSocial(socialName)
  }

  const handleNavigateToDetailAppIntegration = (appName: string) => {
    // For now added the if, remove when adding the inside screens
    if (appName === 'hubspot' || appName === 'salesforce') {
      setCurrentDetailSocial(appName)
    }
  }

  // useEffect(() => {
  //   setTelegramTableData(getTelegramTableData(user))
  // }, [user])

  useEffect(() => {
    const getMetadata = async (platform: any, data: any, setData: any) => {
      try {
        if (platform === 'instagram') {
          const metadata = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find((m: any) => m.username === account?.handle?.replace('@', ''))
            if (metadataAccount) {
              const toReturn = {
                ...account,
                profilePhoto: metadataAccount.profile_picture_url,
                username: account?.handle,
                email: account?.email,
              }
              return toReturn
            }
            return {
              ...account,
              username: account.handle,
            }
          })
          setData(newData)
        }
        if (platform === 'facebook') {
          const metadata: any = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find(
              (m: any) => m.username.toLowerCase() === account.username.toLowerCase(),
            )
            if (metadataAccount) {
              return {
                ...metadataAccount,
                profilePhoto: metadataAccount.profile_picture_url,
                displayName: metadataAccount.contact_name,
              }
            }
            return {
              ...account,
            }
          })
          setData(newData)
        }
        if (platform === 'linkedin') {
          const metadata = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find((m: any) => m.username === account.username)
            if (metadataAccount) {
              return {
                ...metadataAccount,
                profilePhoto: metadataAccount.profile_picture_url,
                displayName: metadataAccount.contact_name,
              }
            }
            return {
              ...account,
            }
          })
          setData(newData)
        }
        if (platform === 'telegram') {
          const metadata = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find((m: any) => m.username === account.username)
            if (metadataAccount) {
              return {
                ...metadataAccount,
                profilePhoto: metadataAccount.profile_picture_url,
                displayName: metadataAccount.contact_name,
              }
            }
            return {
              ...account,
            }
          })
          setData(newData)
        }
      } catch (err) {
        console.error(err)
      }
    }
    getMetadata('instagram', getInstagramTableData(user), setInstagramTableData)
    getMetadata('linkedin', getLinkedinTableData(user), setLinkedinTableData)
    getMetadata('facebook', getFacebookTableData(user), setFacebookTableData)
    getMetadata('telegram', getTelegramTableData(user), setTelegramTableData)
  }, [checkLocalStorage, openSocialConnectionModal, user])

  useEffect(() => {
    const getPlans = async () => {
      await getPricingPlans()
    }

    const getCampaignsData = async () => {
      await getCampaignNames()
    }

    getCampaignsData()
    getPlans()
  }, [])

  useEffect(() => {
    if (Object.keys(user)?.length > 0) {
      setTelegramTableData(getTelegramTableData(user, allCampaignsAccount))
    }
  }, [user])

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const { data } = await getUserMetadata()
        if (data) {
          const { instagram, facebook, linkedin } = data
          // Initialize updated user object with metadata
          const updatedUser = {
            ...user,
            instagram: instagram.map((account: any) => ({
              ...account,
              profilePicture: account.profilePhoto || '', // Replace with actual property name
              displayName: account.displayName || '',
            })),
            facebook: facebook.map((account: any) => ({
              ...account,
              profilePicture: account.profilePhoto || '', // Replace with actual property name
              displayName: account.displayName || '',
            })),
            linkedin: linkedin.map((account: any) => ({
              ...account,
              profilePicture: account.profilePhoto || '', // Replace with actual property name
              displayName: account.displayName || '',
            })),
          }

          setUser(updatedUser) // Update state with new user object
        }
      } catch (error) {
        console.error('Error fetching user metadata:', error)
      }
    }

    getMetadata()
  }, [])

  return (
    <>
      <div className={styles['social-page']}>
        <div className={styles['social-page__header']}>
          <Breadcrumb
            items={[
              {
                title: 'Settings',
                onClick: () => setCurrentDetailSocial(''),
              },
              {
                title: <span>{currentDetailSocial && 'Social Intergration'}</span>,
              },
            ]}
          />

          <Typography className={styles['social-page__header__pageName']}>
            {currentDetailSocial ? currentDetailSocial : 'Social & Intergrations'}
          </Typography>
        </div>

        {!currentDetailSocial && (
          <div className={styles['social-page__body']}>
            {channelConnectedCount > 0 && (
              <div className={styles['social-page__body__section']}>
                <Typography className={styles['social-page__body__section__title']}>
                  {channelConnectedCount} Channels Connected to Convrt
                </Typography>
                <div className={styles.wrapping}>
                  {Object.keys(formatAmountBySocial)
                    .filter((social) => formatAmountBySocial[social] > 0)
                    .map((social: any, index: number) => {
                      return (
                        <SocialBox
                          key={index}
                          name={social}
                          imageSocialKey={social}
                          accountCount={formatAmountBySocial[social]}
                          onClickBox={() => handleNavigateToDetailSocial(social)}
                          isCommingSoon={false}
                        />
                      )
                    })}
                </div>
              </div>
            )}

            {shouldRender && (
              <div className={styles['social-page__body__section']}>
                <Typography className={styles['social-page__body__section__title']}>
                  Add {channelConnectedCount > 0 && 'More'} Networks
                </Typography>
                <div className={styles.wrapping}>
                  {Object.keys(formatAmountBySocial)
                    .filter((social) => formatAmountBySocial[social] === 0)
                    .map((social: any, index: number) => {
                      return (
                        <SocialBox
                          key={index}
                          name={social}
                          imageSocialKey={social}
                          isAddAccount
                          onAddAccount={() => handleAddSocialAccount(social)}
                        />
                      )
                    })}
                </div>
              </div>
            )}

            <div className={styles['social-page__body__section']}>
              <Typography className={styles['social-page__body__section__title']}>App Intergrations</Typography>

              <div className={styles['social-page__body__appIntergrate']}>
                {appIntergration.map((app) => {
                  return (
                    <div
                      className={styles['social-page__body__appIntergrate__item']}
                      onClick={() => app.connected && handleNavigateToDetailAppIntegration(app.name)}>
                      <div className={styles['social-page__body__appIntergrate__item__info']}>
                        <img src={app.logo} alt={`${app.name} logo`} />
                        <div>
                          <Typography className={styles['social-page__body__appIntergrate__item__info__name']}>
                            {app.name}
                          </Typography>
                          <Tooltip title={app.description}>
                            <Typography className={styles['social-page__body__appIntergrate__item__info__description']}>
                              {app.description}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                      {app.comingSoon && (
                        <div className={styles['social-page__body__appIntergrate__item__bottom']}>Coming Soon</div>
                      )}
                      {!app.comingSoon && (
                        <div
                          className={styles['social-page__body__appIntergrate__item__bottom__connected']}
                          onClick={(e) => {
                            e.stopPropagation()
                            app.connected ? app.functions?.button() : app.functions?.connect()
                          }}>
                          {app.connected ? app.button : 'Connect'}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {currentDetailSocial === 'linkedin' && linkedinTableData?.length > 0 && (
          <SocialAccountTable
            data={linkedinTableData}
            setData={setLinkedinTableData}
            platform='linkedin'
            onAddAccount={() => handleOpenSocialConnectionModal('linkedin')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'instagram' && instagramTableData?.length > 0 && (
          <SocialAccountTable
            data={instagramTableData}
            setData={setInstagramTableData}
            platform='instagram'
            onAddAccount={() => handleOpenSocialConnectionModal('instagram')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'facebook' && facebookTableData?.length > 0 && (
          <SocialAccountTable
            data={facebookTableData}
            setData={setFacebookTableData}
            platform='facebook'
            onAddAccount={() => handleOpenSocialConnectionModal('facebook')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'telegram' && telegramTableData?.length > 0 && (
          <SocialAccountTable
            data={telegramTableData}
            setData={setTelegramTableData}
            platform='telegram'
            onAddAccount={handleAddTelegramAccount}
            onEditAccount={handleEditTelegramAccount}
          />
        )}

        {/* LEGACY */}
        {currentDetailSocial === 'Hubspot' && <AppIntegration setCurrentDetailSocial={setCurrentDetailSocial} />}
        {/* LEGACY */}

        {/* LEGACY */}
        {currentDetailSocial === 'Salesforce' && (
          <Salesforce resetKeysStates={resetKeysStates} setCurrentDetailSocial={setCurrentDetailSocial} />
        )}
        {/* LEGACY */}
      </div>
      {openSocialConnectionModal && (
        <SocialConnectionModal
          open={openSocialConnectionModal}
          onClose={() => setOpenSocialConnectionModal(false)}
          platform={platform}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
      {openUpgradeModal && (
        <NotificationModal
          open={openUpgradeModal}
          onClose={() => setOpenUpgradeModal(false)}
          type='addAccount'
          data={{ ...plansData, platform }}
        />
      )}
      {/* LEGACY */}
      {outreachConnectionModalOpen && (
        <OutreachConnection
          open={outreachConnectionModalOpen}
          onClose={() => {
            setOutreachConnectionModalOpen(false)
          }}
        />
      )}
      {/* LEGACY */}
    </>
  )
}

export default SocialSettings

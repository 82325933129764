import React, { useState, useEffect } from 'react'
import { Button, Card, message, Typography, Table } from 'antd'
import { useLazyGetAllRegistrationLinksQuery, useLazySetActiveABLinkQuery } from 'common/APIs/SettingsApi'
import { SwapOutlined } from '@ant-design/icons'

const { Title } = Typography

interface ActiveLink {
  ab_url: string
  ab_link_id: string
  ab_link_name: string
  link_a_name: string
  link_b_name: string
  website_url: string
  probability_a_percentage: string
  link_purpose: string
}

const ActiveABLink: React.FC = () => {
  const [activeABLink, setActiveABLink] = useState<ActiveLink | null>(null)
  const [getAllLinks] = useLazyGetAllRegistrationLinksQuery()
  const [allABLinks, setAllABLinks] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [setActiveLink] = useLazySetActiveABLinkQuery()

  useEffect(() => {
    const fetchLinks = async () => {
      setIsLoading(true)
      try {
        const { data } = await getAllLinks()
        if (data) {
          setAllABLinks(data.allABLinks || [])
          const activeWebsiteLink = data?.activeMappedLinks?.find((l: any) => l?.link_purpose === 'website')
          setActiveABLink(activeWebsiteLink)
        }
      } catch (error) {
        message.error('Failed to fetch A/B links')
      }
      setIsLoading(false)
    }
    fetchLinks()
  }, [getAllLinks])

  const handleChangeActiveLink = async (record: any) => {
    try {
      const { data: res } = await setActiveLink({
        newLinkId: record.id,
        link_purpose: 'website',
        website_token: activeABLink?.website_url,
      })
      if (res) {
        message.success('Active A/B link updated successfully!')
        // Refetch the data to update the view
        const { data } = await getAllLinks()
        if (data) {
          setAllABLinks(data.allABLinks || [])
          const activeWebsiteLink = data?.activeMappedLinks?.find((l: any) => l?.link_purpose === 'website')
          setActiveABLink(activeWebsiteLink)
        }
      } else {
        message.error('Something went wrong.')
      }
    } catch (error) {
      message.error('Failed to update active A/B link')
    }
  }

  return (
    <div>
      <Card style={{ marginBottom: 16 }}>
        <Title level={4} style={{ margin: 0 }}>
          Currently Active A/B Test
        </Title>
        {isLoading ? (
          <div>Loading...</div>
        ) : activeABLink ? (
          <div>
            <p>
              <strong>Name:</strong> {activeABLink.ab_link_name}
            </p>
            <p>
              <strong>URL:</strong> {activeABLink.ab_url}
            </p>
            <p>
              <strong>Variant A:</strong> {activeABLink.link_a_name}
            </p>
            <p>
              <strong>Variant B:</strong> {activeABLink.link_b_name}
            </p>
            <p>
              <strong>Probability A:</strong> {Number(activeABLink.probability_a_percentage).toFixed(0)}%
            </p>
            <p>
              <strong>Website Token:</strong> {activeABLink.website_url}
            </p>
          </div>
        ) : (
          <p>No active A/B test</p>
        )}
      </Card>

      <Card>
        <Title level={4} style={{ margin: 0 }}>
          Select New Active Test
        </Title>
        <Table
          dataSource={allABLinks}
          rowKey='id'
          scroll={{ y: 200 }}
          style={{ marginBottom: '25px' }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Variant A',
              dataIndex: 'link_a_name',
              key: 'link_a_name',
            },
            {
              title: 'Variant B',
              dataIndex: 'link_b_name',
              key: 'link_b_name',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => (
                <Button
                  type='primary'
                  icon={<SwapOutlined />}
                  onClick={() => handleChangeActiveLink(record)}
                  disabled={record.id === activeABLink?.ab_link_id}>
                  {record.id === activeABLink?.ab_link_id ? 'Current Active' : 'Set as Active'}
                </Button>
              ),
            },
          ]}
        />
      </Card>
    </div>
  )
}

export default ActiveABLink

import React from 'react'
import styles from './TrialBanner.module.scss'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { post } from 'LEGACY/API/axios'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
// import BuyConvrtModal from '../BuyConvrt/BuyConvrtModal'

interface TrialBannerProps {
  daysLeft: number | string
}

const TrialBanner: React.FC<TrialBannerProps> = ({ daysLeft }) => {
  const { userDetails } = useAuth()
  const { email } = userDetails
  const currentDate = new Date()
  const navigate = useNavigate()
  const numberDaysLeft = Number(daysLeft)
  const caption =
    numberDaysLeft < 0
      ? `Your trial is over ${Math.abs(numberDaysLeft)} days ago`
      : `You have ${numberDaysLeft} days left to the trial`
  // const [openModal, setOpenModal] = useState(false)
  const handleRequestExtension = async () => {
    try {
      post('/log', {
        action: 'Request Trial Extension',
      })

      const response = await post('/user/trialExtension', {
        currentDate,
        email,
      })
      if (response.status === 200) {
        message.success('Trial Extension was request successfully!')
      }
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e))
      if (error.status === 400) {
        message.error('You have already requested a trial extension!')
      } else {
        message.error('Something went wrong, please try again later.')
      }
    }
  }

  const handleOpenModal = () => {
    navigate('/checkout')
    // setOpenModal(true)
  }

  return (
    <div className={styles.trialBanner}>
      <p className={styles.trialText}>{caption}</p>
      <button className={styles.trialButtonBuy} onClick={handleOpenModal}>
        Buy Convrt
      </button>
      <button className={styles.trialButtonExtension} onClick={handleRequestExtension}>
        Request Trial Extension
      </button>

      {/* <BuyConvrtModal
        open={openModal}
        onCancel={() => {
          setOpenModal(false)
        }}
      /> */}
    </div>
  )
}

export default TrialBanner

import React from 'react'
import styles from './Chat.module.scss'
import MessageComponent from './MessageComponent'
interface Props {
  selectedChannelId: string | null
  selectedChannelMessages: any[]
  screenSize: { width: number; height: number }
}
const ChatMessage: React.FC<Props> = ({ selectedChannelId, selectedChannelMessages, screenSize }) => {
  return (
    <div
      style={{ minWidth: '400px' }}
      className={`${styles['ChatTabsMessageContent']} flex flex-column relative overflow-auto max-h-full`}>
      {/* <NoChat /> */}
      <MessageComponent
        channelId={selectedChannelId}
        channelMessages={selectedChannelMessages}
        screenSize={screenSize}
      />
    </div>
  )
}

export default ChatMessage

import { Button } from 'antd'

import { useAuth } from 'common/hooks/useAuth.hooks'
import { useAppSelector } from 'state'
import { filtersKeysMap } from 'LEGACY/common/static-data/filterKeysMap'
import { ReactComponent as ShootingStars } from 'LEGACY/Assets/svg/ShootingStars.svg'

import './IntentSignalsPaywall.scss'

interface IIntentSignalsPaywall {
  totalCompanies: number
  totalDataNumber: number
  subscriptionType: 'PREMIUM' | 'BETA' | 'BASIC' | 'TRIAL'
  page_type: string
  style?: React.CSSProperties
}

const IntentSignalsPaywall = ({
  totalCompanies,
  totalDataNumber,
  subscriptionType,
  page_type,
  style,
}: IIntentSignalsPaywall) => {
  const { userDetails } = useAuth()
  const addons = userDetails?.addons

  const { filter } = useAppSelector((state) => state.companies)

  const filterKeys = Object.keys(filter) as string[]

  const handleRedirect = () => window.open(`https://calendly.com/roy-convrt/demo-convrt`, '_blank', 'noreferrer')

  const haveByFive = filter?.companies?.companyName === undefined || filter?.companies?.companyName === ''

  const formatNumberToK = (number: any) => {
    if (Number(number) >= 1000) {
      return (
        Math.floor(Number(number) / 1000)
          .toFixed(0)
          .toString() + 'K'
      )
    }
    return number.toString()
  }

  return (
    <div className='intent-signals-paywall' style={style}>
      {totalCompanies > 0 && (
        <h1 style={{ margin: '0px', fontSize: '25px' }}>
          <ShootingStars />
          {` There are over ${
            haveByFive ? formatNumberToK(totalDataNumber * 3) : Math.floor(totalCompanies)
          } additional matches. Upgrade your plan to unlock them.`}
        </h1>
      )}
      <p style={{ marginTop: '8px', marginBottom: '8px' }}>
        {filterKeys.filter((key) => !addons?.[key]).length > 1
          ? 'These filters are advanced features that can help you find the companies with the highest probability to Convrt to a customer/partnership.'
          : filterKeys.filter((key) => !addons?.[key]).length === 1
          ? `${
              filtersKeysMap?.[filterKeys.filter((key) => !addons?.[key])[0] as keyof typeof filtersKeysMap] ||
              'This filter'
            } is an advanced feature that can help you find the companies with the highest probability to Convrt to a customer/partnership.`
          : (subscriptionType === 'BETA' || subscriptionType === 'TRIAL') &&
            'Your plan only shows limited results. Upgrade your plan to see all matching results.'}
      </p>
      <Button onClick={handleRedirect}>Contact Sales</Button>
    </div>
  )
}

export default IntentSignalsPaywall

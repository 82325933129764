import { LoadingOutlined } from '@ant-design/icons'
import { Button, message, Progress, Spin, Typography } from 'antd'
import Papa from 'papaparse'

import { post } from 'LEGACY/API/axios'
import {
  SET_ENRICH_POINT,
  SET_LOADING_ENRICH,
  SET_USER_POINTS,
} from 'LEGACY/MyContacts/Outreach/state/importContactSlice'
import { useLazyGetPointsStoreQuery } from 'LEGACY/API/SettingsApi'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state'
import { EnrichChannel } from '../EnrichModal/EnrichChannel'
import styles from './EnrichYourData.module.scss'
import { CHUNK_ROW_AMOUNT, limit } from './import.constants'

export const EnrichYourData = ({ onBackToExposedContact }: any) => {
  const { list_id } = useParams()
  const dispatch = useAppDispatch()

  const [channelSelect, setChannelSelect] = React.useState<any>({
    linkedin: false,
    telegram: false,
  })
  const [userPoints, setUserPoints] = React.useState<any>()
  const [isLoadingUpload, setIsLoadingUpload] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [isApproveAction, setIsApproveAction] = React.useState(false)

  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const { csvData, enrichPoints, isLoadingEnrich } = useAppSelector((state) => state.importContact)
  const dataAfterMapping = csvData?.dataAfterMapping ? csvData.dataAfterMapping : []
  const isLargeData = dataAfterMapping.length > CHUNK_ROW_AMOUNT

  const setLoadingEnrich = (isLoading: boolean) => dispatch(SET_LOADING_ENRICH(isLoading))

  const handleFormatEnrichmentText = (channelSelect: any) => {
    const enrichmentArr = []
    channelSelect.linkedin && enrichmentArr.push('linkedin')
    channelSelect.telegram && enrichmentArr.push('telegram')

    return enrichmentArr.length > 0 ? enrichmentArr.join(',') : ''
  }

  const bodyTocalculateEnrich = React.useMemo(() => {
    let body: any = {
      rows: [],
      enrichment: handleFormatEnrichmentText(channelSelect),
    }

    let rowCount = {
      telegram: 0,
      linkedin: 0,
    }

    dataAfterMapping.length > 0 &&
      csvData?.dataAfterMapping?.forEach((row: any) => {
        if (!row.linkedin || !row.telegram) {
          body.rows.push({
            full_name: row.full_name,
            company_domain: row.company_domain,
            linkedin: row.linkedin,
            telegram: row.telegram,
            email: row.email,
          })
        }

        if (row.linkedin) {
          rowCount.linkedin += 1
        }

        if (row.telegram) {
          rowCount.telegram += 1
        }
      })
    return {
      data: {
        ...body,
        rows: body.rows.every((obj: any) => Object.values(obj).every((data) => !data)) ? [] : body.rows,
      },
      rowCount,
    }
  }, [csvData, channelSelect])

  const handleChangeToggle = (platform: string, isChecked: boolean) => {
    setChannelSelect((pre: any) => ({
      ...pre,
      [platform]: isChecked,
    }))
  }

  const handleFetchEnrichPoint = async (body: any) => await post('/contacts/enrich-point', body)
  const limitedHandleFetchEnrichPoint = async (body: any) => {
    const chunks = []
    for (let i = 0; i < body.rows.length; i += CHUNK_ROW_AMOUNT) {
      chunks.push(body.rows.slice(i, i + CHUNK_ROW_AMOUNT))
    }

    const pointDataPromises = chunks.map((chunk) =>
      limit(async () => {
        const chunkBody = { ...body, rows: chunk }
        const response = await handleFetchEnrichPoint(chunkBody)
        return response?.data
      }),
    )

    const pointDataResults = await Promise.all(pointDataPromises)
    return pointDataResults.flat()
  }

  const handleFetchError = (error: any, text: string) => {
    message.error(text)
    setLoadingEnrich(false)
    setIsLoadingUpload(false)
  }

  const handleUploadChunk = async (param: {
    chunk: any
    chunkIndex: number
    list_id: string
    enrichmentText: string
  }) => {
    const { chunk, list_id, enrichmentText } = param

    const parsedCSV = Papa.unparse(chunk)
    const blob = new Blob([parsedCSV], { type: 'text/csv;charset=utf-8;' })

    const formData = new FormData()
    const fileName = `${param.chunkIndex}_${csvData.file?.name}`
    const file = new File([blob], fileName, { type: 'text/csv;charset=utf-8' })
    formData.append('file', file)
    formData.append('list_id', list_id)
    formData.append('enrichment', enrichmentText)

    try {
      const endpointToUpload = '/contacts/importContactsCSV'
      const data = await post(endpointToUpload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      localStorage.setItem(
        'importResult',
        JSON.stringify({
          ...data,
          listId: list_id,
          invalidateCache: 'true',
        }),
      )
      return true
    } catch (error) {
      handleFetchError(error, 'Error when upload CSV')
      setIsLoadingUpload(false)
      return false
    }
  }

  const handleUploadMultiChunk = async (chunks: any, list_id: string, enrichmentText: string) => {
    for (let index = 0; index < chunks.length; index++) {
      const chunk = chunks[index]
      const chunkProgress = ((index + 1) / chunks.length) * 100

      const res = await handleUploadChunk({
        chunk,
        list_id,
        enrichmentText,
        chunkIndex: index,
      })
      if (!res) {
        return { error: true }
      }
      setProgress(chunkProgress)
    }
  }

  const handleAction = async ({ isSkip }: any) => {
    if (list_id) {
      const enrichmentText = isSkip ? '' : handleFormatEnrichmentText(channelSelect)
      if (enrichPoints?.totalPoints > userPoints?.points) {
        message.error('Insufficient points')
        return
      }
      try {
        setIsLoadingUpload(true)
        if (isLargeData) {
          const chunks = []
          for (let i = 0; i < dataAfterMapping.length; i += CHUNK_ROW_AMOUNT) {
            chunks.push(dataAfterMapping.slice(i, i + CHUNK_ROW_AMOUNT))
          }

          const response = await handleUploadMultiChunk(chunks, list_id, enrichmentText)
          if (response?.error) {
            return
          }
          onBackToExposedContact()
        } else {
          const res = await handleUploadChunk({ chunk: dataAfterMapping, list_id, enrichmentText, chunkIndex: 0 })
          if (res) {
            onBackToExposedContact()
          }
        }
        setIsLoadingUpload(false)
      } catch (error) {
        setIsLoadingUpload(false)
      }
    }
  }

  React.useEffect(() => {
    const isNoSelectEnrich = !channelSelect.linkedin && !channelSelect.telegram
    if (isNoSelectEnrich) {
      dispatch(
        SET_ENRICH_POINT({
          linkedin: 0,
          telegram: 0,
          totalPoints: 0,
          foundContacts: 0,
          foundTelegram: 0,
          foundLinkedin: 0,
        }),
      )
      return
    }
    if (channelSelect.linkedin || channelSelect.telegram) {
      ;(async () => {
        setLoadingEnrich(true)
        const pointData = await limitedHandleFetchEnrichPoint(bodyTocalculateEnrich.data)

        const linkedin = pointData.map((d) => d.linkedin).reduce((acc, point) => acc + point, 0)
        const telegram = pointData.map((d) => d.telegram).reduce((acc, point) => acc + point, 0)
        const totalPoints = pointData.map((d) => d.totalPoints).reduce((acc, point) => acc + point, 0)
        const foundContacts = pointData.flatMap((d) => d.foundContacts).length
        const foundTelegram = pointData.flatMap((d) => d.foundTelegram).length
        const foundLinkedin = pointData.flatMap((d) => d.foundLinkedin).length

        const dataRes = {
          linkedin,
          telegram,
          totalPoints,
          foundContacts,
          foundTelegram,
          foundLinkedin,
        }
        dispatch(SET_ENRICH_POINT(dataRes))
        setLoadingEnrich(false)
      })().catch((error) => {
        handleFetchError(error, 'Error when calculating enrich points')
      })
    }
  }, [channelSelect, bodyTocalculateEnrich])

  React.useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      const data = {
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      }
      setUserPoints(data)
      dispatch(SET_USER_POINTS(data))
    }
    fetchData().catch(console.error)
  }, [])

  return (
    <div className={styles.enrichData}>
      <div className={styles.enrichData__body}>
        <EnrichChannel
          channelSelect={channelSelect}
          channelTotalRow={{
            linkedin: enrichPoints?.foundLinkedin || 0,
            telegram: enrichPoints?.foundTelegram || 0,
          }}
          channelCostPerRow={{
            linkedin: userPoints?.pointsStore?.linkedin,
            telegram: userPoints?.pointsStore?.telegram,
          }}
          channelTotalCost={{
            linkedin: enrichPoints?.linkedin || 0,
            telegram: enrichPoints?.telegram || 0,
          }}
          loading={isLoadingEnrich}
          handleChangeToggle={handleChangeToggle}
        />
      </div>

      <div className={styles.enrichData__footer}>
        {isLoadingUpload && isApproveAction && (
          <Typography style={{ whiteSpace: 'nowrap', color: '#a7a7a7' }}>
            Enriching... please don't close the window
          </Typography>
        )}

        {(isLoadingEnrich || isLoadingUpload) && (
          <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
        )}

        {isLoadingUpload && isLargeData && (
          <div style={{ minWidth: '200px', marginRight: '8px' }}>
            <Progress percent={+Number(progress).toFixed(2)} size='small' strokeColor='#7043FF' />
          </div>
        )}

        <Button
          disabled={isLoadingEnrich || isLoadingUpload}
          onClick={() => {
            handleAction({ isSkip: true })
            setIsApproveAction(false)
          }}>
          Skip
        </Button>
        <Button
          disabled={isLoadingEnrich || isLoadingUpload}
          type='primary'
          onClick={() => {
            handleAction({ isSkip: false })
            setIsApproveAction(true)
          }}>
          Approve
        </Button>
      </div>
    </div>
  )
}

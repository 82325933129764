import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Skeleton, Table } from 'antd'
import { ReactComponent as FacebookIcon } from 'common/assets/svg/logo_facebook.svg'
import { ReactComponent as TelegramIcon } from 'common/assets/svg/logo_telegram.svg'
import { ReactComponent as LinkedInIcon } from 'common/assets/svg/logo_linkedin.svg'
import { ReactComponent as InstagramIcon } from 'common/assets/svg/logo_instagram.svg'
import { ReactComponent as DiscordIcon } from 'common/assets/svg/discord_logo.svg'
import { ReactComponent as TwitterIcon } from 'common/assets/svg/twitter_logo.svg'
import getColumns from './SocialAccountTableColumns'
import { capitalize } from 'lodash'
import styles from './SocialAccountTable.module.scss'
import EditAccountDrawer from './EditAccountDrawer'
import { useLazyGetTelegramAccountInfoQuery, useLazyRemoveAccountQuery } from 'common/APIs/SettingsApi'
import { useLazyGetUserDetailsQuery } from 'common/APIs/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
import close from 'common/assets/svg/closeVideo.svg'
import crown from 'common/assets/svg/crown_icon.svg'
import { useIntercom } from 'react-use-intercom'
import WarningDeleteModal from './WarningDeleteModal'
interface SocialAccount {
  profilePhoto?: string
  userName?: string
  fullName?: string
  phoneNumber?: string
  bio?: string
  outreachMethod?: string
  status?: string
  photos?: string[]
}

interface SocialAccountTableProps {
  data: SocialAccount[] | any
  setData: any
  platform: string
  onAddAccount: () => void
  setOpenQrcodPage?: any
  onEditAccount: (e: any) => void
  handleOutreachEdit?: any
}

const getPlatformIcon = (platform: string) => {
  switch (platform) {
    case 'telegram':
      return <TelegramIcon className={styles.platformIcon} />
    case 'instagram':
      return <InstagramIcon className={styles.platformIcon} />
    case 'facebook':
      return <FacebookIcon className={styles.platformIcon} />
    case 'twitter':
      return <TwitterIcon className={styles.platformIcon} />
    case 'linkedin':
      return <LinkedInIcon className={styles.platformIcon} />
    case 'discord':
      return <DiscordIcon className={styles.platformIcon} />
    default:
      return null
  }
}

const SocialAccountTable: React.FC<SocialAccountTableProps> = ({
  data,
  setData,
  platform,
  onAddAccount,
  setOpenQrcodPage,
  onEditAccount,
  handleOutreachEdit,
}) => {
  const [tableLoading] = useState(false)
  const [getTelegramAccountInfo] = useLazyGetTelegramAccountInfoQuery()
  const [removeAccount] = useLazyRemoveAccountQuery()
  const [isLoadingAccount, setIsLoadingAccount] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)
  const [pageSize, setPageSize] = useState(7)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [user, setLocalUser] = useLocalStorage<any>('user', {})
  const [showToManyModal, setShowToManyModal] = useState(false)
  const { boot, showNewMessage } = useIntercom()
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [loadingColumn, setLoadingColumn] = useState<any>(null)
  const [remove, setRemove] = useState<any>(null)
  const [key, setKey] = useState(1)

  const handleEditAccount = async (accountData: any) => {
    setIsLoadingAccount(true)
    const telegram_number = `${accountData?.area_code?.replace('+', '')}${accountData?.number}`
    const { data } = await getTelegramAccountInfo({ telegram_number })
    setSelectedAccount({ ...accountData, ...data })
    setIsLoadingAccount(false)
    setOpenDrawer(true)
  }

  const getUserDetails = async () => {
    const { data } = await refetchGetUserDetails()
    setLocalUser(data)
    setLoadingColumn(null)
  }

  const handleRemoveAccount = async (account: any, platform: any) => {
    try {
      setLoadingColumn(account?.id)
      const result = await removeAccount({ account, platform })
      // check if error
      if (result?.error) {
        if (result?.error?.status === 400) {
          // TODO: to much deletions modal
          setShowToManyModal(true)
        }
        if (result?.error?.status === 500) {
          message.error('Oops! Something went wrong. Please try again later.')
        }
      }
      getUserDetails()
    } catch (e) {
      console.log(e)
    }
  }
  const columns = getColumns(
    platform,
    setOpenDrawer,
    setPhoneNumber,
    handleEditAccount,
    setOpenQrcodPage,
    handleOutreachEdit,
    isLoadingAccount,
    handleRemoveAccount,
    loadingColumn,
    setShowWarningModal,
    setRemove,
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1600) {
        setPageSize(6)
      } else {
        setPageSize(8)
      }
    }

    handleResize() // Set initial page size
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth])

  return (
    <>
      {showToManyModal && (
        <Modal
          open={showToManyModal}
          onCancel={() => setShowToManyModal(false)}
          footer={null}
          closeIcon={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={close}
                alt='close'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '15px',
                  height: '15px',
                  cursor: 'pointer',
                }}
                onClick={() => setShowToManyModal(false)}
              />
            </div>
          }
          centered
          width={550}
          bodyStyle={{
            height: 270,
            padding: 16,
          }}>
          <div
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 35,
            }}>
            <div style={{ height: '60%', width: '100%', display: 'flex', flexDirection: 'column', gap: 23 }}>
              <div
                style={{
                  height: '30%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <img src={crown} alt='Crown' style={{ width: '60px', height: '60px' }} />
              </div>
              <div
                style={{
                  height: '70%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}>
                <div
                  style={{
                    width: '100%',
                    height: '25%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <h1
                    style={{
                      color: '#051123',
                      fontFamily: 'Roboto',
                      fontSize: '29.5px',
                      fontWeight: '900',
                    }}>
                    Limit Reached!
                  </h1>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '75%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <div style={{ width: '90%', height: '100%' }}>
                    <p
                      style={{
                        color: '#5E6C84',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontSize: '16 px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px' /* 171.429% */,
                        letterSpacing: '0.07px',
                      }}>
                      You have reached the limit of removing accounts, please contact support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: '35%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 10,
              }}>
              <Button
                type='primary'
                className={styles.upgradeNow}
                onClick={() => {
                  setShowToManyModal(false)
                  boot({
                    name: user?.name,
                    email: user?.email,
                  })
                  showNewMessage(`I need help with removing a social account`)
                }}>
                Contact Support
              </Button>
              <Button type='text' className={styles.talkToSales} onClick={() => setShowToManyModal(false)}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showWarningModal && !showToManyModal && (
        <WarningDeleteModal
          onCancel={() => setShowWarningModal(false)}
          onConfirm={() => {
            setShowWarningModal(false)
            remove()
          }}
          open={showWarningModal}
        />
      )}
      <div className={styles.tableContainer}>
        <div className={styles.header}>
          <div className={styles.leftSide}>
            {getPlatformIcon(platform)}
            <h2>{capitalize(platform)} Accounts</h2>
          </div>
          <div className={styles.rightSide}>
            <Button type='primary' onClick={onAddAccount}>
              Add Account
            </Button>
          </div>
        </div>
        <Table
          scroll={{ x: 1000 }}
          columns={
            tableLoading
              ? columns?.map((column: any) => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return (
                        <Skeleton
                          // className='campaignsPageTable'
                          style={{
                            margin: '0px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '5px',
                            height: '30px',
                            paddingRight: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            opacity: 0.5,
                          }}
                          key={column.dataIndex}
                          title={true}
                          paragraph={false}
                          active
                        />
                      )
                    },
                  }
                })
              : columns
          }
          dataSource={
            tableLoading
              ? [...Array(10)].map((_, index) => ({
                  key: `key${index}`,
                }))
              : data
          }
          rowKey='id'
          pagination={{ pageSize: pageSize }}
        />
        {openDrawer && (
          <EditAccountDrawer
            open={openDrawer}
            onClose={() => {
              setOpenDrawer(false)
            }}
            data={selectedAccount}
            phoneNumber={phoneNumber}
            onSave={() => setKey(key + 1)}
          />
        )}
      </div>
    </>
  )
}
export default SocialAccountTable

import styles from './OnBoardingPageF.module.scss'

const videoSrc = require('LEGACY/Assets/mp4/OutreachAutomation.mp4') as any

const OnBoardingPageF = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Leverage Your Telegram Outreach Suite'}</p>
        <section>
          The #1 Outreach tool in response rate. Boost your sales by 20x!
          <br />
          {/* {userType === 'BETA' && (
            <span style={{ fontWeight: 600 }}>Also, use your Telegram account for outreach to prospects.</span>
          )} */}
        </section>
      </div>
      <div className={styles.animation}>
        <video src={videoSrc} autoPlay loop muted />
      </div>
    </div>
  )
}

export default OnBoardingPageF

import React from 'react'
import { Layout, Descriptions, Tooltip, Divider, message, Switch } from 'antd'
import { CloseOutlined, LeftSquareOutlined, SettingOutlined } from '@ant-design/icons'
import styles from './ProfileSider.module.scss'
import { useAppSelector } from 'state'
import ActiveAccounts from './UI/ActiveAccounts'
import TagDialog from './Tag'
import Category from './Category'
import useHubspot from 'LEGACY/features/settings/Pages/Hooks/useHubspot'
import hubspotLogo from 'common/assets/svg/hubspot_icon.svg'
import { useGetHubspotUnifiedInboxQuery } from 'common/APIs/Chat-API'

const { Sider } = Layout

interface ProfileSiderProps {
  isVisible: boolean
  onOpen: () => void
  onClose: () => void
  userImage: any
  onChangeSyncContact: (value: boolean) => Promise<void>
  openHubSpotSync: () => void
}

const ProfileSider: React.FC<ProfileSiderProps> = ({
  isVisible,
  onOpen,
  onClose,
  userImage,
  onChangeSyncContact,
  openHubSpotSync,
}) => {
  const { selected_chat_recipient, selected_chat_platform } = useAppSelector((state) => state.chat)
  const { title, job_title, campaign_name, company, vertical, username, dialog_bio, full_name, categories } =
    selected_chat_recipient
  const { hubSpotKey } = useHubspot()
  const {
    refetch: refetchHubspotUnifiedSetting,
    data: hubspotUnifiedSettings,
    isFetching: fetchingUnifiedSetting,
  } = useGetHubspotUnifiedInboxQuery(selected_chat_recipient.id, { refetchOnMountOrArgChange: true })

  const handleShowErrorHubspotConnect = () => {
    message.error('You have to connect your hubspot first')
  }
  const handleOpenHubspotSync = () => {
    if (!hubSpotKey) {
      handleShowErrorHubspotConnect()
      return
    }

    openHubSpotSync()
  }

  return (
    <>
      <Sider
        width={300}
        className={`${styles.profileSider} profileSiderGlobal`}
        collapsedWidth={0}
        collapsible
        collapsed={!isVisible}
        reverseArrow
        trigger={true}>
        <div className={styles.header}>
          <CloseOutlined onClick={onClose} className={styles.closeIcon} />
          {userImage}
          <h2 className={styles.name}>{title}</h2>
          {job_title && <p className={styles.title}>{job_title}</p>}
        </div>
        <div className={styles.container}>
          <Descriptions
            column={1}
            className={`${styles.descriptions} profileSiderDescription`}
            layout='vertical'
            colon={false}>
            {title && <Descriptions.Item label='Name'>{full_name ? full_name : title}</Descriptions.Item>}
            {username && <Descriptions.Item label='Username'>{username}</Descriptions.Item>}
            {dialog_bio && <Descriptions.Item label='Bio'>{dialog_bio}</Descriptions.Item>}
            {company && <Descriptions.Item label='Company name'>{company}</Descriptions.Item>}
            {vertical && <Descriptions.Item label='Industry'>{vertical}</Descriptions.Item>}
            {campaign_name && <Descriptions.Item label='Last Added Campaign'>{campaign_name}</Descriptions.Item>}
            <Descriptions.Item
              label={
                <div className={styles.activeAccounts}>
                  <span>Active Accounts</span>
                  {/* <span style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Enrich</span> */}
                  <Tooltip title='Coming Soon'>
                    <span style={{ textDecoration: 'underline', color: 'gray' }}>Enrich</span>
                  </Tooltip>
                </div>
              }>
              <ActiveAccounts
                activePlatforms={{
                  facebook: true,
                  instagram: true,
                  telegram: true,
                  twitter: true,
                  linkedin: true,
                  discord: true,
                }}
              />
            </Descriptions.Item>
          </Descriptions>
          <Divider style={{ margin: '8px 0px 24px 0px' }} />
          <TagDialog />
          <Divider style={{ margin: '8px 0px 24px 0px' }} />
          {categories?.length > 0 && <Category data={categories} />}
          {selected_chat_platform === 'telegram' && selected_chat_recipient?.platform === 'telegram' && (
            <div className={styles.hubspotSync}>
              <div className={styles.border} />

              <div className={styles.hubspotSync__title}>
                <span>Active Sync</span>
                <SettingOutlined
                  style={{ fontSize: '24px', color: '#5E6C84', cursor: 'pointer' }}
                  onClick={handleOpenHubspotSync}
                />
              </div>

              <div className={styles.hubspotSync__body}>
                <div className={styles.hubspotSync__body__left}>
                  <img src={hubspotLogo} alt='hubspot-logo' />
                  <span>Hubspot</span>
                </div>

                <Switch
                  size='small'
                  defaultChecked={false}
                  checked={hubspotUnifiedSettings?.response?.is_sync}
                  disabled={fetchingUnifiedSetting}
                  onChange={(v) => {
                    if (!hubSpotKey) {
                      handleShowErrorHubspotConnect()
                      return
                    }

                    onChangeSyncContact(v).then(() => {
                      if (v) {
                        openHubSpotSync()
                      }
                      refetchHubspotUnifiedSetting()
                    })
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Sider>
      <div className={styles.trigger} style={isVisible ? { display: 'none' } : {}}>
        <LeftSquareOutlined onClick={onOpen} style={{ fontSize: '20px', cursor: 'pointer', color: '#97a0af' }} />
      </div>
    </>
  )
}

export default ProfileSider

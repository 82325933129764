import React from 'react'
import styles from './ShouldJoinGroupsModal.module.scss'
import { Button, Modal } from 'antd'
interface Props {
  open: boolean
  data: any
  setOpen: (open: boolean) => void
  onContinue: () => void
}
export default function ShouldJoinGroupModal({ open, data, onContinue, setOpen }: Props) {
  return (
    <Modal
      className='modalNoPadding'
      open={open}
      onCancel={() => setOpen(false)}
      width={560}
      style={{
        height: 225,
        maxHeight: 225,
        width: 560,
      }}
      centered
      footer={
        <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button className={styles.cancel} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              type='ghost'
              className={styles.joined}
              onClick={() => {
                setOpen(false)
                onContinue()
              }}>
              <span style={{ padding: '0px 16px' }}>I have joined and approved by admins</span>
            </Button>
          </div>
        </>
      }>
      <span className={styles.headline}>Make Sure You Are a Member of This Group</span>
      <p className={styles.para}>
        This is a private group that none of your accounts are members of. Please ensure you have joined the group and
        been approved by the admins before collecting.{' '}
        <a href={data?.username} target='_blank' rel='noreferrer'>
          Join here
        </a>
      </p>
    </Modal>
  )
}

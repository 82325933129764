import { useAppDispatch, useAppSelector } from 'state'
import lead_icon1 from 'common/assets/svg/facebook_icon.svg'
import lead_icon2 from 'common/assets/svg/lead_icon2.svg'
import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'
import { Col, Popconfirm } from 'antd'
import { useEffect, useState } from 'react'
import { useDisclaimer } from 'common/hooks/useDisclaimer'
import { ConfirmBox } from 'common/constants/modal.constants'
import { useLazyExposePlatformQuery, useLazyExposeTempLeadPlatformQuery } from 'common/APIs/OutreachApi'
import {
  UPDATE_INITIAL_AI_DATA,
  UPDATE_SELECTED_LEADS,
} from 'common/components/Campaigns/state/outreachAICampaignSlice'
import OutOfPointsUseRemaining from 'common/components/GeneralComponents/Modals/OutOfPoints/OutOfPointsUseRemaining'

interface ChannelsProps {
  text: string
  data: any
}
export function AiLeadsChannels({ text, data }: ChannelsProps) {
  const { active_platforms, ai_leads_type, userPoints, initial_ai_data, selected_leads } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()

  const [refetcExposePlatform, { data: exposePlatformData }] = useLazyExposePlatformQuery()
  const [isMagic, setIsMagic] = useState(false)
  const [costPoints, setCostPoints] = useState(0)
  const [outOfPoints, setOutOfPoints] = useState(false)

  const style = 'grayscale(100%)'
  const instagramUrl = data.instagram.startsWith('https://www.instagram.com/')
  const dispatch = useAppDispatch()
  const [toExpose, setToExpose] = useState(false)
  const [exposeTempLead] = useLazyExposeTempLeadPlatformQuery()
  const retryWithRemainingPoints = () => {
    setOutOfPoints(false)
  }
  const socialMediaIcons = [
    {
      platform: 'facebook',
      url: active_platforms['facebook'] === true && data.facebook !== null ? data.facebook : '',
      icon: lead_icon1,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'instagram',
      url:
        active_platforms['instagram'] === true && data.instagram !== null && data.instagram !== ''
          ? instagramUrl
            ? `${data.instagram}`
            : `https://instagram.com/${data.instagram}`
          : '',
      icon: lead_icon2,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'telegram',
      url: active_platforms['telegram'] === true && data.telegram !== null ? data.telegram : '',
      icon: lead_icon3,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'linkedin',
      url: active_platforms['linkedin'] === true && data.linkedin !== null ? data.linkedin : '',
      icon: lead_icon4,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'discord',
      url: active_platforms['discord'] === true && data.discord !== null ? data.discord : '',
      icon: lead_icon5,
      index: data.index,
      source: data.source,
    },
    {
      platform: 'twitter',
      url: active_platforms['twitter'] === true && data.twitter !== null ? data.twitter : '',
      icon: lead_icon6,
      index: data.index,
      source: data.source,
    },
  ]
  useEffect(() => {
    if (initial_ai_data?.data !== undefined) {
      dispatch(UPDATE_INITIAL_AI_DATA(exposePlatformData))
    }

    if (selected_leads !== undefined && exposePlatformData) {
      dispatch(UPDATE_SELECTED_LEADS(exposePlatformData))
    }
  }, [exposePlatformData])
  useEffect(() => {
    const expose = async () => {
      await exposeTempLead({
        lead: selected_leads.filter((l: any) => l.index === data.index)[0],
        platform: [data.platform],
        isBusiness: ai_leads_type === 'companies_leads' ? true : false,
      })
    }
    if (toExpose) {
      expose()
      setToExpose(false)
    }
  }, [toExpose])
  return (
    <div className='img_group'>
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
          isMagic={isMagic}
          isShowUseRemainingPointsButton={false}
        />
      )}
      {socialMediaIcons.map(({ platform, url, icon, index, source }) => {
        return (
          <>
            {url.length === 0 && source !== 'CSV' ? (
              <Popconfirm
                title={`Get ${platform.charAt(0).toUpperCase() + platform.slice(1)} Details`}
                description={
                  <Col>
                    <div>{`This action will cost you ${
                      userPoints?.pointsStore?.[`magic_${platform}`]
                    } Magic point. Read disclaimer:`}</div>
                    {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                    {showDisclaimer ? (
                      <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                        {ConfirmBox.DISCLAIMER}
                      </p>
                    ) : (
                      <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                        {'Disclaimer'}
                      </span>
                    )}
                  </Col>
                }
                onCancel={() => {
                  toggleShowDisclaimer()
                  setShowDisclaimer(false)
                }}
                onConfirm={async () => {
                  toggleShowDisclaimer()
                  setShowDisclaimer(false)
                  const dataExposePlatform = await refetcExposePlatform({
                    index: [index],
                    platform: [platform],
                    isBusiness: ai_leads_type === 'companies_leads' ? true : false,
                  })

                  if (dataExposePlatform?.error?.status === 470) {
                    if (dataExposePlatform?.error?.data?.type === 'magic_points') {
                      setIsMagic(true)
                    } else {
                      setIsMagic(false)
                    }
                    setCostPoints(dataExposePlatform?.error?.data?.cost_point)
                    setOutOfPoints(true)
                  } else {
                    setToExpose(true)
                    const data = dataExposePlatform?.data?.leads_index?.[0]?.[platform]
                    if (platform === 'instagram') {
                      const instagramUrl = data.startsWith('https://www.instagram.com/')
                        ? data
                        : `https://www.instagram.com/${data}`
                      window.open(instagramUrl, '_blank')
                    } else {
                      window.open(data, '_blank')
                    }
                  }
                }}
                disabled={!active_platforms[platform] ? true : false}>
                <img
                  key={platform}
                  style={{
                    filter: style,
                    opacity: active_platforms[platform] ? 1 : 0.25, //!url ? 0.25 : 1,
                    cursor: 'pointer', //url ? 'pointer' : 'default',
                    height: '20px',
                    width: '20px',
                  }}
                  src={icon}
                  alt={platform}
                />
              </Popconfirm>
            ) : (
              <img
                key={platform}
                onClick={() => url && window.open(url, '_blank')}
                style={{
                  filter: !url ? style : '',
                  opacity: !url ? 0.25 : 1,
                  cursor: url ? 'pointer' : 'default',
                  height: '20px',
                  width: '20px',
                }}
                src={icon}
                alt={platform}
              />
            )}
          </>
        )
      })}
    </div>
  )
}

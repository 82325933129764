import { Button } from 'antd'
import custom from 'common/assets/svg/CustomImport.svg'
import shadowElipse from 'common/assets/svg/shadowElipse.svg'
import styles from './NotSeeing.module.scss'

interface NotSeeingProps {
  key: string
  style: React.CSSProperties
  onClick?: () => void
  isSmallScreen: boolean
}

const NotSeeing: React.FC<NotSeeingProps> = ({ key, style, onClick, isSmallScreen }) => {
  // Scale factor for small screens (30% smaller)
  const scale = isSmallScreen ? 0.7 : 1

  return (
    <div
      key={key}
      style={{
        ...style,
        width: '100%',
        textAlign: 'center',
        top: style?.top || 1000 + 500,
      }}
      className={styles.not}>
      <div>
        <h1
          style={{
            paddingBottom: 10 * scale,
            marginTop: 0,
            fontSize: `${2 * scale}rem`,
          }}>
          Not Seeing What You Need?
        </h1>
        <div className={styles.notBox} style={isSmallScreen ? { height: '300px' } : {}}>
          <div className={styles.notInner} style={{ gap: 15 * scale }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4 * scale,
                marginBottom: 16 * scale,
              }}>
              <img
                src={custom}
                alt='Custom Import'
                style={{
                  width: 100 * scale,
                  height: 100 * scale,
                }}
              />
              <img
                src={shadowElipse}
                alt='shadowElipse'
                style={{
                  marginTop: -10 * scale,
                  marginLeft: 13 * scale,
                  width: `${100 * scale}%`,
                }}
              />
            </div>
            <div
              className={styles.notTextFrame}
              style={{
                padding: `${20 * scale}px ${15 * scale}px`,
                gap: 10 * scale,
                height: `${40 * scale}%`,
              }}>
              <span
                className={styles.collect}
                style={{
                  fontSize: `${1.4 * scale}rem`,
                  marginBottom: 12 * scale,
                }}>
                Collect From Custom Source
              </span>
              <p
                className={styles.paraCollect}
                style={{
                  fontSize: `${1.2 * scale}rem`,
                  marginBottom: 15 * scale,
                }}>
                Collect leads from any social platform by simply sharing a custom link to your desired source!
              </p>
            </div>

            <Button
              onClick={onClick}
              type='ghost'
              className={styles.collectButtonNot}
              style={{
                fontSize: 22 * scale,
                padding: `${8 * scale}px ${16 * scale}px`,
                marginLeft: 0,
                width: 206 * scale,
                height: 40 * scale,
              }}>
              Collect Leads
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotSeeing

import React, { useState } from 'react'
import { Modal, Form, Input, Checkbox, Button, Select, Row, Col } from 'antd'
import styles from './RunInScheduleModal.module.scss'
import { Content } from 'antd/es/layout/layout'
import SelectTime from './SelectTime'
import moment from 'moment-timezone'
import { useLazySetCampaignScheduleQuery, useLazyUpdateCampaignScheduleQuery } from 'common/APIs/OutreachApi'

const { Option } = Select

interface RunInScheduleModalProps {
  open: boolean
  onClose: () => void
  onCancel?: () => void
  onSave: (schedule: { name: string; timeZone: string; timeRanges: any } | any) => void
  onUpdate: () => void
  data: any
  scheduleToOpen?: any
}

const RunInScheduleModal: React.FC<RunInScheduleModalProps> = ({
  open,
  onClose,
  onCancel,
  onSave,
  onUpdate,
  data,
  scheduleToOpen,
}) => {
  const [refetchSetCampaignSchedule] = useLazySetCampaignScheduleQuery()
  const [refetchUpdateCampaignSchedule] = useLazyUpdateCampaignScheduleQuery()
  const [form] = Form.useForm()
  const scheduleObject = data?.find((schedule: any) => schedule.schedule_label === scheduleToOpen)
  const scheduleData = JSON?.parse(scheduleObject?.schedule || '{}')
  const scheduleLabel = scheduleObject?.schedule_label
  const [selectedDays, setSelectedDays] = useState<string[]>(
    scheduleData?.timeRanges ? Object.keys(scheduleData?.timeRanges) : [],
  )
  const defaultTimeRange = ['09', '00', '21', '00']
  const [timeRanges, setTimeRanges] = useState<{ [day: string]: string[] }>(
    scheduleData?.timeRanges
      ? {
          ...{
            Monday: defaultTimeRange,
            Tuesday: defaultTimeRange,
            Wednesday: defaultTimeRange,
            Thursday: defaultTimeRange,
            Friday: defaultTimeRange,
            Saturday: defaultTimeRange,
            Sunday: defaultTimeRange,
          },
          ...scheduleData.timeRanges,
        }
      : {
          Monday: defaultTimeRange,
          Tuesday: defaultTimeRange,
          Wednesday: defaultTimeRange,
          Thursday: defaultTimeRange,
          Friday: defaultTimeRange,
          Saturday: defaultTimeRange,
          Sunday: defaultTimeRange,
        },
  )

  const timeZones = moment.tz.names().map((zone) => ({
    name: zone,
    offset: moment.tz(zone).format('Z'),
  }))

  const handleSaveSchedule = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const filteredSchedule = Object.fromEntries(
          Object.entries(timeRanges).filter(([day]) => selectedDays.includes(day)),
        )
        const dataObject = {
          name: values.name,
          timeZone: values.timeZone,
          timeRanges: filteredSchedule,
        }

        const { data } = await refetchSetCampaignSchedule({
          schedule: JSON.stringify(dataObject),
          schedule_type: 'custom',
          schedule_label: values.name,
        })
        // onSave({
        //   name: values.name,
        //   timeZone: values.timeZone,
        //   timeRanges: filteredSchedule,
        // })
        onSave(data?.response?.[0])
        //
        //
        // NEED TO SAVE SCHEDULE TO THE DB !!!
        //
        //
        form.resetFields()
        onClose()
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo)
      })
  }

  const handleUpdateSchedule = () => {
    form
      .validateFields()
      .then(async (values) => {
        const filteredSchedule = Object.fromEntries(
          Object.entries(timeRanges).filter(([day]) => selectedDays.includes(day)),
        )
        const dataObject = {
          name: values.name ? values.name : scheduleLabel,
          timeZone: values.timeZone ? values.timeZone : scheduleData?.timeZone,
          timeRanges: filteredSchedule,
        }

        const schedule_type = scheduleObject?.schedule_type
        const schedule_id = scheduleObject?.schedule_id
        const schedule_label = dataObject.name

        await refetchUpdateCampaignSchedule({
          schedule_id: schedule_id,
          schedule: JSON.stringify(dataObject),
          schedule_type: schedule_type,
          schedule_label: schedule_label,
        })
        onUpdate()
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo)
      })
  }

  const handleCheckboxChange = (day: string) => {
    setSelectedDays((prevSelected) => {
      if (prevSelected.includes(day)) {
        return prevSelected.filter((selectedDay) => selectedDay !== day)
      } else {
        return [...prevSelected, day]
      }
    })
  }

  const handleTimeRangeChange = (newValues: string[], day: string) => {
    setTimeRanges((prevRanges) => ({
      ...prevRanges,
      [day]: newValues,
    }))
  }

  const handleApplyToAll = (value: any) => {
    setTimeRanges({
      Monday: value,
      Tuesday: value,
      Wednesday: value,
      Thursday: value,
      Friday: value,
      Saturday: value,
      Sunday: value,
    })
  }

  const DAYS_LIST = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ]

  // useEffect(() => {
  //   setTimeRanges((prevRanges) => {
  //     const newRanges = { ...prevRanges }

  //     DAYS_LIST.forEach((day) => {
  //       const defaultTimes = defaultSche?.timeRanges?.[day.name] || []
  //       if (JSON.stringify(defaultTimes) !== JSON.stringify(prevRanges[day.name])) {
  //         newRanges[day.name] = defaultTimes
  //       }
  //     })
  //     return newRanges
  //   })
  // }, [])

  const customSchedule = (
    <Content className={styles.contentStyle}>
      <Form
        form={form}
        id='dealForm'
        name='dealForm'
        onFinish={handleSaveSchedule}
        style={{ maxWidth: 1200 }}
        className='newDealForm'
        colon={false}>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={<p className={styles.formSelect}>Name</p>}
            name='name'
            className={styles.formSelect}
            rules={[
              {
                required: true,
                message: 'Please enter schedule name',
              },
            ]}>
            <Input placeholder='Enter schedule name' />
          </Form.Item>
          <Form.Item
            name='timeZone'
            label={<p>Time Zone</p>}
            className={styles.formSelect}
            style={{ margin: '0px 0px 20px 0px' }}
            rules={[
              {
                required: true,
                message: 'Please select time zone',
              },
            ]}>
            <Select size='large' placeholder='Select time zone' showSearch>
              {timeZones.map((zone, index) => (
                <Option key={index} value={zone.name}>
                  {`${zone.name} (UTC${zone.offset})`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item
          name='timeRanges'
          // className={styles.formSelect}
          // style={{ margin: '0px 0px 20px 0px' }}

          rules={[
            {
              required: true,
              message: 'Please select at least one day',
            },
          ]}>
          <div className={styles.listsContainer}>
            <p>Time Ranges</p>
            {DAYS_LIST.map((list) => (
              <Row key={list.id} className={styles.list} gutter={16} align='middle'>
                <Col span={6}>
                  <div className={styles.checkbox}>
                    <Checkbox
                      onChange={() => handleCheckboxChange(list.name)}
                      // defaultChecked={
                      //   timeRanges?.hasOwnProperty(list.name) && list.name !== 'Sunday' && list.name !== 'Saturday'
                      // }
                    />
                    <p>{list.name}</p>
                  </div>
                </Col>
                <Col span={13} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SelectTime
                    value={timeRanges[list.name]}
                    onChange={(newValues) => handleTimeRangeChange(newValues, list.name)}
                    row={list.name}
                    onApplyToAll={handleApplyToAll}
                  />
                </Col>
              </Row>
            ))}
          </div>
        </Form.Item>

        <div className={styles.footer}>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </Content>
  )

  return (
    <Modal
      title={<span>Run in Schedule</span>}
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      style={{ minWidth: '600px' }}
      width={'fit-content'}>
      {scheduleToOpen === 'custom' ? (
        customSchedule
      ) : (
        <Content className={styles.contentStyle}>
          <Form
            form={form}
            id='dealForm'
            name='dealForm'
            onFinish={handleUpdateSchedule}
            style={{ maxWidth: 1200 }}
            className='newDealForm'
            colon={false}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item label={<p className={styles.formSelect}>Name</p>} name='name' className={styles.formSelect}>
                <Input defaultValue={scheduleLabel} disabled={true} />
              </Form.Item>
              <Form.Item
                name='timeZone'
                label={<p>Time Zone</p>}
                className={styles.formSelect}
                style={{ margin: '0px 0px 20px 0px' }}>
                <Select size='large' placeholder='Select time zone' showSearch defaultValue={scheduleData?.timeZone}>
                  {timeZones.map((zone, index) => (
                    <Option key={index} value={zone.name}>
                      {`${zone.name} (UTC${zone.offset})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            {/* 
            <Form.Item
              name='timeRangesUpdate'
              // className={styles.formSelect}
              // style={{ margin: '0px 0px 20px 0px' }}
              initialValue={scheduleData?.timeRanges}
              rules={[
                {
                  required: true,
                  message: 'Please select at least one day',
                },
              ]}> */}
            <div className={styles.listsContainer}>
              <p>Time Ranges</p>
              {DAYS_LIST.map((list) => (
                <Row key={list.id} className={styles.list} gutter={16} align='middle'>
                  <Col span={6}>
                    <div className={styles.checkbox}>
                      <Checkbox
                        defaultChecked={scheduleData?.timeRanges?.hasOwnProperty(list.name)}
                        onChange={() => handleCheckboxChange(list.name)}
                      />
                      <p>{list.name}</p>
                    </div>
                  </Col>
                  <Col span={13} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <SelectTime
                      value={timeRanges[list.name]}
                      onChange={(newValues) => handleTimeRangeChange(newValues, list.name)}
                      onApplyToAll={handleApplyToAll}
                    />
                  </Col>
                </Row>
              ))}
            </div>
            {/* </Form.Item> */}

            <div className={styles.footer}>
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Content>
      )}
    </Modal>
  )
}

export default RunInScheduleModal

import { useState } from 'react'
import { Button, Dropdown, Alert, Space } from 'antd'
import type { MenuProps } from 'antd'
import styles from './SendMessage.module.scss'
import { useLocation } from 'react-router-dom'
import { UpOutlined } from '@ant-design/icons'
import hubspot from 'LEGACY/Assets/svg/hubspot.svg'
import automation from 'LEGACY/Assets/svg/automation.svg'
import salesforce from 'LEGACY/Assets/svg/salesforce.svg'
import download_csv from 'LEGACY/Assets/svg/download_csv.svg'
import add_to_list from 'LEGACY/Assets/svg/add_to_list.svg'
import { useLazyGetLoginHubSpotQuery } from 'LEGACY/API/SettingsApi' // '../settings/state/api/SettingsApi'

import { useAppSelector } from 'state'
import HubspotModal from './Hubspot/HubspotModal'
import SalesforceModal from './Salesforce/SalesforceModal'
import CSVModal from './CSVModal'
import AddToListModal from './AddToListModal'
import { useActiveFilters } from 'LEGACY/common/hooks/useFilters'
import { useLazyGetPointsStoreQuery } from 'LEGACY/API/SettingsApi'
import MyContactRemoveFromListModal from 'LEGACY/MyContacts/components/RemoveFromListModal'
import useIntegrations from 'LEGACY/features/settings/Pages/Hooks/useIntegrations'

const OutreachActions = ({ selectedContacts, refetchData, isCompany, selectedCompaniesObject }: any) => {
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const [userPoints, setUserPoints] = useState<any>()

  const [actions, setActions] = useState(false)
  const [openHubspotModal, setOpenHubspotModal] = useState(false)
  const [openSalesforceModal, setOpenSalesforceModal] = useState(false)
  const [openCSVModal, setOpenCSVModal] = useState(false)
  const [openAddToListModal, setOpenAddToListModal] = useState(false)
  const [openRemoveFromListModal, setOpenRemoveFromListModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertSalesforce, setShowAlertSalesforce] = useState(false)
  const token = localStorage.getItem('hubspot_access_token')
  const salesforceToken = localStorage.getItem('salesforce_access_token')
  const [activeFilters] = useActiveFilters()
  const locationPathName = useLocation().pathname.split('/')[1]
  const list_id =
    locationPathName === 'contacts'
      ? activeFilters?.contactsList?.[0]?.list_id
      : activeFilters?.companyList?.[0]?.list_id

  // const [tokenHubSpot, setTokenHubSpot] = useState(localStorage.getItem('hubspot_access_token'))

  const { handleConnect } = useIntegrations()
  const { selected_companies, company_lists } = useAppSelector((state) => state.companies)

  const disabled = isCompany ? selected_companies.length === 0 : selectedContacts.length === 0

  const disableRemoveFromListButton =
    activeFilters.hasOwnProperty('companyList') || activeFilters.hasOwnProperty('contactsList')

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.actions}>
          <img src={hubspot} alt='' />
          <span>Hubspot Enrichment</span>
        </div>
      ),
      onClick: () => {
        const hubspot_access_token = localStorage.getItem('hubspot_access_token')
        if (hubspot_access_token === 'null' || !hubspot_access_token) {
          setShowAlert(true)
        } else {
          setOpenHubspotModal(true)
          setActions(false)
          setShowAlert(false)
        }
      },
    },
    {
      key: '2',
      label: (
        <div className={styles.actions}>
          <img src={salesforce} alt='' />
          <span>Salesforce Enrichment</span>
        </div>
      ),
      onClick: () => {
        const salesforce_access_token = localStorage.getItem('salesforce_access_token')
        if (salesforce_access_token === 'null' || !salesforce_access_token) {
          setShowAlertSalesforce(true)
        } else {
          setOpenSalesforceModal(true)
          setActions(false)
          setShowAlertSalesforce(false)
        }
      },
    },
    {
      key: '3',
      label: (
        <div className={styles.actions}>
          <img src={download_csv} alt='' />
          <span>Download CSV</span>
        </div>
      ),
      onClick: () => {
        const fetchData = async () => {
          const points_store = await refetchGetPointsStore()
          console.log('---=---')
          console.log(points_store)
          console.log('---=---')
          setUserPoints({
            points: points_store?.data?.response?.points,
            pointsStore: points_store?.data?.response?.pointsStore,
          })
        }
        fetchData().catch(console.error)
        setOpenCSVModal(true)
        setActions(false)
      },
    },
    {
      key: '4',
      label: (
        <div className={styles.actions}>
          <img src={add_to_list} alt='' />
          <span>Add to List</span>
        </div>
      ),
      onClick: () => {
        setOpenAddToListModal(true)
        setActions(false)
      },
    },
    {
      key: '5',
      label: (
        <div className={styles.actions}>
          <img src={add_to_list} alt='' />
          <span>Remove from List</span>
        </div>
      ),
      onClick: () => {
        setOpenRemoveFromListModal(true)
        setActions(false)
      },
      disabled: !disableRemoveFromListButton,
    },
    {
      key: '5',
      label: (
        <div className={styles.actions}>
          <img src={automation} alt='' />
          <span>Add to Automation</span>
        </div>
      ),
      disabled: true,
    },
  ]

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  const handleLoginSalesforce = async () => {
    handleConnect('Salesforce_blank')
  }

  return (
    <>
      <Dropdown disabled={disabled} menu={{ items }} placement='top' trigger={['click']} arrow>
        <Button
          className={styles.actionsButton}
          onClick={() => {
            setActions(!actions)
          }}>
          <span>Actions</span>
          <UpOutlined
            style={{ color: disabled ? 'lightgray' : '#7043ff', fontSize: '11px' }}
            rotate={actions ? 180 : 0}
          />
        </Button>
      </Dropdown>

      {token && (
        <HubspotModal
          open={openHubspotModal}
          onClose={() => {
            setOpenHubspotModal(false)
          }}
          selectedContacts={selectedContacts}
          selectedCompanies={selectedCompaniesObject}
          isCompany={isCompany}
        />
      )}

      {salesforceToken && openSalesforceModal && (
        <SalesforceModal
          open={openSalesforceModal}
          onClose={() => {
            setOpenSalesforceModal(false)
          }}
          selectedContacts={selectedContacts}
          selectedCompanies={selectedCompaniesObject}
          isCompany={isCompany}
        />
      )}

      {openCSVModal && (
        <CSVModal
          isCompany={isCompany}
          open={openCSVModal}
          onClose={() => {
            setOpenCSVModal(false)
          }}
          userPoints={userPoints}
        />
      )}

      {openAddToListModal && (
        <AddToListModal
          open={openAddToListModal}
          onClose={() => {
            setOpenAddToListModal(false)
          }}
          isCompany={isCompany}
        />
      )}

      {openRemoveFromListModal && (
        <MyContactRemoveFromListModal
          listID={list_id}
          lists={company_lists}
          open={openRemoveFromListModal}
          onClose={() => {
            setOpenRemoveFromListModal(false)
          }}
          isCompany={isCompany}
          selectedContacts={
            selectedContacts ? selectedContacts?.map((item: any) => (item?.contact_id ? item.contact_id : item)) : []
          }
          selectedCompanies={
            selectedCompaniesObject
              ? selectedCompaniesObject.map((company: any) => (company?.id ? company.id : company))
              : []
          }
          refetchData={refetchData}
        />
      )}

      {showAlert && (
        <Alert
          style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
          message='Enrich HubSpot with Convrt'
          description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
          type='info'
          action={
            <Space direction='vertical'>
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  handleLoginHubSpot()
                  setShowAlert(false)
                }}>
                Connect
              </Button>
            </Space>
          }
          closable
          onClose={() => setShowAlert(false)}
        />
      )}
      {showAlertSalesforce && (
        <Alert
          style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
          message='Enrich Salesforce with Convrt'
          description='To enrich contacts/companies and create/update deals, connecting your Salesforce account with Convrt is required'
          type='info'
          action={
            <Space direction='vertical'>
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  handleLoginSalesforce()
                  setShowAlertSalesforce(false)
                }}>
                Connect
              </Button>
            </Space>
          }
          closable
          onClose={() => setShowAlertSalesforce(false)}
        />
      )}
    </>
  )
}

export default OutreachActions

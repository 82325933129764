import React, { useState } from 'react'
import { Button, Layout, Space, Switch, Tooltip, message } from 'antd'
import left_arrow from 'LEGACY/Assets/svg/left_arrow.svg'
import info_icon from 'LEGACY/Assets/svg/info_icon.svg'
import hubspot from 'LEGACY/Assets/svg/hubspot_purple.svg'
import company_icon from 'LEGACY/Assets/svg/company_icon.svg'
import styles from './HubspotExportCompanies.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { useGetCompaniesHubSpotMutation } from 'LEGACY/API/CompaniesApi'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'
import OutOfCreditsModal from 'LEGACY/General/OutOfCredits/OutOfCreditsModal'
import { post } from 'LEGACY/API/axios'

interface HubspotExportCompaniesProps {
  onBack: () => void
  onCloseModal: () => void
  companyIds: any
  isCompany: boolean
  userCredits: any
  userPoints: any
}

const HubspotExportCompanies: React.FC<HubspotExportCompaniesProps> = ({
  onBack,
  onCloseModal,
  companyIds,
  isCompany,
  userCredits,
  userPoints,
}) => {
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfCreditsType, setOutOfCreditsType] = useState('')
  const [fetchHubSpot] = useGetCompaniesHubSpotMutation()
  const [overwriteToggle, setOverwriteToggle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const onChangeOverwriteToggle = () => {
    setOverwriteToggle(!overwriteToggle)
  }

  const handleCompanyExportHubSpot = async () => {
    // if (userCredits.companies_export < companyIds.length) {
    if (userPoints.points < userPoints.pointsStore.companies_export * companyIds.length) {
      setOutOfCreditsType('Company')
      setOutOfCredits(true)
      return
    }
    setIsLoading(true)
    try {
      const result = await fetchHubSpot({ companiesId: companyIds, do_override_if_exists: overwriteToggle })
      if (result?.error?.status === 470) {
        setOutOfCreditsType('Company')
        setOutOfCredits(true)
        setIsLoading(false)
      } else if (result.error) {
        message.error(
          `Oops! Your HubSpot enrichment failed to complete. Please try again or contact support if the issue persists`,
        )
      } else {
        message.success(
          `HubSpot enriched! We've added data for ${result.data.addedToHubspot} ${
            result.data.addedToHubspot === 1 ? 'company' : 'companies'
          } to your HubSpot`,
        )
        onCloseModal()
      }
    } catch (error) {
      console.error(error)
      message.error('Something went wrong!')
    }
  }

  return (
    <>
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={outOfCreditsType}
          location='hubspot'
        />
      )}
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header className={styles.headerStyle}>
            <img src={hubspot} alt='' />
            <h1>Export Companies List</h1>
          </Header>
          <Content className={styles.contentStyle}>
            {isLoading ? (
              <div className={styles.loading}>
                <LoadingBox />
              </div>
            ) : (
              <div className={styles.container}>
                <img src={company_icon} alt='' />
                <p>Export Companies</p>
                <span>
                  Export a list of companies to Hubspot, this action will cost{' '}
                  {companyIds.length * userPoints.pointsStore.companies_export} points.
                  <br />
                  {/* NEED TO OPEN THE MAPPING COMPONENT WHEN A USER CLICKS ON THE MAPPING SETTINGS */}
                  {/* style={{ color: '#7043ff' }} */}
                  The export will be done according to your <span>Mapping Setting</span>.
                </span>
              </div>
            )}
          </Content>
          <Footer className={styles.footerStyle}>
            <div className={styles.section}>
              <div
                className={styles.back}
                onClick={() => {
                  !isLoading && onBack()
                }}>
                <img src={left_arrow} alt='' />
                <span>Back</span>
              </div>

              <div className={styles.centeredText}>
                <Switch
                  style={{ marginRight: '5px' }}
                  onChange={onChangeOverwriteToggle}
                  checked={overwriteToggle}
                  size={'small'}
                  disabled={isLoading}
                />
                <span>Overwrite Populated Fields is {overwriteToggle ? 'ON' : 'OFF'}</span>
                <Tooltip
                  className='tooltipHubspotNewDeal'
                  overlayInnerStyle={{
                    color: 'black',
                    backgroundColor: 'white',
                  }}
                  title='By default, Convrt will not overwrite existing data in populated fields when enriching HubSpot records. It is possible to overwrite populated fields with Convrt data by turning this toggle ON.'>
                  <img src={info_icon} alt='' />
                </Tooltip>
              </div>

              <Button
                className={styles.button}
                onClick={() => {
                  post('/log', {
                    action: ' Export Hubspot Companies',
                    quantity: companyIds?.length,
                  })
                  !isLoading && handleCompanyExportHubSpot()
                }}>
                Export
              </Button>
            </div>
          </Footer>
        </Layout>
      </Space>
    </>
  )
}
export default HubspotExportCompanies

import { useEffect, useState } from 'react'

import { JobTitles } from 'LEGACY/common/static-data/job-title-filters'
import { techStackTools } from 'LEGACY/common/static-data/tech-stack-filters'

import OptionsTree from '../../OptionsTree/OptionsTree'
import { useAppSelector } from 'state'

import './TechSideFilter.scss'
const TechSideFilter = () => {
  const { techSidebarOpenType } = useAppSelector((state) => state.filters)

  const [baseTreeData, setBaseTreeData] = useState<any[]>()
  const [parentKey, setParentKey] = useState('')
  useEffect(() => {
    setBaseTreeData(
      {
        techstacktools: techStackTools,
        jobTitle: JobTitles,
      }?.[techSidebarOpenType],
    )

    setParentKey(
      {
        techstacktools: 'techStack',
        jobTitle: 'jobTitle',
      }?.[techSidebarOpenType] || '',
    )
  }, [])

  const isJobTitle = {
    techstacktools: false,
    jobTitle: true,
  }?.[techSidebarOpenType]

  const pageTitle =
    {
      techstacktools: 'Category',
      jobTitle: 'Job Titles Search',
    }?.[techSidebarOpenType] || ''

  return (
    <div className='tech-stack-container'>
      <div className='tech-stack-container__category'>
        <h1>{pageTitle}</h1>
        <div className='tech-stack-container__category__divider' />
        <OptionsTree
          className='px-3'
          treeData={baseTreeData || []}
          isSearchable
          showJustOnSearch={isJobTitle}
          groupKey={parentKey}
        />
      </div>
    </div>
  )
}

export default TechSideFilter

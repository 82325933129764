import React, { useEffect, useState } from 'react'

import type { MenuProps } from 'antd'
import { Menu } from 'antd'

import logo from 'common/assets/svg/logo.svg'
import convrt from 'common/assets/svg/convrt.svg'
import { useAppDispatch } from 'state'
import { setReset } from 'state/filtersSlice/filtersSlice'

import { MenuServices } from 'common/services/menu-services'
import { useNavigate } from 'react-router-dom'
import styles from './Logo.module.scss'
import ExitModal from 'common/components/Campaigns/Campaign/UI/ExitModal'
import { useAppSelector } from 'state'
import { useLocalStorage } from 'usehooks-ts'

interface ILogo {
  collapsed: boolean
}

const Logo: React.FC<ILogo> = ({ collapsed }) => {
  const dispatch = useAppDispatch()
  const [localUser] = useLocalStorage<any>('user', null)
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)
  const [blockNavigation, setBlockNavigation] = useState(false)

  type MenuItem = Required<MenuProps>['items'][number]
  const [openExitModal, setOpenExitModal] = useState(false)
  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const [navigateTo, setNavigateTo] = useState('')
  const items: MenuItem[] = [
    MenuServices.getItem(
      !collapsed ? <img src={convrt} alt={''} className='convrt' /> : '',
      'home',
      <img src={logo} alt={''} />,
      blockNavigation,
    ),
  ]

  const handleNavigation = () => {
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo('/outreach')
    } else {
      dispatch(setReset(true))
      navigate('/outreach')
    }

    const refreshAfterNavigation = ['/checkout/success']
    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate('/outreach')
    }
  }

  useEffect(() => {
    if (localUser) {
      const { requires_onboarding, onboarding_status } = localUser?.addons
      if ((requires_onboarding && !onboarding_status) || isSubscriptionDaysPassed) {
        setBlockNavigation(true)
      } else {
        setBlockNavigation(false)
      }
    }
  }, [localUser])

  return (
    <div className='logoParent'>
      <Menu
        onClick={handleNavigation}
        theme='dark'
        defaultSelectedKeys={[]}
        mode='inline'
        items={items}
        className={styles.logoMenu}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </div>
  )
}

export default Logo

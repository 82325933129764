// import { useAuth } from 'common/hooks/useAuth.hooks'
import styles from './OnBoardingPaymentSuccessfulyPage.module.scss'

const videoSrc = require('LEGACY/Assets/mp4/giphy.mp4') as any

const OnBoardingPaymentSuccessfulyPage = () => {
  // const { userDetails } = useAuth()
  // const userType = userDetails.subscription_type
  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Woop! You’re on your way to become a sales master!'}</p>
        <section>
          Follow the next steps to setup your account
          <br />
        </section>
      </div>
      <div className={styles.animation}>
        <video src={videoSrc} autoPlay loop muted />
      </div>
    </div>
  )
}

export default OnBoardingPaymentSuccessfulyPage

import { useAuth } from 'common/hooks/useAuth.hooks'
import Success from '../../../General/SuccessAnimation/Success'

const FinishPage = () => {
  const { userDetails } = useAuth()
  const userType = userDetails?.subscription_type
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '70%',
        justifyContent: 'center',
        flexGrow: '1',
      }}>
      <Success />
      <h2>{`Your Telegram Account Has Been Connected Successfully!`}</h2>
      <div>
        {userType === 'BETA' && (
          <span style={{ fontWeight: 600 }}>
            You can now Outreach to prospects via Convrt!
            <br />
          </span>
        )}
        <span>
          Please note that as there's a lot of data to import and analyze, it may take a few hours to complete, so
          please bear with us.
        </span>
      </div>
    </div>
  )
}

export default FinishPage

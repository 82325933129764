import { createSlice } from '@reduxjs/toolkit'
import { ICompaniesSlice } from 'LEGACY/Research/intent-signals/state/interface/ICompaniesSlice'

const initialState = {
  status: '',
  companies: { rows: [], totalNumber: 0, displayAll: true },
  selected_companies: [],
  selected_companies_details: [],
  company: null,
  tagCompanies: null,
  filter: {},
  key: '',
  error: false,
  company_lists: [],
  save_filter_properties: {
    open_modal: false,
    filter_name: null,
    filters: null,
    filter_id: null,
    open_from: '',
    isSaved: false,
  },
  last_action_page: 1,
  is_filter_changed: false,
  company_selected_rows: [],
  delete_company_selected_rows: false,
  global_company_selected: [],
  apply_filters_key: 0,
  lists_loading: false,
} as ICompaniesSlice

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    SET_COMPANIES(state, action) {
      state.status = 'succeeded'
      state.companies = action.payload
    },
    SET_TAG_COMPANIES(state, action) {
      state.status = 'succeeded'
      state.tagCompanies = action.payload
    },
    SET_SELECTED_COMPANIES(state, action) {
      state.status = 'succeeded'
      state.selected_companies = action.payload
    },
    SET_SELECTED_COMPANIES_DETAILS(state, action) {
      state.status = 'succeeded'
      state.selected_companies_details = action.payload
    },
    SET_COMPANY(state, action) {
      state.status = 'succeeded'
      state.company = action.payload.content
    },
    SET_FILTER(state, action) {
      state.status = 'succeeded'
      state.filter = action.payload
    },
    SET_KEY(state, action) {
      state.status = 'succeeded'
      state.key = action.payload
    },
    SET_ERROR(state, action) {
      state.status = 'succeeded'
      state.error = action.payload
    },
    SET_LISTS(state, action) {
      state.status = 'succeeded'
      state.company_lists = action.payload
    },
    SET_SAVE_FILTER_MODAL(state, action) {
      state.status = 'succeeded'
      state.save_filter_properties = action.payload
    },
    SET_LAST_ACTION_PAGE(state, action) {
      state.status = 'succeeded'
      state.last_action_page = action.payload
    },
    SET_IS_FILTER_CHANGED(state, action) {
      state.status = 'succeeded'
      state.is_filter_changed = action.payload
    },
    SET_COMPANY_SELECTED_ROWS(state, action) {
      state.status = 'succeeded'
      state.company_selected_rows = action.payload
    },
    SET_DELETE_COMPANY_SELECTED_ROWS(state, action) {
      state.status = 'succeeded'
      state.delete_company_selected_rows = action.payload
    },
    SET_GLOBAL_COMPANY_SELECTED(state, action) {
      state.status = 'succeeded'
      state.global_company_selected = action.payload
    },
    SET_APPLY_FILTERS_KEY(state, action) {
      state.status = 'succeeded'
      state.apply_filters_key = action.payload
    },
    SET_LISTS_LOADING(state, action) {
      state.status = 'succeeded'
      state.lists_loading = action.payload
    },
  },
})

export const {
  SET_COMPANIES,
  SET_TAG_COMPANIES,
  SET_SELECTED_COMPANIES,
  SET_SELECTED_COMPANIES_DETAILS,
  SET_COMPANY,
  SET_FILTER,
  SET_KEY,
  SET_ERROR,
  SET_LISTS,
  SET_SAVE_FILTER_MODAL,
  SET_LAST_ACTION_PAGE,
  SET_IS_FILTER_CHANGED,
  SET_COMPANY_SELECTED_ROWS,
  SET_DELETE_COMPANY_SELECTED_ROWS,
  SET_GLOBAL_COMPANY_SELECTED,
  SET_APPLY_FILTERS_KEY,
  SET_LISTS_LOADING,
} = companiesSlice.actions

export const companiesReducer = companiesSlice.reducer

import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Card, message } from 'antd'
import styles from './GettingStarted.module.scss'
import { StepCard } from './StepCard'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useLazyUpdateUserProgressQuery } from 'common/APIs/UserApi'
import { useLocalStorage } from 'usehooks-ts'
import { useLazyGetUserInfoQuery } from 'common/APIs/UserApi'

const { Title, Paragraph } = Typography

export const GettingStarted: React.FC = () => {
  const [user, setUser] = useLocalStorage<any>('user', null)
  const navigate = useNavigate()
  const { startTour } = useIntercom()
  const [updateUser] = useLazyUpdateUserProgressQuery()
  const [progress, setProgress] = useState(user?.progress || {})
  const [getUserInfo] = useLazyGetUserInfoQuery()
  const [hasCampaigns, setHasCampaigns] = useState(false)
  const [latestCampaignId, setLatestCampaignId] = useState('')

  const isAllComplete = (updatedProgress: Record<string, boolean>) => {
    const tasks = ['create_campaign', 'collect_leads', 'setup_categorizer']
    return tasks.every((task) => updatedProgress[task])
  }

  // Helper function to update user progress
  const updateProgress = async (feature: string, isComplete: boolean) => {
    try {
      const updatedGettingStarted = {
        ...progress.getting_started,
        [feature]: isComplete,
      }

      const completed = isAllComplete(updatedGettingStarted)

      const updatedProgress = {
        ...progress,
        getting_started: {
          ...updatedGettingStarted,
          ...(completed ? { completed: true } : { completed: false }),
        },
      }

      setProgress(updatedProgress)

      // Save progress to the backend
      const response = await updateUser({ feature: 'getting_started', updates: updatedProgress.getting_started })
      if (!response?.data?.success) {
        throw new Error('Failed to update progress.')
      }

      // Update local user object
      setUser((prevUser: any) => ({
        ...prevUser,
        progress: updatedProgress,
      }))
    } catch (error) {
      console.error('Error updating progress:', error)
      message.error('Failed to update progress. Please try again.')
    }
  }

  // Handlers for each task
  const handleCreateCampaign = () => {
    updateProgress('create_campaign', true)
    if (!hasCampaigns) {
      navigate('/outreach')
    } else {
      navigate('/outreach/create-new-campaign')
    }
    startTour(581488)
  }

  const handleCollectLeads = () => {
    if (!hasCampaigns) {
      return message.error('Please create a campaign first.')
    }
    updateProgress('collect_leads', true)
    navigate(`/outreach/create-new-campaign?campaign_id=${latestCampaignId}`)
    // startTour(581449)
  }

  const handleSetupCategorizer = () => {
    updateProgress('setup_categorizer', true)
    navigate('/settings/convrt-ai/categorizer')
    startTour(581483)
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await getUserInfo()
        if (data?.hasCampaigns) {
          setHasCampaigns(true)
        }
        if (data?.latestCampaignId) {
          setLatestCampaignId(data.latestCampaignId)
        }
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    }

    fetchUserInfo()
  }, [])

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
          <Title style={{ margin: 0, lineHeight: 1 }}>Start reaching your leads in minutes</Title>
          <Paragraph className={styles.subtitle}>Follow our quick guide to get started</Paragraph>
          <Row gutter={[24, 24]} style={{ height: '100%', maxWidth: 1200 }}>
            <Col xs={24} md={8}>
              <StepCard
                title='Create a Campaign'
                description='Create a fully automated sequence to engage, reach, and nurture your leads.'
                buttonText='Create Campaign'
                onClick={handleCreateCampaign}
                imageSrc={'https://storage.googleapis.com/convrt-fe-preprod/getting_started_card_1.svg'}
                isComplete={progress?.getting_started?.create_campaign || false}
              />
            </Col>

            <Col xs={24} md={8}>
              <StepCard
                title='Collect Leads'
                description='Use Convrt to collect leads from social networks and discover the best communities, pages, and searches to target for optimal results.'
                buttonText='Collect Leads'
                onClick={handleCollectLeads}
                imageSrc={'https://storage.googleapis.com/convrt-fe-preprod/getting_started_card_2.svg'}
                isComplete={progress?.getting_started?.collect_leads || false}
              />
            </Col>

            <Col xs={24} md={8}>
              <StepCard
                title='Setup your AI Categorizer Agent'
                description='Define your response categories to enable the AI responder to reply appropriately and with precision.'
                buttonText='Setup Categorizer'
                onClick={handleSetupCategorizer}
                imageSrc={'https://storage.googleapis.com/convrt-fe-preprod/getting_started_card_3.svg'}
                isComplete={progress?.getting_started?.setup_categorizer || false}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  )
}

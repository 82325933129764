import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Input, Modal, message, Alert, Space } from 'antd'
import FancyDrawer from 'LEGACY/General/fancy-drawer/fancy-drawer'
import FancyTable from 'LEGACY/General/fancy-table/fancy-table'
import CompanyDetails from 'LEGACY/Research/intent-signals/components/company-details/company-details'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'
import { useAppDispatch, useAppSelector } from 'state'
import { useLazyGetLoginHubSpotQuery } from 'LEGACY/API/SettingsApi'
import classnames from 'classnames'
import { setSidebarOpen } from 'state/filtersSlice/filtersSlice'
import { injectionRegex } from 'LEGACY/common/static-data/userData'
import { MESSAGE_ERROR } from 'LEGACY/common/constants/messages.constants'
import { useGetForContactsListMutation } from 'LEGACY/API/ContactsApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { get } from 'LEGACY/API/axios'
import OutOfCreditsModal from 'LEGACY/General/OutOfCredits/OutOfCreditsModal'
import AreYouSureModal from 'LEGACY/General/AreYouSureModal'
import { useLazyGetUserDetailsQuery } from 'LEGACY/API/OutreachApi'
import {
  useLazyUpdateListQuery,
  useGetCompanyMutation,
  useLazyAddNewListQuery,
  useLazyGetCompaniesTelegramContactsQuery,
  useLazyGetListsQuery,
} from 'LEGACY/API/CompaniesApi'
import ErrorMessage from 'LEGACY/Research/intent-signals/components/ErrorMessage'
import { SET_LISTS, SET_SELECTED_COMPANIES, SET_LAST_ACTION_PAGE } from 'LEGACY/MyContacts/state/slice/companiesSlice'
import { companyTableColumn } from './constants/companyTable'
import { ContactTableAction } from './components/ContactTableAction'
import { isArray } from 'lodash'
import { SET_HUBSPOT_DEALS } from 'LEGACY/Research/contacts/state/slice/contactsSlice'
import { FancyImage } from 'LEGACY/Research/contacts/components/FancyImage/FancyImage'

const CompanyContact = ({
  companies,
  isLoading,
  companyErrors,
  tableParams,
  handleChangeTableParams,
  currentList,
  refetchGetContacts,
}: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userDetails } = useAuth()
  const { selectedId } = useParams()
  const { search, pathname } = useLocation()

  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [userData, setUserData] = useState(userDetails)

  const [showDetails, setShowDetails] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { selected_companies, company_lists, company, lists } = useAppSelector((state) => state.myContactsCompanies)
  const [companyDetails, setCompanyDetails] = useState<any>(null)
  const [selectedList, setSelectedList] = useState<string>('')

  const [isErrorModalOpen, setIsModalErrorModalOpen] = useState(false)
  const [addToListModal, setAddToListModal] = useState(false)
  const [addToListInput, setAddToListInput] = useState(false)

  const [refetchAddList] = useLazyAddNewListQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchUpdateList] = useLazyUpdateListQuery()
  const [refetchGetCompaniesTelegramContactsQuery] = useLazyGetCompaniesTelegramContactsQuery()

  const [openOutOfCreditsModal, setOpenOutOfCreditsModal] = useState(false)
  const [openLoadingErrorModal, setOpenLoadingErrorModal] = useState(false)

  const [refetchGetCompany, { data: details, isLoading: isLoadingCompany, error: selectedCompanyError }] =
    useGetCompanyMutation()

  const [refetchGetForContactsList] = useGetForContactsListMutation()

  const companiesRows = useMemo(() => companies?.rows, [companies])

  const handleSelectedRows = (rows: any, keys: any) => {
    dispatch(SET_SELECTED_COMPANIES(keys))
  }

  const handleUnSelectRows = (rows: any) => {
    let arrSelectedCompanies = selected_companies
    for (let i = 0; i < rows.length; i++) {
      arrSelectedCompanies = arrSelectedCompanies.filter((item) => item !== rows[i]?.id)
    }
    dispatch(SET_SELECTED_COMPANIES(arrSelectedCompanies))
  }

  const handleRemoveRow = (key: string) => {
    let arr = selected_companies.filter((e) => e !== key)
    dispatch(SET_SELECTED_COMPANIES(arr))
  }

  const handleAddNewItem = async (event: any) => {
    if (13 === event.keyCode) {
      if (injectionRegex.test(event.target.value)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = company_lists.find((item: any) => {
          return item.name === event.target.value
        })
        if (!checkItem) {
          let result = await refetchAddList({ name: event.target.value, type: 1 })
          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              let getLists = await refetchLists()
              if (getLists) dispatch(SET_LISTS(getLists.data))
              message.success(`The list, '${event.target.value}' was created successfully`)
              setAddToListInput(!addToListInput)
            }
          }
        } else {
          message.success(`We already have a list with the same name!`)
        }
      }
    }
  }

  const handleAddItemsToList = async () => {
    const list = company_lists?.filter((item: any) => item.id === selectedList)

    if (list.length > 0) {
      let com_assignedItems = list[0].company_ids ? list[0].company_ids : ''
      selected_companies.forEach((item) => {
        if (com_assignedItems.indexOf(item) === -1) com_assignedItems += item + ','
      })
      await refetchUpdateList({
        id: selectedList,
        items_list: com_assignedItems,
        field_name: 'company_ids',
        page_type: 'company',
      })

      let ids = ''
      selected_companies?.forEach((item: any) => {
        if (item && ids.indexOf(item) === -1) {
          ids += item + ','
        }
      })

      const result = await refetchGetForContactsList({
        company_ids: ids,
      })

      let assignedItems = list[0].contact_ids ? list[0].contact_ids : ''

      for (let index = 0; index < result?.data?.rows?.length; index++) {
        if (assignedItems.indexOf(result?.data?.rows[index]?.index) === -1)
          assignedItems += result?.data?.rows[index]?.index + ','
      }

      await refetchUpdateList({ id: selectedList, items_list: assignedItems, field_name: 'contact_ids' })
    }

    let getLists = await refetchLists()
    if (getLists) dispatch(SET_LISTS(getLists.data))
    message.success(`Items added to the list successfully`)
    setAddToListModal(false)
    dispatch(SET_SELECTED_COMPANIES([]))
  }

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  const handleSelectFifty = () => {
    const newCompanyIds = [...selected_companies, ...companies.company_ids]
    const uniqueCompanyIds = Array.from(new Set(newCompanyIds))
    console.log({ companies })

    dispatch(SET_SELECTED_COMPANIES(uniqueCompanyIds))
  }

  const handleSelectAllData = () => {
    if (userData.addons.allow_select_all_data) {
      const newCompanyIds = [...selected_companies, ...companies.allCompanyIdsData]
      const uniqueCompanyIds = Array.from(new Set(newCompanyIds))
      dispatch(SET_SELECTED_COMPANIES(uniqueCompanyIds))
    } else {
      message.warning('Your plan does not support selecting all data.')
    }
  }

  const isRowSelected = isArray(selected_companies) && selected_companies?.length > 0

  useEffect(() => {
    if (localStorage.getItem('fetchDeals') === 'true') {
      const fetchHubspotDealData = async () => {
        const fetchedDeals = await get('/outreach/getHubspotAllDeals')
        dispatch(SET_HUBSPOT_DEALS(fetchedDeals.data.response))
      }
      const tokenHubSpot = localStorage.getItem('hubspot_access_token')
      if (tokenHubSpot && tokenHubSpot !== 'null') {
        window.onload = () => {
          fetchHubspotDealData().catch(console.error)
          localStorage.setItem('fetchDeals', 'false')
        }
      }
    }
  }, [])

  useEffect(() => {
    if (company) {
      setCompanyDetails(company)
    } else if (!company && details) {
      setCompanyDetails(details)
    }
  }, [company, details])

  useEffect(() => {
    const getTelegram = async () => {
      await refetchGetCompaniesTelegramContactsQuery()
    }

    getTelegram()
  }, [])

  useEffect(() => {
    dispatch(SET_LAST_ACTION_PAGE(tableParams.current))
  }, [tableParams.current])

  useEffect(() => {
    if (selectedCompanyError?.status !== 470) return

    setShowDetails(false)
    setIsModalErrorModalOpen(true)
  }, [selectedCompanyError])

  useEffect(() => {
    const fetchData = async () => {
      setShowDetails(true)
      await refetchGetCompany(selectedId)
    }
    if (selectedId) {
      fetchData()
    }
  }, [selectedId])

  useEffect(() => {
    if (companyErrors) {
      // console.log(companyErrors)
      if (companyErrors.status === 504 || companyErrors.status === 'FETCH_ERROR') {
        setOpenLoadingErrorModal(true)
      } else {
        setOpenOutOfCreditsModal(true)
      }
    }
  }, [companyErrors])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }
    getUserDetails()
  }, [])

  useEffect(() => {
    dispatch(setSidebarOpen(false))
  }, [])

  return (
    <>
      <div className='flex flex-column flex-1'>
        <div className='flex-1'>
          {isRowSelected && (
            <div style={{ padding: '24px 24px 0 24px' }}>
              <ContactTableAction
                lists={lists}
                selectedContacts={[]}
                selectedCompaniesObject={companiesRows?.filter((contact: any) =>
                  selected_companies.includes(contact?.id),
                )}
                isCompany={true}
                currentList={currentList}
                refetchData={refetchGetContacts}
                onDissmiss={() => {
                  dispatch(SET_SELECTED_COMPANIES([]))
                }}
              />
            </div>
          )}

          <FancyTable
            preserveSelectedRowKeys
            loading={isLoading}
            page_type={'company'}
            displayAll={companies?.displayAll}
            totalItems={companies?.totalNumber}
            columns={companyTableColumn}
            totalDataNumber={companies?.totalDataNumber}
            allIdsData={companies?.allCompanyIdsData}
            data={companiesRows}
            handleShowDetails={async (id) => {
              navigate(`${pathname}/${id}${search}`)
            }}
            key={'id'}
            tableParams={{ ...tableParams, total: companies?.totalDataNumber }}
            setTableParams={handleChangeTableParams}
            handleSelectedRows={handleSelectedRows}
            handleUnSelectRows={handleUnSelectRows}
            selected_items={selected_companies}
            handleRemoveRow={handleRemoveRow}
            hasBanner={false}
            handleSelectFifty={handleSelectFifty}
            handleSelectAllData={handleSelectAllData}
            className={classnames('my_contact_table', {
              'with-tableAction': isRowSelected,
              'no-data': companiesRows?.length === 0,
            })}
            isFromOutreach={true}
          />
        </div>

        <Modal
          open={isErrorModalOpen}
          onCancel={() => {
            setIsModalErrorModalOpen(false)
          }}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <ErrorMessage
              caption={`Wow, you're really crushing it!`}
              subCaption={`Our system noticed you've hit the limit of searches your plan can take you. Please contact our sales team.`}
            />
            {/* {userDetails.subscription_type === 'PREMIUM' ? <ErrorMessage /> : <IntentSignalsPermanentPaywall />} */}
          </div>
        </Modal>
        <FancyDrawer
          onClose={() => {
            setShowDetails(false)
            navigate(-1)
          }}
          open={showDetails}
          customClass={'companyDetails'}
          title={
            !isLoadingCompany && (
              <div className={'drawerTitle'}>
                <FancyImage url={companyDetails?.s3_logo} iscompany={true} name={companyDetails?.name} />
                <p>
                  {companyDetails?.name}
                  <a href={companyDetails?.url} target='_blanck'>
                    {companyDetails?.url}
                  </a>
                </p>
              </div>
            )
          }>
          {!isLoadingCompany ? (
            <CompanyDetails
              data={companyDetails}
              toggleHide={(value: any) => {
                setShowDetails(value)
              }}
            />
          ) : (
            <LoadingBox />
          )}
        </FancyDrawer>
      </div>

      <Modal
        className='modalErrorMessage'
        title='Choose export destination'
        open={showErrorMessage}
        onCancel={() => {
          setShowErrorMessage(false)
          setErrorMessage('')
        }}
        footer={null}>
        <p>{errorMessage}</p>
      </Modal>
      <Modal
        title='Add to List:'
        className='listModal'
        centered
        open={addToListModal}
        footer={
          <Button
            className='align-items-center gap-2 addToList'
            onClick={handleAddItemsToList}
            disabled={!selectedList}>
            Add to List
          </Button>
        }
        onCancel={() => setAddToListModal(false)}>
        <>
          {company_lists?.map((item: any, index: any) => (
            <div className='rowListItem' key={index}>
              <span
                className={classnames('listitem', selectedList === item.id ? 'selectedList' : '')}
                onClick={() => {
                  setSelectedList(item.id)
                }}>
                {item.name}
              </span>
            </div>
          ))}
          {addToListInput && (
            <div className='addNewContainer'>
              <Input placeholder='' className='addNewInput' onKeyDown={handleAddNewItem} />
            </div>
          )}
          <span
            className='createANewItem'
            onClick={() => {
              setAddToListInput(!addToListInput)
            }}>
            + Create New
          </span>
        </>
      </Modal>
      {openOutOfCreditsModal && (
        <OutOfCreditsModal
          creditType='Company Filter'
          open={openOutOfCreditsModal}
          onClose={() => {
            setOpenOutOfCreditsModal(false)
            navigate('/exposedContacts')
          }}
          location={'companies'}
        />
      )}
      {showAlert && (
        <Alert
          style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
          message='Enrich HubSpot with Convrt'
          description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
          type='info'
          action={
            <Space direction='vertical'>
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  setShowAlert(false)
                  handleLoginHubSpot()
                }}>
                Connect
              </Button>
            </Space>
          }
          closable
          onClose={() => setShowAlert(false)}
        />
      )}
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
        />
      )}
      {openLoadingErrorModal && (
        <AreYouSureModal
          open={openLoadingErrorModal}
          onClose={() => {
            setOpenLoadingErrorModal(false)
          }}
          title='Oops! It seems like this is taking longer than expected.'
          message="Please try refreshing the page or consider adjusting your filters for a smoother experience. If the issue persists, we're here to help!"
          onConfirm={() => {
            window.location.reload()
          }}
          widthStyle={600}
          confirmText='Refresh'
        />
      )}
    </>
  )
}

export default CompanyContact

import React, { useEffect, useState } from 'react'
import styles from './OutreachAndChannels.module.scss'
import SocialMediaCard from './SocialMediaCard'
import SocialMediaAccounts from './SocialMediaAccounts'
import { Select } from 'antd'
import CustomCollapseFooter from '../UI/CustomCollapseFooter'
import { useLocalStorage } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from 'state'
import {
  RESET_CAMPAIGN_AI_CUSTOM,
  SET_ACTIVE_PLATFORMS,
  SET_NAVIGATE_TO_BUILDER,
  SET_OPEN_TEMPLATES,
  SET_SELECTED_PLATFORMS_ACCOUNTS,
  SET_SELECT_CUSTOM_TEMPLATE,
  SET_SETUP_METHOD,
} from '../../state/outreachAICampaignSlice'
import AreYouSureModal from 'common/components/GeneralComponents/Modals/AreYouSureModal'
import {
  getFacebookAccounts,
  getInstagramAccounts,
  getLinkedinAccounts,
  getTelegramAccounts,
} from 'common/utils/userHelpers'
import { RESET_BUILDER } from '../../Builder/Slice/CampaignBuilderSlice'
import { useLazyUpdateCampaignDataQuery } from '../../../../APIs/OutreachAI-API'
import { trackEvent } from 'eventConfig'

interface OutreachAndChannelsProps {
  onBack?: () => void
  onNext: () => void
}
const { Option } = Select

const OutreachAndChannels: React.FC<OutreachAndChannelsProps> = ({ onBack, onNext }) => {
  const dispatch = useAppDispatch()
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const { active_platforms, selected_platforms_accounts, campaign_id, campaign_settings_ai, selected_leads } =
    useAppSelector((state) => state.outreachAICampaign)
  const [updateCampaignData] = useLazyUpdateCampaignDataQuery()
  const [connections, setConnections] = useState(active_platforms)
  const [user] = useLocalStorage<any>('user', null)
  const isWeb3User = user?.user_type === 'web3'
  const [instagramAccounts, setInstagramAccounts] = useState(getInstagramAccounts(user))
  const [linkedinAccounts, setLinkedinAccounts] = useState(getLinkedinAccounts(user))
  const [facebookAccounts, setFacebookAccounts] = useState(getFacebookAccounts(user))
  const [telegramAccounts, setTelegramAccounts] = useState(getTelegramAccounts(user))
  const [checkLocalStorage, setCheckLocalStorage] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState('')
  const [userMetadata] = useLocalStorage<any>('user_metadata', {})

  const toggleConnection = (platform: any) => {
    setSelectedPlatform(platform)

    // const atLeastOneTrue = Object.values(active_platforms).some((value) => value === true) && prompt?.id //&& !generate_messages

    if (selected_leads?.length === 0) {
      // if (!use_custom_flow && !atLeastOneTrue) {
      trackEvent('PERCENTAGE_OF_OUTREACH_ACCURACY', { platform })
      setConnections((prev: any) => {
        if (user?.addons?.multi_channel_campaign === true) {
          dispatch(SET_ACTIVE_PLATFORMS({ ...prev, [platform]: !prev[platform] }))
          return { ...prev, [platform]: !prev[platform] }
        } else {
          const allFalsePlatforms = Object.fromEntries(Object.keys(prev).map((key) => [key, false]))

          dispatch(SET_ACTIVE_PLATFORMS({ ...allFalsePlatforms, [platform]: !prev[platform] }))
          return { ...allFalsePlatforms, [platform]: !prev[platform] }
        }
      })
    } else if (selected_platforms_accounts[platform]?.length === 0) {
      setOpenAreYouSureModal(true)
    }
  }

  const platformAccounts: any = {
    facebook: facebookAccounts.length,
    instagram: instagramAccounts.length,
    telegram: telegramAccounts.length,
    linkedin: linkedinAccounts?.length,
    discord: 0,
    twitter: 0,
  }

  const generateSelectOptions: any = (platform: string) => {
    let options = []
    const platforms: any = {
      instagram: instagramAccounts,
      facebook: facebookAccounts,
      linkedin: linkedinAccounts,
      telegram: telegramAccounts,
    }
    for (let i = 0; i < platformAccounts[platform]; i++) {
      if (platform === 'facebook') {
        const inMetadata = userMetadata?.facebook?.find(
          (metadata: any) => metadata.username.toLowerCase() === platforms[platform][i].toLowerCase(),
        )
        options.push(
          <Option key={platforms[platform][i]} value={platforms[platform][i]}>
            {platforms[platform][i]} {inMetadata && `(${inMetadata.contact_name})`}
          </Option>,
        )
      } else {
        options.push(
          <Option key={platforms[platform][i]} value={platforms[platform][i]}>
            {platforms[platform][i]}
          </Option>,
        )
      }
    }
    return options
  }

  const clearNonMatchingPlatforms = (data: { [key: string]: string[] }, platform: string) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = key === platform ? data[key] : []
      return acc
    }, {} as { [key: string]: string[] })
  }

  useEffect(() => {
    setConnections(active_platforms)
    if (selectedPlatform) {
      const updatedSelectedPlatformsAccounts = clearNonMatchingPlatforms(selected_platforms_accounts, selectedPlatform)
      dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(updatedSelectedPlatformsAccounts))
    }
  }, [active_platforms])

  useEffect(() => {
    setInstagramAccounts(getInstagramAccounts(user))
    setLinkedinAccounts(getLinkedinAccounts(user))
    setFacebookAccounts(getFacebookAccounts(user))
    setTelegramAccounts(getTelegramAccounts(user))
  }, [checkLocalStorage, user])

  const handleConfirmModal = async () => {
    dispatch(RESET_CAMPAIGN_AI_CUSTOM({ excludeFields: ['campaign_id', 'ai_campaign_name'] }))
    dispatch(RESET_BUILDER())
    dispatch(SET_SELECT_CUSTOM_TEMPLATE(null))
    dispatch(SET_OPEN_TEMPLATES(false))
    dispatch(SET_NAVIGATE_TO_BUILDER(false))
    dispatch(SET_SETUP_METHOD(''))
    setOpenAreYouSureModal(false)

    const allFalsePlatforms = Object.fromEntries(Object.keys(active_platforms).map((key) => [key, false]))

    const updatedActivePlatforms =
      user?.addons?.multi_channel_campaign === true
        ? { ...active_platforms, [selectedPlatform]: !active_platforms[selectedPlatform] }
        : { ...allFalsePlatforms, [selectedPlatform]: !active_platforms[selectedPlatform] }

    const currentSettings = {
      selected_platforms_accounts: null,
      prompt: '0',
      saved_prompt_params: null,
      active_platforms: updatedActivePlatforms,
      campaign_settings_ai,
      skip_prompt: false,
      are_leads_from_groups: false,
      select_custom_template: null,
      ai_leads_type: 'leads',
      setup_method: '',
      allSenders: [],
      current_collapse_key: 1,
    }
    await updateCampaignData({ campaign_id: campaign_id, currentSettings })

    trackEvent('PERCENTAGE_OF_OUTREACH_ACCURACY', { selectedPlatform })
    setConnections((prev: any) => {
      if (user?.addons?.multi_channel_campaign === true) {
        dispatch(SET_ACTIVE_PLATFORMS({ ...prev, [selectedPlatform]: !prev[selectedPlatform] }))
        return { ...prev, [selectedPlatform]: !prev[selectedPlatform] }
      } else {
        const allFalsePlatforms = Object.fromEntries(Object.keys(prev).map((key) => [key, false]))
        dispatch(SET_ACTIVE_PLATFORMS({ ...allFalsePlatforms, [selectedPlatform]: !prev[selectedPlatform] }))
        return { ...allFalsePlatforms, [selectedPlatform]: !prev[selectedPlatform] }
      }
    })
  }

  const sortPlatforms = (connections: any) => {
    const customOrder = isWeb3User ? ['telegram', 'linkedin', 'discord', 'twitter', 'instagram', 'facebook'] : null
    if (customOrder) {
      return Object.entries(connections).sort(([a], [b]) => customOrder.indexOf(a) - customOrder.indexOf(b))
    } else {
      return Object.entries(connections)
    }
  }

  return (
    <div>
      <div className={styles.top}>
        <p style={{ fontSize: '18px', color: '#091E4299', margin: 0 }}>Pick a channel to build your sequence</p>

        {/* <PercentageControl /> */}
      </div>
      <div className={styles.socialMediaCards} style={{ marginTop: '20px' }}>
        {sortPlatforms(connections).map(([platform, isConnected]) => (
          <SocialMediaCard
            key={platform}
            platform={platform}
            count={platformAccounts[platform]}
            isConnected={true}
            socialId={platform}
            toggleConnection={() => toggleConnection(platform)}
            setCheckLocalStorage={setCheckLocalStorage}
          />
        ))}
      </div>
      <div>
        {sortPlatforms(connections).map(([platform, isConnected]) =>
          isConnected ? (
            <SocialMediaAccounts
              key={platform}
              platform={platform}
              selectId={platform}
              selectOptions={generateSelectOptions(platform)}
              setCheckLocalStorage={setCheckLocalStorage}
            />
          ) : null,
        )}
      </div>
      <CustomCollapseFooter id='1' onBack={onBack} onNext={onNext} />
      <AreYouSureModal
        open={openAreYouSureModal}
        onClose={() => {
          setOpenAreYouSureModal(false)
          // setCurrentKey('2')
        }}
        onConfirm={handleConfirmModal}
        title='Changing Channel Will Erase Your Campaign Content'
        message='This will erase your campaign sequence, messages, leads, and settings.'
        confirmText={'Change Anyway'}
        cancelText={'Discard'}
      />
    </div>
  )
}

export default OutreachAndChannels

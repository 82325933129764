import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Checkbox, Radio } from 'antd'

import { SET_FILTER } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'
import { removeEmpty } from 'LEGACY/common/utils/objectToValues'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useLazyGetFundingRoundTypeQuery } from 'LEGACY/API/CompaniesApi'

import styles from './fancy-filter-expand-item-tabs-checkbox.module.scss'

type TabPosition = 'twitter' | 'discord' | 'telegram'
interface IFancyFilterExpandItemTabs {
  data: any
}
const FancyFilterExpandItemTabsCheckbox: React.FC<IFancyFilterExpandItemTabs> = ({ data }) => {
  const [mode, setMode] = useState<TabPosition>('twitter')
  const [current, setCurrent] = useState<any>(data[0])
  const [options, setOptions] = useState<any>([])
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.companies)
  const [refetchFunding] = useLazyGetFundingRoundTypeQuery()

  useEffect(() => {
    const fetchData = async () => {
      const response = await refetchFunding()
      let arrType: any = []
      response?.data?.forEach((element: any) => {
        arrType.push({ label: element.funding_type, value: element.funding_type })
      })
      setOptions(arrType)
    }
    fetchData()
  }, [])

  const handleFilter = (key: string, value: any) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    _.set(filterCopy, key, value)
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  return (
    <div className={styles.tabsContainer}>
      {data?.length > 0 && (
        <>
          <Radio.Group
            className={styles.tabsBox}
            onChange={(event) => {
              setMode(event.target.value)
              data.forEach((element: any) => {
                if (element.key.indexOf(event.target.value) > 0) setCurrent(element)
              })
            }}
            value={mode}
            style={{ marginBottom: 8 }}>
            {data?.map((item: any, idx: number) => {
              return (
                <Radio.Button
                  className='w-full flex justify-content-center'
                  value={item.key.split('.')[item.key.split('.').length - 1]}
                  key={idx}>
                  {item.label}
                </Radio.Button>
              )
            })}
          </Radio.Group>
          {current && (
            <div className='fullWidth'>
              <Checkbox.Group
                className='customFilterCheckboxes'
                options={options}
                defaultValue={['angel_deed']}
                onChange={(event) => {
                  handleFilter(current?.children[0]?.key || '', event)
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FancyFilterExpandItemTabsCheckbox

import React, { useEffect } from 'react'
import { Button } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { useIntercom } from 'react-use-intercom'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { useLocalStorage } from 'usehooks-ts'
import { SET_INTERCOM_OPEN } from '../../LearningCenter/state/slice/learningSlice'
import { useAuth } from 'common/hooks/useAuth.hooks'

import styles from './SmallLogout.module.scss'
interface SmallLogoutProps {
  //
}

const SmallLogout: React.FC<SmallLogoutProps> = () => {
  const { shutdown } = useIntercom()
  const { logout } = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userDetails } = useAuth()
  const { boot, show } = useIntercom()
  const [user] = useLocalStorage<any>('user', null)

  const bootIntercom = () => {
    boot({
      name: userDetails?.name,
      email: userDetails?.email,
    })
    show()
  }

  const handleLogout = () => {
    logout()
    shutdown()
    navigate('/login')
  }

  const handleRequestTrialExtension = () => {
    dispatch(SET_INTERCOM_OPEN(true))
    bootIntercom()
  }

  useEffect(() => {
    dispatch(SET_INTERCOM_OPEN(true))
    boot({
      name: userDetails?.name,
      email: userDetails?.email,
    })
  }, [])

  return (
    <div className={styles.smallLogoutContainer}>
      <div className={styles.buttonWrapper}>
        <Button className={styles.logoutButton} type='primary' icon={<LogoutOutlined />} onClick={handleLogout}>
          Sign out
        </Button>
        {!user?.addons?.show_trial_option &&
          window?.location?.pathname !== '/onboarding' &&
          window?.location?.pathname !== '/upgrade' && (
            <span className={styles.trialExtensionButton} onClick={handleRequestTrialExtension}>
              Request Trial Extension
            </span>
          )}
      </div>
    </div>
  )
}

export default SmallLogout

import React, { useEffect, useState } from 'react'
import styles from './SubscriptionSettings.module.scss'
import points_icon from 'common/assets/svg/points_icon.svg'
import talk_to_us from 'common/assets/svg/talk_to_us.svg'
import { Button, Card, Progress, Spin, message } from 'antd'
import { useLocalStorage } from 'usehooks-ts'
import { useNavigate } from 'react-router-dom'
import { useLazyGenerateStripeLinkQuery } from '../../../APIs/UpgradeApi'
import { LoadingOutlined } from '@ant-design/icons'
import PointsTableTooltip from './PointsTableTooltip'

interface SubscriptionSettingsProps {
  data: any
  isFetching?: any
}

const SubscriptionSettings: React.FC<SubscriptionSettingsProps> = ({ data, isFetching }) => {
  const navigate = useNavigate()
  const [generateStripeLink] = useLazyGenerateStripeLinkQuery()
  const [user] = useLocalStorage<any>('user', null)
  const [convrtPoints, setConvrtPoints] = useState(user?.convrt_points)
  const [initialConvrtPoints, setInitialConvrtPoints] = useState(user?.initial_convrt_points)
  const [magicPoints, setMagicPoints] = useState(user?.magic_points)
  const [initialMagicPoints, setInitialMagicPoints] = useState(user?.initial_magic_points)
  const [credits, setCredits] = useState(user?.credits)
  const [initialCredits, setInitialCredits] = useState(user?.initial_credits)
  const [loadingSettings, setLoadingSettings] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [loadingInvoices, setLoadingInvoices] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const customer = data?.plan_type !== 'trial' || !!data.current_period_end

  const planName =
    data?.plan_type === 'trial'
      ? 'Trial'
      : data?.plan_name
      ? `${data?.plan_name} ${data?.tier === 1 ? 'I' : data?.tier === 2 ? 'II' : data?.tier === 3 ? 'III' : ''}`
      : user?.plan || user?.plan_type || ''

  const handleManagePlan = async (type: string) => {
    if (type === 'invoices') {
      setLoadingInvoices(true)
    }

    const { data } =
      type === 'settings' ? await generateStripeLink({ settings: true }) : await generateStripeLink({ invoices: true })

    if (data?.url) {
      window.open(data.url, '_self')
    } else if (data?.not_found) {
      message.error('Your account is not associated with Stripe yet, please contact support.')
      setLoadingSettings(false)
      setLoadingInvoices(false)
      setLoadingDetails(false)
    } else {
      message.error('Something went wrong, please contact support.')
      setLoadingSettings(false)
      setLoadingInvoices(false)
      setLoadingDetails(false)
    }
  }

  const handleCancelSubscription = async () => {
    setLoadingCancel(true)

    const { data } = await generateStripeLink({ cancelSubscription: true })

    if (data?.cancel_at_period_end) {
      message.error('Your subscription is already set to be canceled at period end.')
      setLoadingCancel(false)
    } else if (data?.no_customer) {
      message.error('You do not have an active subscription yet.')
      setLoadingCancel(false)
    } else if (data?.url) {
      window.open(data.url, '_self')
    } else {
      message.error('Something went wrong. Please contact support.')
      setLoadingCancel(false)
    }
  }

  useEffect(() => {
    if (user) {
      setConvrtPoints(user?.convrt_points)
      setInitialConvrtPoints(user?.initial_convrt_points)
      setMagicPoints(user?.magic_points)
      setInitialMagicPoints(user?.initial_magic_points)
      setCredits(user?.credits)
      setInitialCredits(user?.initial_credits)
    }
  }, [user])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>Settings</span>
        <p>Profile Details</p>
      </div>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <Card className='CardNoPadding CardFullHeight' style={{ flex: '1' }}>
            <div className={styles.cardHeader}>
              <p>Billing Details</p>
            </div>
            <div className={styles.cardBody}>
              {customer && (
                <>
                  <div className={styles.section}>
                    <div className={styles.subSection}>
                      <p>Billing Details</p>
                      <span>Update your personal details</span>
                    </div>
                    <Button
                      style={{ borderColor: '#dfe1e6' }}
                      loading={loadingDetails}
                      onClick={() => {
                        setLoadingDetails(true)
                        handleManagePlan('settings')
                      }}>
                      View Details
                    </Button>
                  </div>
                  <div className={styles.section}>
                    <div className={styles.subSection}>
                      <p>Invoices</p>
                      <span>Manage your account contact email for invoice and login</span>
                    </div>
                    <Button
                      style={{ borderColor: '#dfe1e6' }}
                      loading={loadingInvoices}
                      onClick={() => handleManagePlan('invoices')}>
                      View Invoices
                    </Button>
                  </div>
                </>
              )}
              <div className={styles.section}>
                <div className={styles.subSection}>
                  <p>Plan</p>
                  <span>{planName}</span>
                </div>
                <div className={styles.subSection}>
                  <p>Company</p>
                  <span>{user?.company}</span>
                </div>
                <div className={styles.subSection}>
                  <p>Status</p>
                  {isFetching ? (
                    <div className={styles.fetching}>. . . .</div>
                  ) : data?.overdue_days > 0 ? (
                    <span style={{ color: 'red' }}>
                      {data?.overdue_days} day{data?.overdue_days === 1 ? '' : 's'} Overdue
                    </span>
                  ) : (
                    <span>{data?.current_period_end || data?.plan_type === 'trial' ? 'Active' : 'Not Active'}</span>
                  )}
                </div>
                <div className={styles.subSection}>
                  <p>Renewal Date</p>
                  {isFetching ? (
                    <div className={styles.fetching}>. . . .</div>
                  ) : (
                    <span>
                      {data?.cancel_at_period_end
                        ? `Cancels on ${new Date(data.current_period_end * 1000).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}`
                        : data?.current_period_end
                        ? `Renews on ${new Date(data.current_period_end * 1000).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}`
                        : 'N/A'}
                    </span>
                  )}
                </div>
                {customer && (
                  <div style={{ paddingBottom: '25px' }} className={styles.cancelPlan}>
                    <span onClick={handleCancelSubscription}>Cancel Plan</span>
                    {loadingCancel && (
                      <Spin indicator={<LoadingOutlined style={{ marginLeft: 8, fontSize: 14 }} spin />} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.rightSide}>
          <Card className='CardNoPadding' style={{ flex: '0.6' }}>
            <div className={styles.cardHeader}>
              <p>Your Current Plan</p>
              <div className={styles.availablePoints}>
                <span className={styles.dot}></span>
                <span>{convrtPoints?.toLocaleString()} Available Convrt Points</span>
              </div>
            </div>
            <div className={styles.currentPlan}>
              <div className={styles.title}>
                <img src={points_icon} alt='' />
                <p>{planName}</p>
                {/* <span>description...</span> */}
              </div>
              <div className={styles.progress}>
                <p>
                  Convrt Points Used <PointsTableTooltip isMagic={false} />
                </p>
                <Progress
                  className='settingsProgress'
                  percent={
                    initialConvrtPoints
                      ? ((Math.max(initialConvrtPoints, convrtPoints) - convrtPoints) /
                          Math.max(initialConvrtPoints, convrtPoints)) *
                        100
                      : 0
                  }
                  showInfo={false}
                />
                <span className={styles.progressInfo}>
                  {Math.max(initialConvrtPoints, convrtPoints) - convrtPoints} /{' '}
                  {Math.max(initialConvrtPoints, convrtPoints)}
                </span>
              </div>
              <div className={styles.progress}>
                <p>
                  Magic Points Used <PointsTableTooltip isMagic={true} />
                </p>
                <Progress
                  className='settingsProgress'
                  percent={
                    initialMagicPoints
                      ? ((Math.max(initialMagicPoints, magicPoints) - magicPoints) /
                          Math.max(initialMagicPoints, magicPoints)) *
                        100
                      : 0
                  }
                  showInfo={false}
                />
                <span className={styles.progressInfo}>
                  {Math.max(initialMagicPoints, magicPoints) - magicPoints} /{' '}
                  {Math.max(initialMagicPoints, magicPoints)}
                </span>
              </div>
              <div className={styles.progress}>
                <p>Contacts Outreached</p>
                <Progress
                  className='settingsProgress'
                  percent={
                    ((initialCredits?.contacts_outreached_left - credits?.contacts_outreached_left) /
                      initialCredits?.contacts_outreached_left) *
                    100
                  }
                  showInfo={false}
                />
                <span className={styles.progressInfo}>
                  {initialCredits?.contacts_outreached_left - credits?.contacts_outreached_left} /{' '}
                  {initialCredits?.contacts_outreached_left}
                </span>
              </div>
              <div className={styles.bottom}>
                <Button type='primary' onClick={() => navigate('/upgrade')}>
                  Upgrade Plan
                </Button>
                {customer && (
                  <Button
                    style={{ borderColor: '#dfe1e6' }}
                    loading={loadingSettings}
                    onClick={() => {
                      setLoadingSettings(true)
                      handleManagePlan('settings')
                    }}>
                    Manage your plan
                  </Button>
                )}
              </div>
            </div>
          </Card>
          <Card className='CardNoPadding ' style={{ flex: '0.4' }}>
            <div className={styles.cardHeader}>
              <p>Talk to us</p>
            </div>
            <div className={styles.talkToUs}>
              <img style={{ marginTop: '20px' }} src={talk_to_us} alt='' />
              <span className={styles.talkDescription}>
                Talk to one of our sales expert to help you
                <br />
                boost your success.
              </span>
              <Button
                style={{ borderColor: '#dfe1e6', marginBottom: '20px' }}
                onClick={() => window.open('https://calendly.com/roy-convrt/convrt-ai-cs', '_blank')}>
                Request Sales Expert
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionSettings

import { Tooltip, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { FancyImage } from 'LEGACY/Research/contacts/components/FancyImage/FancyImage'
import { IContactsTable } from 'LEGACY/Research/contacts/interfaces/contacts-table.interface'
import { TagsList } from 'LEGACY/Research/contacts/components/TagsList/TagsList'
import classNames from 'classnames'
import styles from './contactTable.module.scss'
import discord from 'LEGACY/Assets/svg/discord_logo.svg'
import twitter from 'LEGACY/Assets/svg/twitter_logo.svg'
import telegram from 'LEGACY/Assets/svg/telegram_icon.svg'
import linkedin from 'LEGACY/Assets/svg/linkedin_new.svg'
import { getApproximateHeadcount } from 'LEGACY/Research/StartScreen/utils/getApproximateAmount'
import { CommonServices } from 'LEGACY/common/services/common-services'
import { LinkOutlined } from '@ant-design/icons'

export const companyTableColumn: ColumnsType<IContactsTable> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    render: (text: string, item: any) => {
      return (
        <div className='tblLogoName'>
          <FancyImage
            className={`${styles['company_logo']} mainPicture`}
            url={item.s3_logo}
            name={text}
            style={{ filter: 'none !important' }}
          />
          <Tooltip placement='topLeft' title={text}>
            <span className={`${styles['table__text__company']} textTooltip`}>
              {text?.length > 20 ? `${text.slice(0, 20)?.trim()}...` : text}
            </span>
          </Tooltip>
        </div>
      )
    },
  },

  {
    title: 'Employees',
    dataIndex: 'number_of_employees',
    key: 'number_of_employees',
    width: 120,
    render: (text: string, item: any) => {
      return <Typography className={styles['table__text']}>{getApproximateHeadcount(text)}</Typography>
    },
  },

  {
    title: 'Channels',
    dataIndex: 'channels',
    width: 120,
    render: (text: boolean, item: any) => (
      <div className={styles.channelsContainer}>
        {item.linkedin && (
          <div className={classNames(styles.iconWrapper)}>
            <img src={linkedin} alt='' />
          </div>
        )}
        {item.telegram && (
          <div className={classNames(styles.iconWrapper)}>
            <img src={telegram} style={{ marginRight: '6px' }} alt='' />
          </div>
        )}
        {item.discord && (
          <div className={classNames(styles.iconWrapper)}>
            <img src={discord} style={{ marginRight: '6px' }} alt='' />
          </div>
        )}
        {item.twitter && (
          <div className={classNames(styles.iconWrapper)}>
            <img src={twitter} style={{ marginRight: '6px' }} alt='' />
          </div>
        )}

        <LinkOutlined
          onClick={() => item.url && window.open(item.url, '_blank')}
          style={{
            color: item.url ? '#1890FF' : 'gray',
            cursor: item.url ? 'pointer' : 'default',
          }}
        />
      </div>
    ),
  },

  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 200,
    render: (text: string, item: any) => {
      const location = item?.location ? item?.location : `${item.city || ''} ${item.state || ''}, ${item.country || ''}`
      return (
        <div className=''>
          <Tooltip placement='topLeft' title={location}>
            <span className={`${styles['table__text']} textTooltip`}>
              {location ? (location.length > 35 ? `${location.substring(0, 35)}...` : location) : 'N/A'}
            </span>
          </Tooltip>
        </div>
      )
    },
  },

  {
    title: (
      <Tooltip placement='top' title={'The sub-industry of the company.'}>
        {'Vertical'}
      </Tooltip>
    ),
    dataIndex: 'vertical',
    key: 'vertical',
    width: 120,
    render: (text: string) => (
      <div className=''>
        <Tooltip placement='topLeft' title={CommonServices.generateStringFromArr(text)}>
          <span className={`${styles['table__text']} textTooltip`}>
            {CommonServices.generateStringFromArr(text).length > 18
              ? `${CommonServices.generateStringFromArr(text).substring(0, 18).trim()}...`
              : CommonServices.generateStringFromArr(text)}
          </span>
        </Tooltip>
      </div>
    ),
  },

  {
    title: (
      <Tooltip placement='top' title={'Related tag of products and activities of the company.'}>
        {'Tags'}
      </Tooltip>
    ),
    dataIndex: 'company_tags',
    key: 'company_tags',
    width: 150,
    render: (text: string, item: any) => {
      const tagsList = item?.company_tags ? JSON.parse(item?.company_tags || {}) : []
      const tags: string[] = []
      tagsList.forEach((element: any) => {
        tags.push(element)
      })
      return (
        <div
          className='tblFieldText columnMinWidth'
          style={
            {
              // minHeight: '60px',
              // maxHeight: '60px',
              // display: 'flex',
              // alignItems: 'center',
              // flexDirection: 'column',
              // justifyContent: 'center',
            }
          }>
          {tags.length > 0 ? <TagsList tags={tags} /> : <span>{'N/A'}</span>}
        </div>
      )
    },
  },
]

import { Tooltip } from 'antd'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'common/APIs/OutreachApi'
import React, { useEffect, useState } from 'react'
import styles from './UsernameFromNumber.module.scss'

interface UsernameFromNumberProps {
  phoneNumbers: string[]
}

const UsernameFromNumber: React.FC<UsernameFromNumberProps> = ({ phoneNumbers }) => {
  const phoneNumbersArr = phoneNumbers[0]?.split(', ')
  const { userDetails } = useAuth()
  const [refetchUserDetails] = useLazyGetUserDetailsQuery()
  const [outreachAccounts, setOutreachAccounts] = useState(userDetails?.outreach || [])

  useEffect(() => {
    const getOutreachArr = async () => {
      const { data } = await refetchUserDetails()
      setOutreachAccounts(data?.outreach)
    }

    getOutreachArr()
  }, [])

  const getUsernameFromNumber = (phoneNumber: string): any => {
    const user: any = outreachAccounts?.find((u: any) => phoneNumber?.includes(u.number))
    if (user?.userName?.length > 0) {
      return (
        <Tooltip title={<span>+{phoneNumber}</span>} key={user.number}>
          <span style={{ cursor: 'pointer' }}>{user?.userName}</span>
        </Tooltip>
      )
    } else {
      return <span key={phoneNumber}>+{phoneNumber}</span>
    }
  }

  return (
    <div className={styles.container}>
      {phoneNumbersArr?.slice(0, 1).map((phoneNumber, index) => (
        <div key={index}>{getUsernameFromNumber(phoneNumber)}</div>
      ))}
      {phoneNumbersArr?.length > 1 && (
        <Tooltip
          title={
            <>
              {phoneNumbersArr?.slice(1).map((phoneNumber) => {
                const userName = getUsernameFromNumber(phoneNumber)?.key
                return (
                  <div className={styles.tooltipContainer}>
                    <span>{`+${phoneNumber}`}</span>
                    {userName !== phoneNumber && <span>{getUsernameFromNumber(phoneNumber)}</span>}
                  </div>
                )
              })}
            </>
          }>
          <span style={{ cursor: 'pointer', alignItems: 'center', fontWeight: 600 }}>
            +{phoneNumbersArr?.length - 1} More
          </span>
        </Tooltip>
      )}
    </div>
  )
}

export default UsernameFromNumber

import { post } from 'LEGACY/API/axios'
import { filterObjectToString } from 'LEGACY/common/utils/objectToValues'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'

import styles from './RecentFilters.module.scss'
import { SearchOutlined } from '@ant-design/icons'
import { CommonServices } from 'LEGACY/common/services/common-services'
import { Tooltip } from 'antd'

const RecentFilters = ({ recentFilters, handleFilter, isLoading, locationPathName }: any) => {
  const handleClick = async (filter: any, route: string) => {
    handleFilter(filter, route)
    await post(`/companies/log/recent-filter`, filter)
  }

  return (
    <div className={styles.recentFilters}>
      <p>Recently Filtered:</p>
      <div className={styles.container}>
        {!isLoading ? (
          recentFilters?.length ? (
            recentFilters.map(({ filter, route, created_at }: any, index: number, array: any[]) => (
              <div
                key={index}
                className={`${styles.section} ${
                  index === array.length - 1 && recentFilters.length > 3 ? styles.lastSection : ''
                }`}
                onClick={() => handleClick(filter, route)}>
                <Tooltip title={filterObjectToString(filter).length > 30 ? filterObjectToString(filter) : ''}>
                  <div className={styles.item}>
                    <span>
                      <span style={{ fontWeight: 600, color: 'black' }}>
                        {route === 'contacts' ? 'Contacts' : 'Companies'}:{' '}
                      </span>
                      {filterObjectToString(filter).length > 55
                        ? `${filterObjectToString(filter).slice(0, 55)}...`
                        : filterObjectToString(filter)}
                    </span>
                    <div className={styles.date}>
                      <span>{created_at ? CommonServices.convertToDate(created_at) : ''}</span>
                    </div>
                  </div>
                </Tooltip>
              </div>
            ))
          ) : (
            <div className={styles.noFilters}>
              <SearchOutlined style={{ color: '#777', fontSize: '18px' }} />
              <p>No Searches Made Yet</p>
            </div>
          )
        ) : (
          <div className={styles.loading}>
            <LoadingBox />
          </div>
        )}
      </div>
    </div>

    // <>
    //   <h4 className={styles.subtitle}>{`Recently Filtered:`}</h4>
    //   <div className={styles.recentlyContainer}>
    //     {recentFilters?.length ? (
    //       recentFilters?.map(({ filter, route }: any, index: number) => (
    //         <div onClick={() => handleClick(filter, route)} className={styles.recently}>
    //           <h5>{filterObjectToString(filter)}</h5>
    //           {index !== recentFilters?.length - 1 && <Divider />}
    //         </div>
    //       ))
    //     ) : (
    //       <h4>{'Your Recent Filters Will Appear Here'}</h4>
    //     )}
    //   </div>
    // </>
  )
}

export default RecentFilters

import React, { useEffect, useState, useRef } from 'react'
import { Modal, Input, Checkbox, Button } from 'antd'
import { SET_SAVE_FILTER_MODAL } from './state/slice/companiesSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { objectToValues, removePropertyByLeaf } from 'LEGACY/common/utils/objectToValues'
import { useLazyAddSaveFiltersQuery, useLazyUpdateSaveFiltersQuery } from '../../API/CompaniesApi'
import _ from 'lodash'

import styles from './saveFiltersModal.module.scss'

let allFilteToRemovePropertyByLeaf: any = {}

export const SaveFilledModal: React.FC = () => {
  const inputElementFilterName = useRef<any>(null)
  const [isSaveFiltersBtnDisabled, setIsSaveFiltersBtnDisabled] = useState(true)
  const [refetchAddSaveFilters] = useLazyAddSaveFiltersQuery()
  const [refetchUpdateSaveFilters] = useLazyUpdateSaveFiltersQuery()
  const dispatch = useAppDispatch()
  const { save_filter_properties } = useAppSelector((state) => state.companies)
  const [filterName, setFilterName] = useState<any>()
  const [saveActiveFilters, setSaveActiveFilters] = useState<any>(save_filter_properties.filters)
  const filterTags = objectToValues(save_filter_properties.filters)

  useEffect(() => {
    setFilterName(save_filter_properties?.filter_name)
    setSaveActiveFilters(save_filter_properties?.filters)
    allFilteToRemovePropertyByLeaf = {}
  }, [save_filter_properties.filter_name])

  let tempDataFilter = []
  for (const key in filterTags) {
    if (filterTags[key].length !== 0) {
      const tempFilterTag = filterTags[key].map((data: any) => ({
        ...data,
        caption: data.key
          .replace(/_/g, ' ')
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str: string) => str.toUpperCase()),
      }))
      tempDataFilter.push(...tempFilterTag)
    }
  }

  const handleFilterNameChange = (e: any) => {
    setFilterName(e.target.value)
    if (e.target.value !== '' && e.target.value !== ' ') {
      setIsSaveFiltersBtnDisabled(false)
    } else {
      setIsSaveFiltersBtnDisabled(true)
    }
  }

  const handleChange = (
    filterName: string,
    checked: boolean,
    isExcluded: boolean,
    title: string,
    isObject: boolean,
  ) => {
    if (inputElementFilterName.current.input.value !== '' && inputElementFilterName.current.input.value !== ' ') {
      setIsSaveFiltersBtnDisabled(false)
    } else {
      setIsSaveFiltersBtnDisabled(true)
    }
    if (checked === false) {
      allFilteToRemovePropertyByLeaf[title] = { filterName, isExcluded, title, isObject }
    } else {
      delete allFilteToRemovePropertyByLeaf[title]
    }
  }

  const showSaveSuccessPanel = () => {
    dispatch(SET_SAVE_FILTER_MODAL({ isSaved: true }))
    const timeoutID = setTimeout(() => {
      dispatch(SET_SAVE_FILTER_MODAL({ isSaved: false }))
      clearTimeout(timeoutID)
    }, 1000)
  }

  const handleConfirm = () => {
    /* eslint-disable no-param-reassign */
    let tempSaveActiveFilters = Array.isArray(saveActiveFilters)
      ? _.cloneDeep(saveActiveFilters[0])
      : _.cloneDeep(saveActiveFilters)

    /* eslint-disable no-param-reassign */
    for (let key in allFilteToRemovePropertyByLeaf) {
      let obj = allFilteToRemovePropertyByLeaf[key]

      /* eslint-disable no-param-reassign */
      tempSaveActiveFilters = handleRemoveFilters(
        obj.filterName,
        obj.isExcluded,
        obj.title,
        obj.isObject,
        tempSaveActiveFilters,
      )
    }

    if (tempSaveActiveFilters.hasOwnProperty('connectPlus')) {
      delete tempSaveActiveFilters.connectPlus
    }

    if (tempSaveActiveFilters.hasOwnProperty('circle')) {
      delete tempSaveActiveFilters.circle
    }

    setSaveActiveFilters(tempSaveActiveFilters)

    const onUpdateSaveFilters = async () => {
      const result = await refetchUpdateSaveFilters({
        filters: tempSaveActiveFilters,
        id: save_filter_properties.filter_id,
        filter_name: inputElementFilterName.current.input.value,
      })

      if (result.isSuccess) {
        showSaveSuccessPanel()
      }
    }
    const onAddSaveFilters = async () => {
      const result = await refetchAddSaveFilters({
        filters: tempSaveActiveFilters,
        filter_name: inputElementFilterName.current.input.value,
      })

      if (result.isSuccess) {
        showSaveSuccessPanel()
      }
    }

    if (save_filter_properties.open_from === 'filterBar') {
      onAddSaveFilters()
    } else {
      onUpdateSaveFilters()
    }

    onClose()
  }

  const onClose = () => {
    dispatch(SET_SAVE_FILTER_MODAL({ open_modal: false, isSaved: false }))
  }

  const handleRemoveFilters = (
    filterName: string,
    isExcluded?: boolean,
    title?: string,
    isObject?: boolean,
    tempSaveActiveFilters?: any,
  ) => {
    if (isObject) {
      for (const key in tempSaveActiveFilters) {
        if (typeof tempSaveActiveFilters[key] === 'object' && tempSaveActiveFilters[key] !== null) {
          for (const innerKey in tempSaveActiveFilters[key]) {
            if (innerKey === filterName) {
              // eslint-disable-next-line
              Object.entries(tempSaveActiveFilters[key][innerKey]).forEach((entry: any) => {
                if (entry[1].excluded === isExcluded) {
                  if (entry[1]?.title === title) {
                    // eslint-disable-next-line
                    delete tempSaveActiveFilters[key][innerKey][entry[0]]
                  }
                }
              })
            }
          }
        }
      }
      return tempSaveActiveFilters
    }

    try {
      /* eslint-disable no-param-reassign */
      const indexToRemove = tempSaveActiveFilters[filterName].indexOf(title)
      if (indexToRemove !== -1) {
        tempSaveActiveFilters[filterName].splice(indexToRemove, 1)
      }
    } catch {
      tempSaveActiveFilters = removePropertyByLeaf(tempSaveActiveFilters, filterName)
    }

    return tempSaveActiveFilters
  }

  return (
    <Modal
      className={styles.parentModal}
      zIndex={99999}
      closeIcon={false}
      open={true}
      onCancel={onClose}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ background: '#EBECF0', height: '1px', marginBottom: '10px', width: '100%' }}></div>
          <Button
            style={{ width: '150px', height: '36px' }}
            key='confirm'
            type='primary'
            onClick={handleConfirm}
            disabled={isSaveFiltersBtnDisabled}>
            {'Save Filters'}
          </Button>
        </div>,
      ]}>
      <div className='save-filter-title'>{'Save Filters'}</div>
      <div className='modalBody'>
        <Input
          placeholder='Name this filters group'
          className={styles.input}
          onChange={handleFilterNameChange}
          value={filterName || ''}
          ref={inputElementFilterName}
        />
        <div style={{ minHeight: '430px', maxHeight: '450px', overflow: 'auto' }}>
          {tempDataFilter.map((item: any, index: any) => {
            if (item.key.indexOf('nameOfCompany_') !== -1) {
              item.categories = [item.categories.join('')]
              item.caption = 'Search Bar'
            }
            if (item.key.indexOf('companyHQ') !== -1) {
              item.caption = 'Company HQ'
            }
            if (item.key.indexOf('contactsCountry') !== -1) {
              item.caption = `Prospect's Location`
            }
            return (
              <div key={item.key}>
                <div style={{ margin: '15px' }}>
                  <h3>{item.caption}</h3>
                  <div>
                    {item?.categories?.map((checkboxItem: any, index: any) => {
                      return (
                        <div key={index}>
                          <Checkbox
                            defaultChecked={true}
                            onChange={(e) =>
                              handleChange(
                                item.key,
                                e.target.checked,
                                typeof checkboxItem === 'object' ? !!checkboxItem.excluded : false,
                                typeof checkboxItem === 'object' ? checkboxItem.title : checkboxItem,
                                typeof checkboxItem === 'object',
                              )
                            }>
                            {typeof checkboxItem === 'object' ? checkboxItem.title : checkboxItem}
                          </Checkbox>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {index !== tempDataFilter.length - 1 && <div style={{ background: '#EBECF0', height: '1px' }} />}
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

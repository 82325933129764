import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'LEGACY/API/baseQueryWithValidation'

export const AdminCompanyApi: any = createApi({
  reducerPath: 'AdminCompanyApiWeb3',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getUsersList: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/company_admin/users_list`,
      }),
    }),
    deleteUser: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/company_admin/delete_user`,
        body: params,
      }),
    }),
    disableUser: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/company_admin/disable_user`,
        body: params,
      }),
    }),
  }),
})

export const { useLazyGetUsersListQuery, useLazyDeleteUserQuery, useLazyDisableUserQuery } = AdminCompanyApi

import { forEach, isEmpty, isNull, trim } from 'lodash'
import { ParseResult } from 'papaparse'

const isValidDomain = (domain: any) => {
  const trimDomain = trim(domain)
  if (isEmpty(trimDomain)) {
    return false
  }

  const domainRegex = /^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,}))$/
  return domainRegex.test(trimDomain)
}

const isValidUrl = (value: string) => {
  // eslint-disable-next-line
  const pattern =
    // eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

  return !!pattern.test(value)
}

const formatDomain = (domain: string) => {
  if (!domain) return

  if (!isValidUrl(domain)) return domain

  const newDomainWithHttp = domain.startsWith('http') ? domain : 'https://' + domain
  const parsedUrl = new URL(newDomainWithHttp)

  let hostname = parsedUrl.hostname
  return hostname.replace(/^www\./, '')
}

const calculatePointCampaign = (messages: string[], nodeId: string, optionLabels: string[], followUps: number) => {
  let score = 0
  let totalLabelCount = 0
  let scoreDetails = [
    {
      action: 'Message detected',
      points: 50,
    },
    {
      action: 'A/B testing variation.',
      points: 10,
    },
    {
      action: 'Use personalization variable.',
      points: 5,
    },
  ]

  const templates = ['A', 'B', 'C']
  const maxLength = followUps === 2 ? 500 : 300

  // Track processed unique messages
  const uniqueMessages: Set<string> = new Set()

  // Identify non-empty, unique messages
  const nonEmptyMessages = messages
    .map((msg, index) => {
      const trimmedMsg = msg?.trim()
      if (trimmedMsg && !uniqueMessages.has(trimmedMsg)) {
        uniqueMessages.add(trimmedMsg)
        return templates[index]
      }
      return null
    })
    .filter(Boolean)

  // Step 1: Base scoring for any unique, non-empty message
  if (nonEmptyMessages.length > 0) {
    score += 50 // Add base score for detecting messages
  }

  // Step 2: Additional scoring for variations (combinations)
  if (nonEmptyMessages.length > 1) {
    const variationPoints = (nonEmptyMessages.length - 1) * 10
    score += variationPoints
  }

  // Step 3: Check for personalization variables, limit points to 2 per index
  const labelCountByIndex: Record<number, number> = {}

  messages.forEach((msg, index) => {
    const trimmedMsg = msg?.trim()
    if (trimmedMsg && uniqueMessages.has(trimmedMsg)) {
      optionLabels.forEach((label) => {
        if (msg.includes(`@${label}`)) {
          if (!labelCountByIndex[index]) {
            labelCountByIndex[index] = 0
          }
          if (labelCountByIndex[index] < 2) {
            totalLabelCount++
            labelCountByIndex[index]++
          }
        }
      })
    }
  })

  if (totalLabelCount > 0) {
    const personalizationPoints = totalLabelCount * 5
    score += personalizationPoints
  }

  // Step 4: Node-specific penalties or rules
  if (nodeId === '1') {
    const urlRegex =
      /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/
    const urlMatches = messages.find((msg) => msg && msg.match(urlRegex))
    const longMgs = messages.find((msg) => msg?.trim()?.length > maxLength)
    scoreDetails.push(
      {
        action: `Link on first message.`,
        points: -50,
      },
      {
        action: `Long message.`,
        points: -50,
      },
    )
    if (urlMatches) {
      score -= 50
    }
    if (longMgs) {
      score -= 50
    }
  }

  return {
    total: score,
    details: scoreDetails,
  }
}

const validateResultPapaParse = async (result: ParseResult<any>) => {
  let formatedData: any = []
  // let validRowData: any = []
  let unvalidRowData: any = []
  let isValidAmountOfRow = true

  if (result.data) {
    const { data } = result

    //REMOVE EMPTY COLUMN AND ROW
    forEach(data, (rowData) => {
      const isEmptyRow = rowData?.every((value: any) => isNull(value) || isEmpty(value) || !value)
      if (!isEmptyRow) {
        formatedData.push(rowData)
      }
    })

    if (formatedData.length > 10000) {
      isValidAmountOfRow = false

      return { data: {}, isValidAmountOfRow }
    }
  }

  return {
    data: {
      totalRowData: [...formatedData.slice(1)],
      rowData: [...formatedData.slice(1)],
      unvalidRowData,
      fieldArray: formatedData[0],
    },
    isValidAmountOfRow,
  }
}

const numberFormatter = new Intl.NumberFormat('en-US')

const checkIsBetaOrTrialUser = (userObject: any) =>
  userObject?.subscription_type === 'BETA' || userObject?.subscription_type === 'TRIAL'

const checkIsPaidTrialUser = (userObject: any) => userObject?.subscription_type === 'PAID_TRIAL'

export {
  isValidDomain,
  formatDomain,
  validateResultPapaParse,
  numberFormatter,
  calculatePointCampaign,
  checkIsBetaOrTrialUser,
  checkIsPaidTrialUser,
}

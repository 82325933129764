import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Typography, Tooltip, Breadcrumb } from 'antd'
import left_arrow from 'common/assets/svg/left_arrow.svg'
// import delete_icon from 'common/assets/svg/delete_icon.svg'
// import share_icon from 'common/assets/svg/share_icon.svg'
// import duplicate_icon from 'common/assets/svg/duplicate_icon.svg'
import edit_icon from 'common/assets/svg/edit_icon.svg'
import {
  SET_AI_CAMPAIGN_NAME,
  SET_IS_CAMPAIGN_RUNNING_ALREADY,
  SET_IS_REVIEW_MODE,
} from '../state/outreachAICampaignSlice'
import styles from './PageHeader.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import CampaignSuccessModal from './UI/CampaignSuccessModal'
import { useAppDispatch, useAppSelector } from 'state'
// import { transformToNewStructure } from './transformStepsUtil'
import {
  useLazyCreateCampaignActionsQuery,
  useLazyCreateCampaignQuery,
  useLazyRunCampaignQuery,
  // useLazyCreateCampaignRecipientsQuery,
  useLazyUpdateCampaignAiQuery,
  useLazyUpdateAiCampaignNameQuery,
  // useLazyCreateCampaignRecipientsUseRemainingPointsQuery,
  useLazyAddLeadsFromGroupsQuery,
  useLazyAddCampaignAILeadsQuery,
  useLazyAddLeadsFromListsQuery,
} from '../../../APIs/OutreachAI-API'
import { transformToNewStructure } from './transformStepsUtil'
import { SET_LOADING_GENERATE_CAMPAIGN } from '../state/outreachAICampaignSlice'
import OutOfPointsUseRemaining from 'common/components/GeneralComponents/Modals/OutOfPoints/OutOfPointsUseRemaining'
import { getSenderByPlatform } from 'common/utils/campaignHelpers'
import useCampaignNavigation from '../Helpers/useCampaignNavigation'
import { trackEvent } from 'eventConfig'

const PageHeader: React.FC = () => {
  const { Paragraph } = Typography
  const dispatch = useAppDispatch()
  const { navigateWithCampaignId } = useCampaignNavigation()
  const navigate = useNavigate()
  const location = useLocation()
  const [createCampaign] = useLazyCreateCampaignQuery()
  const [createCampaignActions] = useLazyCreateCampaignActionsQuery()
  const [addLeadsFromGroups] = useLazyAddLeadsFromGroupsQuery()
  const [addCampaignAILeadsQuery] = useLazyAddCampaignAILeadsQuery() // Only For Companies AI for now.
  const [isMagic, setIsMagic] = useState(false)
  const [updateCampaign] = useLazyUpdateCampaignAiQuery()
  const [updateCampaignName] = useLazyUpdateAiCampaignNameQuery()

  const [runCampaign] = useLazyRunCampaignQuery()

  // const [editableCampaignDescription, setEditableCampaignDescription] = useState(defaultDescription)
  const { custom_flow_id, flag_campaign_actions } = useAppSelector((state) => state.CampaignBuilder)
  const {
    saved_prompt_params,
    selected_platforms_accounts,
    selected_leads,
    stored_leads_from_db,
    nodes_data,
    campaign_id,
    campaign_settings_ai,
    messages_types,
    ai_campaign_name,
    active_platforms,
    prompt,
    campaign_status,
    are_messages_missing,
    trigger,
    skip_prompt,
    select_custom_template,
    are_leads_from_groups,
    ai_leads_type,
    setup_method,
    user_updated_sequence,
    default_columns,
  } = useAppSelector((state) => state.outreachAICampaign)
  const [isEditing, setIsEditing] = useState(false)
  const [editableCampaignName, setEditableCampaignName] = useState(ai_campaign_name)
  const [openModal, setOpenModal] = useState(false)
  const [addLeadsFromLists] = useLazyAddLeadsFromListsQuery()

  const path = window.location.pathname
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])

  const allNonEmpty = Object.values(saved_prompt_params).every(
    (value) => typeof value === 'string' && value.trim() !== '',
  )
  const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
    (value) => Array.isArray(value) && value.length > 0,
  )

  const allMessageIdsHaveData = nodes_data.every((node: any) => {
    if (messages_types.includes(node.type) && node?.data?.messages) {
      for (const message of node?.data?.messages) {
        if (message?.trim() === '') {
          return false
        }
      }
      if (node?.data?.messages?.length < 1) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  })
  const validator = () => {
    return (
      selected_leads?.length > 0 &&
      nodes_data?.length > 0 &&
      (allNonEmpty || skip_prompt || setup_method === 'manually') &&
      atLeastOneNonEmpty &&
      allMessageIdsHaveData
    )
  }

  const locationData = path.split('/')

  const handleBackClick = async () => {
    trackEvent('SAVE_CHANGES')
    const location_view = locationData[locationData.length - 2]

    if (location_view === 'view') {
      const campaign_id = locationData[locationData.length - 1]
      const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
      await updateCampaign({
        campaign_id,
        campaign_name: ai_campaign_name,
        ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
        steps: JSON.stringify(nodes_data),
      })

      navigateWithCampaignId(`/outreach/create-new-campaign/summary`, {
        useCampaignId: true,
      })
    } else {
      if (path.includes('edit-manually')) {
        navigateWithCampaignId('/outreach/create-new-campaign')
      } else {
        navigateWithCampaignId('/outreach', { useCampaignId: false })
      }
    }
  }

  const saveChanges = async () => {
    const params = new URLSearchParams(location.search)
    const campaign_id = params.get('campaign_id')
    const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
    await updateCampaign({
      campaign_id,
      campaign_name: ai_campaign_name,
      ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
      steps: JSON.stringify(nodes_data),
    })
  }

  useEffect(() => {
    if (path.includes('summary') && trigger > 0) {
      // if (path.includes('summary') && trigger > 0 && !use_custom_flow) {
      saveChanges()
    }
  }, [trigger])

  const handleSetCampaignName = async (val: any) => {
    const newVal = val.replace(/'/g, '')
    setEditableCampaignName(newVal)
    setIsEditing(false)
    dispatch(SET_AI_CAMPAIGN_NAME(newVal))
    await updateCampaignName({ campaign_id: campaign_id, campaign_name: newVal })
  }

  // delete later
  // const handleConsoleLog = () => {
  //   const newSteps = transformToNewStructure(nodes_data, 'Instagram')
  // }
  const [outOfPoints, setOutOfPoints] = useState(false)
  const [costPoints, setCostPoints] = useState(0)

  // const handleGenerateCampaign = async (isRetryWithRemainingPoints: any) => {
  //   try {
  //     dispatch(SET_IS_REVIEW_MODE(true))
  //     dispatch(SET_LOADING_GENERATE_CAMPAIGN(true))
  //     // Update latest changes in campaigns table
  //     const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
  //     const platforms = Object.entries(selected_platforms_accounts).reduce((acc: any, [key, value]: any) => {
  //       if (value.length > 0) {
  //         acc[key] = value
  //       }
  //       return acc
  //     }, {})

  //     // Filter out leads that are already stored in the database

  //     const filteredLeads = selected_leads.filter((lead: any) => {
  //       if (!lead.source || lead.mode !== 'groups') {
  //         return !stored_leads_from_db.includes(lead.index)
  //       }
  //       const combinedIndex = `${lead.source}_${lead.index}`
  //       return !stored_leads_from_db.includes(lead.index) && !stored_leads_from_db.includes(combinedIndex)
  //     })

  //     const indexValues = filteredLeads
  //       .filter((item: any) => item.type !== 'company' && item?.source !== 'CSV')
  //       .map((item: any) => item.index)
  //       .filter((val: any) => val !== null && val?.trim() !== '')

  //     const companyIndexes = filteredLeads
  //       .filter((item: any) => item.type === 'company')
  //       .map((item: any) => item.index)
  //       .filter((val: any) => val !== null)
  //     const recipientsData = filteredLeads.filter((item: any) => item.source === 'CSV')
  //     const filtered_active_platforms = Object.entries(active_platforms)
  //       .filter(([platform, isActive]) => isActive === true)
  //       .map(([platform]) => platform)
  //     // Create campign recipient

  //     const allSenders = getSenderByPlatform(selected_platforms_accounts)

  //     let campaignRecipientsData

  //     if (are_leads_from_groups) {
  //       campaignRecipientsData = await addLeadsFromGroups({
  //         campaign_id,
  //         campaign_name: ai_campaign_name || 'New Campaign',
  //         leads: filteredLeads.map((item: any) => ({
  //           username: item.username,
  //           platform: item.platform,
  //           source:
  //             item.platform === 'linkedin'
  //               ? item.search_id
  //               : item?.followed_username || item?.source || item.group_name,
  //           ...(item?.type && { type: item?.type }),
  //         })), // Each item contains platform, in case we need in the future
  //         ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
  //         ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
  //         sent_from: selected_platforms_accounts,
  //         useRemainingPoints: isRetryWithRemainingPoints,
  //       })
  //     }
  //     if (indexValues.length > 0 || recipientsData.length > 0 || companyIndexes.length > 0) {
  //       // return dispatch(SET_LOADING_GENERATE_CAMPAIGN(false)) // delete later
  //       campaignRecipientsData = await addCampaignAILeadsQuery({
  //         campaign_id,
  //         campaign_name: ai_campaign_name || 'New Campaign',
  //         companyLeads: companyIndexes,
  //         AILeads: indexValues,
  //         CSVLeads: recipientsData,
  //         ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
  //         ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
  //         sent_from: selected_platforms_accounts,
  //         useRemainingPoints: isRetryWithRemainingPoints,
  //       })
  //     }

  //     if (campaignRecipientsData?.error?.status === 470) {
  //       dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
  //       if (campaignRecipientsData?.error?.data?.type === 'magic_points') {
  //         setIsMagic(true)
  //       } else {
  //         setIsMagic(false)
  //       }
  //       setCostPoints(campaignRecipientsData?.error?.data?.cost_point)
  //       setOutOfPoints(true)
  //     } else {
  //       await createCampaign({
  //         campaign_id: campaign_id,
  //         campaign_name: ai_campaign_name || 'New Campaign',
  //         prompt_params: JSON.stringify(saved_prompt_params),
  //         platforms: platforms,
  //         ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
  //         steps: JSON.stringify(nodes_data),
  //         summarytSettings: {
  //           selected_platforms_accounts,
  //           prompt,
  //           saved_prompt_params,
  //           active_platforms,
  //           campaign_settings_ai,
  //           skip_prompt,
  //           are_leads_from_groups,
  //           select_custom_template,
  //           ai_leads_type,
  //           setup_method,
  //           allSenders,
  //           user_updated_sequence,
  //           default_columns,
  //         },
  //         custom_flow_id: custom_flow_id,
  //       })
  //       // Create Campaign Actions
  //       await createCampaignActions({
  //         campaign_id: campaign_id,
  //         campaign_settings: campaign_settings_ai,
  //         platforms: filtered_active_platforms,
  //       })
  //       dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
  //       // navigate(`/outreach/create-new-campaign/summary/${campaign_id}`)

  //       trackEvent('CAMPAIGN_REVIEW_LAUNCH')

  //       navigateWithCampaignId(`/outreach/create-new-campaign/summary`, {
  //         useCampaignId: true,
  //       })
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }
  const handleGenerateCampaign = async (isRetryWithRemainingPoints: any) => {
    try {
      dispatch(SET_IS_REVIEW_MODE(true))
      dispatch(SET_LOADING_GENERATE_CAMPAIGN(true))
      // Update latest changes in campaigns table
      const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
      const platforms = Object.entries(selected_platforms_accounts).reduce((acc: any, [key, value]: any) => {
        if (value.length > 0) {
          acc[key] = value
        }
        return acc
      }, {})

      // Filter out leads that are already stored in the database

      const filteredLeads = selected_leads.filter((lead: any) => {
        if (!lead.source || lead.mode !== 'groups') {
          return !stored_leads_from_db.includes(lead.index)
        }
        const combinedIndex = `${lead.source}_${lead.index}`
        return !stored_leads_from_db.includes(lead.index) && !stored_leads_from_db.includes(combinedIndex)
      })
      // Ai Leads
      const indexValues = filteredLeads
        .filter((item: any) => item.convrt_source === 'ai_leads')
        .map((item: any) => item.index)
        .filter((val: any) => val !== null && val?.trim() !== '')

      // Company Leads
      const companyIndexes = filteredLeads
        .filter((item: any) => item.convrt_source === 'ai_leads_companies')
        .map((item: any) => item.index)
        .filter((val: any) => val !== null)
      // CSV Leads
      const recipientsData = filteredLeads.filter((item: any) => item.convrt_source === 'CSV')
      const filtered_active_platforms = Object.entries(active_platforms)
        .filter(([platform, isActive]) => isActive === true)
        .map(([platform]) => platform)

      const groupLeads = filteredLeads
        ?.filter((lead: any) => {
          return lead?.convrt_source === 'social_hub'
        })
        .map((item: any) => ({
          username: item.username,
          platform: item.platform,
          source:
            item.platform === 'linkedin' ? item.search_id : item?.followed_username || item?.source || item.group_name,
          ...(item?.type && { type: item?.type }),
        }))
      const listsLeads = filteredLeads
        ?.filter((lead: any) => {
          return lead?.convrt_source === 'lists'
        })
        .map((item: any) => ({
          index: item.index,
        }))

      const allSenders = getSenderByPlatform(selected_platforms_accounts)

      let campaignRecipientsData
      if (listsLeads.length > 0) {
        campaignRecipientsData = await addLeadsFromLists({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          leads: listsLeads,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
          platform: truePlatforms[0],
        })
      }
      if (groupLeads.length > 0) {
        campaignRecipientsData = await addLeadsFromGroups({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          leads: groupLeads,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
        })
        // return dispatch(SET_LOADING_GENERATE_CAMPAIGN(false)) // delete later
      }
      if (indexValues.length > 0 || recipientsData.length > 0 || companyIndexes.length > 0) {
        campaignRecipientsData = await addCampaignAILeadsQuery({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          companyLeads: companyIndexes,
          AILeads: indexValues,
          CSVLeads: recipientsData,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
        })
      }

      if (campaignRecipientsData?.error?.status === 470) {
        dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
        if (campaignRecipientsData?.error?.data?.type === 'magic_points') {
          setIsMagic(true)
        } else {
          setIsMagic(false)
        }
        setCostPoints(campaignRecipientsData?.error?.data?.cost_point)
        setOutOfPoints(true)
      } else {
        await createCampaign({
          campaign_id: campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          prompt_params: JSON.stringify(saved_prompt_params),
          platforms: platforms,
          ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
          steps: JSON.stringify(nodes_data),
          summarytSettings: {
            selected_platforms_accounts,
            prompt,
            saved_prompt_params,
            active_platforms,
            campaign_settings_ai,
            skip_prompt,
            are_leads_from_groups,
            select_custom_template,
            ai_leads_type,
            setup_method,
            allSenders,
            user_updated_sequence,
            default_columns,
          },
          custom_flow_id: custom_flow_id,
        })
        // Create Campaign Actions
        await createCampaignActions({
          campaign_id: campaign_id,
          campaign_settings: campaign_settings_ai,
          platforms: filtered_active_platforms,
          stepsToCheck: flag_campaign_actions,
        })
        dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
        // navigate(`/outreach/create-new-campaign/summary/${campaign_id}`)

        trackEvent('CAMPAIGN_REVIEW_LAUNCH')

        navigateWithCampaignId(`/outreach/create-new-campaign/summary`, {
          useCampaignId: true,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleRunCampaign = async () => {
    try {
      if (campaign_status === 'running') {
        navigate('/outreach')
      } else {
        const locationData = window.location.pathname.split('/')
        const location_campaign_id = campaign_id !== '' ? campaign_id : locationData[locationData.length - 1]

        await runCampaign({ campaign_id: [location_campaign_id] })

        trackEvent('CAMPAIGN_RUN', { campaign_id: location_campaign_id })

        dispatch(SET_IS_CAMPAIGN_RUNNING_ALREADY(true))
        navigate('/outreach')
      }
    } catch (e) {
      console.error(e)
    }
  }

  const rightButton = path.includes('summary') ? (
    <div className={styles.rightside}>
      {/* <span className={styles.customButton}>
        <img src={delete_icon} alt='' />
        Delete
      </span>
      <span className={styles.customButton}>
        <img src={share_icon} alt='' />
        Share
      </span>
      <span className={styles.customButton}>
        <img src={duplicate_icon} alt='' />
        Duplicate
      </span> */}
      <Button disabled={!validator()} onClick={handleRunCampaign}>
        {campaign_status === 'running' ? 'Exit' : 'Run Campaign'}
      </Button>
    </div>
  ) : path.includes('edit-manually') ? (
    <div className={styles.rightside}>
      <Button onClick={handleBackClick}>Save Changes</Button>
      {/* <Button onClick={handleConsoleLog}>Console Nodes Data</Button> */}
    </div>
  ) : (
    <div className={styles.rightside}>
      <Tooltip title={are_messages_missing ? 'Please fill all messages' : null}>
        <Button
          disabled={!validator()}
          onClick={() => {
            handleGenerateCampaign(false)
          }}>
          Review & Launch
        </Button>
      </Tooltip>
      {/* <Button onClick={handleConsoleLog}>Console Nodes Data</Button> */}
    </div>
  )

  const retryWithRemainingPoints = () => {
    handleGenerateCampaign(true)
    setOutOfPoints(false)
  }

  useEffect(() => {
    setEditableCampaignName(ai_campaign_name)
  }, [ai_campaign_name])

  return (
    <>
      <div className={styles['custom-header']}>
        <Row align='middle' justify='space-between'>
          <Col>
            <div className={styles.leftSide}>
              <Button type='text' size='large' onClick={handleBackClick}>
                <img src={left_arrow} alt='' />
              </Button>

              <div className={styles.campaign}>
                <div className={styles.top}>
                  <Breadcrumb
                    className='breadcrumb'
                    style={{ marginTop: '2px' }}
                    separator='>'
                    items={[
                      {
                        title: 'Campaigns',
                        onClick: () => {},
                      },
                      {
                        title: path.includes('summary') ? 'Campaign Summary' : 'Create a New Campaign',
                      },
                    ]}
                  />
                </div>

                <div style={{ display: 'flex' }} className={styles.bottom}>
                  <Tooltip title={editableCampaignName.length > 40 ? editableCampaignName : ''}>
                    <Paragraph
                      style={{
                        margin: '0px',
                        width: isEditing ? '600px' : '100%',
                        fontSize: '16px',
                      }}
                      editable={{
                        onChange: handleSetCampaignName,
                        onStart: () => setIsEditing(true),
                        maxLength: 100,
                        autoSize: { minRows: 1 },
                        icon: (
                          <img
                            style={{ margin: '0px', height: '13px', width: '13px', marginBottom: '-1px' }}
                            src={edit_icon}
                            alt=''
                          />
                        ),
                      }}>
                      {editableCampaignName.length > 40 && !isEditing
                        ? `${editableCampaignName.substring(0, 40)}...`
                        : editableCampaignName}
                    </Paragraph>
                  </Tooltip>
                  <div className={styles.headerStatus}>
                    <span>{campaign_status === 'awaiting' ? 'READY' : campaign_status?.toLocaleUpperCase()}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div>{rightButton}</div>
        <CampaignSuccessModal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
        />
      </div>

      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
          isMagic={isMagic}
        />
      )}
    </>
  )
}

export default PageHeader

import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'LEGACY/API/baseQueryWithValidation'

export const UserApi: any = createApi({
  reducerPath: 'UserApiWeb3',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    upgradeUserPlan: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/upgradeUserPlan`,
        method: 'POST',
        body: {
          plan: params.plan,
          number_of_seats: params.number_of_seats,
          subscription_months: params.subscription_months,
          session_id: params.session_id,
          convrtPoints: params.convrtPoints,
        },
      }),
    }),
    getPremiumPlans: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/getPremiumPlans`,
        method: 'POST',
        body: {
          addon: params.addon,
        },
      }),
    }),
    getUserInTeam: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/getUserInTeam`,
        method: 'GET',
      }),
    }),
    getIsAdmin: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/isAdmin`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyUpgradeUserPlanQuery,
  useLazyGetPremiumPlansQuery,
  useLazyGetUserInTeamQuery,
  useLazyGetIsAdminQuery,
} = UserApi

import React from 'react'
import { Modal, Button } from 'antd'
import styles from './WarningNodeModal.module.scss'
import warning_triangle from 'common/assets/svg/warning_triangle.svg'

interface WarningNodeModalProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  type?: any
  title?: string
  content?: string
  confirmText?: string
  cancelText?: string
}

const WarningNodeModal: React.FC<WarningNodeModalProps> = ({
  open,
  onConfirm,
  onCancel,
  type,
  title,
  content,
  confirmText,
  cancelText,
}) => {
  if (!type) {
    return (
      <Modal
        centered
        open={open}
        onCancel={onCancel}
        footer={null}
        className={styles.warningModal}
        closeIcon={<span className={styles.closeIcon}>×</span>}>
        <div className={styles.container}>
          <img src={warning_triangle} alt='' />
          <h2 className={styles.title}>Risk of Account Restrictions</h2>
          <p className={styles.description}>
            Adding <b>"Comment on a Post"</b> to your Instagram sequence may increase the likelihood of account
            restrictions.
          </p>
          <div className={styles.buttonWrapper}>
            <Button className={styles.cancelButton} onClick={onCancel}>
              I understand - don't use it
            </Button>
            <Button className={styles.confirmButton} onClick={onConfirm}>
              I understand - I still want to use it
            </Button>
          </div>
        </div>
      </Modal>
    )
  } else {
    return (
      <Modal
        centered
        open={open}
        onCancel={onCancel}
        onOk={onConfirm}
        cancelText={cancelText}
        okText={confirmText}
        title={title}
        className={styles.warningModal}
        closeIcon={<span className={styles.closeIcon}>×</span>}>
        <div className={styles.container}>
          <p className={styles.description}>{content}</p>
        </div>
      </Modal>
    )
  }
}

export default WarningNodeModal

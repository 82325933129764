import axios from 'axios'

import { HttpMethods } from 'common/constants/http-methods.constants'

import { ILogin, Signup } from 'common/components/Login/interfaces/Login.interface'

const doLogin = async (values: ILogin) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/signin`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const doCSLogin = async (values: ILogin) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/cs-signin`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const getUserIdbyEmail = async (values: any) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/getUserIdbyEmail`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const doSSOLogin = async (values: any) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/ssoSignin`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const signUp = async (values: Signup) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/signup`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const changePassword = async (values: any) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/reset-password`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const checkAllowedResetPassword = async (values: any) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/check-allowed-reset-password`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const ssoSignUp = async (values: Signup) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/ssoSignup`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}
const affiliateSSOSignUp = async (values: Signup) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/signupAffiliateSSO`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}
const checkRegistrationCode = async (registrationCode: any) => {
  try {
    const response = await axios({
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_API_URL}/auth/premium-signup/${registrationCode}`,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const signUpRegistrationCode = async (values: any, registrationCode: any) => {
  try {
    const response = await axios({
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_API_URL}/auth/premium-signup/${registrationCode}`,
      data: values,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error) {
    return error
  }
}

const checkEmailToken = async (confirmationCode: any) => {
  try {
    const response = await axios({
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_API_URL}/auth/confirm-email?token=${confirmationCode}`,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error: any) {
    return { ...error?.response?.data, error: true }
  }
}

const resendConfirmationEmail = async (confirmationCode: any) => {
  try {
    const response = await axios({
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_API_URL}/auth/resend-email?email=${confirmationCode}`,
      headers: {
        accept: 'application/json',
      },
    })
    return response.data
  } catch (error: any) {
    return { ...error?.response?.data, error: true }
  }
}

export const AuthService = {
  doLogin,
  doCSLogin,
  doSSOLogin,
  signUp,
  ssoSignUp,
  checkRegistrationCode,
  signUpRegistrationCode,
  changePassword,
  getUserIdbyEmail,
  checkAllowedResetPassword,
  checkEmailToken,
  resendConfirmationEmail,
  affiliateSSOSignUp,
}

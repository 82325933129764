// import { useAuth } from 'common/hooks/useAuth.hooks'
import styles from './OnBoardingPageA.module.scss'

const videoSrc = require('LEGACY/Assets/mp4/OutreachAutomation.mp4') as any

const OnBoardingPageA = () => {
  // const { userDetails } = useAuth()
  // const userType = userDetails.subscription_type
  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Welcome to Telegram Outreach Suite'}</p>
        <section>
          The #1 Outreach tool in response rate. Boost your sales by 20x!
          <br />
          {/* {userType === 'BETA' && (
            <span style={{ fontWeight: 600 }}>Also, use your Telegram account for outreach to prospects.</span>
          )} */}
        </section>
      </div>
      <div className={styles.animation}>
        <video src={videoSrc} autoPlay loop muted />
      </div>
    </div>
  )
}

export default OnBoardingPageA

import { Select } from 'antd'
import { IExposedContact } from 'LEGACY/Research/contacts/interfaces/contact.interface'
import { useCallback, useEffect, useState } from 'react'
import styles from './ExposedContacts.module.scss'
import CustomnMeetingBook from 'LEGACY/General/UI/CustomMeetingBook'

export const dataTypeContact = [
  {
    label: 'Contacts',
    value: 'contact',
  },
  {
    label: 'Companies',
    value: 'company',
  },
]

export const formatOrderBy = (order_by: any) => {
  let result = ''
  if (order_by && order_by !== 'none') {
    switch (order_by) {
      case 'oldest':
        result = 'time,DESC'
        break
      case 'newest':
        result = 'time,ASC'
        break
      default:
        result = `name,${order_by}`
    }
  }

  return result
}

export const PriorityTableCell: React.FC<{
  item: IExposedContact
  handleUpdatePriority: (id: string, val: string) => Promise<any>
}> = ({ item, handleUpdatePriority }: any) => {
  const [value, setVal] = useState(item?.priority)
  const [loadingUpdatePriority, setLoadingUpdatePriority] = useState(false)

  useEffect(() => {
    setVal(item?.priority)
  }, [item])

  const hanndleChange = useCallback(
    async (val: string) => {
      setLoadingUpdatePriority(true)
      await handleUpdatePriority(item.contact_id || item.key, val)
      setLoadingUpdatePriority(false)
    },
    [item],
  )
  return (
    <Select
      className='selectPriority'
      size='small'
      style={{ width: 99 }}
      placeholder='Priority'
      value={value}
      disabled={loadingUpdatePriority}
      loading={loadingUpdatePriority}
      onChange={(value) => hanndleChange(value)}>
      <Select.Option value='high' disabled={value === 'high'}>
        <div className={styles.priority}>
          <span className='select_dots'></span>
          <span>High</span>
        </div>
      </Select.Option>
      <Select.Option value='medium' disabled={value === 'medium'}>
        <div className={styles.priority}>
          <span className='select_dots' style={{ backgroundColor: '#FFC700' }}></span>
          <span>Medium</span>
        </div>
      </Select.Option>
      <Select.Option value='low' disabled={value === 'low'}>
        <div className={styles.priority}>
          <span className='select_dots' style={{ backgroundColor: 'green' }}></span>
          <span>Low</span>
        </div>
      </Select.Option>
    </Select>
  )
}

export const MeetingBookedTableCell: React.FC<{
  item: IExposedContact
  handleUpsertMeetingBooked: (id: string, val: string) => Promise<any>
}> = ({ item, handleUpsertMeetingBooked }: any) => {
  const [value, setVal] = useState(item?.meeting_booked)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setVal(item?.meeting_booked)
  }, [item])

  const hanndleChange = useCallback(
    async (val: string) => {
      setLoading(true)
      await handleUpsertMeetingBooked(item.contact_id || item.key, val)
      setLoading(false)
    },
    [item],
  )
  return <CustomnMeetingBook loading={loading} value={value} onChange={hanndleChange} />
}

export const initTableParam = {
  current: 1,
  pageSize: 10,
}

export const getResultFromImport = () => {
  const keyResultImport = 'importResult'
  const resultImport = localStorage.getItem(keyResultImport)
  const resultAfterImportContact = resultImport ? JSON.parse(resultImport || '') : null

  return resultAfterImportContact
}

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { OutreachApi } from 'common/APIs/OutreachApi'
import { SettingsApi } from 'common/APIs/SettingsApi'
import { settingsReducer } from 'common/components/Settings/state/slice/settingsSlice'
import { filtersReducer } from './filtersSlice/filtersSlice'
import { paginationReducer } from './paginationSlice/paginationSlice'
import { outreachedCampaignReducer } from 'common/components/Campaigns/state/outreachCampaignSlice'
import { UserApi } from 'common/APIs/UserApi'
import { outreachAICampaignReducer } from 'common/components/Campaigns/state/outreachAICampaignSlice'
import { SocialConnectionApi } from 'common/APIs/SocialConnectionApi'
import { OutreachAIApi } from 'common/APIs/OutreachAI-API'
import { ChatApi } from 'common/APIs/Chat-API'
import { chatSliceReducer } from 'common/components/Chat/state/chatSlice'
import { CampaignBuilderReducer } from 'common/components/Campaigns/Builder/Slice/CampaignBuilderSlice'
import { learningReducer } from 'common/components/GeneralComponents/LearningCenter/state/slice/learningSlice'
import { LearningCenterAPI } from 'common/APIs/LearningCenterAPI'
import { UpgradeApi } from 'common/APIs/UpgradeApi'
import { GeneralReducer } from 'GeneralSlice'
import { SignUpApi } from 'common/APIs/SignUpApi'
import { signupReducer } from 'common/components/Signup/AffiliateSignup/state/slice/signupSlice'
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// LEGACY ///////////////////////////////////////////////////
import { CompaniesApi } from 'LEGACY/API/CompaniesApi' ///////////////////////////////////////////////////////
import { ContactsApi } from 'LEGACY/API/ContactsApi' /////////////////////////////////////////////////////////
import { AdminCompanyApi } from 'LEGACY/API/AdminCompanyApi' /////////////////////////////////////////////////
import { companiesReducer } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice' /////////////////
import { contactsReducer } from 'LEGACY/Research/contacts/state/slice/contactsSlice' /////////////////////////
import { adminCompanyReducer } from 'LEGACY/features/company-admin-pannel/state/slice/adminCompanySlice' /////
import { outreachedReducer } from 'LEGACY/MyContacts/Outreach/state/sendMessageSlice' ////////////////////////
import { myContactsCompaniesReducer } from 'LEGACY/MyContacts/state/slice/companiesSlice' ////////////////////
import { myContactsReducer } from 'LEGACY/MyContacts/state/slice/contactsSlice' //////////////////////////////
import { importContactReducer } from 'LEGACY/MyContacts/Outreach/state/importContactSlice' ///////////////////
import { OutreachApiWeb3 } from 'LEGACY/API/OutreachApi' /////////////////////////////////////////////////////
import { SettingsApiWeb3 } from 'LEGACY/API/SettingsApi' /////////////////////////////////////////////////////
/////////////////////////////////////////////////// LEGACY ///////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const store = configureStore({
  reducer: {
    [CompaniesApi.reducerPath]: CompaniesApi.reducer, // LEGACY
    [ContactsApi.reducerPath]: ContactsApi.reducer, // LEGACY
    [SettingsApiWeb3.reducerPath]: SettingsApiWeb3.reducer, // LEGACY
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [UpgradeApi.reducerPath]: UpgradeApi.reducer,
    [OutreachApi.reducerPath]: OutreachApi.reducer,
    [OutreachApiWeb3.reducerPath]: OutreachApiWeb3.reducer, // LEGACY
    [OutreachAIApi.reducerPath]: OutreachAIApi.reducer,
    // [EventsApi.reducerPath]: EventsApi.reducer,
    [AdminCompanyApi.reducerPath]: AdminCompanyApi.reducer, // LEGACY
    [UserApi.reducerPath]: UserApi.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
    [SocialConnectionApi.reducerPath]: SocialConnectionApi.reducer,
    [LearningCenterAPI.reducerPath]: LearningCenterAPI.reducer,
    [SignUpApi.reducerPath]: SignUpApi.reducer,
    pagination: paginationReducer,
    companies: companiesReducer,
    contacts: contactsReducer,
    filters: filtersReducer,
    settings: settingsReducer,
    company_admin_pannel: adminCompanyReducer,
    learning: learningReducer,
    signup: signupReducer,
    outreached: outreachedReducer,
    outreachCampaign: outreachedCampaignReducer,
    outreachAICampaign: outreachAICampaignReducer,
    CampaignBuilder: CampaignBuilderReducer,
    chat: chatSliceReducer,
    General: GeneralReducer,
    myContacts: myContactsReducer, // LEGACY
    myContactsCompanies: myContactsCompaniesReducer, // LEGACY
    importContact: importContactReducer, // LEGACY
  },
  // Disabled serializableCheck because it failed the image imported by user for profilePic.
  // A non-serializable value was detected in the state
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      CompaniesApi.middleware, // LEGACY
      ContactsApi.middleware, // LEGACY
      SettingsApiWeb3.middleware, // LEGACY
      SettingsApi.middleware,
      UpgradeApi.middleware,
      OutreachApiWeb3.middleware, // LEGACY
      OutreachApi.middleware,
      OutreachAIApi.middleware,
      // EventsApi.middleware,
      AdminCompanyApi.middleware, // LEGACY
      UserApi.middleware,
      SocialConnectionApi.middleware,
      ChatApi.middleware,
      LearningCenterAPI.middleware,
      SignUpApi.middleware,
    ]),
})

setupListeners(store.dispatch)

import { Select, Tooltip } from 'antd'

import { countriesWithPhonePrefix } from 'LEGACY/common/static-data/countires'

import styles from './FlagInput.module.scss'

interface FlagInputProps {
  className?: any
  onChange: any
  labelCountryCode?: boolean
  prefix?: any
}

const FlagInput = ({ className, onChange: handleChange, prefix, labelCountryCode = false }: FlagInputProps) => {
  const filterOption: any = (inputValue: any, option: any) => {
    if (!option) return false

    return (
      option.searchValue.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0 ||
      option.value.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
    )
  }

  return (
    <Select
      className={className}
      showSearch={true}
      // defaultValue={'+972'}
      value={prefix}
      filterOption={filterOption}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      options={countriesWithPhonePrefix?.map(({ countryCode, phonePrefix, label }): any => ({
        key: countryCode,
        label: (
          <Tooltip title={label}>
            <div className={styles.optionLabel}>
              <img
                className={styles.flag}
                alt={label}
                src={`https://convrt-media-store.s3.us-west-2.amazonaws.com/${countryCode?.toLowerCase()}.png`}
              />
              {labelCountryCode ? <span>{countryCode}</span> : <span>{`+${phonePrefix}`}</span>}

              {/* <span>{`+${phonePrefix}`}</span> */}
            </div>
          </Tooltip>
        ),
        value: labelCountryCode ? countryCode : `+${phonePrefix}-${countryCode}`,

        // value: `+${phonePrefix}`,
        searchValue: label,
      }))}
    />
  )
}

export default FlagInput

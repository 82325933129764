import { post } from 'LEGACY/API/axios'
import { filterObjectToString } from 'LEGACY/common/utils/objectToValues'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'
import styles from './SavedFilters.module.scss'
import { Tooltip } from 'antd'

interface SavedFiltersProps {
  savedFilters: any
  handleFilter: any
  isLoading: any
  locationPathName: any
}

const SavedFilters: React.FC<SavedFiltersProps> = ({ savedFilters, handleFilter, isLoading, locationPathName }) => {
  const handleClick = async (filter: any, route: string) => {
    handleFilter(filter, route)
    await post(`/companies/log/recent-filter`, filter)
  }

  return (
    <div className={styles.savedFilters}>
      <p>Saved Filters:</p>
      <div className={styles.container}>
        {savedFilters?.length ? (
          savedFilters?.map(({ filter, filter_name }: any, index: number, array: any[]) => (
            <div
              className={`${styles.section} ${
                index === array.length - 1 && savedFilters.length > 3 ? styles.lastSection : ''
              }`}
              onClick={() => handleClick(filter, locationPathName)}>
              <Tooltip
                title={
                  filterObjectToString(filter).length + filter_name?.length > 55 ? filterObjectToString(filter) : ''
                }>
                <div className={styles.item}>
                  <span>
                    <span style={{ fontWeight: 600, color: 'black' }}>{`${filter_name}: `}</span>
                    {filterObjectToString(filter).length > 65
                      ? `${filterObjectToString(filter).slice(0, 65 - filter_name?.length)}...`
                      : filterObjectToString(filter)}
                  </span>
                </div>
              </Tooltip>
              {/* <div className={styles.item}>
                <span>
                  <span style={{ fontWeight: 600, color: 'black' }}>{`${filter_name}: `}</span>
                  {filterObjectToString(filter)}
                </span>
              </div> */}
            </div>
          ))
        ) : isLoading ? (
          <div className={styles.loading}>
            <LoadingBox />
          </div>
        ) : (
          <div className={styles.noFilters}>
            <p>Your saved filters will be stored here...</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SavedFilters

import { ChangeEvent, useEffect, useState } from 'react'

import { Button, Checkbox, Col, Input, Modal, Row, Select } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'

import { IExpandGroupeChildren } from 'LEGACY/common/interfaces/IFilters'
import { countriesWithPhonePrefix, regions } from 'LEGACY/common/static-data/countires'

import { post } from 'LEGACY/API/axios'

import { SET_FILTER, SET_SELECTED_COMPANIES_DETAILS } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'

import { useAppDispatch, useAppSelector } from 'state'

import { ReactComponent as LockComponent } from 'LEGACY/Assets/svg/lock.svg'
import { removeEmpty } from 'LEGACY/common/utils/objectToValues'
import OptionsTree from 'LEGACY/Research/Filters/OptionsTree/OptionsTree'
import { useGetTagsMutation, useGetCompanyNamesMutation } from 'LEGACY/API/CompaniesApi'
import { useLazyGetJobTitleQuery } from 'LEGACY/API/ContactsApi'

import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { milestonesMap, interestsMap } from 'LEGACY/common/static-data/company-filter'
import NewTag from 'LEGACY/Research/Filters/NewTag/NewTag'
import { setReset, setTechSidebarOpen, setTechSidebarType } from 'state/filtersSlice/filtersSlice'
import Range from 'LEGACY/Research/Filters/Range/Range'
import RangeYears from 'LEGACY/Research/Filters/RangeYears/RangeYears'

import styles from './fancy-filter-expand-groupe.module.scss'
import { outreach } from 'LEGACY/common/constants/outreach.constants'

import BlurPaywallFilters from 'LEGACY/Research/intent-signals/components/paywalls/BlurPaywallFilters'
import { JobTitles } from 'LEGACY/common/static-data/job-title-filters'
import { headcounList } from 'LEGACY/common/static-data/headcount'

interface IFancyFilterGroupe {
  data: IExpandGroupeChildren
}

const { Option } = Select

const FancyFilterGroupe: React.FC<IFancyFilterGroupe> = ({ data }) => {
  const [refetchGetTags] = useGetTagsMutation()
  const [refetchGetJobTitle] = useLazyGetJobTitleQuery()

  const [refetchGetCompanyNames] = useGetCompanyNamesMutation()
  const [expand, setExpand] = useState(false)
  const [openContactSales, setOpenContactSales] = useState(false)
  const [tags, setTags] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [jobTitle, setJobTitle] = useState<string[]>([])
  const [newJobTitle, setNewJobTitle] = useState('')
  const [selectedJobTitle, setSelectedJobTitle] = useState<any>([])
  const [newTag, setNewTag] = useState('')
  const [newCompanyName, setNewCompanyName] = useState('')
  const [companyName, setCompanyName] = useState<string[]>([])
  const [selectedCompanyName, setSelectedCompanyName] = useState<any>([])

  // const [headcoun, setHeadcoun] = useState<string[]>([])
  // const [newHeadcoun, setNewHeadcoun] = useState('')
  const [selectedHeadcoun, setSelectedHeadcoun] = useState<any>([])

  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.companies)
  const { isTechSidebarOpen, resetFilter } = useAppSelector((state) => state.filters)
  const { selected_companies_details } = useAppSelector((state) => state.companies)

  useEffect(() => {
    if (resetFilter) {
      dispatch(setReset(false))
      setExpand(false)
    }
  }, [resetFilter])

  const expandableFilters = [
    'three',
    'alceva',
    'range',
    'tags',
    'interestsCheckbox',
    'sentimentsCheckbox',
    'checkbox',
    'sidelist',
    'companies',
    'rangeYears',
    'jobTitle',
    'headcountList',
    'companyHQ',
    'contactLocation',
  ]

  const handleFilter = (key: string, value: string[]) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    const selectedValues = value.flatMap((selected: string) => {
      if (selected in regions) {
        return regions[selected as keyof typeof regions]
      }
      return selected
    })
    _.set(filterCopy, key, selectedValues)
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const filterOption: any = (inputValue: any, option: any) => {
    if (!option) return false

    return option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    const { name, value } = e.target
    filterCopy[name] = value
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const onCheckboxChange = (checked: CheckboxValueType[]) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    filterCopy[data.key.split('.')[1]] = checked
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const onNumberChange = (minKey: string, maxKey: string, event: any, value: 'min' | 'max') => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    if (value === 'min') {
      _.set(filterCopy, minKey, event)
    } else {
      _.set(filterCopy, maxKey, event)
    }
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const toggleExpand = (key: string) => {
    if (!data?.comingSoon) {
      if (data.type === 'sidelist') {
        dispatch(setTechSidebarOpen(!isTechSidebarOpen))
        dispatch(setTechSidebarType(key))
      }
      setExpand(!expand)
    }
  }

  const handleChangeTags = (value: string | string[]) => {
    setSelectedTags(value)
    const filterCopy = JSON.parse(JSON.stringify(filter))
    filterCopy['tags'] = value
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const handleChangeJobTitle = (value: string | string[]) => {
    setSelectedJobTitle(value)
    const filterCopy = JSON.parse(JSON.stringify(filter))
    filterCopy['jobTitle'] = value
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const handleChangeHeadcoun = (value: string) => {
    setSelectedHeadcoun(value)
    const filterCopy = JSON.parse(JSON.stringify(filter))

    // const headcountArr = value === '5001+' ? [5001, 1000000] : value.split('-')
    filterCopy['companies'] = { ...filterCopy['companies'], headcount: value }

    //  _.set(filterCopy, 'companies.headcount.headcountMin', Number(headcountArr[0]))
    // _.set(filterCopy, 'companies.headcount.headcountMax', Number(headcountArr[1]))

    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const addNewTag = () => {
    if (newTag.trim() !== '' && !tags.includes(newTag) && !selectedTags.includes(newTag)) {
      setTags([...tags, newTag])
      setSelectedTags([...selectedTags, newTag])
      setNewTag('')
    }
  }

  const addNewJobTitle = () => {
    if (newJobTitle.trim() !== '' && !jobTitle.includes(newTag) && !selectedJobTitle.includes(newTag)) {
      setJobTitle([...jobTitle, newJobTitle])
      setSelectedJobTitle([...selectedJobTitle, newJobTitle])
      setNewJobTitle('')
    }
  }

  const handleGetTags = async () => {
    if (tags.length === 0) {
      const result = await refetchGetTags()
      setTags(result.data)
    }
  }

  const handleGetJobTitle = async () => {
    if (jobTitle.length === 0) {
      setJobTitle(JobTitles)
    }
  }

  const handleChangeCompanyName = (value: string | string[], option: any) => {
    const filteredData = option.filter((item: any) => item?.key !== undefined)

    if (!value.length) {
      dispatch(SET_SELECTED_COMPANIES_DETAILS([]))

      dispatch(
        SET_SELECTED_COMPANIES_DETAILS(
          selected_companies_details.map((obj: any) => {
            return { selected: false }
          }),
        ),
      )
    }

    const tmpItem = filteredData[filteredData.length - 1]
    const tempOption = [{ ...tmpItem, name: tmpItem?.value, selected: true, id: tmpItem?.key }]

    filteredData.slice(1).map((item: any) => ({
      ...item,
      name: item.value,
      selected: true,
      id: item.key,
    }))

    if (tempOption[0].id)
      dispatch(
        SET_SELECTED_COMPANIES_DETAILS([
          ...tempOption,
          ...selected_companies_details.filter((item: any) => value.includes(item.name)),
        ]),
      )

    setSelectedCompanyName(value)
    const filterCopy = JSON.parse(JSON.stringify(filter))
    filterCopy['companiesName'] = value
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const addNewnCompanyName = () => {
    if (
      newCompanyName.trim() !== '' &&
      !companyName.includes(newCompanyName) &&
      !selectedCompanyName.includes(newCompanyName)
    ) {
      setCompanyName([...companyName, newCompanyName])
      setSelectedCompanyName([...selectedCompanyName, newCompanyName])
      setNewCompanyName('')
    }
  }

  const handleGetCompanyName = async () => {
    if (companyName.length === 0) {
      const result = await refetchGetCompanyNames('a')
      setCompanyName(result.data)
    }
  }

  useEffect(() => {
    if (newTag !== '') {
      const fetchData = async () => {
        const result = await refetchGetTags(newTag)
        setTags(result.data)
      }

      fetchData().catch(console.error)
    }
  }, [newTag])

  useEffect(() => {
    if (newJobTitle !== '') {
      const fetchData = async () => {
        const result = await refetchGetJobTitle(newJobTitle)
        const jobs: any = result.data.map((jobTitle: any) => {
          return jobTitle.title
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        })
        setJobTitle(jobs)
      }

      fetchData().catch(console.error)
    } else {
      setJobTitle(JobTitles)
    }
  }, [newJobTitle])

  useEffect(() => {
    if (newCompanyName !== '') {
      const fetchData = async () => {
        const result = await refetchGetCompanyNames(newCompanyName)
        setCompanyName(result.data)
      }

      fetchData().catch(console.error)
    }
  }, [newCompanyName])

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      setTags([])
      setSelectedTags('')
    }
  }, [filter])

  useEffect(() => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    filterCopy['tags'] = selectedTags
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }, [selectedTags])

  return (
    <>
      <Row
        className={classNames(styles.expandableChildren, styles.expanded, {
          'px-2': expandableFilters.includes(data.type),
          relative: true,
        })}
        key={data.key}>
        {expandableFilters.includes(data.type) && (
          <div
            className='flex cursor-pointer w-full'
            onClick={() => {
              toggleExpand(data.key)
              data.comingSoon && setOpenContactSales(true)
            }}>
            <Col className='flex-1' span={20}>
              {data?.new && <NewTag />}
            </Col>
            {data.comingSoon && (
              <LockComponent className={data.subType === 'comingSoon' ? styles.soonTagSentiments : styles.soonTag} />
            )}
          </div>
        )}
        {data.type === 'three' && <OptionsTree treeData={data.threeContent || []} isSearchable groupKey={data.key} />}
        {data.type === 'input' && (
          <Input name={data.key} value={_.get(filter, data.key)} placeholder={data.label} onChange={onInputChange} />
        )}
        {data.type === 'range' && (
          <Range
            item={data}
            onNumberChange={onNumberChange}
            filter={filter}
            // setOpenContactSales={setOpenContactSales}
          />
        )}
        {data.type === 'tags' && (
          <>
            <Select
              onClick={handleGetTags}
              className='tagsList'
              mode='multiple'
              size={'middle'}
              placeholder='Search By Tag'
              defaultValue={[]}
              value={selectedTags || undefined}
              onChange={handleChangeTags}
              style={{ width: '100%' }}
              onSearch={(value: string) => {
                setNewTag(value)
              }}
              notFoundContent={
                newTag !== '' && (
                  <Button onClick={addNewTag} className='addNewTag'>
                    {`Add "${newTag}"`}
                  </Button>
                )
              }>
              {tags?.length > 0 &&
                tags?.map((tag) => <Option value={tag} label={tag} children={undefined} key={tag} />)}
            </Select>
          </>
        )}
        {data.type === 'jobTitle' && (
          <>
            <Select
              onClick={handleGetJobTitle}
              className='tagsList'
              mode='multiple'
              size={'middle'}
              placeholder='Search By Job Title'
              defaultValue={[]}
              value={selectedJobTitle || undefined}
              onChange={handleChangeJobTitle}
              style={{ width: '100%' }}
              onSearch={(value: string) => {
                setNewJobTitle(value)
              }}
              notFoundContent={
                newJobTitle !== '' && (
                  <Button onClick={addNewJobTitle} className='addNewTag'>
                    {`Add "${newJobTitle}"`}
                  </Button>
                )
              }>
              {jobTitle?.length > 0 &&
                jobTitle?.map((job) => <Option value={job} label={job} children={undefined} key={job} />)}
            </Select>
          </>
        )}
        {data.type === 'headcountList' && (
          <>
            <Select
              // onClick={handleGetJobTitle}
              mode='multiple'
              className='tagsList'
              size={'middle'}
              placeholder='Select'
              value={selectedHeadcoun || undefined}
              onChange={handleChangeHeadcoun}
              style={{ width: '100%' }}>
              {headcounList?.length > 0 &&
                headcounList?.map((item) => (
                  <Option value={item.value} label={item.label} children={undefined} key={item.value} />
                ))}
            </Select>
          </>
        )}
        {data.type === 'checkbox' && (
          <Checkbox.Group
            className={styles.checkboxFilter}
            defaultValue={filter.milestones}
            onChange={onCheckboxChange}>
            {Object.keys(milestonesMap).map((key) => (
              <div
                onClick={() => {
                  if (milestonesMap[key].comingSoon) {
                    post('/log', {
                      action: `${milestonesMap[key].label}`,
                    })
                    setOpenContactSales(true)
                  }
                }}>
                <Checkbox
                  key={milestonesMap[key].value}
                  value={milestonesMap[key].value}
                  disabled={milestonesMap[key].comingSoon}>
                  {milestonesMap[key].label}
                </Checkbox>
                {milestonesMap[key].comingSoon && <LockComponent className={styles.soonTagCheckbox} />}
              </div>
            ))}
          </Checkbox.Group>
        )}
        {data.type === 'interestsCheckbox' && (
          <Checkbox.Group className={styles.checkboxFilter} defaultValue={filter.interests} onChange={onCheckboxChange}>
            {Object.keys(interestsMap).map((key) => (
              <div
                onClick={() => {
                  if (interestsMap[key].comingSoon) {
                    post('/log', {
                      action: `${interestsMap[key].label}`,
                    })
                    setOpenContactSales(true)
                  }
                }}>
                <Checkbox
                  key={interestsMap[key].value}
                  value={interestsMap[key].value}
                  disabled={interestsMap[key].comingSoon}
                  className={styles.checkboxClass}>
                  {interestsMap[key].label}
                </Checkbox>
                {interestsMap[key].comingSoon && <LockComponent className={styles.soonTagCheckbox} />}
              </div>
            ))}
          </Checkbox.Group>
        )}
        {data.type === 'sidelist'}
        {data.type === 'companies' && (
          <>
            <Select
              onClick={handleGetCompanyName}
              className='tagsList'
              mode='multiple'
              size={'middle'}
              placeholder='Search Company Name'
              defaultValue={selected_companies_details
                .filter((item: any) => item.name !== undefined)
                .map((item: any) => item.name)}
              onChange={handleChangeCompanyName}
              style={{ width: '100%' }}
              onSearch={(value: string) => {
                setNewCompanyName(value)
              }}
              optionLabelProp='label'
              notFoundContent={
                newCompanyName !== '' && (
                  <Button onClick={addNewnCompanyName} className='addNewTag'>
                    {`Add "${newCompanyName}"`}
                  </Button>
                )
              }>
              {companyName?.length > 0 &&
                companyName?.map((item: any) => (
                  <Option value={item.name} label={item.name} children={undefined} key={item.id} />
                ))}
            </Select>
          </>
        )}
        {data.type === 'companyHQ' && (
          //          <>
          //   <Select
          //     onClick={handleGetCompanyName}
          //     className='tagsList'
          //     mode='multiple'
          //     size={'middle'}
          //     placeholder='Search Company Name'
          //     defaultValue={selected_companies_details
          //       .filter((item: any) => item.name !== undefined)
          //       .map((item: any) => item.name)}
          //     onChange={handleChangeCompanyName}
          //     style={{ width: '100%' }}
          //     onSearch={(value: string) => {
          //       setNewCompanyName(value)
          //     }}
          //     optionLabelProp='label'
          //     notFoundContent={
          //       newCompanyName !== '' && (
          //         <Button onClick={addNewnCompanyName} className='addNewTag'>
          //           {`Add "${newCompanyName}"`}
          //         </Button>
          //       )
          //     }>
          //     {companyName?.length > 0 &&
          //       companyName?.map((item: any) => (
          //         <Option value={item.name} label={item.name} children={undefined} key={item.id} />
          //       ))}
          //   </Select>
          // </>
          <>
            <Select
              maxTagCount={3}
              className='tagsList'
              size={'middle'}
              style={{ width: '100%' }}
              placeholder='Choose HQ Location'
              mode={'multiple'}
              value={_.get(filter, data.key)}
              onChange={(event) => {
                handleFilter('companyHQ', event)
              }}
              showSearch={true}
              options={[
                {
                  label: 'Regions',
                  options: [
                    { value: 'europe', label: 'Europe' },
                    { value: 'north_america', label: 'North America' },
                    { value: 'apac', label: 'APAC' },
                    { value: 'latin_america', label: 'Latin America' },
                    { value: 'mea', label: 'MEA' },
                    { value: 'cis', label: 'CIS' },
                  ],
                },
                { label: 'Countries', options: countriesWithPhonePrefix },
              ]}
              filterOption={filterOption}
            />
          </>
        )}
        {data.type === 'contactLocation' && (
          <>
            <Select
              maxTagCount={3}
              className='tagsList'
              size={'middle'}
              style={{ width: '100%' }}
              placeholder={`Prospect's Location`}
              mode={'multiple'}
              onChange={(event) => {
                handleFilter('contactsCountry', event)
              }}
              showSearch={true}
              options={countriesWithPhonePrefix}
              filterOption={filterOption}
            />
          </>
        )}
        {data.type === 'rangeYears' && <RangeYears item={data} onNumberChange={onNumberChange} filter={filter} />}
      </Row>
      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={600}
        bodyStyle={{
          height: 350,
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywallFilters
            addon={data.label}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>
    </>
  )
}

export default FancyFilterGroupe

import _ from 'lodash'
import { Key, useEffect, useState } from 'react'
import { Input, Tooltip, Tree } from 'antd'
import { DataNode } from 'antd/es/tree'
import { PlusCircleFilled, PlusCircleOutlined } from '@ant-design/icons'

import { techStackTools } from 'LEGACY/common/static-data/tech-stack-filters'
import { removeEmpty } from 'LEGACY/common/utils/objectToValues'
import { findNodeByKey, findTitle } from 'LEGACY/common/utils/treeUtils'
import { SET_FILTER } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'
import { useAppDispatch, useAppSelector } from 'state'

import styles from './OptionsTree.module.scss'

interface IOptionsTree {
  treeData: any[]
  isSearchable: boolean
  groupKey: string
  className?: string
  showJustOnSearch?: boolean
}

type groupKeyMapType = {
  techStack: string
  jobTitle: string
  'companies.vertical': string
  [key: string]: string
}

const groupKeyMap: groupKeyMapType = {
  techStack: 'Type any Tech Stack to filter by. Try “IPFS“',
  jobTitle: 'Type any job title to filter by. Try “Solidity Developer“',
  'companies.vertical': 'Type any vertical to filter by. Try “DeFi“',
}

const treesWithExclude = ['techStack', 'companies.vertical']

const OptionsTree = ({ treeData, isSearchable, groupKey, className, showJustOnSearch = false }: IOptionsTree) => {
  const [checked, setChecked] = useState<string[]>([])
  const [hoveredKey, setHoveredKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [selectAllKeys, setSelectAllKeys] = useState<string[]>([])
  const [excludedFilters, setExcludedFilters] = useState<string[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)

  const { filter } = useAppSelector((state) => state.companies)

  const dispatch = useAppDispatch()

  const handleNodeMouseEnter = (key: string) => {
    setHoveredKey(key)
  }

  const handleNodeMouseLeave = () => {
    setHoveredKey('')
  }

  const handleExclude = (e: React.MouseEvent, key: string) => {
    e.stopPropagation()
    if (!checked.includes(key)) return

    const keyIndex = excludedFilters?.indexOf(key)
    const newExcludedFilters =
      keyIndex === -1
        ? [...excludedFilters, key]
        : [...excludedFilters.slice(0, keyIndex), ...excludedFilters.slice(keyIndex + 1)]

    const filterCopy = JSON.parse(JSON.stringify(filter))
    _.set(filterCopy, key, { title: findTitle(treeData, key), excluded: newExcludedFilters.includes(key) })
    dispatch(SET_FILTER(removeEmpty(filterCopy)))

    setExcludedFilters(newExcludedFilters)
  }

  const handleExpand = (expandedKeys: React.Key[]) => {
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }

  const getExpandedKeys = (data: DataNode[], searchValue: string, expandedKeys: React.Key[] = []) => {
    for (const node of data) {
      if (typeof node.title === 'string') {
        if (node.title?.toLowerCase().includes(searchValue.toLowerCase())) {
          expandedKeys.push(node.key!)
        }

        if (node.children) {
          getExpandedKeys(node.children, searchValue, expandedKeys)
        }
      }
    }

    return expandedKeys
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const expandedKeys = getExpandedKeys(techStackTools, value)
    setExpandedKeys(expandedKeys)
    setSearchValue(value)
  }

  const onCheck = (checkedParams: Key[] | { checked: Key[]; halfChecked: Key[] }) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    _.set(filterCopy, groupKey, {})

    const prevChecked = checked.filter((checked) => findNodeByKey(filteredData, checked) === null)
    const checkedArray = _.uniq([...prevChecked, ...(checkedParams as string[])])

    selectAllKeys.forEach((key) => {
      if (!checkedArray.includes(key)) {
        const deletedKey = key as string
        const splitKey = deletedKey.split('.')
        splitKey.pop()
        const parentKey = splitKey.join('.')
        _.set(filterCopy, parentKey, {})
        setSelectAllKeys((prev) => prev.filter((key) => key !== deletedKey))
        dispatch(SET_FILTER(removeEmpty(filterCopy)))
      }
    })

    checkedArray.forEach((checkedKey: string) => {
      const splitKey = checkedKey.split('.')
      const lastKey = splitKey?.[splitKey.length - 1]

      if (lastKey === 'select' && !selectAllKeys.includes(checkedKey)) {
        const checkedAllArray: Key[] = []
        splitKey.pop()
        const parentKey = splitKey.join('.')
        const parentObject = treeData?.find((value: any) => value.key === parentKey)

        parentObject?.children.forEach((child: any) => {
          if (!child.key.endsWith('select'))
            _.set(
              filterCopy,
              child.key,
              treesWithExclude.includes(groupKey)
                ? {
                    title: findTitle(treeData, child.key),
                    excluded: excludedFilters.includes(child.key),
                  }
                : findTitle(treeData, child.key),
            )
          checkedAllArray.push(child.key)
        })
        setSelectAllKeys((prev) => [...prev, checkedKey])
      } else {
        if (!checkedKey.endsWith('select'))
          _.set(
            filterCopy,
            checkedKey,
            treesWithExclude.includes(groupKey)
              ? {
                  title: findTitle(treeData, checkedKey),
                  excluded: excludedFilters.includes(checkedKey),
                }
              : findTitle(treeData, checkedKey),
          )
      }
    })
    setChecked(checkedArray)

    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const filterTreeData = (data: any[], searchValue: string): DataNode[] => {
    if (searchValue.length <= 2 && showJustOnSearch) return []

    return data
      .map((node) => {
        const newNode: DataNode = { ...node }

        if (newNode.title?.toString().toLowerCase().includes(searchValue.toLowerCase()) && newNode.children) {
          return newNode
        } else if (newNode.title?.toString().toLowerCase().includes(searchValue.toLowerCase()) && !newNode.children) {
          return newNode
        }

        if (newNode.children) {
          newNode.children = filterTreeData(newNode.children, searchValue)
          if (newNode.children.length > 0) {
            return newNode
          }
        }

        return null
      })
      .filter(Boolean)
      .sort((a: any, b: any) => {
        if (searchValue.length === 0) return 1
        if (
          a.title?.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >
          b.title?.toString().toLowerCase().indexOf(searchValue.toLowerCase())
        ) {
          return 1
        } else if (
          a.title?.toString().toLowerCase().indexOf(searchValue.toLowerCase()) <
          b.title?.toString().toLowerCase().indexOf(searchValue.toLowerCase())
        ) {
          return -1
        } else {
          if (a.title?.toString() > b.title?.toString()) return 1
          else return -1
        }
      }) as DataNode[]
  }

  const filteredData = filterTreeData(treeData || [], searchValue)

  useEffect(() => {
    setChecked((checked) => [...checked.filter((checked) => checked.toString().endsWith('select'))])
    treeData?.forEach((parent: any) => {
      if (parent?.children) {
        parent.children?.forEach((child: any) => {
          const result = _.get(filter, child.key)
          if (result) {
            setChecked((prev) => {
              return [...prev, child.key]
            })
          }
        })
      } else {
        const result = _.get(filter, parent.key)

        if (result) {
          setChecked((prev) => {
            return [...prev, parent.key]
          })
        }
      }
    })
    if (Object.keys(filter).length === 0) {
      setExpandedKeys([])
    }
  }, [filter])

  useEffect(() => {
    if (searchValue !== '') {
      let arrKeys: any = []
      filteredData.map((item) => {
        return arrKeys.push(item.key)
      })
      setExpandedKeys(arrKeys)
    } else {
      setExpandedKeys([])
    }
  }, [searchValue])

  const renderNodeTitle = (node: any) => {
    const disabled = !checked.includes(node.key)
    const selected = excludedFilters.includes(node.key)
    const showButton =
      (selected && !disabled) ||
      (hoveredKey === node.key && node.key.split('.').length > 2 && !node.key.endsWith('select'))
    const ExcludeIcon = disabled || selected ? PlusCircleFilled : PlusCircleOutlined

    return (
      <div
        className={styles.title}
        onMouseEnter={() => handleNodeMouseEnter(node.key)}
        onMouseLeave={handleNodeMouseLeave}>
        <span className='treeNodeTitle'>{node?.title}</span>
        {showButton && (
          <Tooltip title='Exclude'>
            <ExcludeIcon
              onClick={(e) => handleExclude(e, node.key)}
              className={disabled ? styles.disabled : styles.exclude}
              rotate={45}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  return (
    <div className={`${className} py-3 flex flex-column`}>
      {isSearchable && <Input className='mb-2' placeholder='Search' onChange={handleSearch} />}
      {filteredData.length === 0 && <p className='text-center'>{groupKeyMap[groupKey]}</p>}
      <Tree
        selectable={false}
        blockNode
        checkable
        onCheck={onCheck}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onExpand={handleExpand}
        checkedKeys={checked}
        treeData={filteredData}
        titleRender={renderNodeTitle}
      />
    </div>
  )
}

export default OptionsTree

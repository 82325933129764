import { useCallback, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { useSearchParams } from 'react-router-dom'

export const useActiveFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setActiveFilters = useCallback(
    (filters: any) => {
      if (isEqual(filters, {})) return setSearchParams({})
      setSearchParams({ filters: JSON.stringify(filters) })
    },
    [setSearchParams],
  )

  const activeFilters = useMemo(() => JSON.parse(searchParams.get('filters') || '{}'), [searchParams])

  return [activeFilters, setActiveFilters]
}

export const useActiveFiltersNoSearch = () => {
  const [filters, setFilters] = useState<any>({}) // Default to an empty object

  const setActiveFilters = useCallback(
    (f: any) => {
      setFilters(f && !isEqual(f, {}) ? f : {}) // Avoid unnecessary wrapping
    },
    [setFilters],
  )

  const activeFilters = useMemo(() => filters, [filters])

  return [activeFilters, setActiveFilters]
}

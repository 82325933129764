import React from 'react'
import { Button, Modal } from 'antd'
import warning from 'LEGACY/Assets/svg/warning.svg'
import styles from './BuyConvrtErrorModal.module.scss'

interface BuyConvrtErrorModalProps {
  open: boolean
  onClose: () => void
}

const BuyConvrtErrorModal: React.FC<BuyConvrtErrorModalProps> = ({ open, onClose }) => (
  <div className={styles.modal}>
    <Modal
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={onClose}
      width={600}
      bodyStyle={{
        // height: 590,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className={styles.contentStyle}>
        <div className={styles.container}>
          <img src={warning} alt='' />
          <p>Upgrading Plan Request</p>
          <span>Please contact your account owner in order to make this purchase.</span>
          <div className={styles.footer}>
            {/* <Button size={'large'} onClick={onClose}>
              Cancel
            </Button> */}
            <Button size={'large'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={onClose}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
)

export default BuyConvrtErrorModal

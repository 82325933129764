import { useState, useEffect } from 'react'
import { Radio, Popover } from 'antd'
import type { RadioChangeEvent } from 'antd'

import styles from './OnBoardingPageC.module.scss'
import redX from 'LEGACY/Assets/svg/redX.svg'
import greenV from 'LEGACY/Assets/svg/greenV.svg'
import info_icon from 'LEGACY/Assets/svg/info_icon.svg'

// import { useAuth } from 'common/hooks/useAuth.hooks'

interface OnBoardingPageCProps {
  formData: any
  handleSelectRadio?: any
  handleVerificationCompletion: any
  isDisabledRadio?: boolean
  outreachSuite?: any
}

const OnBoardingPageC = ({
  formData,
  handleVerificationCompletion,
  handleSelectRadio,
  isDisabledRadio,
  outreachSuite,
}: OnBoardingPageCProps) => {
  const [value, setValue] = useState(1)
  const [freeOutreachAccount, setFreeOutreachAccount] = useState(0)

  // const { userDetails } = useAuth()

  const [isDisabled] = useState(isDisabledRadio)

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    handleSelectRadio(value)
  }, [value])

  useEffect(() => {
    const user = localStorage.getItem('user') || '{}'
    const userData = JSON.parse(user)

    const free_outreach =
      userData.addons?.free_outreach_account !== null && userData.addons?.free_outreach_account !== undefined
        ? userData.addons?.free_outreach_account
        : 0
    setFreeOutreachAccount(free_outreach)
  }, [])

  const optionsList = [
    { caption: 'Bulk Messaging', isExists: true },
    { caption: 'Fully AI Managed', isExists: true },
    { caption: 'US Number', isExists: true },
    { caption: 'Block Protection', isExists: true },
  ]

  const optionsListFree = [
    { caption: 'Bulk messaging', isExists: true },
    { caption: 'High block/delete risk', isExists: false },
    { caption: 'No replies tracking', isExists: false },
    { caption: 'Risk for personal account', isExists: false },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.centeredContent}>
        <p>{'Choose Outreach Method'}</p>
        <section>{`Choose a dedicated account (a customized account for your sales goals) or use your own Telegram account`}</section>
        <div className={styles.cardParent}>
          <Radio.Group onChange={onChange} value={value}>
            <div
              className={value === 2 ? styles.card : styles.card_select}
              onClick={(e: any) => {
                setValue(1)
              }}>
              <Radio value={1} className={styles.radio}></Radio>
              <p className={styles.caption}>{'Warmed Managed Account'}</p>
              <div className={styles.recommended}>{'Recommended'}</div>
              {optionsList.map((item, index) => {
                return (
                  <div key={index}>
                    <img src={item.isExists ? greenV : redX} alt={''} />
                    <div className={styles.item_caption}>{item.caption}</div>
                  </div>
                )
              })}

              {freeOutreachAccount !== 0 && (
                <div className={styles.free_account}>{`You have ${freeOutreachAccount} free account included`}</div>
              )}

              <span className={styles.priceParent}>
                <p className={styles.fullPrice}>{'$49.9/m'}</p>
                <p className={styles.discount}>{freeOutreachAccount !== 0 ? '100% off' : '61% off'}</p>
              </span>

              <p className={styles.price}>
                {freeOutreachAccount !== 0 ? '$0/m' : outreachSuite % 5 === 0 ? `$19.9/m + $99/m ` : '$19.9/m'}
              </p>
              {freeOutreachAccount === 0 && outreachSuite % 5 === 0 && (
                <Popover
                  className={styles.outreachSuitePopover}
                  placement='top'
                  trigger='hover'
                  content='Every 5 Warmed TG accounts require an Outreach Suite'>
                  <img src={info_icon} alt={''} />
                </Popover>
              )}
            </div>
            <div
              className={value === 1 ? styles.card : styles.card_select}
              onClick={(e: any) => {
                !isDisabled && setValue(2)
              }}>
              <Radio value={2} disabled={isDisabled}></Radio>
              <p className={styles.caption}>{'My Own Account'}</p>
              <p className={styles.available}>{'Only available for specific regions'}</p>
              {optionsListFree.map((item, index) => {
                return (
                  <div key={index}>
                    <img src={item.isExists ? greenV : redX} alt={''} />
                    <div className={styles.item_caption}>{item.caption}</div>
                  </div>
                )
              })}
              <p className={styles.price}>{'Free'}</p>
            </div>
          </Radio.Group>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingPageC

import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const OutreachApi: any = createApi({
  reducerPath: 'OutreachApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    /* ---------- Exposed Contacts ------- */
    downloadCSV: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/downloadCSV`,
        method: 'POST',
        body: { fileName: params.fileName },
      }),
    }),
    getAutoCheckReplies: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/outreach/autoCheckReplies`,
        method: 'POST',
        body: {},
      }),
    }),
    /* ---------- Exposed Contacts ------- */

    getCampaigns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaigns`,
        method: 'POST',
        body: {
          archive: params?.is_archived === 'archive',
          campaign_name: params?.campaign_name,
          senders: params?.senders,
          platform: params?.platform,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          dateType: params?.dateType,
        },
      }),
    }),
    getCampaignById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaignById`,
        method: 'GET',
        params: { campaign_id: params.campaign_id },
      }),
    }),
    getSender: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSender`,
        method: 'GET',
      }),
    }),
    getCampaignHeader: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaignHeader`,
        method: 'POST',
        body: {
          archive: params?.is_archived === 'archive',
          campaign_name: params?.campaign_name,
          senders: params?.senders,
          platform: params?.platform,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          dateType: params?.dateType,
        },
      }),
    }),
    getCampaignsRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getRecipients`,
        method: 'GET',
        params: { is_archived: false },
      }),
    }),
    getCampaignsRecipientsById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getRecipientsByCampaign`,
        method: 'GET',
        params: { campaign_id: params.campaign_id },
      }),
    }),
    getCampaignsMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getMessages`,
        method: 'GET',
        params: { is_archived: false },
      }),
    }),
    setCampaignsActive: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setActive`,
        method: 'POST',
        body: { campaign_ids: params.campaign_ids, is_active: params.is_active, status: params.status },
      }),
    }),
    deleteCampaignRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/deleteRecipients`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          recipient_ids: params.recipient_ids,
        },
      }),
    }),
    updateRecipientOutcome: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/updateRecipientOutcome`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          outcome: params.outcome,
          recipient_id: params.recipient_id,
        },
      }),
    }),
    setCampaignsSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setSettings`,
        method: 'POST',
        body: {
          start_date: params.start_date,
          schedule_id: params.schedule_id,
          campaign_name: params.campaign_name,
          campaign_id: params.campaign_id,
          timezone: params.timezone,
          send_after_cooldown: params.cooldownSwitch,
        },
      }),
    }),
    getCampaignsSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSettings`,
        method: 'GET',
        params: {
          campaign_id: params.campaign_id,
        },
      }),
    }),
    getCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSchedule`,
        method: 'GET',
      }),
    }),
    setCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setSchedule`,
        method: 'POST',
        body: {
          schedule: params.schedule,
          schedule_type: params.schedule_type,
          // schedule_id: params.schedule_id,
          schedule_label: params.schedule_label,
        },
      }),
    }),
    updateCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/updateSchedule`,
        method: 'POST',
        body: {
          schedule: params.schedule,
          schedule_type: params.schedule_type,
          schedule_id: params.schedule_id,
          schedule_label: params.schedule_label,
        },
      }),
    }),
    archiveCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/addToArchive`,
        method: 'POST',
        body: {
          campaign_ids: params.campaign_ids,
          archive: params.archive,
        },
      }),
    }),
    unarchiveCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/unarchiveCampaign`,
        method: 'POST',
        body: {
          campaign_ids: params.campaign_ids,
          archive: params.archive,
        },
      }),
    }),
    getCooldowns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCooldowns`,
        method: 'GET',
      }),
    }),
    getUserDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/user/userDetails`,
        method: 'GET',
      }),
    }),
    /* ---------- AI ------- */
    generateWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/generate`,
        method: 'POST',
        body: {
          audience: params.audience,
          pain_points: params.pain_points,
          solution: params.solution,
          hierarchy: params.hierarchy,
        },
      }),
    }),
    getAllLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getAllLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    refineList: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/refineList`,
        method: 'POST',
        body: params,
      }),
    }),
    setRecipientsFromCsvAi: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setRecipientsFromCsvAI`,
        method: 'POST',
        body: params,
      }),
    }),
    getCampaignSummary: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaignSummary`,
        method: 'POST',
        body: params,
      }),
    }),

    exposePlatform: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/exposePlatform`,
        method: 'POST',
        body: params,
      }),
    }),
    exposePlatformProspect: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/exposePlatformProspect`,
        method: 'POST',
        body: params,
      }),
    }),
    saveCampaignLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveCampaignLeads`,
        method: 'POST',
        body: {
          leads: params.leads,
          campaign_id: params.campaign_id,
          mode: params.mode,
        },
      }),
    }),
    getTempLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getTempLeads`,
        method: 'GET',
      }),
    }),
    exposeCompanyPlatform: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/exposeCompanyPlatform`,
        method: 'POST',
        body: params,
      }),
    }),
    updateDefaultColumns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateDefaultColumns`,
        method: 'POST',
        body: params,
      }),
    }),
    exposeTempLeadPlatform: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/exposeTempLeadPlatform`,
        method: 'POST',
        body: params,
      }),
    }),
    createSampleCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/createSampleCampaign`,
        method: 'POST',
        body: params,
      }),
    }),
    saveCampaignsDefualtColumns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveCampaignsDefualtColumns`,
        method: 'POST',
        body: params,
      }),
    }),
    getCustomMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCustomMessages`,
        method: 'GET',
      }),
    }),
    insertCustomMessage: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/insertCustomMessage`,
        method: 'POST',
        body: params,
      }),
    }),
    updateCustomMessage: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/updateCustomMessage`,
        method: 'POST',
        body: params,
      }),
    }),
    deleteCustomMessage: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/deleteCustomMessage`,
        method: 'POST',
        body: params,
      }),
    }),
    getDynamicCampaignsColumns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getDynamicCampaignsColumns`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyGetCustomMessagesQuery,
  useLazyInsertCustomMessageQuery,
  useLazyUpdateCustomMessageQuery,
  useLazyDeleteCustomMessageQuery,
  useLazyExposeTempLeadPlatformQuery,
  useLazyGetAutoCheckRepliesQuery,
  useLazyGetCampaignsQuery,
  useLazyGetSenderQuery,
  useLazyGetCampaignHeaderQuery,
  useLazyGetCampaignsRecipientsQuery,
  useLazyUpdateRecipientOutcomeQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazyGetCampaignsMessagesQuery,
  useLazySetCampaignsActiveQuery,
  useLazyUpdateDefaultColumnsQuery,
  useLazyGetCampaignScheduleQuery,
  useLazySetCampaignScheduleQuery,
  useLazyUpdateCampaignScheduleQuery,
  useLazyArchiveCampaignQuery,
  useLazyUnarchiveCampaignQuery,
  useLazyGetCooldownsQuery,
  useLazyGetUserDetailsQuery,
  useLazyDownloadCSVQuery,
  useLazyGetAllLeadsQuery,
  useLazyRefineListQuery,
  useLazySetRecipientsFromCsvAiQuery,
  useLazyGetCampaignSummaryQuery,
  useLazyExposePlatformQuery,
  useLazyExposePlatformProspectQuery,
  useLazySaveCampaignLeadsQuery,
  useLazyExposeCompanyPlatformQuery,
  useLazyCreateSampleCampaignQuery,
  useLazySaveCampaignsDefualtColumnsQuery,
  useLazyGetDynamicCampaignsColumnsQuery,
} = OutreachApi

import React, { useEffect, useState } from 'react'
import { Result, Button } from 'antd'
// import Success from '../../components/SuccessAnimation/Success'

import styles from './ThankYouPage.module.scss'
import LoadingBox from '../../../General/LoadingBox/LoadingBox'
import AssignSeatsModal from './AssignSeatsModal'
import {
  useLazyGetAssignedPointsQuery,
  useLazyGetAssignedSeatsQuery,
  useLazyGetUnassignedPointsQuery,
  useLazyGetUnassignedSeatsQuery,
} from 'LEGACY/API/SettingsApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useNavigate } from 'react-router-dom'
const videoSrc = require('LEGACY/Assets/mp4/thank_you_2.mp4') as any

interface ThankYouPageProps {
  loadingPage: any
  errorUpgradingUser: any
  invalidSession?: any
}
const ThankYouPage: React.FC<ThankYouPageProps> = ({ loadingPage, errorUpgradingUser, invalidSession }) => {
  const [assignSeatsModal, setOpenAssignSeatsModal] = useState(false)
  const { updateUserDetails } = useAuth()
  const navigate = useNavigate()
  const [refetchGetUnassignedSeats, { data: seatsToAssign }] = useLazyGetUnassignedSeatsQuery()
  const [refetchGetAssignedSeats, { data: assignedSeats }] = useLazyGetAssignedSeatsQuery()
  const [refetchGetUnassignedPoints, { data: pointsToAssign }] = useLazyGetUnassignedPointsQuery()
  const [refetchGetAssignedPoints, { data: assignedPoints }] = useLazyGetAssignedPointsQuery()

  const handleStartExploring = () => {
    navigate('/companies')
    window.location.reload()
  }

  useEffect(() => {
    const getSeats = async () => {
      await refetchGetUnassignedSeats()
      await refetchGetAssignedSeats()
      await refetchGetUnassignedPoints()
      await refetchGetAssignedPoints()
    }
    getSeats()
    updateUserDetails()
  }, [])

  return (
    <div className={styles.thankYouContainer}>
      {loadingPage ? (
        <div className={styles.upgrade}>
          <LoadingBox />
        </div>
      ) : (
        <div>
          {errorUpgradingUser || invalidSession ? (
            invalidSession ? (
              <Result
                status={500}
                title='Sorry, this session is invalid.'
                subTitle='Please contact us for more details.'
                extra={[
                  <Button
                    type='primary'
                    onClick={() => {
                      window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
                    }}>
                    Contact Us
                  </Button>,
                ]}
              />
            ) : (
              <Result
                status={500}
                title='Sorry, something went wrong while upgrading your account.'
                subTitle='Please contact us to fix your account.'
                extra={[
                  <Button
                    type='primary'
                    onClick={() => {
                      window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
                    }}>
                    Contact Us
                  </Button>,
                ]}
              />
            )
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <video src={videoSrc} autoPlay loop muted style={{ width: '500px' }} />
              <h2>Thank you for subscribing to Convrt!</h2>
              <div style={{ paddingBottom: '20px' }}>Congratulations on unlocking unlimited possibilities.</div>
              <div>
                <Button
                  type='primary'
                  key='continue'
                  // href='/companies'
                  style={{ borderRadius: '10px', paddingTop: '10px', paddingBottom: '30px', width: '180px' }}
                  onClick={handleStartExploring}>
                  Start Exploring Now!
                </Button>
                {(seatsToAssign?.length > 0 || pointsToAssign?.length > 0) && (
                  <Button
                    type='primary'
                    key='continue'
                    // href='/companies'
                    style={{
                      borderRadius: '10px',
                      paddingTop: '10px',
                      paddingBottom: '30px',
                      marginLeft: '10px',
                      width: '180px',
                    }}
                    onClick={() => {
                      setOpenAssignSeatsModal(true)
                    }}>
                    Assign Seats & Points
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {assignSeatsModal && (
        <AssignSeatsModal
          open={assignSeatsModal}
          onClose={() => {
            setOpenAssignSeatsModal(false)
          }}
          seatsToAssign={seatsToAssign}
          assignedSeats={assignedSeats}
          pointsToAssign={pointsToAssign?.data}
          assignedPoints={assignedPoints}
          allEmails={pointsToAssign?.allEmails}
        />
      )}
    </div>
  )
}

export default ThankYouPage

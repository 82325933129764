import { Dropdown, MenuProps } from 'antd'
import { getApproximateSocialNumbers } from 'LEGACY/Research/StartScreen/utils/getApproximateAmount'

import styles from './SocialDropdown.module.scss'

const SocialDropdown = ({ addresses, icon, count }: any) => {
  const items: MenuProps['items'] = addresses?.map((address: any, index: number) => ({
    key: index,
    label: (
      <a target='_blank' rel='noreferrer' href={address}>
        {`@${address.split('/').pop()}`}
      </a>
    ),
  }))

  return (
    <Dropdown menu={{ items }} disabled={addresses.length <= 1}>
      <div className={styles.item}>
        <a target='_blank' rel='noreferrer' href={addresses[0]}>
          <img src={icon} alt={''} style={count ? {} : { marginRight: '12px' }} />
          {count && <span>{getApproximateSocialNumbers(count)}</span>}
        </a>
      </div>
    </Dropdown>
  )
}

export default SocialDropdown

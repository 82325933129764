import { Button, Drawer, Input, message, Select, Spin, Typography } from 'antd'
import { useLazyAddNewListQuery } from 'LEGACY/API/CompaniesApi'
import React from 'react'
import styles from './AddListSider.module.scss'
import { injectionRegex } from 'LEGACY/common/static-data/userData'
import { MESSAGE_ERROR } from 'LEGACY/common/constants/messages.constants'
import OutOfCreditsModal from 'LEGACY/General/OutOfCredits/OutOfCreditsModal'
import { LoadingOutlined } from '@ant-design/icons'
import { useIntercom } from 'react-use-intercom'
import { useLocalStorage } from 'usehooks-ts'
import { post } from 'LEGACY/API/axios'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { SET_CURRENT_CUSTOM_LIST } from 'LEGACY/MyContacts/Outreach/state/importContactSlice'

export const AddListSider = ({ open, onClose, list, refetchLists, onAddSuccess }: any) => {
  const [listName, setListName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [outOfCredits, setOutOfCredits] = React.useState(false)
  const [listType, setListType] = React.useState(1)
  const [user] = useLocalStorage<any>('user', null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { boot, showNewMessage } = useIntercom()

  const handleMessageIntercom = () => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage(`Hi! I want to upload a lead list and enrich the contacts!`)
  }

  const [refetchAddList] = useLazyAddNewListQuery()

  const handleAddList = async () => {
    try {
      setLoading(true)

      if (injectionRegex.test(listName)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = list?.find((item: any) => item.name === listName)
        if (!checkItem) {
          if (listType === 2) {
            return setLoading(false)
          } else {
            let result = await refetchAddList({ name: listName, type: 1 })
            const data = await refetchLists()
            if (result?.error?.status === 470) {
              setOutOfCredits(true)
            } else {
              if (result) {
                if (window.location.pathname === '/importTelegramContacts') {
                  const newList = data?.data?.find((item: any) => item?.name === listName)
                  localStorage.setItem('current_list', JSON.stringify(newList))
                  dispatch(SET_CURRENT_CUSTOM_LIST(newList))
                  navigate('/exposedContacts')
                }
                onAddSuccess(data?.data)
                setLoading(false)
                setListName('')
              }
            }
          }
        } else {
          setLoading(false)
          return message.error(`You already have a list with the same name!`)
        }
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  const renderTitle = () => {
    return (
      <div className={styles.sider__header}>
        <div className={styles.sider__header__left}>
          <Typography>Add new list</Typography>
          {loading && (
            <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          )}
        </div>
        <Button type='primary' disabled={!listName || loading || listType === 2} onClick={handleAddList}>
          Add
        </Button>
      </div>
    )
  }

  const handleChangeListType = (value: any) => {
    setListType(value)
    if (value === 2) {
      handleMessageIntercom()
      // Mixpanel
      post('/log', {
        action: `My Contacts Page: Contacts List Requested`,
      })
    }
  }

  const handleClose = () => {
    setListType(1)
    setListName('')
    onClose()
  }

  return (
    <Drawer title={renderTitle()} open={open} closable onClose={handleClose} className={'add-list-sider'}>
      <div className={styles.sider__body}>
        <div>
          <Typography>Type</Typography>
          <Select
            placeholder='Type'
            style={{ width: '100%' }}
            value={listType}
            onChange={handleChangeListType}
            size='large'
            options={[
              { value: 1, label: 'Companies List' },
              { value: 2, label: 'Contacts List (Request)' },
            ]}
          />
        </div>

        <div>
          <Typography>List name</Typography>
          <Input
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            style={{ padding: '6px 16px' }}
            disabled={listType === 2}
          />
        </div>
      </div>

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
          type='limit'
        />
      )}
    </Drawer>
  )
}

import React, { useState } from 'react'
import { AutoSizer, Grid } from 'react-virtualized'
import GroupCard from './GroupCard'
import styles from './MainExplore.module.scss'
import arrowRight from 'common/assets/svg/right_purple_arrow.svg'
import NotSeeing from './Components/NotSeeing'
import { useUser } from 'Utils/UserContext'
interface MainExploreProps {
  groupsData: any
  setSelectedGroup: any
  setSelectedGroupName: any
  myGroupsTab: any
  searchValue: any
  pointsStore: any
  onLinkedinCollect: any
  importData: any
  icpData: any
  handleFacebookShouldJoin: any
  setImportData: any
  goToProgress: (type?: any) => void
  goToMyHub: any
  setFilterObject: any
  onCollectLeads: () => void
  isSmallScreen: boolean
}

const filterTopThreeGroups = (groups: any[], isFacebook: boolean, isInstagram: boolean, isLinkedin: boolean): any[] => {
  // Filter groups based on user platforms
  const userPlatforms: string[] = []
  if (isFacebook) userPlatforms.push('facebook')
  if (isInstagram) userPlatforms.push('instagram')
  if (isLinkedin) userPlatforms.push('linkedin')

  const filteredGroups = groups.filter((group) => userPlatforms.length <= 3 || userPlatforms.includes(group.platform))

  // Group by platform and sort by score
  const groupedByPlatform = userPlatforms.map((platform) => {
    const groupsForPlatform = filteredGroups.filter((group) => group.platform === platform)
    return groupsForPlatform.sort((a, b) => b.score - a.score)
  })

  // Combine to ensure at least one group per platform
  const selectedGroups: any[] = []
  groupedByPlatform.forEach((groups) => {
    if (groups.length) {
      selectedGroups.push(groups[0])
    }
  })

  // Fill remaining slots with highest score groups
  const remainingGroups = filteredGroups
    .filter((group) => !selectedGroups.includes(group))
    .sort((a, b) => b.score - a.score)

  while (selectedGroups.length < 3 && remainingGroups.length) {
    selectedGroups.push(remainingGroups.shift())
  }

  return selectedGroups.slice(0, 3)
}

interface GroupData {
  name?: string
  username?: string
  title?: string
  platform?: string
  suggested?: boolean
  mine?: boolean
  event?: boolean
  score?: number
  is_web3Group: boolean
  is_web3Event: boolean
}

interface GroupSectionProps {
  title: string
  groups: GroupData[]
  showMore: boolean
  onSeeMore: (filterName: string) => void
  commonProps: any
}

const GroupSection: React.FC<GroupSectionProps> = ({ title, groups, showMore, onSeeMore, commonProps }) => {
  if (!groups?.length) return null

  const columnCount = 3
  const sectionHeight = (showMore ? Math.ceil(groups.length / columnCount) : 1) * 270

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
      }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 50,
          padding: 15,
        }}>
        <span className={styles.mainExploreTitle}>{title}</span>
        <span className={styles.mainExploreSeeMore} onClick={() => onSeeMore(title)}>
          <span>See More</span>
          <img src={arrowRight} alt='Arrow Right' />
        </span>
      </div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Grid
            columnCount={columnCount}
            columnWidth={Math.max(width / columnCount, 250)}
            rowCount={showMore ? Math.ceil(groups.length / columnCount) : 1}
            rowHeight={263}
            height={sectionHeight}
            width={width}
            cellRenderer={({ columnIndex, key, rowIndex, style }) => {
              const index = rowIndex * columnCount + columnIndex
              if (index >= groups.length || (!showMore && index >= 3)) return null

              const group = groups[index]
              return (
                <div key={key} style={style}>
                  <div style={{ padding: '2px 10px 0px 10px', flex: '1' }}>
                    <GroupCard key={group?.name || group?.username || group?.title} group={group} {...commonProps} />
                  </div>
                </div>
              )
            }}
          />
        )}
      </AutoSizer>
    </div>
  )
}

export default function MainExplore({
  groupsData,
  setSelectedGroup,
  setSelectedGroupName,
  myGroupsTab,
  searchValue,
  pointsStore,
  onLinkedinCollect,
  importData,
  icpData,
  handleFacebookShouldJoin,
  setImportData,
  goToProgress,
  setFilterObject,
  onCollectLeads,
  isSmallScreen,
  goToMyHub,
}: MainExploreProps) {
  const { user } = useUser()
  const isWeb3 = user?.user_type === 'web3'
  const isFacebook = user?.facebook?.length > 0
  const isInstagram = user?.instagram?.length > 0
  const isLinkedin = user?.linkedin?.length > 0
  const [suggestedShowMore] = useState(false)
  const [accountsShowMore] = useState(false)
  const [eventsShowMore] = useState(false)
  const [web3ShowMore] = useState(false)

  const suggestedGroups = groupsData?.filter((group: GroupData) => group?.suggested === true)
  const myAccounts = isWeb3 ? null : groupsData?.filter((group: GroupData) => group?.mine === true)

  const events = isWeb3 ? groupsData?.filter((group: GroupData) => group?.is_web3Event === true) : null // TO DO
  const web3Groups = isWeb3
    ? groupsData
        ?.filter((group: GroupData) => group?.is_web3Group)
        ?.sort((a: any, b: any) => +b?.number_of_members - +a?.number_of_members)
    : null

  const threeSuggested = filterTopThreeGroups(suggestedGroups, isFacebook, isInstagram, isLinkedin)

  const handleSeeMore = (filterName: string, type: string = 'a') => {
    setFilterObject((prev: any) => ({
      ...prev,
      [type]: filterName,
    }))
  }

  const commonProps = {
    onSelect: setSelectedGroup,
    setSelectedGroupName,
    myGroupsTab,
    refinement: searchValue,
    pointsStore,
    onLinkedinCollect,
    importData,
    icpData,
    handleFacebookShouldJoin,
    setImportData,
    goToProgress,
    goToMyHub,
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '80%',
        overflowY: 'auto',
      }}>
      <GroupSection
        title='Suggested By Convrt'
        groups={threeSuggested}
        showMore={suggestedShowMore}
        onSeeMore={() => handleSeeMore('Suggested')}
        commonProps={commonProps}
      />

      {!isWeb3 && (
        <GroupSection
          title='From My Accounts'
          groups={myAccounts}
          showMore={accountsShowMore}
          onSeeMore={handleSeeMore}
          commonProps={commonProps}
        />
      )}

      {isWeb3 && (
        <>
          <GroupSection
            title='Conferences'
            groups={events}
            showMore={eventsShowMore}
            onSeeMore={() => handleSeeMore('Conferences', 'b')}
            commonProps={commonProps}
          />
          <GroupSection
            title='Groups'
            groups={web3Groups}
            showMore={web3ShowMore}
            onSeeMore={() => handleSeeMore('Groups', 'b')}
            commonProps={commonProps}
          />
        </>
      )}

      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <NotSeeing
            key='notseeing'
            onClick={onCollectLeads}
            style={{
              top: -10,
              position: 'relative',
              width: '100%',
              marginBottom: isSmallScreen ? 110 : 0,
              marginTop: 50,
            }}
            isSmallScreen={isSmallScreen}
          />
        </div>
      </div>
    </div>
  )
}

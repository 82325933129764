import { useEffect, useState } from 'react'

import { Button, Input, Space } from 'antd'

import styles from './ExposedContacts.module.scss'
import { useAppDispatch, useAppSelector } from 'state'

import { SideBar } from 'LEGACY/MyContacts/components/Sidebar'
import { SET_LISTS } from 'LEGACY/MyContacts/state/slice/companiesSlice'
import { SET_LISTS as SET_LISTS_GLOBAL } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'

import { useLazyGetListsQuery } from 'LEGACY/API/CompaniesApi'
import { SET_CURRENT_CUSTOM_LIST } from './state/importContactSlice'
import { getResultFromImport, initTableParam } from './outreach.helper'
import { useGetAllMatchedLeadsIdsQuery, useGetMatchedLeadsQuery } from '../../API/OutreachApi'
import { SelectComponent } from 'LEGACY/common/components/SelectComponent'
import { AddListSider } from './components/AddListSider'
import MatchedLeadsTable from './components/MatchedLeadsTable'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useNavigate } from 'react-router-dom'

const MatchedLeads = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userDetails } = useAuth()
  const { currentCustomList: currentList } = useAppSelector((state) => state.importContact)

  const { lists } = useAppSelector((state) => state.myContactsCompanies)
  const setList = (data: any) => {
    dispatch(SET_LISTS(data))
    dispatch(SET_LISTS_GLOBAL(data))
  }
  const setCurrentList = (data: any) => dispatch(SET_CURRENT_CUSTOM_LIST(data))
  const [search, setSearch] = useState('')
  const [sortOption, setSortOption] = useState('none')

  const [currentDefaultList, setCurrentDefaultList] = useState('matched_leads')
  const [openSiderAddList, setOpenSiderAddList] = useState(false)

  const [tableParamsMatchedLeads, setTableParamsMatchedLeads] = useState<any>({
    ...initTableParam,
    search: '',
    order_by: '',
  })

  const [refetchLists] = useLazyGetListsQuery()

  const { data: matchedLeads, isFetching } = useGetMatchedLeadsQuery(tableParamsMatchedLeads, {
    refetchOnMountOrArgChange: true,
  })

  const [isSelectAll, setIsSelectAll] = useState(false)
  const { data: allMatchedLeadsIds, isFetchingAllIds } = useGetAllMatchedLeadsIdsQuery(tableParamsMatchedLeads, {
    refetchOnMountOrArgChange: true,
    skip: !isSelectAll,
  })

  const fetchList = async () => {
    try {
      const res = await refetchLists()
      if (res) {
        setList(res.data)

        const resultImport = getResultFromImport()
        if (resultImport) {
          const { listId } = resultImport
          const newList = res.data?.find((item: any) => item?.id === listId)

          if (newList) setCurrentList(newList)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeCustomList = (list: any) => {
    localStorage.setItem('current_list', JSON.stringify(list))
    navigate('/exposedContacts')
    setCurrentList(list)
    setCurrentDefaultList('')
  }

  const handleAddNewListSuccess = (lists: any) => {
    setOpenSiderAddList(false)
    setCurrentDefaultList('')
    setCurrentList(lists?.[0])
    setList(lists)

    localStorage.setItem('recentAddedList', JSON.stringify(lists?.[0]))
  }

  const handleSearch = (value: string) => {
    setSearch(value)
    setTableParamsMatchedLeads({
      ...tableParamsMatchedLeads,
      ...initTableParam,
      search: value,
    })
  }

  const handleOrderBy = (value: any) => {
    setSortOption(value)
    setTableParamsMatchedLeads({
      ...tableParamsMatchedLeads,
      order_by: value,
    })
  }

  useEffect(() => {
    fetchList()
  }, [])

  useEffect(() => {
    if (!isFetchingAllIds) {
      setIsSelectAll(false)
    }
  }, [isFetchingAllIds])

  return (
    <div className={`${styles['myContact']} myContact`}>
      <SideBar
        setIndexDefault={() => {}}
        listContact={lists}
        currentDefaultList={currentDefaultList}
        currentList={currentList}
        onCreateList={() => setOpenSiderAddList(true)}
        onChangeCurrentDetailList={(value) => {
          setCurrentDefaultList(value)
          setCurrentList(null)
        }}
        onChangeCustomList={handleChangeCustomList}
        refetchLists={refetchLists}
        onChangeNewList={setList}
      />

      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        <div className={styles['myContact__top']}>
          <div className={styles['myContact__top__left']}>
            {currentList ? currentList.name : 'Import Telegram Contacts'}
          </div>

          <div className={styles['myContact__top__right']}>
            <Button type='primary' onClick={() => setOpenSiderAddList(true)}>
              Add New List
            </Button>
          </div>
        </div>
        <>
          <div className={styles['myContact__header']}>
            <div className={styles['myContact__header__left']}>
              <div className={styles['myContact__header__left__text']}>
                Displaying <Space />
                <span>
                  All of <Space />
                  {isFetching ? 0 : matchedLeads?.totalNumber || 0}
                </span>
              </div>
            </div>

            <div className={styles['myContact__header__right']}>
              {/* <div className={styles['myContact__header__right__filter']}>
              <img src={filterIcon} alt='filter icon' />
              <Typography>Filters</Typography>
            </div> */}
              <Input
                placeholder='Search Contact or Company'
                style={{ height: '34px', width: '268px' }}
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />

              <SelectComponent
                prefixText='Sort By'
                defaultValue='none'
                width='200px'
                height='32px'
                options={[
                  { label: 'None', value: 'none' },
                  { label: 'Alphabetical (A to Z)', value: 'name:ASC' },
                  { label: 'Alphabetical (Z to A)', value: 'name:DESC' },
                  { label: 'Newest first', value: 'time:DESC' },
                  { label: 'Oldest first', value: 'time:ASC' },
                ]}
                value={sortOption}
                onChange={(value: string) => {
                  handleOrderBy(value)
                }}
              />
            </div>
          </div>

          {/* <div className={styles['myContact__metric']}>
          <div className={styles['myContact__metric__item']}>
            <Typography className={styles['myContact__metric__item__label']}>Total Contacts</Typography>
            <Typography className={styles['myContact__metric__item__value']}>{formatTotalContactCount}</Typography>
          </div>
          <div className={styles['myContact__metric__item']}>
            <Typography className={styles['myContact__metric__item__label']}>Total Companies</Typography>
            <Typography className={styles['myContact__metric__item__value']}>{formatedTotalCompanyCount}</Typography>
          </div>
          <div className={styles['myContact__metric__item']}>
            <Typography className={styles['myContact__metric__item__label']}>Contacted Contacts</Typography>
            <Typography className={styles['myContact__metric__item__value']}>
              {contactListFormat && !isLoadingContacts ? connectedContactCount : <Skeleton.Input size='small' active />}
            </Typography>
          </div>
        </div> */}
          <div className={styles['myContact__table']}>
            <MatchedLeadsTable
              contacts={
                isFetching || isFetchingAllIds
                  ? {}
                  : {
                      ...matchedLeads,
                      rows:
                        matchedLeads &&
                        matchedLeads.rows.map((item: any) => ({
                          ...item,
                          ...item.convrt_contact,
                          ...item.item,
                          contactsTracking: userDetails?.contacts_tracking,
                        })),
                    }
              }
              allIdContacts={allMatchedLeadsIds}
              setIsSelectAll={setIsSelectAll}
              contactsError={null}
              isLoading={isFetching}
              currentList={currentList}
              tableParams={tableParamsMatchedLeads}
              handleChangeTableParamsContact={(param: any) => setTableParamsMatchedLeads(param)}
              refetchGetContacts={() => {}}
            />
          </div>
        </>
      </div>

      <AddListSider
        isCompany={true}
        open={openSiderAddList}
        onClose={() => setOpenSiderAddList(false)}
        list={lists}
        onAddSuccess={handleAddNewListSuccess}
        refetchLists={refetchLists}
      />
    </div>
  )
}

export default MatchedLeads

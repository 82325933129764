import React from 'react'
import { Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import _ from 'lodash'

const { Title } = Typography

export const Header: React.FC = () => {
  const location = useLocation()

  // Function to format route name
  const getRouteName = (): string => {
    // Remove leading slash and split by remaining slashes
    const path = location.pathname.slice(1)

    if (path === '') return ''

    return path
      .split('/')
      .pop()!
      .split('-')
      .map((word) => _.startCase(word))
      .join(' ')
  }

  return (
    <header className={styles.header}>
      <Title level={5} className={styles.title}>
        {getRouteName()}
      </Title>
    </header>
  )
}

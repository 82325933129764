import { IFilterBar } from 'LEGACY/common/interfaces/IFilters'
import { filtersKeysMap } from 'LEGACY/common/static-data/filterKeysMap'

import './FilterBar.scss'
import { CustomTag } from 'LEGACY/Research/CustomTag/CustomTag'

interface ITags {
  tag: IFilterBar
  excluded?: boolean
  eventName: string
  handleRemoveFilters: any
}

const Tags = ({ tag, handleRemoveFilters, eventName, excluded }: ITags) => {
  return (
    <>
      {tag.key === 'events' ? (
        <CustomTag className='h-2rem w-min' excluded={excluded} closable onClose={handleRemoveFilters}>
          {filtersKeysMap?.[tag.key as keyof typeof filtersKeysMap]}
          {tag?.number && `(${tag?.number})`}
          {`: ${eventName} `}
        </CustomTag>
      ) : (
        <CustomTag className='h-2rem w-min' excluded={excluded} closable onClose={handleRemoveFilters}>
          {tag.key.indexOf('nameOfCompany') >= 0 || tag.key.indexOf('nameOfContact') >= 0
            ? 'Search Value'
            : filtersKeysMap?.[tag.key as keyof typeof filtersKeysMap]}
          {tag?.number && ` (${tag?.number})`}
          {tag?.range && ` (${tag?.range})`}
          {tag.value && (tag.key.indexOf('companyList') >= 0 || tag.key.indexOf('contactsList') >= 0)
            ? ` ${tag?.value}`
            : tag?.value
            ? `: ${tag?.value} `
            : ''}
        </CustomTag>
      )}
    </>
  )
}

export default Tags

import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import {
  defaultColumnsByAction,
  defaultColumnsByPlatform,
} from 'common/components/GeneralComponents/ModularTable/ModularColumns'

// export const getSenderByPlatform = (selectedPlatforms: any) => {
//   const userData = []
//   const user = localStorage.getItem('user')
//   const userObj = user ? JSON.parse(user) : []

//   for (const platform of Object.keys(selectedPlatforms)) {
//     const selectedPlatform = platform === 'telegram' ? userObj['outreach'] : userObj[platform] // LEGACY
//     if (selectedPlatform && selectedPlatform.length > 0) {
//       const matchedUsers = selectedPlatform.filter((user: any) => {
//         if (platform === 'linkedin') {
//           return selectedPlatforms[platform].includes(user.email)
//         } else if (platform === 'telegram') {
//           return (
//             selectedPlatforms[platform].includes(user.userName) || selectedPlatforms[platform].includes(user.username)
//           )
//         } else {
//           return selectedPlatforms[platform].includes(user.username)
//         }
//       })
//       if (matchedUsers.length > 0) {
//         userData.push(...matchedUsers)
//       }
//     }
//   }
//   return userData
// }

export const getSenderByPlatform = (selectedPlatforms: any) => {
  const userData: any = []
  for (const platform of Object.keys(selectedPlatforms)) {
    const user: any = localStorage.getItem('user')
    const userObj = user ? JSON.parse(user) : []
    const selectedPlatform = userObj[platform]
    const telegramOutreach = userObj?.['outreach']

    if (
      (selectedPlatform && selectedPlatform.length > 0) ||
      (platform === 'telegram' && (telegramOutreach.length > 0 || (selectedPlatform && selectedPlatform.length > 0)))
    ) {
      if (platform === 'linkedin') {
        const matchedUsers = selectedPlatform.filter((user: any) => selectedPlatforms[platform].includes(user.email))
        if (matchedUsers.length > 0) {
          userData.push(...matchedUsers)
        }
      } else if (platform === 'telegram') {
        const matchedUsers = (
          selectedPlatform && Object.keys(selectedPlatform)?.length > 0
            ? [selectedPlatform, ...telegramOutreach]
            : telegramOutreach
        ).filter(
          (user: any) =>
            selectedPlatforms[platform].includes(user.userName) || selectedPlatforms[platform].includes(user.username),
        )
        if (matchedUsers.length > 0) {
          userData.push(...matchedUsers)
        }
      } else {
        const matchedUsers = selectedPlatform.filter((user: any) => selectedPlatforms[platform].includes(user.username))
        if (matchedUsers.length > 0) {
          userData.push(...matchedUsers)
        }
      }
    }
  }
  return userData
}

export const getShouldContainMessages = (type: any, truePlatforms: any) => {
  switch (type) {
    case 'send_message':
      return true
    case 'comment_on_post':
      return true
    case 'connect_lead_with_note':
      if (truePlatforms?.includes('linkedin')) {
        return true
      }
      return false
    default:
      return false
  }
}

export const replaceMessageIds = (nodes: any) => {
  return nodes.map((node: any) => {
    const newNode = _.cloneDeep(node)
    if (newNode.data && newNode.data.message_id && Array.isArray(newNode.data.message_id)) {
      newNode.data.message_id = newNode.data.message_id.map(() => uuidv4())
    }
    return newNode
  })
}

export const mergeTemplateNodesWithStepsNodes = (templateNodes: any, stepsNodes: any) => {
  const newNodes = templateNodes.map((node: any) => {
    const newNode = _.cloneDeep(node)
    const stepNode = stepsNodes.find((stepNode: any) => stepNode.step_id === newNode.id)
    if (stepNode) {
      if (stepNode.data?.messages) {
        newNode.data.messages = stepNode.data.messages || newNode.data.messages
      }
      if (stepNode.data?.sender) {
        newNode.data.sender = stepNode.data.sender || newNode.data.sender
      }
      if (stepNode.data?.amount) {
        newNode.data.amount = stepNode.data.amount || newNode.data.amount
      }
      if (stepNode.data?.unit) {
        newNode.data.unit = stepNode.data.unit || newNode.data.unit
      }
    }
    return newNode
  })

  return newNodes
}

const getDefaultColumnsByPlatform = (recipient: any) => {
  const { platform } = recipient
  if (!platform) return defaultColumnsByPlatform['instagram']
  switch (platform) {
    case 'instagram':
      return defaultColumnsByPlatform['instagram']
    case 'linkedin':
      return defaultColumnsByPlatform['linkedin']
    case 'facebook':
      return defaultColumnsByPlatform['facebook']
    default:
      return defaultColumnsByPlatform['instagram']
  }
}
export const getDefaultColumnsByRecipient = (recipient: any) => {
  const { convrt_source } = recipient
  if (!convrt_source) return defaultColumnsByAction.ai_leads
  switch (convrt_source) {
    case 'lists':
      return defaultColumnsByAction.lists
    case 'social_hub':
      return getDefaultColumnsByPlatform(recipient)
    case 'ai_leads':
      return defaultColumnsByAction.ai_leads
    case 'ai_leads_companies':
      return defaultColumnsByAction.ai_leads_companies
    case 'CSV':
      return defaultColumnsByAction.csv
    default:
      return defaultColumnsByAction.ai_leads
  }
}

export const buildRecipientArray = (items: any[], isTemp?: boolean): any[] => {
  if (!Array.isArray(items)) return []

  return items.map((item) => {
    if (item.convrt_source === 'lists') {
      const retu = {
        accuracy: item.accuracy || '',
        annual_revenue: item.annual_revenue || '',
        biography: item.biography || '',
        businessType: item.businessType || '',
        categories: item.categories || '',
        channels: item.channels || '',
        company: item.company || '',
        company_linkedin: item.company_linkedin || '',
        company_photo: item?.company_photo || '',
        company_website: item.company_website || '',
        description: item.description || '',
        discord: item.discord || '',
        enrich: item.enrich || '',
        facebook: item.facebook || '',
        follower_count: item.follower_count || '',
        followed_username: item.followed_username || item.source || item.source_name || '',
        founded_year: item.founded_year || '',
        full_name: item.full_name || '',
        index: item.index || '',
        industry: item.industry || '',
        industries: item.industries || '',
        is_private: item.is_private || '',
        is_verified: item.is_verified || '',
        instagram: item.instagram || '',
        jobTitle: item.job_title || '',
        key: item.key,
        last_step: item.status,
        lead_photo: item?.lead_photo || '',
        linkedin: item.linkedin || '',
        location: item.location || '',
        mode: item.mode || '',
        name: item.full_name || item.name || '',
        number_of_employees: item.number_of_employees || '',
        physical_location: item.physical_location || '',
        platform: item.platform || '',
        source: item.source || item.source_name || '',
        source_type: item.source_type || '',
        step_type: item.step_type,
        tags: item.tags || item.categories || '',
        technologies: item.technologies || '',
        telegram: item.telegram || '',
        total_funding: item.total_funding || '',
        twitter: item.twitter || '',
        type: item.type || '',
        username: item.username || '',
        isTemp: isTemp,
        display: item.display || false,
        custom_1: item?.custom_1 || '',
        custom_2: item?.custom_2 || '',
        recipient: item.recipient || '', // ADDED
        recipient_id: item.recipient_id || '', // ADDED
        // linkedin
        time_in_role_and_company: item?.time_in_role_and_company || item?.linkedin_sales_nav_time_in_role_and_company,
        category: item.category || '',
        skills: item?.skills,
        search_name: item?.search_name,
        position: item?.position,
        number_of_followers: item?.number_of_followers,
        nav_url: item?.nav_url,
        headline: item?.headline,
        experience: item?.experience,
        connection_level: item?.connection_level,
        about: item?.about,
        search_id: item?.search_id,
        profile_id: item?.profile_id,
        company_pic_url: item?.company_pic_url,
        profile_pic_url: item?.profile_pic_url,
        convrt_source: item?.convrt_source,

        ...item,
      }
      return retu
    }
    return {
      accuracy: item.accuracy || '',
      annual_revenue: item.annual_revenue || '',
      biography: item.biography || '',
      businessType: item.businessType || '',
      categories: item.categories || '',
      channels: item.channels || '',
      company: item.company || '',
      company_linkedin: item.company_linkedin || '',
      company_photo: item?.company_photo || '',
      company_website: item.company_website || '',
      description: item.description || '',
      discord: item.discord || '',
      enrich: item.enrich || '',
      facebook: item.facebook || '',
      follower_count: item.follower_count || '',
      followed_username: item.followed_username || item.source || item.source_name || '',
      founded_year: item.founded_year || '',
      full_name: item.full_name || '',
      index: item.index || '',
      industry: item.industry || '',
      industries: item.industries || '',
      is_private: item.is_private || '',
      is_verified: item.is_verified || '',
      instagram: item.instagram || '',
      jobTitle: item.job_title || '',
      key: item.key,
      last_step: item.status,
      lead_photo: item?.lead_photo || '',
      linkedin: item.linkedin || '',
      location: item.location || '',
      mode: item.mode || '',
      name: item.full_name || item.name || '',
      number_of_employees: item.number_of_employees || '',
      physical_location: item.physical_location || '',
      platform: item.platform || '',
      source: item.source || item.source_name || '',
      source_type: item.source_type || '',
      step_type: item.step_type,
      tags: item.tags || '',
      technologies: item.technologies || '',
      telegram: item.telegram || '',
      total_funding: item.total_funding || '',
      twitter: item.twitter || '',
      type: item.type || '',
      username: item.username || '',
      isTemp: isTemp,
      display: item.display || false,
      custom_1: item?.custom_1 || '',
      custom_2: item?.custom_2 || '',
      recipient: item.recipient || '', // ADDED
      recipient_id: item.recipient_id || '', // ADDED
      // linkedin
      time_in_role_and_company: item?.time_in_role_and_company || item?.linkedin_sales_nav_time_in_role_and_company,
      category: item.category || '',
      skills: item?.skills,
      search_name: item?.search_name,
      position: item?.position,
      number_of_followers: item?.number_of_followers,
      nav_url: item?.nav_url,
      headline: item?.headline,
      experience: item?.experience,
      connection_level: item?.connection_level,
      about: item?.about,
      search_id: item?.search_id,
      profile_id: item?.profile_id,
      company_pic_url: item?.company_pic_url,
      profile_pic_url: item?.profile_pic_url,
      convrt_source: item?.convrt_source,
    }
  })
}

import React from 'react'
import { Layout, Menu } from 'antd'
// import styles from './GroupsSidebar.module.scss'
import type { MenuProps } from 'antd'
import telegram_icon from 'common/assets/svg/tele_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'
import { useUser } from 'Utils/UserContext'

const getLogoByPlatform = (platform: any) => {
  const platformLowerCase = platform.toLocaleLowerCase()
  switch (platformLowerCase) {
    case 'instagram':
      return instagram_icon
    case 'facebook':
      return facebook_icon
    case 'linkedin':
      return linkedin_icon
    case 'twitter':
      return twitter_icon
    case 'discord':
      return discord_icon
    case 'telegram':
      return telegram_icon
    default:
      return ''
  }
}

const { Sider } = Layout

const siderStyle: React.CSSProperties = {
  borderRight: '1px solid #dfe1e6',
  // borderLeft: '1px solid #dfe1e6',
  background: 'white',
}

interface GroupsSidebarProps {
  myGroups?: boolean
  setFilterObject?: any
  filterObject?: any
  setLoadIncrement?: any
  setSelectedType?: any
  setSearchValue?: any
}

const GroupsSidebar: React.FC<GroupsSidebarProps> = ({
  myGroups,
  setFilterObject,
  filterObject,
  setLoadIncrement,
  setSelectedType,
  setSearchValue,
}) => {
  const { user } = useUser()
  const isWeb3 = user?.user_type === 'web3'

  const setSelected = (subList: any, key: any) => {
    setFilterObject((prev: any) => {
      return {
        ...prev,
        [subList]: key,
      }
    })
  }

  const scrollTop = () => {
    const element = document.querySelector('.ReactVirtualized__Grid')
    if (element) {
      element.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    const el = document.querySelector('.groupsContentRowScrollContainer')

    if (el) {
      el.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    const fallback = document.querySelector('.ReactVirtualized__Grid__innerScrollContainer')
    if (fallback) {
      fallback.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  const timeScrollTop = () => {
    setTimeout(() => {
      scrollTop()
    }, 100)
  }

  const toggle = (subList: any, key: any) => {
    timeScrollTop()
    setLoadIncrement(9)
    if (filterObject?.[subList] === key) {
      if (subList === 'a') {
        if (!filterObject['b']) {
          setSelectedType('Result')
        }
      } else {
        setSelectedType('Result')
      }
    } else {
      if (subList === 'a') {
        setSearchValue('')
        if (!filterObject['b']) {
          setSelectedType('Result')
        }
      } else if (subList === 'b') {
        setSelectedType(key.replace(/s$/, ''))
      }
    }
    if (filterObject?.[subList] === key) {
      if (subList === 'a') {
        setSelected(subList, key)
      } else {
        setSelected(subList, '')
      }
    } else {
      if (subList === 'a') {
        setSearchValue('')
      }
      setSelected(subList, key)
    }
  }

  const addOrRemove = (subList: any, key: any) => {
    if (filterObject?.[subList]?.includes(key)) {
      setSelected(
        subList,
        filterObject?.[subList]?.filter((item: any) => item !== key),
      )
    } else {
      setSelected(subList, [...filterObject?.[subList], key])
    }
  }

  const platforms = isWeb3
    ? ['Telegram', 'Facebook', 'Instagram', 'LinkedIn', 'Discord', 'Twitter']
    : ['Facebook', 'Instagram', 'LinkedIn', 'Telegram', 'Discord', 'Twitter']

  const exploreSidebar = {
    a: isWeb3 ? ['Explore All', 'Suggested'] : ['Explore All', 'Suggested', 'From My Accounts'],
    b: isWeb3 ? ['Groups', 'Conferences', 'Searches'] : ['Hashtags', 'Groups', 'Searches', 'Pages'],
  }

  const myHubSidebar = {
    a: ['All', 'On Importing Progress', 'On Tracking', 'Completed'],
    b: isWeb3 ? ['Lists', 'Groups', 'Conferences', 'Searches'] : ['Hashtags', 'Groups', 'Searches', 'Pages'],
  }

  const sidebar = myGroups ? myHubSidebar : exploreSidebar

  // const handleExploreAll = () => {
  //   setSelectedKey(null)
  //   onChannelChange('')
  //   onCategoryChange('')
  //   dispatch(SET_MY_GROUPS_FILTERS(''))
  // }

  // const handlePlatformSelection = (key: string) => {
  //   if (selectedKey?.channel === key) {
  //     setSelectedKey({ ...selectedKey, channel: undefined })
  //     onChannelChange('')
  //   } else {
  //     setSelectedKey({ ...selectedKey, channel: key })
  //     onChannelChange(key)
  //   }
  // }

  // const handleCategorySelection = (key: string) => {
  //   if (selectedKey?.category === key) {
  //     setSelectedKey({ ...selectedKey, category: undefined })
  //     onCategoryChange('')
  //   } else {
  //     setSelectedKey({ ...selectedKey, category: key })
  //     onCategoryChange(key)
  //   }
  // }

  const onClick: MenuProps['onClick'] = (e) => {
    globalThis.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    if (sidebar.a.includes(e.key)) {
      toggle('a', e.key)
    } else if (sidebar.b.includes(e.key)) {
      toggle('b', e.key)
    } else {
      addOrRemove('c', e.key)
    }
  }

  const exploreItems: MenuProps['items'] = ['Options', 'Filter', 'Channels'].map((text, index) => {
    const key = String(index + 1)
    switch (index) {
      case 0:
        return {
          key: `sub${key}`,
          label: <span style={{ fontWeight: 600 }}>{text}</span>,
          children: sidebar.a.map((cat, j) => {
            return {
              key: cat,
              label: <span>{cat}</span>,
            }
          }),
        }
      case 1:
        return {
          key: `sub${key}`,
          label: <span style={{ fontWeight: 600 }}>{text}</span>,
          children: sidebar.b.map((cat, j) => {
            return {
              key: cat,
              label: <span>{cat}</span>,
            }
          }),
        }
      default:
        return {
          key: `sub${key}`,
          label: <span style={{ fontWeight: 600 }}>{text === 'Twitter' ? '(X) Twitter' : text}</span>,

          children: platforms.map((channel, j) => {
            return {
              key: channel.toLowerCase(),
              label: channel === 'Twitter' ? 'X (Twitter)' : channel,
              icon: (
                <img
                  style={{
                    height: '25px',
                    width: '25px',
                    opacity: (isWeb3 ? ['Twitter', 'Discord'] : ['Twitter', 'Discord', 'Telegram']).includes(channel)
                      ? '0.3'
                      : '1',
                  }}
                  src={getLogoByPlatform(channel)}
                  alt=''
                />
              ),
              disabled: (isWeb3 ? ['Twitter', 'Discord'] : ['Twitter', 'Discord', 'Telegram']).includes(channel),
            }
          }),
        }
    }
  })

  const hubItems: MenuProps['items'] = ['Status', 'Filter', 'Channels'].map((text, index) => {
    const key = String(index + 1)
    switch (index) {
      case 0:
        return {
          key: `sub${key}`,
          label: <span style={{ fontWeight: 600 }}>{text}</span>,
          children: sidebar.a.map((cat, j) => {
            return {
              key: cat,
              label: <span>{cat}</span>,
            }
          }),
        }
      case 1:
        return {
          key: `sub${key}`,
          label: <span style={{ fontWeight: 600 }}>{text}</span>,
          children: sidebar.b.map((cat, j) => {
            return {
              key: cat,
              label: <span>{cat}</span>,
            }
          }),
        }
      default:
        return {
          key: `sub${key}`,
          label: <span style={{ fontWeight: 600 }}>{text === 'Twitter' ? '(X) Twitter' : text}</span>,

          children: platforms.map((channel, j) => {
            return {
              key: channel.toLowerCase(),
              label: channel === 'Twitter' ? 'X (Twitter)' : channel,
              icon: (
                <img
                  style={{
                    height: '25px',
                    width: '25px',
                    opacity: (isWeb3 ? ['Twitter', 'Discord'] : ['Twitter', 'Discord', 'Telegram']).includes(channel)
                      ? '0.3'
                      : '1',
                  }}
                  src={getLogoByPlatform(channel)}
                  alt=''
                />
              ),
              disabled: (isWeb3 ? ['Twitter', 'Discord'] : ['Twitter', 'Discord', 'Telegram']).includes(channel),
            }
          }),
        }
    }
  })

  const items = myGroups ? hubItems : exploreItems

  const selectedKeys = [
    filterObject?.a,
    filterObject?.b,
    ...[...filterObject?.c?.map((item: any) => item.toLowerCase())],
  ]

  return (
    <Sider style={siderStyle} width={220}>
      <Menu
        defaultOpenKeys={['sub1', 'sub2', 'sub3']}
        className='groupsSidebar'
        mode='inline'
        style={{ height: '100%', borderRight: 0, maxHeight: '680px', overflowY: 'auto' }}
        items={items}
        onClick={onClick}
        selectedKeys={selectedKeys}
      />
    </Sider>
  )
}

export default GroupsSidebar
